import QueryProfile from "./QueryProfileBrowser.vue";
import { createApp, h } from 'vue';
import VuePivottableUI from 'silicon_ioi/public/node_modules/vue3-pivottable/PivottableUi.js'

frappe.provide("frappe.views");

frappe.views.PivotView = class ioiPivotExpertView extends frappe.views.Factory {
	constructor(opts) {
		super(opts);

		let me = this
		this.doctype = opts.doctype;
		this.page_name = frappe.get_route_str();

		this.page = frappe.container.page.page
		this.meta = frappe.get_meta(this.doctype)

		this.current_select = {}
		this.fieldnames = {}
		this.pivot_query_fields = []
		this.pivot_saved_query_profile = ""

		frappe.breadcrumbs.add(
			locals.DocType[me.doctype].module,
			me.doctype
		);

		// Fix awesome bar
		frappe.container.page.list_view = this

		this.show()
	}

	get view_name() {
		return "ioi Pivot Expert";
	}

	show() {
		this.render_page()
	}

	async render_page() {
		await this.check_user_site()

		this.set_logo()
		this.page.set_title(__(this.doctype));

		this.page.wrapper.find(".sidebar-toggle-btn").remove()
		this.page.wrapper.find(".layout-side-section").remove()

		this.page.main.addClass("frappe-card border-0");

		// Build custom buttons
		if (this.is_multi_site)	await this.add_site_button()

		this.setup_view_menu()
		this.set_primary_action()
		this.set_menu_items()

		await this.prepare_query_creation()

		// (re)draw pivot table
		this.redraw_main_content()
	}

	set_logo() {
		if (this.page.wrapper.find(".title-image")[0]) {
			this.page.wrapper.find(".title-image")[0].remove()
		}

		let html_before = this.page.wrapper.find(".title-area")[0]
		let html = `<div class="title-image mr-2"><img src='/assets/silicon_ioi/images/modules/${this.doctype.replace(/ /g, '_').toLowerCase()}.svg' width="56px" height="56px" style="horizontal-align:left;"></img></div>`

		html_before.insertAdjacentHTML("afterbegin", html)
	}

	async check_user_site() {
		let me = this;

		await frappe.call({
			method: 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_has_site',
			args: {
				check_if_multi_site: true
			},
			async: false,
			callback: function (r) {
				me.current_site = r.message.site_id;
				me.is_multi_site = r.message.multi_site
			}
		})
	}

	add_custom_action(html, action) {
		this.page.wrapper.find(".custom-actions")[0].classList.replace('hide', 'show')

		let action_button = $(html);
		this.page.custom_actions.append(action_button);

		action && action_button.click(action);
	}

	show_select_site(reload = false) {
		if (!document.getElementById('ioi_site_list')) {

			let html = '';

			let me = this;
			let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_site'

			frappe.call({
				method: method,
				args: {},
				async: false,
				callback: function (r) {
					me.current_site = r.message;
				}
			});

			let division_tab = []
			method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_division_with_sites'

			frappe.call({
				method: method,
				args: {},
				async: false,
				callback: function (r) {
					division_tab = r.message;
				}
			});

			let current_division = ''

			if ((me.current_site) && (me.current_site.trim() != '')) {

				method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_division_for_site'

				frappe.call({
					method: method,
					args: { "site_id": me.current_site },
					async: false,
					callback: function (r) {
						current_division = r.message;
					}
				});

			} else {
				if (division_tab.length != 0) {
					current_division = division_tab[0].name;
				}
			}

			if (document.getElementById('ioi_site_list_label')) {
				document.getElementById('ioi_site_list_label').remove();
			}
			if (document.getElementById('ioi_site_list')) {
				document.getElementById('ioi_site_list').remove();
			}

			html += '<div style="position: relative; top: -20px; left: 0px; width:300px; height: 25px;">';
			html += '	<label id="ioi_site_list_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a site") + '</label>';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
			html += '		<select id="ioi_site_list" class="input-with-feedback form-control bold"> ';

			if (current_division != '') {

				method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division'

				frappe.call({
					method: method,
					args: { "division_id": current_division },
					async: false,
					callback: function (r) {

						for (var i = 0; i < r.message.length; i++) {
							if (me.current_site == r.message[i].name) {
								html += '		<option value="' + r.message[i].name + '" selected>' + r.message[i].description + '</option> ';
							} else {
								html += '		<option value="' + r.message[i].name + '">' + r.message[i].description + '</option> ';
							}
						}
					}
				});
			}

			html += '		</select> ';
			html += '	</div>';
			html += '</div>';

			if (division_tab.length > 1) {

				html += '<div style="position: relative; top: -45px; left: 310px; width:200px; height: 32px;">';
				html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:200px; height: 32px;"> ';
				html += '		<input id="ioi_select_division" type="button" class="btn btn-default ellipsis" value="' + __("Change division") + '" style="width:100%; height:100%">';
				html += '	</div>';
				html += '</div>';

				if (document.getElementById('ioi_select_division')) {
					document.getElementById('ioi_select_division').remove();
				}
			}

			frappe.confirm(html,
				() => {
					// action to perform if Yes is selected
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

					if ((!document.getElementById('ioi_site_list')) || (document.getElementById('ioi_site_list').value.trim() == '')) {
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = false;
						frappe.msgprint({ title: __("Message"), message: __('Site is mandatory'), indicator: "red" });
						raise;
					}

					me.current_site = document.getElementById('ioi_site_list').value;

					let method_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_save_site'
					frappe.call({
						method: method_user,
						args: { "site_id": document.getElementById('ioi_site_list').value },
						async: false,
						callback: function (r) {
							if (reload) {
								window.location.reload();
							}
						}
					});
				},
				() => {
					// action to perform if No is selected or if click outsite the modal screen
					if (reload) {
						window.location.reload();
					}
				}
			);

			this.sleep_ms(300).then(() => {

				let fct_click = function () {

					var changeDivisionDialog = new frappe.ui.Dialog({
						title: __("Change division"),
						static: true,
						fields: [{ 'fieldname': 'html_change_division', 'fieldtype': 'HTML' }
						],
						primary_action_label: __("Ok"),
						primary_action: function () {

							let value = document.getElementById('html_change_division_id').value;

							changeDivisionDialog.hide();

							if (value != current_division) {

								let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division';

								frappe.call({
									method: method,
									args: { "division_id": value },
									async: false,
									callback: function (r) {

										while (document.getElementById('ioi_site_list').options.length > 0) {
											document.getElementById('ioi_site_list').remove(0);
										}

										for (var i = 0; i < r.message.length; i++) {

											var option = document.createElement("option");
											option.value = r.message[i].name;
											option.text = r.message[i].description;
											document.getElementById('ioi_site_list').add(option);
										}
									}
								});

								current_division = value;
							}
						},
						secondary_action_label: __("Cancel"),
						secondary_action: function () {

							changeDivisionDialog.hide();
						}
					});

					if (document.getElementById('html_change_division_label')) {
						document.getElementById('html_change_division_label').remove();
					}

					if (document.getElementById('html_change_division_id')) {
						document.getElementById('html_change_division_id').remove();
					}

					let html = ""
					html += '<div style="height: 70px;">';
					html += '	<div style="position: relative; top: 0px; left: 0px; width:300px; height: 25px;">';
					html += '		<label id="html_change_division_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a division") + '</label>';
					html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
					html += '			<select id="html_change_division_id" class="input-with-feedback form-control bold"> ';

					for (var i = 0; i < division_tab.length; i++) {
						if (current_division == division_tab[i].name) {
							html += '		<option value="' + division_tab[i].name + '" selected>' + division_tab[i].description + '</option> ';
						} else {
							html += '		<option value="' + division_tab[i].name + '">' + division_tab[i].description + '</option> ';
						}
					}

					html += '			</select> ';
					html += '		</div>';
					html += '	</div>';
					html += '</div>';

					changeDivisionDialog.fields_dict.html_change_division.$wrapper.html(html);
					changeDivisionDialog.$wrapper.find('.modal-dialog').css("max-width", "340px").css("width", "340px");
					changeDivisionDialog.show();
				}

				document.getElementById('ioi_select_division').onclick = fct_click;

			});

			this.sleep_ms(200).then(() => {

				if (division_tab.length <= 1) {
					document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].style.width = '340px';
				} else {
					document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length - 1].style.width = '555px';
				}
			});
		}
	}

	sleep_ms(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	get_search_params() {
		let search_params = new URLSearchParams();
		return search_params;
	}

	async add_site_button() {
		await frappe.db.get_value('ioi Module', this.doctype, 'select_site').then(r => {
			if (r.message.select_site == 1) {

				if (document.getElementById('expert_view_site_button')) {
					document.getElementById('expert_view_site_button').remove()
				}

				let html = `<button id="expert_view_site_button" class="btn btn-default btn-sm ellipsis">${__('Site')} : ${this.current_site}</button>`

				let action = () => this.show_select_site(true)
				this.add_custom_action(html, action)
			} else {
				this.current_site = ''
			}
		})
	}

	setup_view_menu() {

		if (this.page.wrapper.find(".custom-btn-group")[0]) {
			this.page.wrapper.find(".custom-btn-group")[0].remove()
		}

		this.list_view = this
		this.list_view.settings = {}

		if (frappe.boot.desk_settings.view_switcher && !this.meta.force_re_route_to_default_view) {
			const icon_map = {
				Image: "image-view",
				List: "list",
				Report: "small-file",
				Calendar: "calendar",
				Gantt: "gantt",
				Kanban: "kanban",
				Dashboard: "dashboard",
				Map: "map",
				'ioi List Expert': "list",
				'ioi Pivot Expert': "list"
			};

			const label_map = {
				List: __("List View"),
				Report: __("Report View"),
				Dashboard: __("Dashboard View"),
				Gantt: __("Gantt View"),
				Kanban: __("Kanban View"),
				Calendar: __("Calendar View"),
				Image: __("Image View"),
				Inbox: __("Inbox View"),
				Tree: __("Tree View"),
				Map: __("Map View"),
				'ioi List Expert': __('ioi List Expert'),
				'ioi Pivot Expert': __('ioi Pivot Expert')
			};

			this.views_menu = this.page.add_custom_button_group(
				label_map[this.view_name] || label_map["List"],
				icon_map[this.view_name] || "list"
			);
			this.views_list = new frappe.views.ListViewSelect({
				doctype: this.doctype,
				parent: this.views_menu,
				page: this.page,
				list_view: this,
				sidebar: this.list_sidebar,
				icon_map: icon_map,
				label_map: label_map,
			});
		}

		if (!frappe.listview_settings[this.doctype] || (frappe.listview_settings[this.doctype] && !frappe.listview_settings[this.doctype].kanban)) {
			this.views_menu.find('[data-view="Kanban"]').hide();
		}

		this.add_refresh_grid_button()
	}

	set_menu_items() {
		this.menu_items = [
			{ label: __('List Settings'), action: () => this.show_query_profile_settings(), standard: true },
		]

		this.menu_items.map((item) => {
			if (item.condition && item.condition() === false) {
				return;
			}
			const $item = this.page.add_menu_item(
				item.label,
				item.action,
				item.standard,
				item.shortcut
			);
			if (item.class) {
				$item && $item.addClass(item.class);
			}
		});
	}

	add_refresh_grid_button() {
		if (document.getElementById('pivot_expert_view_refresh_button')) {
			document.getElementById('pivot_expert_view_refresh_button').remove()
		}

		let html = `
			<button id="pivot_expert_view_refresh_button" class="text-muted btn btn-default icon-btn" data-original-title="Refresh">
				<svg class="es-icon es-line  icon-sm" style="" aria-hidden="true">
					<use class="" href="#es-line-reload"></use>
				</svg>
			</button>`

		let action = () => {
			this.redraw_main_content()
		}

		this.add_custom_action(html, action)
	}

	set_primary_action() {
		const label = `${__("Add", null, "Primary action in list view")} ${__(this.doctype)}`
		this.page.set_primary_action(
			label,
			() => {
				frappe.new_doc(this.doctype)
			},
			"add"
		)
	}

	show_query_profile_settings() {
		this.is_fields_changed = false
		let me = this

		this.remove_dialog_if_exist(this.query_profile_settings)

		const dialog_fields = [
			{
				'label': __('Select a query profile'),
				'fieldname': 'profile',
				'fieldtype': 'Select',
				"default": this.pivot_saved_query_profile,
				'description': `<p id="warning_default_message" class="text-danger">${me.pivot_saved_query_profile == "Default" || me.pivot_saved_query_profile == "" ? __('This profile cannot be modified') : ""}</p>`
			},
			{
				'label': __('New profile'),
				'fieldname': 'new_button',
				'fieldtype': 'Button',
				'btn_size': 'xs',
			},
			{
				'label': __('Short profile description'),
				'fieldname': 'profile_description',
				'fieldtype': 'Data',
				"default": this.pivot_query_description,
			},
			{
				"fieldname": "section",
				"fieldtype": "Section Break",
			},
			{
				'fieldtype': 'HTML',
				'fieldname': 'query_html'
			},
			{
				'fieldtype': 'HTML',
				'fieldname': 'structure_html'
			},
			{
				'label': __('Add field'),
				'fieldname': 'add_button',
				'fieldtype': 'Button',
				'btn_size': 'xs',
			},
			{
				"fieldname": "section",
				"fieldtype": "Column Break",
			},
			{
				"fieldname": "selected_fields",
				"fieldtype": "HTML",
			},
			{
				'label': __('SQL query'),
				'fieldname': 'sql_query',
				'fieldtype': 'Code',
				'wrap': false,
				'max\_lines': 45,
				'min\_lines': 45,
				'description': __("Example : {0} field {1} table {2} condition {3} field {4}", ['SELECT', 'FROM', 'WHERE', 'ORDER BY', 'DESC'])
			},
			{
				"fieldname": "section_memo",
				"fieldtype": "Section Break",
			},
			{
				'label': __('Memo'),
				"fieldname": "profile_memo",
				"fieldtype": "Small Text",
				'default': this.pivot_query_memo,
			},
			{
				'label': __('Is public'),
				'fieldname': 'is_public',
				'fieldtype': 'Check',
			},
			{
				'label': __('Is standard'),
				'fieldname': 'is_standard',
				'fieldtype': 'Check',
				'read_only': true,
			},
		]

		this.query_profile_settings = new frappe.ui.Dialog({
			size: 'extra-large',
			title: __('Query Profile Settings'),
			fields: dialog_fields,
			primary_action_label: __('Save'),
			async primary_action(values) {
				const curr_profile = me.pivot_query_profile_list.find(el => el.name === values.profile);
				const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

				if (is_standard != 1) {
					me.pivot_query_description = values.profile_description
					me.pivot_query_memo = values.profile_memo
	
					if (values.profile != "Default" && values.profile != null) {
						if (me.pivot_query_mode == 1) {
	
							await me.read_query_mode(values.sql_query)
							me.pivot_query_fields = values.sql_query
	
							frappe.db.set_value('ioi Pivot Query Profile', values.profile, {
								'fields': me.pivot_query_fields,
								'short_profile_description': values.profile_description || "",
								'memo': values.profile_memo || ""
							})

						} else {
	
							frappe.db.set_value('ioi Pivot Query Profile', values.profile, {
								'short_profile_description': values.profile_description || "",
								'memo': values.profile_memo || ""
							})
						}
					}
				}

				me.update_query_user_settings(fields_dict.profile.value)
				me.query_profile_settings.hide();

				me.redraw_main_content()
			},
			on_page_show: () => {
				me.format_pivot_profile_list(fields_dict)
			}
		})

		let fields_dict = this.query_profile_settings.fields_dict
		fields_dict.profile.$input[0].onchange = (e) => this.on_query_profile_change(e.target.value)
		fields_dict.add_button.$input[0].onclick = () => this.add_field(fields_dict)
		fields_dict.is_public.$input[0].onclick = () => frappe.db.set_value('ioi Pivot Query Profile', fields_dict.profile.value, 'is_public', fields_dict.is_public.value == 1 ? 0 : 1)
		fields_dict.new_button.$input[0].onclick = () => this.create_new_query_profile()

		this.query_profile_settings.add_custom_action(__('Remove'), function () {
			me.remove_query_profile(fields_dict.profile.value)
			me.on_query_profile_change()
		}, 'btn-danger');

		this.query_profile_settings.add_custom_action(__('Test SQL Query'), function () {
			me.read_query_mode(me.query_profile_settings.fields_dict.sql_query.editor.getValue(), true)
		}, 'btn-secondary ml-2');

		this.query_profile_settings.show();
	}

	format_pivot_profile_list(fields_dict) {

		const profile_list = Array.isArray(this.pivot_query_profile_list) ? this.pivot_query_profile_list : [];
		const profile_options = profile_list.map(el => el.name);
		profile_options.unshift("Default");

		fields_dict.profile.df.options = profile_options;
	
		$("textarea[data-fieldname='profile_memo']").css({ height: "70" });
	
		const is_default_profile = fields_dict.profile.value === "Default";

		const curr_profile = is_default_profile 
			? null 
			: profile_list.find(el => el.name === fields_dict.profile.value);
	
		if (is_default_profile || !curr_profile) {
			fields_dict.profile.value = "Default";
			this.set_default_profile(fields_dict, "Default");
		} else {
			fields_dict.is_public.value = curr_profile.is_public;
			fields_dict.is_standard.value = curr_profile.is_standard;
		}
	
		this.query_profile_settings.refresh();

		const is_query_mode = curr_profile?.query_mode === 1;
		this.update_dialog_mode(fields_dict, is_query_mode ? "query" : "list");

		if (is_query_mode) {
			this.setup_query_fields_search();
		} else {
			this.setup_query_fields();
			this.display_structure();
			fields_dict.add_button.input_area.classList.add("d-flex", "justify-content-end");
		}
	}

	async get_pivot_profile_list() {
		this.pivot_query_profile_list = await frappe.call('silicon_ioi.utils.lib.system.get_pivot_profile_list', {
			doctype: this.doctype,
		}).then((r) => r.message);
	}

	update_dialog_mode(fields_dict, mode) {
		const is_query_mode = mode === 'query';
		const curr_profile = this.pivot_query_profile_list.find(el => el.name === fields_dict.profile.value);
		const custom_actions = this.query_profile_settings.custom_actions[0];

		this.toggle_visibility(fields_dict, ["selected_fields", "structure_html"], !is_query_mode);
		this.toggle_visibility(fields_dict, ["sql_query", "query_html"], is_query_mode);

		fields_dict.add_button.wrapper.hidden = is_query_mode || fields_dict.profile.value === "Default";

		// Toggle test query button visibility
		custom_actions.children[1].classList.toggle('show', is_query_mode);

		this.toggle_dialog_readonly(fields_dict, custom_actions, curr_profile, is_query_mode);

		if (is_query_mode) {
			if (typeof this.pivot_query_fields === "string") {
				this.query_profile_settings.set_value("sql_query", this.pivot_query_fields);
			}
		} else {
			fields_dict.add_button.input_area.classList.add("d-flex", "justify-content-end");
			if (!fields_dict.structure_html.$wrapper.find('.file-browser').length) {
				this.display_structure();
			}
		}

		this.pivot_query_mode = is_query_mode ? 1 : 0;
	}

	toggle_visibility(fields_dict, fields, visible) {
		fields.forEach(field => {
			fields_dict[field].wrapper.hidden = !visible;
		});
	}

	toggle_dialog_readonly(fields_dict, custom_actions, curr_profile, is_query_mode) {

		const is_readonly = curr_profile == undefined || curr_profile?.is_standard === 1;

		// Toggle test query/remove button visibility
		custom_actions.children[0].classList.toggle('hide', is_readonly);

		if (is_query_mode && !is_readonly) {
			custom_actions.children[1].classList.toggle('hide', false);
		} else {
			custom_actions.children[1].classList.toggle('hide', true);
		}

		fields_dict.add_button.$wrapper.find('button').prop('hidden', is_readonly);

		const warning_message = is_readonly ? __('This profile cannot be modified') : '';
		fields_dict.profile.$wrapper.find('#warning_default_message').text(warning_message);

		// Set main content to read only
		const pointer_events = is_readonly ? "none" : "";
		const opacity = is_readonly ? "0.65" : "1";

		const toggle_css = (fields, css) => {
			fields.forEach(field => {
				fields_dict[field].$wrapper.css(css);
			});
		};

		toggle_css(["query_html", "structure_html", "selected_fields", "sql_query", "profile_memo", "profile_description"], {"pointer-events": pointer_events, "opacity": opacity});
		
		const toggle_fields = (fields, read_only) => {
			fields.forEach(field => {
				fields_dict[field].$wrapper.find('input, textarea, select, button').prop('disabled', read_only);
			});
		};

		if (fields_dict.sql_query.editor) fields_dict.sql_query.editor.setReadOnly(is_readonly);

		toggle_fields(["profile_description", "profile_memo", "is_public"], is_readonly);
		toggle_fields(["query_html"], !is_readonly);
	}

	set_default_profile(fields_dict, name) {

		this.query_profile_settings.custom_actions[0].children[0].classList.add('hide')
		this.query_profile_settings.custom_actions[0].children[1].classList.add('hide')

		this.toggle_visibility(fields_dict, ["add_button", "is_public", "is_standard", "profile_description", "profile_memo"], false);
		fields_dict.section_memo.wrapper[0].hidden = true

		this.update_query_user_settings(name)
	}

	add_field(fields_dict, idx = null) {

		if (this.pivot_query_mode == 0) {

			const curr_profile = this.pivot_query_profile_list.find(el => el.name == fields_dict.profile.value)
			const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

			if (fields_dict.profile.value != "Default" &&
				fields_dict.profile.value != null &&
				is_standard != 1) {

				let path = ''
				let node = this.current_select

				if (node.field) {

					if (node.field.parent_node) {
						let parent = this.fieldnames[node.field.parent_node]
						let parents = [parent.name]

						while (parent.parent !== null) {
							parent = this.fieldnames[parent.parent]
							parents.unshift(parent.name)
						}

						for (let i = 0; i < parents.length; i++) {
							path += path != '' ? `.${parents[i]}` : parents[i]
						}
					}

					path += path != '' ? `.${node.field.fieldname}` : node.field.fieldname

					if (idx) {
						this.pivot_query_fields.splice(idx, 0, { path: path, field: path.replace('.', '___'), label: this.current_select.label })
					} else {
						this.pivot_query_fields.push({ path: path, field: path.replace('.', '___'), label: this.current_select.label })
					}

					this.setup_query_fields()
					this.is_fields_changed = true

					frappe.db.set_value('ioi Pivot Query Profile', fields_dict.profile.value, 'fields', this.pivot_query_fields)

				} else {

					frappe.show_alert({
						message: __('Select a field first'),
						indicator: 'red'
					}, 5);

					return;
				}
			}
		}
	}

	setup_query_fields() {
		let me = this

		let fields_html = me.query_profile_settings.get_field("selected_fields");
		fields_html.html(``);

		let fields = ``;

		const arrow_down = `<button class="btn btn-outline-secondary btn-xs insert_query_field" style="font-size: var(--text-xs);" title="${__('Insert below')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
								</svg>
							</button>`

		const del_icon = `  <button class="btn btn-outline-secondary btn-xs remove_query_field" style="font-size: var(--text-xs);" title="${__('Remove field')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
									<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
									<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
								</svg>
							</button>`

		const curr_profile = this.pivot_query_profile_list.find(el => el.name == this.pivot_saved_query_profile)
		const is_standard = curr_profile?.name !== "Default" && curr_profile?.is_standard === 1;

		for (let idx in me.pivot_query_fields) {
			fields += `
				<div class="control-input form-control" style="margin-bottom: 5px; padding-top: 4px; font-size: var(--text-md);" data-fieldname="${me.pivot_query_fields[idx].field}" data-idx="${idx}">
					<div class="row d-flex justify-content-between align-items-center mx-1">
						<div class="text-truncate">
							${me.pivot_query_fields[idx].path}
						</div>
						<div>
							<a class="text-muted insert-field" data-fieldname="${me.pivot_query_fields[idx].path}">
								${	this.pivot_saved_query_profile != "Default" &&
									!!this.pivot_saved_query_profile &&
									!is_standard ?
									arrow_down : ""
								}
							</a>
							<a class="text-muted remove-field" data-fieldname="${me.pivot_query_fields[idx].path}">
								${	this.pivot_saved_query_profile != "Default" && 
									!!this.pivot_saved_query_profile &&
									me.pivot_query_fields[idx].path != 'name' &&
									!is_standard ?
									del_icon : ""
								}
							</a>
						</div>
					</div>
				</div>`;
		}

		fields_html.html(`
			<div class="form-group">
				<div class="clearfix">
					<label class="control-label mb-3" style="padding-right: 0px;">${__('Selected fields')}</label>
				</div>
				<div class="control-input-wrapper" style="max-height: 560px; overflow: auto;">
					${fields.length ? fields : `
						<span class="d-flex justify-content-center" style="font-size: var(--text-sm);">${__('No field selected')}</span>
					`}
				</div>
			</div>
		`);

		setTimeout(() => {
			$('.insert_query_field').on('click', (e) => {
				const $idx = $(e.currentTarget).closest('.control-input').attr('data-idx');
				this.add_field(this.query_profile_settings.fields_dict, (Number($idx) + 1))
			});
		}, 200);

		this.setup_remove_fields()
	}

	setup_query_fields_search() {

		let fields_html = this.query_profile_settings.get_field("query_html");
		fields_html.html(``);

		let me = this
		let fields = ``;
		let selected_fields = []

		fields_html.html(`
			<div class="form-group">
				<div class="control-input-wrapper">
					<div id="query_link_doctype"></div>
					<div id="query_link_actions_container" class="d-flex justify-content-between flex-wrap my-3">
						<input id="search_query_field" type="text" class="input-with-feedback form-control w-50" maxlength="140" placeholder="Search a field" />
						<div class="d-flex flex-column">
							<button class="btn btn-xs btn-default" data-fieldtype="Button" data-fieldname="generate_query">${__('Generate SQL query')}</button>
							<button class="btn btn-xs btn-default mt-2" data-fieldtype="Button" data-fieldname="insert_query_fields">${__('Insert selected field(s)')}</button>
						</div>
					</div>
					<div id="query_link_fields" style="overflow: auto; max-height: 400px; min-height: 400px;"></div>
					<div id="query_system_variables"></div>
				</div>
			</div>
		`);

		const link = frappe.ui.form.make_control({
			parent: fields_html.$wrapper.find('#query_link_doctype'),
			df: {
				fieldname: 'select_doctype',
				fieldtype: 'Link',
				options: 'DocType',
				placeholder: __('Select a doctype'),
			},
			render_input: true
		})

		link.$wrapper.addClass("mx-1")
		link.$wrapper.find('.clearfix').remove()
		link.$input.on('change', async (e) => {

			const doctype_fields = await frappe.call('silicon_ioi.utils.lib.system.get_doctype_meta_fields', {
				doctype: link.value,
			}).then(r => r.message)

			if (doctype_fields) {

				fields = ``;

				for (let idx in doctype_fields) {

					fields += `
						<div class="control-input form-control mx-1" style="margin-bottom: 5px; max-width: 517px; padding-top: 4px; font-size: var(--text-md);" data-fieldname="${doctype_fields[idx].fieldname}" data-idx="${idx}">
							<div class="row d-flex align-items-center mx-1 data_sql_value" data-sql-value="\`tab${link.value}\`.${doctype_fields[idx].fieldname} AS ${doctype_fields[idx].label && !!doctype_fields[idx].label.trim() ? `'${doctype_fields[idx].label}'` : `'${doctype_fields[idx].fieldname}'`}">
								<input type="checkbox" id="${doctype_fields[idx].fieldname}-${idx}" style="cursor: pointer!important;">
								<label class="form-check-label ml-1" for="${doctype_fields[idx].fieldname}-${idx}" style="cursor: pointer!important;">
									${doctype_fields[idx].fieldname} ${doctype_fields[idx].label && !!doctype_fields[idx].label.trim() ? `(${doctype_fields[idx].label})` : ''}
								</label>
							</div>
						</div>`;
				}
			} else {

				fields = ``;
			}

			fields_html.$wrapper.find('#query_link_fields').html(fields)
			let timer;

			fields_html.$wrapper.find('#query_link_fields').html(fields).promise().done(() => {
				$('.data_sql_value').off('dblclick').on('dblclick', (ev) => this.insert_fields_in_query(ev));
				$('.data_sql_value input').off('change').on('change', (ev) => selected_fields = this.toggle_query_checkbox(ev, selected_fields));
				$('[data-fieldname="insert_query_fields"]').off('mousedown').on('mousedown', (ev) => {
					this.insert_fields_in_query(ev, selected_fields)
				});
				$('[data-fieldname="generate_query"]').off('mousedown').on('mousedown', () => {
					this.generate_sql_query(link.value, selected_fields)
				});
				$('#search_query_field').off('input').on('input', (e) => {
					clearTimeout(timer);
					timer = setTimeout(() => me.filter_query_fields(e, fields_html), 300);
				});
			});
		})

		if (!!this.doctype) {
			link.set_value(this.doctype);
			setTimeout(() => link.$input.trigger('change'), 200);
		}

		this.setup_system_variables(fields_html)
	}

	reset_selected_fields(selected_fields) {
		$('.data_sql_value input').each(function(i) {
			this.checked = false
		});

		selected_fields.length = 0;

		return selected_fields;
	}

	async setup_system_variables(fields_html) {

		const options = await frappe.call('silicon_ioi.utils.lib.system.get_variables_help', {
			opts: ['system', 'company'],
		}).then(r => r.message.filter(el => el.name !== "section").map(el => ({ value: el.name, label: el.name, description: el.comment })))

		const link = frappe.ui.form.make_control({
			parent: fields_html.$wrapper.find('#query_system_variables'),
			df: {
				label: __('System variables'),
				fieldname: 'system_variables',
				fieldtype: 'Autocomplete',
				options: options
			},
			render_input: true
		})

		link.$wrapper.addClass("mt-3")
		link.$input.on('change', async (e) => {

			this.query_profile_settings.fields_dict.sql_query.editor.insert(`'${e.target.value}'`);
			setTimeout(() => $(e.currentTarget).val(''), 200)

		})
	}

	insert_fields_in_query(e, selected_fields = []) {

		if (selected_fields.length) {

			const formatted_fields = selected_fields.map((el, idx) => {
				return idx === selected_fields.length - 1 ? `\t${el}` : `\t${el},\n`;
			}).join("");

			this.query_profile_settings.fields_dict.sql_query.editor.insert(formatted_fields);
		} else {

			const value = $(e.currentTarget).closest('.data_sql_value').attr('data-sql-value');

			if (!!value) {

				this.query_profile_settings.fields_dict.sql_query.editor.insert(value);
			} else {

				frappe.show_alert({
					message: __('Select at least one field'),
					indicator: 'red'
				}, 5);

				return;
			}
		}

		this.reset_selected_fields(selected_fields)
	}

	toggle_query_checkbox(e, selected_fields) {
		const value = $(e.currentTarget).closest('.data_sql_value').attr('data-sql-value');

		if (e.target.checked) {
			selected_fields.push(value)
		} else {
			selected_fields = selected_fields.filter(el => el != value)
		}

		return selected_fields
	}

	generate_sql_query(input_value, selected_fields) {
		let me = this

		frappe.warn(__('Are you sure you want to proceed?'),
			__('This action only supports simple SQL queries. By clicking "Continue", the current SQL query content will be overwritten with a new query.'),
			() => {
				let sql_query = "SELECT\n"
				const doctype = `\t\`tab${input_value}\``

				selected_fields.map((el, idx) => {
					return idx === selected_fields.length - 1 ? sql_query += `\t${el}\n` : sql_query += `\t${el},\n`;
				}).join("");

				sql_query += "FROM\n"
				sql_query += doctype

				frappe.confirm(__('Do you want to filter by current site?'),
					() => {
						sql_query += "\n"
						sql_query += "WHERE\n"
						sql_query += `${doctype}.site_id = '_{system.user_site}'`

						me.query_profile_settings.fields_dict.sql_query.set_value(sql_query);
						me.reset_selected_fields(selected_fields)
					}, () => {
						me.query_profile_settings.fields_dict.sql_query.set_value(sql_query);
						me.reset_selected_fields(selected_fields)
					})
			},
			__('Continue'),
		)
	}

	filter_query_fields(e, fields_html) {

		const input_value = e.target.value.toLowerCase().trim();
		const field_elements = fields_html.$wrapper.find('#query_link_fields .control-input');

		field_elements.each(function () {
			const field_element = $(this);
			const field_name = field_element.find('label').text().toLowerCase();

			if (field_name.includes(input_value)) {
				field_element.removeClass('hide');
			} else {
				field_element.addClass('hide');
			}
		});
	}

	setup_remove_fields() {
		let fields_html = this.query_profile_settings.get_field("selected_fields");
		let remove_fields_buttons = fields_html.$wrapper[0].getElementsByClassName("remove-field");

		for (let idx = 0; idx < remove_fields_buttons.length; idx++) {
			remove_fields_buttons.item(idx).onclick = () =>
				this.remove_fields(remove_fields_buttons.item(idx));
		}
	}

	remove_fields(remove_fields_buttons) {
		let me = this;
		let attr = remove_fields_buttons.getAttribute("data-fieldname")

		for (let idx in me.pivot_query_fields) {
			let field = me.pivot_query_fields[idx];

			if (field.path == attr) {
				me.pivot_query_fields.splice(idx, 1);
				me.setup_query_fields()

				frappe.db.set_value('ioi Pivot Query Profile', me.query_profile_settings.fields_dict.profile.value, 'fields', me.pivot_query_fields)
				break;
			}
		}

		this.is_fields_changed = true
	}

	display_structure() {
		const structure_html = this.query_profile_settings.$wrapper.find("[data-fieldname='structure_html']")

		if (structure_html && structure_html[0].__vue_app__) {
			const vue_app = structure_html[0].__vue_app__;
			vue_app.unmount();
		}

		createApp({
			render: () => h(QueryProfile, {
				doctype: this.doctype,
				docname: this.docname
			})
		}).mount(structure_html[0]);
	}

	async on_query_profile_change(changeValue = null) {

		let fields_dict = this.query_profile_settings.fields_dict
		let value = changeValue == null ? fields_dict.profile.value : changeValue
		this.pivot_saved_query_profile = value

		fields_dict.profile.set_value(value)

		await this.get_query_fields()

		fields_dict.profile_description.$input.val(this.pivot_query_description)
		fields_dict.profile_memo.$input.val(this.pivot_query_memo)

		if (value == 'Default') {

			this.query_profile_settings.custom_actions[0].children[0].classList.add('hide')
			this.query_profile_settings.custom_actions[0].children[1].classList.add('hide')
			this.toggle_visibility(fields_dict, ["is_public", "is_standard", "profile_description", "profile_memo"], false)
			fields_dict.section_memo.wrapper[0].hidden = true

			fields_dict.profile.$wrapper.find('#warning_default_message').text(__('This profile cannot be modified'))
			this.update_dialog_mode(fields_dict, "list")
			this.setup_query_fields()

		} else {

			this.query_profile_settings.custom_actions[0].children[0].classList.remove('hide')
			this.toggle_visibility(fields_dict, ["is_public", "is_standard", "profile_description", "profile_memo"], true)
			fields_dict.section_memo.wrapper[0].hidden = false

			fields_dict.profile.$wrapper.find('#warning_default_message').text('')

			if (this.pivot_query_mode == 1) {

				this.query_profile_settings.custom_actions[0].children[1].classList.remove('hide')
				this.update_dialog_mode(fields_dict, "query")
				this.setup_query_fields_search()
			} else {

				this.query_profile_settings.custom_actions[0].children[1].classList.add('hide')
				this.update_dialog_mode(fields_dict, "list")
				this.setup_query_fields()
			}
		}
	}

	async prepare_query_creation() {

		const user_settings = frappe.model.user_settings[this.doctype]?.["ioi Pivot Profile"];
		let saved_profile = "";

		if (await frappe.db.exists("ioi Pivot Query Profile", user_settings)) {

			saved_profile = await frappe.db.get_value('ioi Pivot Query Profile', {
				'doc_type': this.doctype,
				'name': user_settings,
			}, ['name', 'query_mode', 'short_profile_description', 'memo']).then(r => r.message);
		} else {

			const standard_profile = await frappe.db.get_value('ioi Pivot Query Profile', {
				'doc_type': this.doctype,
				'user_name': 'User',
				'is_standard': 1
			}, ['name', 'query_mode', 'short_profile_description', 'memo']).then(r => r.message);

			if (standard_profile && standard_profile.name) {
				saved_profile = standard_profile
			} else {
				saved_profile = { name: "Default", query_mode: 0 };
			}
		}

		this.pivot_query_mode = saved_profile.query_mode == 1 ? 1 : 0;
		this.pivot_query_description = !!saved_profile.short_profile_description ? saved_profile.short_profile_description : "";
		this.pivot_query_memo = !!saved_profile.pivot_query_memo ? saved_profile.pivot_query_memo : "";

		if (saved_profile && saved_profile.name) {
			this.pivot_saved_query_profile = saved_profile.name;
			this.update_query_user_settings(saved_profile.name);
		} else {
			this.pivot_saved_query_profile = "Default";
			this.update_query_user_settings("Default");
		}

		await this.get_query_fields();
	}

	async get_query_fields() {
		if (this.pivot_saved_query_profile != "Default" && !!this.pivot_saved_query_profile) {
			const res = await frappe.db.get_value('ioi Pivot Query Profile', { name: this.pivot_saved_query_profile }, ['fields', 'is_public', 'is_standard', 'display_profile', 'query_mode', 'short_profile_description', 'memo']).then(r => r.message)

			this.pivot_query_mode = res.query_mode == 1 ? 1 : 0;
			this.pivot_query_description = !!res.short_profile_description ? res.short_profile_description : "";
			this.pivot_query_memo = !!res.memo ? res.memo : "";

			if (this.query_profile_settings) {
				this.query_profile_settings.fields_dict.is_public.input.checked = Boolean(res.is_public);

				const input_is_standard = this.query_profile_settings.fields_dict.is_standard.disp_area.querySelector('input')
				input_is_standard.classList = res.is_standard == 1 ? 'disabled-selected' : 'disabled-deselected'
			}

			this.pivot_query_fields = res.fields ? res.fields : this.pivot_query_mode == 1 ? "" : [];
			this.pivot_current_display_profile = !!res.display_profile ? res.display_profile : "No Profile";

		} else {

			this.pivot_query_mode = 0
			this.pivot_query_description = "";
			this.pivot_query_memo = "";

			const default_fields = this.meta.fields.filter(field => field.in_list_view === 1);

			this.pivot_query_fields = default_fields.map(field => ({
				path: field.fieldname,
				field: field.fieldname,
				label: field.label
			}));
		}

		if (this.pivot_query_mode == 0) {
			let field_name = { path: 'name', field: 'name', label: 'ID' }

			if (this.pivot_saved_query_profile != "Default") {
				this.pivot_query_fields = this.pivot_query_fields.length ? JSON.parse(this.pivot_query_fields) : []
			} else {
				this.pivot_query_fields = this.pivot_query_fields || []
			}

			if (!this.pivot_query_fields.some(obj => { return obj.path == "name" })) {
				this.pivot_query_fields.unshift(field_name)
			}
		}
	}

	create_new_query_profile() {
		let me = this

		if (this.query_profile_settings) this.query_profile_settings.hide();

		this.remove_dialog_if_exist(this.new_query_profile_dialog)

		this.new_query_profile_dialog = new frappe.ui.Dialog({
			title: __('Create a new profile'),
			fields: [
				{
					label: __('Profile name'),
					fieldname: 'new_profile_name',
					fieldtype: 'Data',
					reqd: true,
				},
				{
					label: __('Query mode'),
					fieldname: 'query_mode',
					fieldtype: 'Check'
				}
			],
			size: 'small',
			primary_action_label: __("Create"),
			primary_action(values) {
				frappe.db.insert({ doctype: 'ioi Pivot Query Profile', doc_type: me.doctype, user_name: frappe.session.user, profile_name: values.new_profile_name, query_mode: values.query_mode }).then(async () => {
					let profile_name = `${me.doctype} • ${frappe.session.user} • ${values.new_profile_name}`

					await me.get_pivot_profile_list()
					me.pivot_saved_query_profile = profile_name
					me.pivot_query_mode = values.query_mode

					me.new_query_profile_dialog.hide();
					me.show_query_profile_settings()
					me.on_query_profile_change()
				})
			},
			secondary_action_label: __("Create from ioi List Expert"),
			secondary_action() {

				me.new_query_profile_dialog.hide();
				me.create_query_profile_from_expert(me.new_query_profile_dialog.fields_dict.new_profile_name.value)
			}
		});

		this.new_query_profile_dialog.show()
	}

	create_query_profile_from_expert(profile_name) {
		let me = this

		this.remove_dialog_if_exist(this.query_profile_from_expert_dialog)

		this.query_profile_from_expert_dialog = new frappe.ui.Dialog({
			title: __('Create a new profile from ioi List Expert'),
			fields: [
				{
					label: __('Profile name'),
					fieldname: 'profile_name',
					fieldtype: 'Data',
					reqd: true,
					default: profile_name
				},
				{
					label: __('Select an ioi expert profile'),
					fieldname: 'select_profile_name',
					fieldtype: 'Link',
					options: 'ioi Query Profile',
					filters: { "doc_type": me.doctype },
					reqd: true,
				},
			],
			size: 'small',
		});

		this.query_profile_from_expert_dialog.set_primary_action(__("Create"), () => {
			let values = me.query_profile_from_expert_dialog.get_values();

			frappe.call('silicon_ioi.utils.lib.system.create_pivot_profile_from_expert', {
				doctype: this.doctype,
				profile_name: values.profile_name,
				expert_profile: values.select_profile_name,
			}).then(async () => {
				let profile_name = `${me.doctype} • ${frappe.session.user} • ${values.profile_name}`
				me.query_profile_from_expert_dialog.hide();

				await me.get_pivot_profile_list()

				me.show_query_profile_settings()
				me.on_query_profile_change(profile_name)
				me.redraw_main_content()
			})
		})

		this.query_profile_from_expert_dialog.show()
	}

	remove_dialog_if_exist(dialog) {
		if (dialog) {
			dialog.$wrapper.remove();
			dialog = null
		}
	}

	remove_query_profile(profile, redraw=false) {
		let me = this;

		frappe.warn(
			__('Are you sure you want to proceed?'),
			__('You are about to delete this profile permanently'),
			() => {
				frappe.call('silicon_ioi.utils.lib.system.delete_pivot_query_profile', {
					doctype: this.doctype,
					query_profile: profile
				}).then(async () => {
					if (this.query_profile_settings && this.query_profile_settings.display) {
						setTimeout(() => {
							me.on_query_profile_change("Default");
	
							// Filter select options
							const updated_options = Array.from(me.query_profile_settings.fields_dict.profile.input.options).filter(option => option.value !== profile);
							me.query_profile_settings.fields_dict.profile.input.innerHTML = '';
							updated_options.forEach(option => me.query_profile_settings.fields_dict.profile.input.appendChild(option));
	
						}, 200);
					}

					if (redraw){
						me.pivot_saved_query_profile = "Default"
						me.pivot_query_mode = 0
						await this.get_query_fields();
						me.redraw_main_content()
					}
				});
			},
			__('Continue'),
		);
	}

	update_query_user_settings(profile) {
		return frappe.model.user_settings.save(this.doctype, "ioi Pivot Profile", !!profile ? profile : "Default")
	}

	async redraw_main_content() {

		if (document.getElementById('pivot-page-content')) document.getElementById('pivot-page-content').remove()

		$(`<div id="pivot-page-content"></div>`).appendTo(this.page.main);
		const data = this.pivot_query_mode == 0 ? await this.generate_query() : await this.read_query_mode(this.pivot_query_fields)

		// Profile Switcher //
		$(`<div id="profile_switcher_content"></div>`).appendTo($('#pivot-page-content'));
		$('#profile_switcher_content').css({ "display": "flex", "justify-content": "end", "align-items": "baseline" });

		await this.create_profile_switcher()
		await this.update_display_profile(data)

		$(`<div id="pivot-content"></div>`).appendTo($('#pivot-page-content'));

		let switch_profile = $('#profile_switcher_content select[data-fieldname="switch_profile"]')
		let switch_display_profile = $('#profile_switcher_content select[data-fieldname="switch_display_profile"]')

		switch_profile.val(this.pivot_saved_query_profile)

		if (!!this.pivot_current_display_profile && this.pivot_current_display_profile !== "No Profile") {
			switch_display_profile.val(this.pivot_current_display_profile).change()
		} else {
			this.build_pivot_table(data)
		}

		this.toggle_del_button()
	}

	async create_profile_switcher() {
		await this.get_pivot_profile_list()

		const main_container = $('#profile_switcher_content')

		$(`<div id="profile_switcher_content_query"></div>`).appendTo(main_container);
		$('#profile_switcher_content_query').css({ "display": "flex", "justify-content": "end", "margin": "0px 14px 0px 14px", "min-width": "360px" });

		const container = $('#profile_switcher_content_query')

		let options = this.pivot_query_profile_list.map(el => el.name)
		options.unshift('Default')

		const profile_switcher = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: __('Query profiles'),
				fieldname: 'switch_profile',
				fieldtype: 'Select',
				options: options,
				default: this.pivot_saved_query_profile || "Default",
				description: this.pivot_query_description
			},
			render_input: true
		})

		profile_switcher.$wrapper.addClass("w-100 mr-1")
		profile_switcher.$input.addClass("pr-5")
		profile_switcher.$input.change((e) => this.on_profile_switcher_change())

		const icon_settings = `	<svg width="16" height="20" class="icon" style="filter: opacity(0.75)">
									<use class="" href="#icon-setting-gear"></use>
								</svg>`

		const settings_btn = frappe.ui.form.make_control({
			parent: container,
			df: {
			label: icon_settings,
			fieldname: 'open_settings',
			fieldtype: 'Button',
			btn_size: 'xs'
			},
			render_input: true
		})

		settings_btn.$wrapper.addClass("mr-3")
		settings_btn.$wrapper.css("margin-top", "27.5px")
		settings_btn.$input.addClass("d-flex align-items-center")
		settings_btn.$input.click(() => this.show_query_profile_settings())

		const icon_new = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16" style="filter: opacity(0.75)">
							<path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
						</svg>`

		const new_btn = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: icon_new,
				fieldname: 'new_profile',
				fieldtype: 'Button',
				btn_size: 'xs'
			},
			render_input: true
		})

		new_btn.$wrapper.addClass("mr-1")
		new_btn.$wrapper.css("margin-top", "27.5px")
		new_btn.$input.addClass("d-flex align-items-center")
		new_btn.$input.click(() => this.create_new_query_profile())

		const icon_del = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16" style="filter: opacity(0.75)">
							<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
							<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
						</svg>`

		const del_btn = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: icon_del,
				fieldname: 'delete_profile',
				fieldtype: 'Button',
				btn_size: 'xs'
			},
			render_input: true
		})

		del_btn.$wrapper.addClass("mr-1")
		del_btn.$wrapper.css("margin-top", "27.5px")
		del_btn.$input.addClass("d-flex align-items-center")
		del_btn.$input.click(() => {
			this.remove_query_profile(profile_switcher.$input.val(), true)
		})
	}

	async on_profile_switcher_change() {
		const profile = $('#pivot-page-content select[data-fieldname="switch_profile"]').val()
		this.pivot_saved_query_profile = profile

		await this.get_query_fields();
		this.toggle_del_button()

		this.update_query_user_settings(profile)
		this.redraw_main_content()
	}

	async update_display_profile(data=[]) {

		if ($('#profile_switcher_content_display')) $('#profile_switcher_content_display').remove()

		if (!!this.pivot_saved_query_profile && this.pivot_saved_query_profile !== "Default") {
			await this.create_display_profile_switcher(data)
		} else {
			this.pivot_current_display_profile = "No Profile"
		}
	}

	async create_display_profile_switcher(data) {
		let options = await frappe.call('silicon_ioi.utils.lib.system.get_pivot_display_profiles', {
			doctype: this.doctype,
			query_profile: this.pivot_saved_query_profile
		}).then(r => r.message.map(el => el.name))
		
		options.unshift('No Profile')

		const main_container = $('#profile_switcher_content')
		$(`<div id="profile_switcher_content_display"></div>`).appendTo(main_container);
		$('#profile_switcher_content_display').css({ "display": "flex", "justify-content": "end", "align-items": "baseline", "margin": "0px 14px 0px 14px", "min-width": "360px" });

		const container = $('#profile_switcher_content_display')

		const profile_switcher = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: __('Display profiles'),
				fieldname: 'switch_display_profile',
				fieldtype: 'Select',
				options: options,
			},
			render_input: true
		})

		profile_switcher.$wrapper.addClass("w-100 mr-1")
		profile_switcher.$input.addClass("pr-5")
		profile_switcher.$input.val("No Profile")
		profile_switcher.$input.change((e) => this.on_display_profile_change(data))

		const save_display_btn = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: __('Save'),
				fieldname: 'save_display_btn',
				fieldtype: 'Button',
				btn_size: 'xs'
			},
			render_input: true
		})

		save_display_btn.$wrapper.addClass("mr-3 align-self-end")
		save_display_btn.$input.css("height", "28px")
		save_display_btn.$input.click(() => this.save_display_profile())

		const icon_new = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16" style="filter: opacity(0.75)">
							<path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
						</svg>`

		const new_btn = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: icon_new,
				fieldname: 'new_display_profile',
				fieldtype: 'Button',
				btn_size: 'xs'
			},
			render_input: true
		})

		new_btn.$wrapper.addClass("mr-1 align-self-end")
		new_btn.$input.addClass("d-flex align-items-center")
		new_btn.$input.click(() => this.add_display_profile())

		const icon_del = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16" style="filter: opacity(0.75)">
							<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
							<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
						</svg>`

		const del_btn = frappe.ui.form.make_control({
			parent: container,
			df: {
				label: icon_del,
				fieldname: 'delete_display_profile',
				fieldtype: 'Button',
				btn_size: 'xs'
			},
			render_input: true
		})

		del_btn.$wrapper.addClass("align-self-end")
		del_btn.$input.addClass("d-flex align-items-center")
		del_btn.$input.click(() => this.remove_display_profile())
	}

	async on_display_profile_change(data) {
		const profile = $('#pivot-page-content select[data-fieldname="switch_display_profile"]').val()

		this.toggle_del_button()

		const display = !!profile && profile !== "No Profile" ? await frappe.call('silicon_ioi.utils.lib.system.get_pivot_display_single_profile', {
			name: profile,
		}).then(r => {
			if (r.message && !!r.message[0].display) {
				return r.message[0].display
			} else {
				return undefined
			}
		}) : undefined

		if (!!display) {
			this.build_pivot_table(data, display);
		} else {
			this.build_pivot_table(data);
		}
	}

	toggle_del_button() {
		const profile = $('#pivot-page-content select[data-fieldname="switch_profile"]').val()
		const display_profile = $('#pivot-page-content select[data-fieldname="switch_display_profile"]').val()

		if (!!profile && profile !== "Default") {
			$('#profile_switcher_content div[data-fieldname="delete_profile"]').show()
		} else {
			$('#profile_switcher_content div[data-fieldname="delete_profile"]').hide()
		}

		if (!!display_profile && display_profile !== "No Profile") {
			$('#profile_switcher_content div[data-fieldname="delete_display_profile"]').show()
		} else {
			$('#profile_switcher_content div[data-fieldname="delete_display_profile"]').hide()
		}
	}

	save_display_profile(profile=undefined) {
		
		if (!profile && profile !== "No Profile") profile = $('#pivot-page-content select[data-fieldname="switch_display_profile"]').val()
		
		const rows = Array.from($("#pivot-content .pvtRows li")).map(el => el.getAttribute('data-id'))
		const cols = Array.from($("#pivot-content .pvtCols li")).map(el => el.getAttribute('data-id'))
		const renderer = $("#pivot-content .pvtRenderers select.pvtDropdown").val();
		const aggregator = $("#pivot-content .pvtVals select.pvtDropdown").eq(0).val();
		const vals = [$("#pivot-content .pvtVals select.pvtDropdown").eq(1).val(), $("#pivot-content .pvtVals select.pvtDropdown").eq(2).val()];
		const filters = this.pivot_value_filter

		const saved_obj = {
			rows: rows,
			cols: cols,
			renderer: renderer,
			aggregator: aggregator,
			vals: vals,
			filters: filters,
		}

		if (!!profile && profile !== "No Profile") {
			frappe.db.set_value('ioi Pivot Expert Display Profile', profile, 'display', JSON.stringify(saved_obj))
		} else {
			profile = 'No Profile'
		}

		if (profile == "No Profile") {
			let me = this

			let d = frappe.msgprint({
				title: __('Notification'),
				message: __("No profile selected. Click 'Close' to save the display without a profile, or 'Yes' to create a new one"),
				primary_action_label: __('Yes'),
				primary_action: {
					action(values) {

						d.hide()
						me.add_display_profile()
					}
				},
				secondary_action: {
					action() {
						d.hide()
					}
				}
			});
		}

		frappe.db.set_value('ioi Pivot Query Profile', this.pivot_saved_query_profile, 'display_profile', profile == "No Profile" ? "" : profile)
		this.pivot_current_display_profile = !!profile ? profile : "No Profile"
	}

	add_display_profile() {
		let me = this

		let d = new frappe.ui.Dialog({
			title: __('Create a new profile'),
			fields: [
				{
					label: 'Profile name',
					fieldname: 'profile_name',
					fieldtype: 'Data'
				},
			],
			size: 'small',
			primary_action_label: __('Create'),
			primary_action(values) {

				frappe.db.insert({
					doctype: 'ioi Pivot Expert Display Profile',
					doc_type: me.doctype,
					user_name: frappe.session.user,
					profile_name: values.profile_name,
					query_profile: me.pivot_saved_query_profile
				}).then(() => {
					me.pivot_current_display_profile = `${me.doctype} • ${frappe.session.user} • ${values.profile_name}`

					me.save_display_profile(me.pivot_current_display_profile !== "No Profile" ? me.pivot_current_display_profile : "")
					me.redraw_main_content()
				})

				d.hide();
			},
		});

		d.show();
	}

	remove_display_profile() {
		const profile = $('#pivot-page-content select[data-fieldname="switch_display_profile"]').val()
		let me = this

		if (!!profile && profile !== "No Profile") {
			frappe.warn(
				__('Are you sure you want to proceed?'),
				__('You are about to delete this display profile permanently'),
				() => {
					frappe.db.set_value('ioi Pivot Query Profile', this.pivot_saved_query_profile, 'display_profile', '').then(() => {
						frappe.db.delete_doc('ioi Pivot Expert Display Profile', profile).then(() => {
							me.pivot_current_display_profile = "No Profile"
							me.redraw_main_content()
						})
					})
				},
				__('Continue'),
			);
		}
	}

	async generate_query() {
		const fields = this.pivot_query_fields.map(field => field.path)

		let path = "name"
		let sort = "desc"
		let limit = 100
		let site_id = (this.meta.fields.filter(field => field.fieldname == "site_id")).length ? this.current_site : ''

		const order_by = [{
			"name": path,
			"desc": sort
		}]

		return await frappe.call('silicon_ioi.utils.lib.system.get_pivot_query_list', {
			params: {
				'doctype_src': this.doctype,
				'fields': fields,
				'order_by': order_by,
				'limit': limit,
				'site_id': site_id
			}
		}).then(r => {
			return r.message
		})
	}

	is_read_only_query(query) {
		if (!!query) {
			const forbidden_keywords = ["insert ", "update ", "delete ", "drop ", "create ", "alter "];
			const normalized_query = query.toLowerCase().replace(/\s+/g, ' ').trim();

			if (!normalized_query.startsWith("select")) {
				return false;
			}

			for (const keyword of forbidden_keywords) {
				if (normalized_query.includes(keyword)) {
					return false;
				}
			}

			return true;
		}
	}

	async read_query_mode(query, testing="false") {

		if (this.is_read_only_query(query)) {
			const regex = /_\{([^}]*)\}/g;
			const reporting_vars = [...query.matchAll(regex)].map(match => match[1]);

			return await frappe.call('silicon_ioi.utils.lib.system.read_pivot_query', {
				query: query,
				variables: reporting_vars,
				testing: testing,
			}).then(r => r.message)
		} else {
			frappe.throw(__('Only read-only queries are allowed, they must start with SELECT'))
		}
	}

	build_pivot_table(datas, display_json = null) {

		const app_container = $("#pivot-content")[0];

		if (this.vue_app) {
			this.vue_app.unmount();
		}

		if (datas.length) {

			if (this.pivot_query_mode == 0) {
				const meta_fields = frappe.get_meta(this.doctype).fields;

				datas = datas.map(data => {
					return Object.keys(data).reduce((acc, obj) => {
						let field = meta_fields.find(field => field.fieldname === obj);
						let key

						if (field) {
							key = __(field.label)
						} else {
							let label = (obj[0].toUpperCase() + obj.slice(1));
							key = __(label)
						}

						acc[key] = data[obj];
						return acc;
					}, {});
				});
			}

			let pivotOptions = {
				data: datas
			};

			// Apply saved profile if exist
			if (display_json) {
				let display = JSON.parse(display_json)

				if (display.rows && display.rows.length) pivotOptions.rows = display.rows.map(row => __(row));
				if (display.cols && display.cols.length) pivotOptions.cols = display.cols.map(col => __(col));
				if (display.renderer) pivotOptions['rendererName'] = __(display.renderer);
				if (display.aggregator) pivotOptions['aggregatorName'] = __(display.aggregator);
				if (display.vals && display.vals.length) pivotOptions.vals = display.vals.map(val => __(val));
				if (display.filters) pivotOptions.valueFilter = display.filters
			}

			this.vue_app = createApp({
				template: ` <vue-pivottable-ui 
											:data="pivotOptions.data" 
											:rows="pivotOptions.rows" 
											:cols="pivotOptions.cols"
											:rendererName="pivotOptions['rendererName']"
											:aggregatorName="pivotOptions['aggregatorName']"
											:vals="pivotOptions.vals"
											:valueFilter="pivotOptions.valueFilter"
							/>`,
				data() {
					return {
						pivotOptions
					};
				}
			});

			this.vue_app.component('vue-pivottable-ui', VuePivottableUI);
			if (app_container) this.vue_app.mount(app_container);
		}
	}
};