class ADatetime extends frappe.ui.form.ControlDatetime {
	make_input() {
		super.make_input();
		let me = this;
		
			me.datepicker.currentDate = new Date();
			me.datepicker.timepicker._setTime(new Date());

			if (me.datepicker.el.parentElement.parentElement.className.includes("filter-field")) {
				me.datepicker.today();
				me.datepicker.timepicker._updateCurrentTime();
			}

			me.refresh();
	}

	get_status(explain) {
		let r = super.get_status(explain);

		// VVR: revert null & read-only field as visible
		if (r === "None" && !cint(this.df.hidden) && !cint(this.df.hidden_due_to_dependency)) {
			r = "Read";
		}

		return r;
	}

};

frappe.provide("frappe.datetime");
$.extend(frappe.datetime, {
	convert_to_user_tz: function (date, format) {
		// format defaults to true
		// Converts the datetime string to system time zone first since the database only stores datetime in
		// system time zone and then convert the string to user time zone(from User doctype).
		let date_obj = null;
		if (frappe.boot.time_zone && frappe.boot.time_zone.system && frappe.boot.time_zone.user) {
			date_obj = moment
				.tz(date, frappe.defaultDatetimeFormat, frappe.boot.time_zone.system)
				.clone()
				.tz(frappe.boot.time_zone.user);
		} else {
			date_obj = moment(date);
		}

		return format === false ? date_obj : date_obj.format(frappe.defaultDatetimeFormat);
	},
	str_to_user: function (val, only_time = false, only_date = false) {
		if (!val) return "";
		const user_date_fmt = frappe.datetime.get_user_date_fmt().toUpperCase();
		const user_time_fmt = frappe.datetime.get_user_time_fmt();
		let user_format = user_time_fmt;

		if (only_time) {
			let date_obj = moment(val, frappe.defaultTimeFormat);
			return date_obj.format(user_format);
		} else if (only_date) {
			let date_obj = moment(val, frappe.defaultDateFormat);
			return date_obj.format(user_date_fmt);
		} else {
			let date_obj = moment.tz(val, frappe.boot.time_zone.system);
			if (typeof val !== "string" || val.indexOf(" ") === -1) {
				user_format = user_date_fmt;
			} else {
				user_format = user_date_fmt + " " + user_time_fmt;
			}
			return date_obj.clone().tz(frappe.boot.time_zone.user).format(user_format);
		}
	},
});
// overriding frappe class
frappe.ui.form.ControlDatetime = ADatetime;
