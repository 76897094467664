frappe.provide('silicon_ioi.scheduling');


const HARD_FIX = {color: 'red', label: 'Hard fix'};
const ADJUST_FIX = {color: 'orange', label: 'Adjust fix'};
const BASIC_ITEM_COLOR = '#8dd2ff'
const BACKGROUND_COLOR_WORKCENTER = '#f3f2f2';
const path_scheduling_production_routing = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_production_routing';
const path_scheduling_dossier_routing = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_dossier_routing';
const path_scheduling_production = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_production';
const path_scheduling_dossier = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_dossier';
const path_scheduling_workcenter = 'silicon_ioi.ioi_manufacturing.ioi_scheduling.ioi_scheduling_workcenter';
const format_date = {
	timeZone: 'UTC'
};

//Warning message
const WARNING_LINE_MACHINE_ALLOCATION_MODE  = 'Please switch workcenter for capacity scheduling on line machine allocation mode';


export class ioiScheduleChart{

	items = [];
	settings = null;
	scheduleChartContainer = null;
	non_working_day = []
	name = ''
	default_zoom = 10
	drag_final_mode_date = 1;
	no_contraint_on_step_in_exec = 0;
	active_load_limit = 0;
	active_load_curve = 0;
	stacking_scope = 30 * 24 * 3600;
	item_selected = null;
	item_selected_name  = null;



	constructor(frm, html_field, mode, name = ''){
		this.frm = frm
		this.name = ((mode == 0 || mode == 1) ? name : '');

		this.scheduleChartContainer = document.createElement('div');
		this.scheduleChartContainer.id = `${html_field}_scheduleChartView`;

		this.mode = cint(mode) //mode = 0: production / if mode = 1: dossier / if mode = 2: workcenter

		this.frm.fields_dict[html_field].$wrapper.append(this.scheduleChartContainer);

		//Usefull data
		this.active_load_limit = (this.frm.doc.active_load_limit ? cint(this.frm.doc.active_load_limit) : 0);
		this.active_load_curve = (this.frm.doc.active_load_curve ? cint(this.frm.doc.active_load_curve) : 0);
		this.stacking_scope = (this.frm.doc.stacking_scope ? cint(this.frm.doc.stacking_scope) : 30 * 24 * 3600);

		this.load_settings()
	}


	load_settings(){
		let me = this
		me.settings = { currentTime: new Date() };
		me.settings.isRelativeToTimezone = true;
		me.settings.itemHeight = 40;
		me.settings.barHeight = 24;
		me.settings.barMargin = 8;
		me.settings.maxDisplayedUnits = 1.5
		me.settings.workingWeekStart  = 1; // Monday
		me.settings.workingWeekFinish = 0; // Sunday
		me.settings.visibleWeekStart  = 1; // Monday
		me.settings.visibleWeekFinish = 0; // Sunday
		me.settings.visibleDayStart   = 0; // 00:00
		me.settings.visibleDayFinish  = 24 * 60 * 60 * 1000; // 24:00
		me.settings.theme = 'ModernBordered';
		me.settings.weekStartDay = 1; // Monday
		me.settings.isMouseWheelZoomEnabled = false;
		me.settings.isMouseWheelZoomEnabledMinHourWidth = 1;
		me.settings.isMouseWheelZoomEnabledMaxHourWidth = 200;
		me.settings.isReadOnly = false;
		// me.settings.containerStyle = 'background-color: red;';

		me.settings.isTaskEffortReadOnly = true;
		me.settings.isTaskEffortPreservedWhenStartChangesInGrid = true;
		me.settings.isTaskCompletionReadOnly = true;
		me.settings.isDraggingTaskStartEndsEnabled = false;
		me.settings.allowCreatingStartDependencies = false;
		me.settings.allowCreatingToFinishDependencies = false;

		me.settings.useInlineToolTips = true;
		me.settings.itemTemplate = function (item) {
			me.item_selected_name = item.name;
			return me.get_tool_tip(item);
		}


		me.settings.extraTaskTemplate = function(item) {
			let scheduleChartView = item.scheduleChartView;
			let settings = scheduleChartView.settings;
			let document = scheduleChartView.ownerDocument;
			let svgns = 'http://www.w3.org/2000/svg';
			// let svgGroup = originalStandardTaskTemplate(item);
			let svgGroup = document.createElementNS(svgns, 'g');
			
			let item_left = scheduleChartView.getChartPosition(item.start, settings);
			let item_right = scheduleChartView.getChartPosition(item.finish, settings);
			let svg_elem = null

			if (item.objects && item_left && item_right) {
				for (const [index, obj] of item.objects.entries()) {

					if (!obj){
						continue
					}

					//Correction min zoom
					if (obj.position_pin == 'end'){
						if ((item_right - item_left) < 12){
							item_right = item_left + 12
						}
					}
					let x = (obj.position_pin == 'start' ? item_left : obj.position_pin == 'end' ? item_right - 1 : 0);
					let y = (obj.position_level == 'top' ? settings.barMargin : obj.position_level == 'bottom' ? settings.barMargin + settings.barHeight : obj.position_level == 'central'? settings.barMargin + settings.barHeight/2: 0);
					let h = settings.barHeight


					if (obj.object == 'cross'){
						svg_elem = document.createElementNS(svgns, 'g');
						let svg_line_1 = document.createElementNS(svgns, 'line');
						let svg_line_2 = document.createElementNS(svgns, 'line');
						svg_line_1.setAttribute('x1', x - h/5);
						svg_line_1.setAttribute('y1', y + h/5);
						svg_line_1.setAttribute('x2', x + h/5);
						svg_line_1.setAttribute('y2', y - h/5);
						svg_line_2.setAttribute('x1', x - h/5);
						svg_line_2.setAttribute('y1', y - h/5);
						svg_line_2.setAttribute('x2', x + h/5);
						svg_line_2.setAttribute('y2', y + h/5);
						svg_elem.setAttribute('stroke', obj.color);
						svg_elem.setAttribute('stroke-width', '2px');

						let svg_hitbox = document.createElementNS(svgns, 'rect');
						svg_hitbox.setAttribute('x', x - h/5 - 5/2); 
						svg_hitbox.setAttribute('y', y - h/5 - 5/2); 
						svg_hitbox.setAttribute('width', (h/5) * 2 + 5); 
						svg_hitbox.setAttribute('height', (h/5) * 2 + 5); 
						svg_hitbox.setAttribute('fill', 'transparent');
						svg_hitbox.setAttribute('stroke', 'transparent'); 

						svg_elem.appendChild(svg_line_1);
						svg_elem.appendChild(svg_line_2);
						svg_elem.appendChild(svg_hitbox);

						//Change mode date on click
						svg_hitbox.addEventListener('click', function(event) {
							if (!me.settings.isReadOnly){
								svg_elem = this.parentNode;
								if (event.ctrlKey){
									if ((obj.position_pin == 'start') && (item.start_mode == 1)){
										svg_elem.setAttribute('stroke', HARD_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = HARD_FIX.label;}
	
										item.objects[index].info = HARD_FIX.label
										item.objects[index].color = HARD_FIX.color
										item.start_mode = 2
									}
									else if ((obj.position_pin == 'end') && (item.end_mode == 1)){
										svg_elem.setAttribute('stroke', HARD_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = HARD_FIX.label;}
	
										item.objects[index].info = HARD_FIX.label
										item.objects[index].color = HARD_FIX.color
										item.end_mode = 2
									}
									else if ((obj.position_pin == 'start') && (item.start_mode == 2)){
										svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
										item.objects[index].info = ADJUST_FIX.label
										item.objects[index].color = ADJUST_FIX.color
										item.start_mode = 1
									}
									else if ((obj.position_pin == 'end') && (item.end_mode == 2)){
										svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
										let titleElement = svg_elem.querySelector('title');
										if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
										item.objects[index].info = ADJUST_FIX.label
										item.objects[index].color = ADJUST_FIX.color
										item.end_mode = 1
									}
								}else{
									if (obj.position_pin == 'start'){
										if (item.start_mode == 0){
											svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
											let titleElement = svg_elem.querySelector('title');
											if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
											item.objects[index].info = ADJUST_FIX.label
											item.objects[index].color = ADJUST_FIX.color
											item.start_mode = 1
										}else{
											svg_elem.setAttribute('stroke', 'transparent');
											item.objects[index].info = ''
											item.objects[index].color = 'transparent'
											item.start_mode = 0
										}
										
									}
									if (obj.position_pin == 'end'){
										if (item.end_mode == 0){
											svg_elem.setAttribute('stroke', ADJUST_FIX.color);
						
											let titleElement = svg_elem.querySelector('title');
											if (titleElement) {titleElement.textContent = ADJUST_FIX.label;}
	
											item.objects[index].info = ADJUST_FIX.label
											item.objects[index].color = ADJUST_FIX.color
											item.end_mode = 1
										}else{
											svg_elem.setAttribute('stroke', 'transparent');
											item.objects[index].info = ''
											item.objects[index].color = 'transparent'
											item.end_mode = 0
										}
									}
								}
								me.compute_scheduled_date(
									{
										'name': item.parent_name,
										'routing_list': [me.get_data_routing(item)]
									},
									item
								);
							}
						});
					}

					if (obj.object == 'left-triangle'){
						svg_elem = document.createElementNS(svgns, 'polygon');
						svg_elem.setAttribute('points', (x - h/3) + ',' + y + ' ' + x  + ',' + (y + h/5) + ' ' + x + ',' + (y - h/5))
						svg_elem.setAttribute('stroke', 'black');
						svg_elem.setAttribute('stroke-width', '0.5px');
						svg_elem.setAttribute('fill', obj.color);
					}
					if (obj.object == 'right-triangle'){
						svg_elem = document.createElementNS(svgns, 'polygon');
						svg_elem.setAttribute('points', (x + h/3) + ',' + y + ' ' + x  + ',' + (y + h/5) + ' ' + x + ',' + (y - h/5))
						svg_elem.setAttribute('stroke', 'black');
						svg_elem.setAttribute('stroke-width', '0.5px');
						svg_elem.setAttribute('fill', obj.color);
					}
					if (svg_elem){
						if (obj.info) {
							let tool_tip = document.createElementNS(svgns, 'title');
							tool_tip.appendChild(document.createTextNode(obj.info));
							svg_elem.appendChild(tool_tip);
						}
						let index = svgGroup.childNodes.length - 1; // Dependency creation thumb.
						svgGroup.insertBefore(svg_elem, svgGroup.childNodes[index]);
					}
				}
			return svgGroup;
			}
		}


		//Scheduling after drag and drop
		me.settings.itemPropertyChangeHandler = function (item, propertyName, isDirect, isFinal) {
			//Drag and drop element in the same line machine
			if (isDirect && isFinal && (propertyName == 'start' || propertyName == 'finish')){
				//Fix the date for the item changed
				if (cint(item.pivot) == 0){
					item.end_mode = 2;
					item.start_mode = 0
				}else{
					item.start_mode = 2;
					item.end_mode = 0
				}
				//Scheduling method
				me.compute_scheduled_date(
					{
						'opening_date': (cint(item.pivot) == 0 ? item.finish : item.start),
						'idx': item.idx
					},
					item
				);
			}
			//Drag and drop element move in other line machine
			if (isDirect && isFinal && propertyName == 'scheduleChartItem'){
				if(cint(item.scheduleChartItem.scheduleChartIndex) != cint(item.displayRowIndex)){
					item.line_machine_id = item.scheduleChartItem.line_machine_id;
					//Scheduling method
					me.compute_scheduled_date(
						{
							'name': item.parent_name,
							'routing_list': [me.get_data_routing(item)]
						},
						item
					);
				}
			}
		}

		me.settings.itemClickHandler = function(isOnChart, item, column){
			if (isOnChart){
				for (var i = 0; i < item.ganttChartItems.length; i++){
					var item_part = item.ganttChartItems[i];
					if (me.item_selected_name == item_part.name){
						me.item_selected = item_part;
						if (me.ioiGanttControl.gantt_custom_bar_color){
							me.ioiGanttControl.gantt_custom_bar_color.value = (item_part.gantt_color? item_part.gantt_color : BASIC_ITEM_COLOR)
						}
						break;
					}
				}
			}
		}

	}


	get_tool_tip(item){
		let toolTip = document.createElementNS('http://www.w3.org/2000/svg', 'title');
		let toolTipContent = '• ' + item.content;
		toolTipContent += '\n' + 'Start: ' + item.start.toLocaleString('fr-FR', format_date);
		toolTipContent += '\n' + 'Finish: ' + item.finish.toLocaleString('fr-FR', format_date);
		toolTip.appendChild(document.createTextNode(toolTipContent));
		// toolTip.setAttribute('style', "font-size: 12px; font-family: 'system-ui', Arial;")
		return toolTip;
	}

	initialize_columns(display_columns){
		let me = this;
		let columns = []
		//Forced stacking
		columns.push({header: __('Forced stacking'), allowUserToResize: false, width: 60, cellTemplate: function (item) {
			if(!item.line_machine_id && !item.main_operator_id){
				return item.ganttChartView.ownerDocument.createTextNode(''); 
			}
			var button = document.createElement('div');
			button.innerHTML = `
				<button title="${__("Forced stacking")}" id="line_${item.scheduleChartIndex}_forced_stack_btn" data-label="Forced stack" class="btn btn-default btn-xs ellipsis" style="margin-left: 10px;">
					<i class="fa fa-fast-forward"></i>
				</button>`;
				button.onclick = () => {
					if(me.active_load_limit != 2){
						if(me.ioiGanttControl){
							me.ioiGanttControl.display_stacking_warning_message(1, WARNING_LINE_MACHINE_ALLOCATION_MODE)
							return
						}
					}
					if(me.settings.isReadOnly) return
					ioiScheduleChart.set_btn_disabled($(`#line_${item.scheduleChartIndex}_forced_stack_btn`));
					setTimeout(function() {
						me.compute_stacking(
							cstr(item.line_machine_id),
							me.stacking_scope
							)
						ioiScheduleChart.set_btn_enabled($(`#line_${item.scheduleChartIndex}_forced_stack_btn`));
					})
				}
			return button;
		}});
		//Contraint stacking
		columns.push({header: __('Contraint stacking'), allowUserToResize: false, width: 60, cellTemplate: function (item) {
			if(!item.line_machine_id && !item.main_operator_id){
				return item.ganttChartView.ownerDocument.createTextNode(''); 
			}
			var button = document.createElement('div');
			button.innerHTML = `
				<button title="${__("Contraint stacking")}" id="line_${item.scheduleChartIndex}_contraint_stack_btn" data-label="Contraint stack" class="btn btn-default btn-xs ellipsis" style="margin-left: 10px;">
					<i class="fa fa-forward"></i>
				</button>`;
				button.onclick = () => {
					if(me.active_load_limit != 2){
						if(me.ioiGanttControl){
							me.ioiGanttControl.display_stacking_warning_message(1, WARNING_LINE_MACHINE_ALLOCATION_MODE)
							return
						}
					}
					if(me.settings.isReadOnly) return
					ioiScheduleChart.set_btn_disabled($(`#line_${item.scheduleChartIndex}_contraint_stack_btn`));
					setTimeout(function() {
						me.compute_constraint_stacking(
							cstr(item.line_machine_id),
							me.stacking_scope
							)
						ioiScheduleChart.set_btn_enabled($(`#line_${item.scheduleChartIndex}_contraint_stack_btn`));
					})
				}
			return button;
		}});
		//Workcenter
		if(display_columns.includes('workcenter')){
			columns.push({ header: __('Workcenter'), width: 200, cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.content); } });
		}
		//Description
		if(display_columns.includes('description')){
			columns.push({ header: __('Description'), width: 200, cellTemplate: function (item) { 
				return item.ganttChartView.ownerDocument.createTextNode(item.description); } });
		}
		//Operator
		if(display_columns.includes('operator')){
			columns.push({header: __('Operator'),width: 150, cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.main_operator_id ? item.main_operator_id : ''); } });
		}
		//initial
		if(display_columns.includes('initial')){
			columns.push({header: __('Initial'),width: 150, isReadOnly: true, isTreeView: true, cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.initial ? item.initial : ''); } });
		}
		//Firstname
		if(display_columns.includes('firstname')){
			columns.push({header: __('Firstname'),width: 150, isReadOnly: true, isTreeView: true, cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.firstname ? item.firstname : ''); } });
		}
		//Lastname
		if(display_columns.includes('lastname')){
			columns.push({header: __('Lastname'),width: 150, isReadOnly: true, isTreeView: true, cellTemplate: function (item) {
				return item.ganttChartView.ownerDocument.createTextNode(item.lastname ? item.lastname : ''); } });
		}
		this.settings.columns = columns
	}

	initialize()
	{
		if(this.ioiGanttControl){
			this.ioiGanttControl.initialize_scales(this.settings);
			this.initialize_scales(this.settings.scales)
		}
		this.scheduleChartView = ioi.DlhSoft.Controls.ScheduleChartView.initialize(
			this.scheduleChartContainer,
			this.items,
			this.settings
		);
	}


	initialize_scales(scales){
		if (scales){
			if (this.stacking_scope) {
				// Define base date and timezone offset
				const scope_date = new Date();
				const timezoneOffsetMinutes = scope_date.getTimezoneOffset() * 60000; // Convert to milliseconds
				const scope_start_time = this.stacking_scope * 1000;
				
				// Calculate special dates
				const scope_date_start = new Date(scope_date.getTime() + scope_start_time - timezoneOffsetMinutes);
				const scope_date_start_separator = new Date(scope_date_start.getTime() - 3600000); // 1 hour earlier
				const scope_date_finish = new Date(scope_date.getFullYear(), 11, 31, 23, 59, 59); // End of year
				
				const specialDayIntervals = [{ start: scope_date_start, finish: scope_date_finish }];

				const removeScaleById = (id) => {
					const index = scales.findIndex(scale => scale.id === id);
					if (index !== -1) scales.splice(index, 1);
				};
		
				// Remove previous separators and highlights
				removeScaleById('stacking_scope_separator');
				removeScaleById('stacking_scope_highlight');
		
				// Add separator
				scales.push({
					id: 'stacking_scope_separator',
					scaleType: 'Custom',
					isHeaderVisible: false,
					intervals: [{ start: scope_date_start_separator, finish: scope_date_start }],
					isSeparatorVisible: true,
					separatorStyle: 'stroke: red; stroke-width: 1px',
					isRelativeToTimezone: true
				});
		
				// Add highlight
				scales.push({
					id: 'stacking_scope_highlight',
					scaleType: 'Custom',
					isHeaderVisible: false,
					intervals: specialDayIntervals,
					isHighlightingVisible: true,
					highlightingStyle: 'fill: grey; opacity: 0.15',
					isRelativeToTimezone: true
				});
				
			}

			this.settings.scales = scales
		}
	}


	refresh_schedule_chart(){
		if (this.scheduleChartView) {
			this.initialize_scales(this.settings.scales);
			this.scheduleChartView.refresh();
		}
	}


	set_read_only(){
		this.settings.isReadOnly = true;

	}


	make_schedule_chart_control(
		html_scheduling_field = '',
		html_timeline_field = '',
		options_context_menu = []
	)
	{
		this.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(2);
		this.frm.fields_dict[html_timeline_field].$wrapper.empty();
		this.frm.fields_dict[html_scheduling_field].$wrapper.empty();

		if (html_timeline_field){
			this.get_schedule_chart_timeline(this.ioiGanttControl, html_timeline_field);
			this.get_selected_routing_control(this.ioiGanttControl);
		}
		if(html_scheduling_field){
			this.get_schedule_chart_scheduling_html(this.ioiGanttControl, html_scheduling_field);
		}
		if(options_context_menu && options_context_menu.length){
			this.get_gantt_context_menu_html(this.ioiGanttControl, options_context_menu)
		}
	}

	get_selected_routing_control(ioiGanttControl){
		let me = this;

		if (!(ioiGanttControl))
			return

		//Change Color
		if (ioiGanttControl.gantt_custom_bar_color){
			ioiGanttControl.gantt_custom_bar_color.oninput = () => {
				if(me.item_selected){
					let item = me.item_selected;
					item.gantt_color = ioiGanttControl.gantt_custom_bar_color.value;
					me.set_item_gantt_color(item, (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR));
					me.scheduleChartView.refreshItem(item);
				}
			}
			ioiGanttControl.gantt_custom_bar_color.onchange = () => {
				if(me.item_selected){
					let item = me.item_selected;
					item.gantt_color = ioiGanttControl.gantt_custom_bar_color.value;
					me.set_item_gantt_color(item, (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR));
					me.update_gantt_color(item.name, item.gantt_color);
					me.refresh_schedule_chart();
				}
			}
		}

		//Schedule Gantt
		if (ioiGanttControl.gantt_schedule_selected_routing){
			ioiGanttControl.gantt_schedule_selected_routing.onclick = () => {
				//Get item selected
				if(me.item_selected){
					let item = me.item_selected;
					me.schedule_item(item);
				}
			}
		}
	}

	schedule_item(item){
		let me = this;
		var schedule_routing_dialog = new frappe.ui.Dialog({
			'title': __("Schedule rounting"),
			'fields': [
				{'fieldname': 'html_canevas', 'fieldtype': 'HTML'}
			],
			primary_action_label: __('Apply'),
			primary_action: function(){
				//Reload Gantt

				let res = me.get_items_by_line_machine(
					cint(me.ioiGanttControl.gantt_select_source_items.value),
					me.name
				)
				//Set Items
				me.items = [];
				for (var i = 0; i < res.length; i++) {
					me.set_item(res[i].data, res[i].routing_detail);
				}

				me.initialize();
				me.refresh_schedule_chart();

				schedule_routing_dialog.hide();
			}
		});

		//Create new Gantt
		let ioi_gantt = new silicon_ioi.scheduling.ioiGantt(schedule_routing_dialog, 'html_canevas', me.mode, item.parent_name);
		//Get routings list
		let res = ioi_gantt.get_items_from_routings();
		//Set items on Gantt
		ioi_gantt.items = ioi_gantt.set_items(
			res.data,
			res.routing_detail
		);
		ioi_gantt.initialize_columns();
		ioi_gantt.initialize();
		ioi_gantt.change_timeline(1);
		//Scales by default
		ioi_gantt.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(0);
		ioi_gantt.ioiGanttControl.initialize_scales(ioi_gantt.settings);
		ioi_gantt.settings.isTaskToolTipVisible = false;

		ioi_gantt.refresh_gantt();

		schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-width", "1080px").css("width", "1080px");
		schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
		schedule_routing_dialog.show();
	}

	get_gantt_context_menu_html(ioiGanttControl, options = []){
		let me = this;

		if(options.length){
			ioiGanttControl.make_gantt_context_menu_html();
			me.set_item_context_menu(ioiGanttControl, options);
		}
	}


	set_item_context_menu(ioiGanttControl, options){
		let me = this;
		//Context menu item
		if(ioiGanttControl){
			me.settings.itemContextMenuHandler = function(e, item){
				me.refresh_schedule_chart();
				//Add context menu 
				ioiGanttControl.itemContextMenuHandler(e, options);

				let scheduleItemMousedown = function() {
					//Manage Double click... 
					if(ioiGanttControl.close_context_menu){
						var schedule_routing_dialog = new frappe.ui.Dialog({
							'title': __("Schedule rounting"),
							'fields': [
								{'fieldname': 'html_canevas', 'fieldtype': 'HTML'}
							],
							primary_action_label: __('Apply'),
							primary_action: function(){
								//Reload Gantt

								let res = me.get_items_by_line_machine(
									cint(me.ioiGanttControl.gantt_select_source_items.value),
									me.name
								)
								//Set Items
								me.items = [];
								for (var i = 0; i < res.length; i++) {
									me.set_item(res[i].data, res[i].routing_detail);
								}

								me.initialize();
								me.refresh_schedule_chart();
	
								schedule_routing_dialog.hide();
								ioiGanttControl.close_context_menu = true;
							}
						});
						//Create new Gantt
						let ioi_gantt = new silicon_ioi.scheduling.ioiGantt(schedule_routing_dialog, 'html_canevas', me.mode, item.parent_name);
						//Get routings list
						let res = ioi_gantt.get_items_from_routings();
						//Set items on Gantt
						ioi_gantt.items = ioi_gantt.set_items(
							res.data,
							res.routing_detail
						);
						ioi_gantt.initialize_columns();
						ioi_gantt.initialize();
						ioi_gantt.change_timeline(1);
						//Scales by default
						ioi_gantt.ioiGanttControl = new silicon_ioi.scheduling.ioiGanttControl(0);
						ioi_gantt.ioiGanttControl.initialize_scales(ioi_gantt.settings);
						ioi_gantt.settings.isTaskToolTipVisible = false;
	
						ioi_gantt.refresh_gantt();
	
						schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-width", "1080px").css("width", "1080px");
						schedule_routing_dialog.$wrapper.find('.modal-dialog').css("max-height", "450px").css("height", "450px");
						schedule_routing_dialog.show();
						ioiGanttControl.close_context_menu = false;
						e.stopPropagation();
					}
					ioiGanttControl.scheduleItemContextMenu.removeEventListener("mousedown", scheduleItemMousedown);
				}
	
				//Add event listener
				//Schedule item
				if(ioiGanttControl.scheduleItemContextMenu){
					ioiGanttControl.scheduleItemContextMenu.addEventListener("mousedown",scheduleItemMousedown);
				}

				//Change color
				if(ioiGanttControl.changeColorContextMenu){
					ioiGanttControl.changeColorContextMenu.set_value((item.gantt_color) ? item.gantt_color : null);
					ioiGanttControl.changeColorContextMenu.df.onchange = () => {
						item.gantt_color = ioiGanttControl.changeColorContextMenu.value;
						me.set_item_gantt_color(item, (item.gantt_color? item.gantt_color : BASIC_ITEM_COLOR));
						me.update_gantt_color(item.name, item.gantt_color);
						me.refresh_schedule_chart();
					}
				}
				me.refresh_schedule_chart();
			}
		}
	}


	get_schedule_chart_scheduling_html(ioiGanttControl, html_field){
		let me = this;

		ioiGanttControl.make_schedule_chart_scheduling_html(me.frm.fields_dict[html_field], html_field);

		//Forced Stacked for all line
		if(ioiGanttControl.schedulechart_forced_stack_btn){
			ioiGanttControl.schedulechart_forced_stack_btn.$input.click(
				() => {
					if(me.active_load_limit != 2){
						ioiGanttControl.display_stacking_warning_message(1, WARNING_LINE_MACHINE_ALLOCATION_MODE)
						return
					}
					if(me.settings.isReadOnly) return
					if(me.items && me.items.length > 1){
						ioiScheduleChart.set_btn_disabled(ioiGanttControl.schedulechart_forced_stack_btn.$input);
						setTimeout(function() {
							me.compute_stacking(
								me.items.slice(1).map(row => (row.line_machine_id)),
								me.stacking_scope
								)
							ioiScheduleChart.set_btn_enabled($(ioiGanttControl.schedulechart_forced_stack_btn.$input));
						}, 50)
					}
				}
			)
		}

		//Contraint Stacked for all line
		if(ioiGanttControl.schedulechart_contraint_stack_btn){
			ioiGanttControl.schedulechart_contraint_stack_btn.$input.click(
				() => {
					if(me.active_load_limit != 2){
						ioiGanttControl.display_stacking_warning_message(1, WARNING_LINE_MACHINE_ALLOCATION_MODE)
						return
					}
					if(me.settings.isReadOnly) return
					if(me.items && me.items.length > 1){
						ioiScheduleChart.set_btn_disabled(ioiGanttControl.schedulechart_contraint_stack_btn.$input);
						setTimeout(function() {
							me.compute_constraint_stacking(
								me.items.slice(1).map(row => (row.line_machine_id)),
								me.stacking_scope
							)
							ioiScheduleChart.set_btn_enabled(ioiGanttControl.schedulechart_contraint_stack_btn.$input);
						}, 50)
					}
				}
			)
		}

		//No contraint on step in exec
		if(ioiGanttControl.schedulechart_no_contraint_on_step_in_exec){
			//jquery
			ioiGanttControl.schedulechart_no_contraint_on_step_in_exec.$input.on('change', () => {
					me.no_contraint_on_step_in_exec = (ioiGanttControl.schedulechart_no_contraint_on_step_in_exec.$input.prop('checked') ? 1 : 0);
				}
			)
		}

	}


	get_schedule_chart_timeline(ioiGanttControl, html_field){
		let me = this;

		ioiGanttControl.make_timeline_html(me.frm.fields_dict[html_field], html_field);
		me.frm.fields_dict[html_field].$wrapper.append(me.scheduleChartContainer);

		//Change Timeline
		if (ioiGanttControl.gantt_timeline_btn){
			ioiGanttControl.gantt_timeline_btn.onclick = () => {
				me.change_timeline(cint(ioiGanttControl.gantt_timeline_input.value))
				me.refresh_schedule_chart();
			};
		}

		//Decrease Timeline
		if (ioiGanttControl.gantt_decrease_timeline_btn){
			ioiGanttControl.gantt_decrease_timeline_btn.onclick = () => {
				ioiGanttControl.decrease_timeline(
					me.scheduleChartView,
					cint(ioiGanttControl.gantt_timeline_input.value)
				)
				me.refresh_schedule_chart();
			};
		}

		//Increase Timeline
		if (ioiGanttControl.gantt_increase_timeline_btn){
			ioiGanttControl.gantt_increase_timeline_btn.onclick = () => {
				ioiGanttControl.increase_timeline(
					me.scheduleChartView,
					cint(ioiGanttControl.gantt_timeline_input.value)
				)
				me.refresh_schedule_chart();
			};
		}

		//Show non-working day
		if (ioiGanttControl.gantt_show_non_working_day){
			ioiGanttControl.gantt_show_non_working_day.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_schedule_chart();
			}
		}

		//Change Major scale
		if (ioiGanttControl.gantt_major_scale_type_select){
			ioiGanttControl.gantt_major_scale_type_select.onchange = () => {
				ioiGanttControl.update_from_selected_major_scale_type();
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_schedule_chart();
			}
		}

		//Change Major format
		if (ioiGanttControl.gantt_major_scale_format_select){
			ioiGanttControl.gantt_major_scale_format_select.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_schedule_chart();
			}
		}

		//Change Minor scale
		if (ioiGanttControl.gantt_minor_scale_type_select){
			ioiGanttControl.gantt_minor_scale_type_select.onchange = () => {
				ioiGanttControl.update_from_selected_minor_scale_type();
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_schedule_chart();
			}
		}

		//Change Minor format
		if (ioiGanttControl.gantt_minor_scale_format_select){
			ioiGanttControl.gantt_minor_scale_format_select.onchange = () => {
				ioiGanttControl.initialize_scales(me.settings);
				me.refresh_schedule_chart();
			}
		}

		//Change gantt zoom level
		if (ioiGanttControl.gantt_zoom_level){
			ioiGanttControl.gantt_zoom_level.oninput = () => {
				ioiGanttControl.initialize_scales_zoom_level(
					me.settings,
					ioiGanttControl.gantt_zoom_level.value,
					ioiGanttControl.gantt_zoom_level.getAttribute('min'),
					ioiGanttControl.gantt_zoom_level.getAttribute('max')
				);
				me.refresh_schedule_chart();
			}
		}

		if (ioiGanttControl.gantt_select_source_items){
			//Remove first option
			ioiGanttControl.gantt_select_source_items.remove(0)
			ioiGanttControl.gantt_select_source_items.onchange = () => {
				if(ioiGanttControl.gantt_select_source_items.value != -1){
					me.mode = cint(ioiGanttControl.gantt_select_source_items.value)

					let res = me.get_items_by_line_machine(
						cint(ioiGanttControl.gantt_select_source_items.value),
						me.name
					)
					//Set Items
					me.items = [];
					for (var i = 0; i < res.length; i++) {
						me.set_item(res[i].data, res[i].routing_detail);
					}
					//Init Schedule
					me.initialize_columns();
					me.initialize();
					me.change_timeline(1);
					me.refresh_schedule_chart();
				}
			}
		}

		if (ioiGanttControl.schedulechart_move_up){
			ioiGanttControl.schedulechart_move_up.onclick = () => {
				if (me.scheduleChartView.selectedItem == null)
					return;
				let item = me.scheduleChartView.selectedItem;
				if(!item.line_machine_id)
					return;
				if(cint(item.scheduleChartIndex) == 1)
					return;
				me.scheduleChartView.moveScheduleChartItemUp(item);
				me.scheduleChartView.scrollToItem(item);
				me.update_line_machine_order();
			}
		}

		if (ioiGanttControl.schedulechart_move_down){
			ioiGanttControl.schedulechart_move_down.onclick = () => {
				if (me.scheduleChartView.selectedItem == null)
					return;
				let item = me.scheduleChartView.selectedItem;
				if(!item.line_machine_id)
					return;
				if(cint(item.scheduleChartIndex) == me.items.length - 1)
					return;
				me.scheduleChartView.moveScheduleChartItemDown(item);
				me.scheduleChartView.scrollToItem(item);
				me.update_line_machine_order();
			}
		}



	}

	change_timeline(time_amount_day){
		silicon_ioi.scheduling.ioiGanttControl.change_timeline(this.settings, time_amount_day);
		if (this.items.length){
			for (var i = 0; i < this.items.length; i++) {
				if(this.items[i].isSelected){
					let ganttChartItemsLength = this.items[i].ganttChartItems.length;
					if(ganttChartItemsLength > 0){
						let start_date = new Date(this.items[i].ganttChartItems[0].start);
						let end_date = new Date(this.items[i].ganttChartItems[ganttChartItemsLength - 1].finish);
						start_date.setDate(start_date.getDate() - (cint(time_amount_day) * 7));
						end_date.setDate(end_date.getDate() + (cint(time_amount_day) * 7));
						this.settings.timelineStart = start_date;
						this.settings.timelineFinish = end_date;
					}
					break;
				}
			}
		}
	}


	set_item(data, routing_detail){
		let me = this;
		let ganttChartItems = [];
		let item = {};
		if (routing_detail && routing_detail.length){
			for (var i = 0; i < routing_detail.length; i++) {
				let row = routing_detail[i];
				item = {}
				item.name = (row.name ? row.name : '')
				item.parent_name= (row.parent_name ? row.parent_name : '')
				item.idx = row.idx
				item.step_id = row.step_id
				item.line_machine_id = (data.line_machine_id ? data.line_machine_id : '')
				item.content= (row.parent_name ? row.parent_name : '') + ' • ' + row.step_id
				item.start= (row.start_scheduled ? new Date(row.start_scheduled) : '')
				item.finish= (row.end_scheduled ? new Date(row.end_scheduled) : '')
				item.start_mode= row.start_mode
				item.end_mode= row.end_mode
				item.pivot= cint(row.pivot)
				item.isRelativeToTimezone= true
				//Color
				item.gantt_color = row.gantt_color;
				me.set_item_gantt_color(item, row.gantt_color);
				//Pivot
				item.objects = [
					{
						position_pin: (cint(row.header_scheduling_pivot) == 0 ? 'end' : 'start'),
						position_level: 'bottom', 
						object: (cint(row.header_scheduling_pivot) == 0 ? 'left-triangle' : 'right-triangle'), 
						color: 'green', 
						info: (cint(row.header_scheduling_pivot) == 0 ? __('At the lastest') : __('At the soonest'))
					}
				];
				//Fixed date: start mode
				if ([0,1,2].includes(cint(row.start_mode))){
					item.objects.push(
						{
							position_pin: 'start', 
							position_level: 'top', 
							object: 'cross', 
							color: (cint(row.start_mode) == 1 ? 'orange' : cint(row.start_mode) == 2 ? 'red': 'transparent'), 
							info: (cint(row.start_mode) == 1 ? 'Adjust fix' : cint(row.start_mode) == 2 ? 'Hard fix': '')
						}
					)
				}
				//Fixed date: end mode
				if ([0,1,2].includes(cint(row.end_mode))){
					item.objects.push(
						{
							position_pin: 'end', 
							position_level: 'top', 
							object: 'cross', 
							color: (cint(row.end_mode) == 1 ? 'orange' : cint(row.end_mode) == 2 ? 'red': 'transparent'), 
							info: (cint(row.end_mode) == 1 ? 'Adjust fix' : cint(row.end_mode) == 2 ? 'Hard fix': '')
						}
					)
				}
				ganttChartItems.push(item)
			}
		}


		this.items.push({
			content: data.name,
			line_machine_id: data.line_machine_id,
			description: data.description,
			main_operator_id: data.main_operator_id,
			initial: data.initial,
			lastname: data.lastname,
			firstname: data.firstname,
			indentation: 0,
			ganttChartItems: ganttChartItems
		})
		this.set_main_workcenter_background_color();
	}


	set_item_gantt_color(item, color){
		if(color){
			item.standardBarStyle = `
				fill: ${color};
				fill-opacity: 0.8;
				stroke: ${silicon_ioi.scheduling.ioiGanttControl.darken_color(color, 30)};
				stroke-width: 0.65px;
			`
			item.standardCompletedBarStyle = `
				fill: ${silicon_ioi.scheduling.ioiGanttControl.darken_color(color, 30)};
				stroke: ${silicon_ioi.scheduling.ioiGanttControl.darken_color(color, 30)}
			`
		}
	}


	set_main_workcenter_background_color(){
		let me = this;
		me.items[0].style = `background: ${BACKGROUND_COLOR_WORKCENTER};`;
		// me.items[0].ganttChartItems.splice(0, 0, {
		// 	taskTemplate: function(item) {
		// 		var ganttChartView = item.ganttChartView;
		// 		var document = ganttChartView.ownerDocument;
		// 		var svgns = 'http://www.w3.org/2000/svg';
		// 		var rect = document.createElementNS(svgns, 'rect');
		// 		var itemLeft = ganttChartView.getChartPosition(me.settings.timelineStart, me.settings);
		// 		var itemRight = ganttChartView.getChartPosition(me.settings.timelineFinish, me.settings);
		// 		rect.setAttribute('x', itemLeft - 1);
		// 		rect.setAttribute('y', 0);
		// 		rect.setAttribute('width', itemRight - itemLeft + 1);
		// 		rect.setAttribute('height', me.settings.itemHeight);
		// 		rect.setAttribute('style', `fill: ${BACKGROUND_COLOR_WORKCENTER}`);
		// 		return rect;
		// 	}
		// });
	}


	get_items_by_line_machine(source, workcenter_id){
		let method = ''
		let res = []
		switch(cint(source)){
			case -1:
				return []
			case 0:
				method = path_scheduling_workcenter + '.ioi_scheduling_production_get_gantt_items_by_line_machine';
				break;
			case 1:
				method = path_scheduling_workcenter + '.ioi_scheduling_dossier_get_gantt_items_by_line_machine';
				break;
		}

		frappe.call({
			method: method,
			args: {workcenter_id : workcenter_id},
			async: false,
			callback:function(r){
				res = r.message;
			}
		});
		return res
	}


	get_items_by_operator(source, workcenter_id){
		let method = ''
		let res = []
		switch(cint(source)){
			case -1:
				return []
			case 0:
				method = path_scheduling_workcenter + '.ioi_scheduling_production_get_gantt_items_by_operator';
				break;
			case 1:
				method = path_scheduling_workcenter + '.ioi_scheduling_dossier_get_gantt_items_by_operator';
				break;
		}

		frappe.call({
			method: method,
			args: {workcenter_id : workcenter_id},
			async: false,
			callback:function(r){
				res = r.message;
			}
		});
		return res
	}


	compute_scheduled_date(args = {}, item = null){
		let me = this;

		let method = ''
		if (me.mode == 0){
			method = path_scheduling_production + '.ioi_production_compute_scheduled_date';
		}else if(me.mode == 1){
			method = path_scheduling_dossier + '.ioi_dossier_compute_scheduled_date';
		}
		//Add name if do not exist
		if (!('name' in args) && item){
			args['name'] = item.parent_name;
		}
		//Add routing_list if do not exist
		if (!('routing_list' in args) && item){
			args['routing_list'] = [me.get_data_routing(item)];
		}
		//Format opening date
		if ('opening_date' in args){
			let opening_date = args['opening_date']
			if(opening_date instanceof Date){
				opening_date = me.format_datetime_to_string(opening_date) //format string: "%Y-%m-%d %H:%M:%S.%f"
			}
			args['opening_date'] = opening_date
		}

		frappe.call({
			method: method,
			args: args,
			async: false,
			callback:function(r){
				let res = me.get_items_by_line_machine(
					cint(me.ioiGanttControl.gantt_select_source_items.value),
					me.name
				)
				//Set Items
				me.items = [];
				for (var i = 0; i < res.length; i++) {
					me.set_item(res[i].data, res[i].routing_detail);
				}
				me.initialize();
				// me.refresh_schedule_chart();
			}
		});
		// me.update_cur_frm(res)
	}

	get_data_routing(item){
		let routing = {}
		if (item){
			routing.name = item.name;
			routing.parent_name = item.parent_name;
			routing.end_mode = cint(item.end_mode);
			routing.start_mode = cint(item.start_mode);
			routing.start_date = item.start;
			routing.end_date = item.finish;
			routing.pivot = cint(item.pivot);
			routing.line_machine_id = item.line_machine_id;
		}
		return routing
	}

	format_datetime_to_string(datetime){
		const day = String(datetime.getDate()).padStart(2, '0');
		const month = String(datetime.getMonth() + 1).padStart(2, '0');
		const year = datetime.getFullYear();
		const hours = String(datetime.getHours()).padStart(2, '0');
		const minutes = String(datetime.getMinutes()).padStart(2, '0');
		return `${year}-${month}-${day} ${hours}:${minutes}`;
	}


	static set_btn_disabled(btn){
		btn.prop('disabled', true);
		btn.css('cursor', 'wait');
		document.body.style.cursor = 'wait';
	}


	static set_btn_enabled(btn){
		btn.prop('disabled', false);
		btn.css('cursor', 'default');
		document.body.style.cursor = 'default';
	}


	compute_stacking(line_machine_id, duration){
		let me = this;
		let args = {};

		let method = ''
		if (me.mode == 0){
			method = path_scheduling_workcenter + '.ioi_scheduling_production_stacking';
		}else if(me.mode == 1){
			method = path_scheduling_workcenter + '.ioi_scheduling_dossier_stacking';
		}
		//Arguments
		args['workcenter_id'] = me.name;
		args['filter_type'] = 'line_machine_id';
		if (Array.isArray(line_machine_id)){
			args['filter_values'] = line_machine_id;
		}else if (typeof line_machine_id === 'string'){
			args['filter_values'] = [line_machine_id];
		}
		args['duration'] = duration;

		frappe.call({
			method: method,
			args: args,
			async: false,
			callback:function(r){
				let res = me.get_items_by_line_machine(
					cint(me.ioiGanttControl.gantt_select_source_items.value),
					me.name
				)
				//Set Items
				me.items = [];
				for (var i = 0; i < res.length; i++) {
					me.set_item(res[i].data, res[i].routing_detail);
				}
				me.settings.isRelativeToTimezone = true;
				me.settings.currentTime = new Date();
				me.initial
				me.initialize();
			}
		});
	}


	compute_constraint_stacking(line_machine_id, duration){
		let me = this;
		let args = {};

		let method = ''
		if (me.mode == 0){
			method = path_scheduling_workcenter + '.ioi_scheduling_production_constraint_stacking';
		}else if(me.mode == 1){
			method = path_scheduling_workcenter + '.ioi_scheduling_dossier_constraint_stacking';
		}
		//Arguments
		args['workcenter_id'] = me.name;
		args['filter_type'] = 'line_machine_id';
		if (Array.isArray(line_machine_id)){
			args['filter_values'] = line_machine_id;
		}else if (typeof line_machine_id === 'string'){
			args['filter_values'] = [line_machine_id];
		}
		args['duration'] = duration;
		args['no_contraint_on_step_exec'] = me.no_contraint_on_step_in_exec;

		frappe.call({
			method: method,
			args: args,
			async: false,
			callback:function(r){
				let res = me.get_items_by_line_machine(
					cint(me.ioiGanttControl.gantt_select_source_items.value),
					me.name
				)
				//Set Items
				me.items = [];
				for (var i = 0; i < res.length; i++) {
					me.set_item(res[i].data, res[i].routing_detail);
				}
				me.settings.isRelativeToTimezone = true;
				me.settings.currentTime = new Date();
				me.initialize();
			}
		});
	}


	update_gantt_color(name, color){
		let me = this
		let method = ''
		if (me.mode == 0){
			method = path_scheduling_production_routing + '.ioi_scheduling_production_set_gantt_color';
		}else if(me.mode == 1){
			method = path_scheduling_dossier_routing + '.ioi_scheduling_dossier_set_gantt_color';
		}
		frappe.call({
			method: method,
			args: {
				"name": name,
				"color": color
			},
			async: false
		});
	}


	update_line_machine_order(){
		let me = this
		let method = path_scheduling_workcenter + '.ioi_scheduling_workcenter_set_line_machine_order';
		let line_machine_list = [];
		for (var i = 0; i < me.items.length; i++) {
			if(me.items[i].line_machine_id){
				line_machine_list.push({
					'line_machine_id': me.items[i].line_machine_id,
					'index': cint(me.items[i].scheduleChartIndex)
				});
			}
		}
		frappe.call({
			method: method,
			args: {
				"line_machine_list": line_machine_list
			},
			async: false
		});
	}


}

silicon_ioi.scheduling.ioiScheduleChart = ioiScheduleChart;