// *******************************************************************************************************************************************
// Class for List
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.list');

export class ioiList {

	path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
	path_site = 'silicon_ioi.ioi_enterprise.doctype.ioi_site.ioi_site';
	path_module = 'silicon_ioi.ioi_configuration.doctype.ioi_module.ioi_module';
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';
	current_site = ''
	usual_site = ''

	constructor(listview) {
		this.listview = listview;

		this.add_dropdown_prefix(this.listview.doctype);

		let method = this.path_module + '.ioi_module_can_select_site_scan_active';
		let can_select_site = 0
		let scan_active = 0;

		frappe.call({
			method: method,
			args: { "doctype": this.listview.doctype },
			async: false,
			callback: function (r) {
				can_select_site = r.message.can_select_site;
				scan_active = r.message.scan_active;
			}
		});

		if (can_select_site == 1) {

			this.check_user_site();

			if (this.is_multi_site) {
				this.add_change_site_to_mainmenu();
			}
		}

		this.jump_to_db_companies();

		if (scan_active == 0) {
			if (document.getElementById('sb_main_scan_section')) {

				if (document.getElementById('sb_main_scan_check')) {
					document.getElementById('sb_main_scan_check').checked = false;
				}

				document.getElementById('sb_main_scan_section').hidden = true;
			}
		} else {
			if (document.getElementById('sb_main_scan_section')) {
				document.getElementById('sb_main_scan_section').hidden = false;
			}
		}


	}


	add_dropdown_prefix(doctype) {
		silicon_ioi.ioiCommon.add_dropdown_prefix('L', doctype);
	}

	jump_to_db_companies() {

		frappe.call({
			method: "silicon_ioi.utils.lib.system.jump_to_db_companies_data",
			args: {route: frappe.get_route()},
			callback: (c) => {
				let cmp_list = c.message;
				if (cmp_list.length > 0) {
					let icon = `<div style="position: relative; top:7px; left: 0px; height: 30px; width:auto">
									<i class="fa fa-globe"></i>
									<span>${__("Jump")}</span>
								</div>`;


					let jump_grp = this.listview.page.add_custom_button_group(icon);

					cmp_list.forEach((company) => {
						this.listview.page.add_custom_menu_item(jump_grp, company?.company_name, () => {
							if (company.jump_on_new_tab){
								window.open(company.jump_url, "_blank");
							}else{
								window.location.href = company.jump_url;
							}
						});
					});

				}
			}
		});
	}

	add_enabled_filter() {
		this.listview.page.add_field({
			"fieldname": "enabled",
			"fieldtype": "Select",
			"label": "Status",
			"options": [{ "label": "All", "value": null },
						{ "label": "Enabled", "value": 1 },
						{ "label": "Disabled", "value": 0 }
			],
			is_filter: 0,
			onchange: () => {
				this.listview.start = 0;
				this.listview.refresh();
				this.listview.on_filter_change();
			}
		}, this.listview.page.page_form.find('.standard-filter-section'));

	}

	hide_sidebar() {
		// (deactivated)
		//this.listview.parent.children[0].children[0].children[0].children[0].children[0].innerHTML = '';
		//this.listview.page.sidebar.css('display', 'none');
	}

	set_title(title) {
		// (deactivated)
		// let html = '<div class="flex">';
		// html += '<h3 class="ellipsis title-text" title="' + title + '">' + title + '</h3>';
		// html += '</div>';

		// this.listview.parent.children[0].children[0].children[0].children[0].children[1].children[0].innerHTML = html;
	}

	set_logo(imgpath) {
		// this.listview.parent.children[0].children[0].children[0].children[0].children[0].innerHTML = '';

		let html_before = this.listview.parent.children[0].children[0].children[0].children[0].children[1].innerHTML;
		let html = '<img src="' + imgpath + '" width="56px" height="56px" style="horizontal-align:left;"></img>&nbsp;&nbsp;';
		this.listview.parent.children[0].children[0].children[0].children[0].children[1].innerHTML = html + html_before;


	}

	format_search_area() {
		this.listview.parent.page.page_form[0].style.backgroundColor = '#a1cdf7';//'#D0E7FB';

	}

	alternate_row_color() {

		let method = this.path_module + '.ioi_module_can_select_site_scan_active';

		let scan_active = 0;

		frappe.call({
			method: method,
			args: { "doctype": this.listview.doctype },
			async: false,
			callback: function (r) {
				scan_active = r.message.scan_active;
			}
		});


		if (scan_active == 0) {
			if (document.getElementById('sb_main_scan_section')) {

				if (document.getElementById('sb_main_scan_check')) {
					document.getElementById('sb_main_scan_check').checked = false;
				}

				document.getElementById('sb_main_scan_section').hidden = true;
			}
		} else {
			if (document.getElementById('sb_main_scan_section')) {
				document.getElementById('sb_main_scan_section').hidden = false;
			}
		}


		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0

		let me = this;
		method = this.path_module_status + '.ioi_module_status_get_list_for_listview';

		let status = [];

		frappe.call({
			method: method,
			args: { "doctype": this.listview.doctype },
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					for (var i = 0; i < r.message.length; i++) {
						//			 dt.ioistatus, dt.background_color, dt.font_color, dt.description, dt.description_lg1, dt.description_lg2, dt.description_lg3, dt.description_lg4, description_language
						status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
					}
				}
			}
		});


		method = this.path_module_user_status + '.ioi_module_user_status_get_list_for_listview';

		let user_status = [];

		frappe.call({
			method: method,
			args: { "doctype": this.listview.doctype },
			async: false,
			callback: function (r) {
				if (r.message.length > 0) {
					for (var i = 0; i < r.message.length; i++) {
						//			 dt.ioistatus, dt.background_color, dt.font_color, dt.description, dt.description_lg1, dt.description_lg2, dt.description_lg3, dt.description_lg4, description_language
						user_status[i] = [r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9], r.message[i][10]];
					}
				}
			}
		});


		for (var i = 2; i <= this.listview.$result[0].childElementCount - 1; i++) {
			if (i % 2 == 0) {
				if (is_dark_mode == 0) {
					this.listview.$result[0].children[i].style.backgroundColor = '#f5fafe';
				} else {
					this.listview.$result[0].children[i].style.backgroundColor = '#242A30';
				}
			}
		}

		if ((status.length > 0) || (user_status.length > 0)) {
			for (var i = 2; i <= cur_list.$result[0].childElementCount - 1; i++) {

				let doctype = cur_list.doctype;

				if ((doctype.toUpperCase() == 'IOI SALES QUOTE') || (doctype.toUpperCase() == 'IOI SALES ORDER') || (doctype.toUpperCase() == 'IOI SALES DELIVERY') || (doctype.toUpperCase() == 'IOI SALES INVOICE') ||
					(doctype.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') || (doctype.toUpperCase() == 'IOI PURCHASES ORDER') || (doctype.toUpperCase() == 'IOI PURCHASES RECEIPT') || (doctype.toUpperCase() == 'IOI PURCHASES INVOICE') ||
					(doctype.toUpperCase() == 'IOI STOCK ENTRY') || (doctype.toUpperCase() == 'IOI STOCK OUTPUT') || (doctype.toUpperCase() == 'IOI STOCK TRANSFER') || (doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') || (doctype.toUpperCase() == 'IOI STOCK INVENTORY') ||
					(doctype.toUpperCase() == 'IOI DOSSIER') || (doctype.toUpperCase() == 'IOI PRODUCTION') || (doctype.toUpperCase() == 'IOI SUBSCRIPTION') || (doctype.toUpperCase() == 'IOI OPPORTUNITY')) {
						
					let value = cur_list.$result[0].children[i].children[0].children[0].children[0].children[1].innerText;

					if ((value) && (value.trim() != '')) {

						if (value.indexOf('•') != -1) {
							value = value.substring(0, value.indexOf('•')-1).trim();

							if (silicon_ioi.ioiCommon.tab_prefix_color.length > 0) {

								cur_list.$result[0].children[i].children[0].children[0].children[0].style.backgroundColor = '';

								for(var k = 0; k < silicon_ioi.ioiCommon.tab_prefix_color.length; k++) {
	
									if ((silicon_ioi.ioiCommon.tab_prefix_color[k].doctype == doctype) && (silicon_ioi.ioiCommon.tab_prefix_color[k].prefix == value)) {
										if (silicon_ioi.ioiCommon.tab_prefix_color[k].color_list == 1) {
											cur_list.$result[0].children[i].children[0].children[0].children[0].children[1].children[0].style.color = '#000000';
											cur_list.$result[0].children[i].children[0].children[0].children[0].style.backgroundColor = silicon_ioi.ioiCommon.tab_prefix_color[k].bgcolor;
											break;
										}
									}
								}
							}

							
						}
					}
				}
	

				for (var j = 1; j < cur_list.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs').length; j++) {

					let txt = '';
					if (cur_list.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')) {
						let element = cur_list.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0];

						if (element){
							
							if (element.innerText) {
								txt = element.innerText;
							}

							txt = txt.trim();
							txt = txt.toUpperCase();

							if (element.dataset) {
								if (element.dataset.filter) {
									let fn = element.dataset.filter.toUpperCase();

									if ((fn.indexOf('IOISTATUS') != -1) || (fn.indexOf('LIB_IOISTATUS') != -1) || (fn.indexOf('IOIUSERSTATUS') != -1) || (fn.indexOf('LIB_IOIUSERSTATUS') != -1)) {
										if ((fn.indexOf('IOISTATUS') != -1) || (fn.indexOf('LIB_IOISTATUS') != -1)) {
											if ((txt) && (txt.trim() != '')) {
												for (var k = 0; k < status.length; k++) {
													let lib_1 = '';
													let lib_2 = '';
													let lib_3 = '';
													let lib_4 = '';
													let lib_5 = '';

													if (status[k][3] != null) {
														lib_1 = status[k][3].toUpperCase();
													}
													if (status[k][4] != null) {
														lib_2 = status[k][4].toUpperCase();
													}
													if (status[k][5] != null) {
														lib_3 = status[k][5].toUpperCase();
													}
													if (status[k][6] != null) {
														lib_4 = status[k][6].toUpperCase();
													}
													if (status[k][7] != null) {
														lib_5 = status[k][7].toUpperCase();
													}

													if ((lib_1 == txt) || (lib_2 == txt) || (lib_3 == txt) || (lib_4 == txt) || (lib_5 == txt)) {
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.background = status[k][1];
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.color = "black";
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].style.color = "black";
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.borderRadius = '6px';
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.padding = '4px';
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].innerText = status[k][8];
													}
												}
											} else {
												if (cur_list.data[i]) {
													if (cur_list.data[i].name) {
														let me = this;
														let meth = this.path_module_status + '.ioi_module_status_get_status_value_from_doctype';

														let curr_status = 0;

														frappe.call({
															method: meth,
															args: { "doctype": this.listview.doctype, "name": cur_list.data[i].name },
															async: false,
															callback: function (r) {
																curr_status = r.message;
															}
														});

														for (var k = 0; k < status.length; k++) {
															if (status[k][0] == curr_status) {
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.background = status[k][1];
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.color = "black";
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].style.color = "black";
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.borderRadius = '6px';
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.padding = '4px';
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].innerText = status[k][8];
															}
														}
													}
												}
											}
										} else if ((fn.indexOf('IOIUSERSTATUS') != -1) || (fn.indexOf('LIB_IOIUSERSTATUS') != -1)) {
											if ((txt) && (txt.trim() != '')) {
												for (var k = 0; k < user_status.length; k++) {
													let lib_1 = '';
													let lib_2 = '';
													let lib_3 = '';
													let lib_4 = '';
													let lib_5 = '';


													if (user_status[k][3] != null) {
														lib_1 = user_status[k][3].toUpperCase();
													}
													if (user_status[k][4] != null) {
														lib_2 = user_status[k][4].toUpperCase();
													}
													if (user_status[k][5] != null) {
														lib_3 = user_status[k][5].toUpperCase();
													}
													if (user_status[k][6] != null) {
														lib_4 = user_status[k][6].toUpperCase();
													}
													if (user_status[k][7] != null) {
														lib_5 = user_status[k][7].toUpperCase();
													}

													if ((lib_1 == txt) || (lib_2 == txt) || (lib_3 == txt) || (lib_4 == txt) || (lib_5 == txt)) {

														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.background = user_status[k][1];
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.color = "black";
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].style.color = "black";
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.borderRadius = '6px';
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.padding = '4px';
														this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].innerText = user_status[k][8];

													}
												}
											} else {
												if (cur_list.data[i]) {
													if (cur_list.data[i].name) {
														let me = this;
														let meth = this.path_module_user_status + '.ioi_module_user_status_get_status_value_from_doctype';

														let curr_status = 0;

														frappe.call({
															method: meth,
															args: { "doctype": this.listview.doctype, "name": cur_list.data[i].name },
															async: false,
															callback: function (r) {
																curr_status = r.message;
															}
														});

														for (var k = 0; k < user_status.length; k++) {
															if (user_status[k][0] == curr_status) {
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.background = user_status[k][1];
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.color = "black";
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].style.color = "black";
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.borderRadius = '6px';
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].style.padding = '4px';
																this.listview.$result[0].children[i].children[0].children[0].getElementsByClassName('list-row-col ellipsis hidden-xs')[j].getElementsByClassName('filterable ellipsis')[0].innerText = user_status[k][8];
															}
														}
													}
												}

											}

										}
									}
								}
							}
						}
					}
				}

			}
		}
	}

	check_user_site() {
		let me = this;
		let method = this.path_user + '.ioi_user_has_site';

		frappe.call({
			method: method,
			args: {
				check_if_multi_site: true
			},
			async: false,
			callback: function (r) {
				if (r.message.has_site == 0) {
					me.show_select_site(true);
				} else {
					me.current_site = r.message.site_id;
					me.usual_site = r.message.usual_site_id;
					me.is_multi_site = r.message.multi_site
				}
			}
		});

	}

	add_change_site_to_mainmenu() {
		let me = this;
		me.listview.page.add_menu_item(__('Change site'), () => me.show_select_site(true));
		me.listview.page.add_menu_item(__('This is my usual site'), () => me.update_usual_site(true));

		this.check_user_site();

		let site_desc = __('select a site')
		if (this.current_site.trim() != '') {
			site_desc = 'Site : ' + this.current_site;
		}

		me.listview.page.add_button(site_desc, function () { me.show_select_site(true); });

		for(var i = 0; i < me.listview.page.custom_actions[0].childElementCount; i++) {

			if (me.listview.page.custom_actions[0].children[i].innerText == site_desc) {

				if ((this.usual_site) && (this.usual_site.trim() != '')) {

					if (this.usual_site != this.current_site) {
						me.listview.page.custom_actions[0].children[i].style.backgroundColor = 'orange';
					}

				}
			}

		}
	}

	show_select_site(reload = false) {


		let html = '';

		let me = this;
		let method = this.path_user + '.ioi_user_get_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{ 	me.current_site = r.message;
												}
		});


		let division_tab = []
		method = this.path_user + '.ioi_user_get_division_with_sites'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							division_tab = r.message;
						}
		});

		let current_division = ''

		if ((me.current_site) && (me.current_site.trim() != '')) {

			method = this.path_user + '.ioi_user_get_division_for_site'

			frappe.call({  	method: method,
							args: {"site_id": me.current_site},
							async: false,
							callback:function(r)	{
								current_division = r.message;
							}
			});

		}else {
			if (division_tab.length != 0) {
				current_division = division_tab[0].name;
			}
		}

		if (document.getElementById('ioi_site_list_label')) {
			document.getElementById('ioi_site_list_label').remove();
		}

		if (document.getElementById('ioi_site_list')) {
			document.getElementById('ioi_site_list').remove();
		}

		html += '<div style="position: relative; top: -20px; left: 0px; width:300px; height: 25px;">';
		html += '	<label id="ioi_site_list_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a site") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
		html += '		<select id="ioi_site_list" class="input-with-feedback form-control bold"> ';


		if (current_division != '') {

			method = this.path_user + '.ioi_user_get_sites_for_division'

			frappe.call({  	method: method,
							args: {"division_id": current_division},
							async: false,
							callback:function(r)	{

								for (var i = 0; i < r.message.length; i++)
								{
									if (me.current_site == r.message[i].name)
									{	html += '		<option value="' + r.message[i].name + '" selected>' + r.message[i].description + '</option> ';
									}else
									{	html += '		<option value="' + r.message[i].name + '">' + r.message[i].description + '</option> ';
									}
								}

							}
			});

		}


		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		if (division_tab.length > 1) {

			if (document.getElementById('ioi_select_division')) {
				document.getElementById('ioi_select_division').remove();
			}

			html += '<div style="position: relative; top: -45px; left: 310px; width:200px; height: 32px;">';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:200px; height: 32px;"> ';
			html += '		<input id="ioi_select_division" type="button" class="btn btn-default ellipsis" value="' + __("Change division")  + '" style="width:100%; height:100%">';
			html += '	</div>';
			html += '</div>';

		}


		frappe.confirm(html,
			() => 	{
						// action to perform if Yes is selected
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

						if ((!document.getElementById('ioi_site_list')) || (document.getElementById('ioi_site_list').value.trim() == ''))
						{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __('Site is mandatory'), indicator: "red"});
							raise;
						}

						me.current_site = document.getElementById('ioi_site_list').value;

						let method_user = this.path_user + '.ioi_user_save_site'
						frappe.call({  	method: method_user,
										args: {"site_id": document.getElementById('ioi_site_list').value},
										async: false,
										callback:function(r)	{ 	if (reload)
																	{
																		window.location.reload();
																	}
																}
						});

					},
			() => 	{
						// action to perform if No is selected or if click outsite the modal screen
						if (reload)
						{
							//window.location.reload();
						}
					}
		);

		this.sleep_ms(300).then(() => {

			let fct_click = function() {

				var changeDivisionDialog = new frappe.ui.Dialog({
					title: __("Change division"),
					static: true,
					fields: [	{'fieldname': 'html_change_division', 'fieldtype': 'HTML'}
							],
					primary_action_label: __("Ok"),
					primary_action: function(){

						let value = document.getElementById('html_change_division_id').value;

						changeDivisionDialog.hide();

						if (value != current_division) {

							let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division';

							frappe.call({  	method: method,
											args: {"division_id": value},
											async: false,
											callback:function(r)	{

												while (document.getElementById('ioi_site_list').options.length > 0) {
													document.getElementById('ioi_site_list').remove(0);
												}

												for (var i = 0; i < r.message.length; i++) {

													var option = document.createElement("option");
													option.value = r.message[i].name;
													option.text = r.message[i].description;
													document.getElementById('ioi_site_list').add(option);
												}
											}
							});

							current_division = value;
						}
					},
					secondary_action_label: __("Cancel"),
					secondary_action: function(){

						changeDivisionDialog.hide();
					}

				});

				if (document.getElementById('html_change_division_label')) {
					document.getElementById('html_change_division_label').remove();
				}

				if (document.getElementById('html_change_division_id')) {
					document.getElementById('html_change_division_id').remove();
				}

				let html = ""
				html += '<div style="height: 70px;">';
				html += '	<div style="position: relative; top: 0px; left: 0px; width:300px; height: 25px;">';
				html += '		<label id="html_change_division_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a division") + '</label>';
				html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
				html += '			<select id="html_change_division_id" class="input-with-feedback form-control bold"> ';

				for (var i = 0; i < division_tab.length; i++)
				{
					if (current_division == division_tab[i].name)
					{	html += '		<option value="' + division_tab[i].name + '" selected>' + division_tab[i].description + '</option> ';
					}else
					{	html += '		<option value="' + division_tab[i].name + '">' + division_tab[i].description + '</option> ';
					}
				}

				html += '			</select> ';
				html += '		</div>';
				html += '	</div>';
				html += '</div>';

				changeDivisionDialog.fields_dict.html_change_division.$wrapper.html(html);
				changeDivisionDialog.$wrapper.find('.modal-dialog').css("max-width", "340px").css("width", "340px");
				changeDivisionDialog.show();
			}

			document.getElementById('ioi_select_division').onclick = fct_click;

		});

		this.sleep_ms(200).then(() => {

			if (division_tab.length <= 1) {
				document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length-1].style.width = '340px';
			}else{
				document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length-1].style.width = '555px';
			}
		});

	}

	update_usual_site(reload = true)
	{
		let method = this.path_user + '.ioi_user_update_usual_site';

		frappe.call({
			method: method,
			args: {},
			async: false,
			callback:function(r) {

				if (reload) {
					window.location.reload();
				}

			}
		});
	}

	sleep_ms(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}

silicon_ioi.list.ioiList = ioiList;
