// *******************************************************************************************************************************************
// Class ioiStockOutputDocumentDocType
// *******************************************************************************************************************************************

import { ioiDocumentStatus } from "./ioi_document_status";

frappe.provide('silicon_ioi.doctype');

export class ioiStockOutputDocumentDocType extends silicon_ioi.doctype.ioiDocType
{

	static img_vt_data_close = '/assets/silicon_ioi/images/buttons/close.png';

	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';
	path_module_status_accessible_fields = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status_accessible_fields.ioi_module_status_accessible_fields';
	path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
	path_site = 'silicon_ioi.ioi_enterprise.doctype.ioi_site.ioi_site';
	path_document_change_status = 'silicon_ioi.common.document_change_status';
	path_currency = 'silicon_ioi.ioi_configuration.doctype.ioi_currency.ioi_currency';
	path_warehouse_journal = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_journal.ioi_warehouse_journal';
	path_stock_output = 'silicon_ioi.ioi_wms.doctype.ioi_stock_output.ioi_stock_output';
	path_period = 'silicon_ioi.ioi_enterprise.doctype.ioi_period.ioi_period';
    path_customer = 'silicon_ioi.ioi_sales.doctype.ioi_customer.ioi_customer';
	path_staff = 'silicon_ioi.ioi_enterprise.doctype.ioi_staff.ioi_staff';
    path_dossier = 'silicon_ioi.ioi_trakker.doctype.ioi_dossier.ioi_dossier';
	path_production = 'silicon_ioi.ioi_production.doctype.ioi_production.ioi_production';
    path_warehouse = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse.ioi_warehouse';
	path_warehouse_location = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_location.ioi_warehouse_location';
    path_postal_code = 'silicon_ioi.ioi_configuration.doctype.ioi_postal_code.ioi_postal_code';
    path_country = 'silicon_ioi.ioi_configuration.doctype.ioi_country.ioi_country';
    path_analytic_1 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_1.ioi_analytic_1';
    path_analytic_2 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_2.ioi_analytic_2';
    path_analytic_3 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_3.ioi_analytic_3';
    path_analytic_4 = 'silicon_ioi.ioi_accounting.doctype.ioi_analytic_4.ioi_analytic_4';
    path_item_unit = 'silicon_ioi.ioi_configuration.doctype.ioi_item_unit.ioi_item_unit';
	path_user_change_status_action = 'silicon_ioi.ioi_system.doctype.ioi_user_change_status_action.ioi_user_change_status_action';
    path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	path_search_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_module_search_definition.ioi_module_search_definition'
	path_purchases_document = "silicon_ioi.common.purchases_document";


	is_dark_mode = 0;

	user_language = '';
	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';

	system_currency = '';
	system_currency_rate = 1;
	system_currency_rate_inv = 1;
	system_currency_digit_rounding = 8;

	journal_division_id = '';
	journal_site_id = '';
	journal_warehouse_id = '';


	journal_wms_output_prefix = '';
	journal_wms_output_last_number = 0;
	journal_wms_output_nb_digits = 0;
	journal_wms_output_sublevel_separator = '';
	journal_wms_output_sublevel_nb_digits = 0;
	journal_wms_output_period_break_position = 0;
	journal_wms_output_period_break_numbering = 0;
	journal_wms_output_reservation_allowed = 0;
	journal_whs_output_allow_to_check_qty = 0;
	journal_whs_output_allow_bad_qty = 0;
	journal_wms_output_dossier_allowed = 0;
	journal_wms_output_production_allowed = 0;
	journal_wms_output_no_dossier_no_production_allowed = 0;
	journal_wms_output_receipt_contact_mandatory = 0;
	journal_wms_output_receipt_signature_mandatory = 0;
	journal_wms_output_qty_to_prepare_mode = 0;
	journal_wms_output_operator_mandatory = 0;
	journal_wms_output_account_analytic1_mandatory = 0;
	journal_wms_output_account_analytic2_mandatory = 0;
	journal_wms_output_account_analytic3_mandatory = 0;
	journal_wms_output_account_analytic4_mandatory = 0;
	journal_wms_output_role_limit = 0;
	journal_wms_output_disabled = 0;



	DocumentStatus = Object;
    ioistatus = [];
	ioiuserstatus = [];


    document_type = [];
    prefixes = [];
    address_mode = [];
	valid_delay_mode = [];
	source_color = [];

	available_status = [];
	available_scheduled_status = [];

	static warehouse_location = [];
	static batch_id = [];


	static static_is_dark_mode = 0;
	static vt_data_up = false;

	default_cash_desk_id = '';

	// ***************************************************************************************************************************************
	// Constructor
	// ***************************************************************************************************************************************
	constructor(frm)
	{
		super(frm);

		let me = this;

		this.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		this.DocumentStatus = new ioiDocumentStatus(frm);
		this.DocumentStatus.fill_status();
		this.ioistatus = this.DocumentStatus.ioistatus;



		setTimeout(silicon_ioi.doctype.ioiStockOutputDocumentDocType.set_static_grid_layout(), 500)
	}




	// ***************************************************************************************************************************************
	// Can access to the module
	// ***************************************************************************************************************************************
	can_access_to_document(doctype, document_type, prefix_id)
	{
		let can_access = false;

		let me = this;

		let method = this.path_stock_output + '.ioi_stock_output_can_access_to_document';

		frappe.call({  	method: method,
						args: {	"prefix_id": prefix_id},
						async: false,
						callback:function(r)	{

							if (r.message == 1) {
								can_access = true;
							}

						}
		});

		return can_access;
	}



	// ***************************************************************************************************************************************
	// Load General Settings
	// ***************************************************************************************************************************************
	load_general_settings()
	{
		let me = this;

		let method = this.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													me.system_currency = r.message.currency;

													if (me.system_currency.trim() != '')
													{
														method = me.path_currency + '.ioi_currency_get_data';

														frappe.call({  	method: method,
																		args: {"currency_id": me.system_currency},
																		async: false,
																		callback:function(r)	{
                                                                                                    me.language_1 = r.message.language_1;
                                                                                                    me.language_2 = r.message.language_2;
                                                                                                    me.language_3 = r.message.language_3;
                                                                                                    me.language_4 = r.message.language_4;
																									me.system_currency_rate = r.message.sales_rate;
																									me.system_currency_rate_inv = r.message.sales_rate_inv;
																									me.system_currency_digit_rounding = r.message.decimals;
																								}
														});
													}
						}
		});
	}


	// ***************************************************************************************************************************************
	// Load Warehouse Journal data
	// ***************************************************************************************************************************************
	load_warehouse_journal_data()
	{
		if ((this.frm.doc.journal_id) && (this.frm.doc.journal_id.trim() != ''))
		{
			let me = this;

			let method = this.path_warehouse_journal + '.ioi_warehouse_journal_get_data';

			frappe.call({  	method: method,
							args: { "journal_id": this.frm.doc.journal_id,
							},
							async: false,
							callback:function(r)	{

								me.user_language = r.message.user_language;

								// General
								me.journal_division_id = r.message.division_id;
								me.journal_site_id = r.message.site_id;
								me.journal_warehouse_id = r.message.warehouse_id;

								// Stock output
								me.journal_wms_output_prefix = r.message.wms_output_prefix;
								me.journal_wms_output_last_number = r.message.wms_output_last_number;
								me.journal_wms_output_nb_digits = r.message.wms_output_nb_digits;
								me.journal_wms_output_sublevel_separator = r.message.wms_output_sublevel_separator;
								me.journal_wms_output_sublevel_nb_digits = r.message.wms_output_sublevel_nb_digits;
								me.journal_wms_output_period_break_position = r.message.wms_output_period_break_position;
								me.journal_wms_output_period_break_numbering = r.message.wms_output_period_break_numbering;
								me.journal_wms_output_reservation_allowed = r.message.wms_output_reservation_allowed;
								me.journal_whs_output_allow_to_check_qty = r.message.whs_output_allow_to_check_qty;
								me.journal_whs_output_allow_bad_qty = r.message.whs_output_allow_bad_qty;
								me.journal_wms_output_dossier_allowed = r.message.wms_output_dossier_allowed;
								me.journal_wms_output_production_allowed = r.message.wms_output_production_allowed;
								me.journal_wms_output_no_dossier_no_production_allowed =r.message.wms_output_no_dossier_no_production_allowed;
								me.journal_wms_output_receipt_contact_mandatory = r.message.wms_output_receipt_contact_mandatory;
								me.journal_wms_output_receipt_signature_mandatory = r.message.wms_output_receipt_signature_mandatory;
								me.journal_wms_output_qty_to_prepare_mode = r.message.wms_output_qty_to_prepare_mode;
								me.journal_wms_output_operator_mandatory = r.message.wms_output_operator_mandatory;
								me.journal_wms_output_account_analytic1_mandatory = r.message.wms_output_account_analytic1_mandatory;
								me.journal_wms_output_account_analytic2_mandatory = r.message.wms_output_account_analytic2_mandatory;
								me.journal_wms_output_account_analytic3_mandatory = r.message.wms_output_account_analytic3_mandatory;
								me.journal_wms_output_account_analytic4_mandatory = r.message.wms_output_account_analytic4_mandatory;
								me.journal_wms_output_role_limit = r.message.wms_output_role_limit;
								me.journal_wms_output_disabled = r.message.wms_output_disabled;
							}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Load User Status
	// ***************************************************************************************************************************************
	fill_ioiuserstatus()
	{
		this.ioiuserstatus = [];

		let me = this;

		let method = this.path_module_user_status + '.ioi_module_user_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length != 0)
													{
														me.ioiuserstatus = [];

														for (var i = 0; i < r.message.length; i++)
														{	//                    code            description       bgcolor          fontcolor
															me.ioiuserstatus[i] = [r.message[i][2],r.message[i][5], r.message[i][3], r.message[i][4]];

														}
													}

													if (me.ioiuserstatus.length == 0)
													{	me.ioiuserstatus[0] = [0, 'Undefined', '#C3C2C4', '#000000'];
													}else
													{
														let found_zero = false;

														for (var i = 0; i < me.ioiuserstatus.length; i++)
														{
															if (me.ioiuserstatus[i][0] == 0)
															{
																found_zero = true;
																break;
															}
														}

														if (!found_zero)
														{	me.ioiuserstatus[me.ioiuserstatus.length] = [0, 'Undefined', '#C3C2C4', '#000000'];
														}
													}
												}
		});
	}


	// ***************************************************************************************************************************************
	// Refresh ioiuserstatus (User Status)
	// ***************************************************************************************************************************************
	refresh_ioiuserstatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		let cur_userstatus = 0;

		if (this.frm.doc.ioiuserstatus)
		{	cur_userstatus = this.frm.doc.ioiuserstatus;
		}

		for (var i = 0; i < this.ioiuserstatus.length; i++)
		{

			if (cur_userstatus == this.ioiuserstatus[i][0])
			{
				description = this.ioiuserstatus[i][1];
				bgcolor = this.ioiuserstatus[i][2];
				color = this.ioiuserstatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("User status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';

		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.append(html);

	}





	// ***************************************************************************************************************************************
	// Change user Status
	// ***************************************************************************************************************************************
	change_user_status()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_user_status(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_user_status();
		}
	}

	do_change_user_status()
	{

		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					method = me.path_module_user_status + '.ioi_module_user_status_change_status';

					let can_change = false;

					let cur_user_status = 0;

					if (me.frm.doc.ioiuserstatus)
					{
						cur_user_status = me.frm.doc.ioiuserstatus;
					}

					frappe.call({  	method: method,
									args: {"doctype": me.frm.doctype, "name" : me.frm.doc.name, "from_status" : cur_user_status, "to_status" : document.getElementById('selected_user_status').value, "silent_mode" : true },
									async: false,
										callback:function(r)	{
																		can_change = true;
																}
					});

					if (can_change)
					{
						window.location.reload();
					}
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (me.frm.doc.ioiuserstatus)
														{
															cur_userstatus = me.frm.doc.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=5% align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}

	compute_line(cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let delivered_qty = 0;

		if (line.delivered_qty) {
			delivered_qty = parseFloat(line.delivered_qty);
		}

		let item_cost = 0;

		if (line.item_cost) {
			item_cost = parseFloat(line.item_cost);
		}

		let item_cost_std_stamp = 0;

		if (line.item_cost_std_stamp) {
			item_cost_std_stamp = parseFloat(line.item_cost_std_stamp);
		}


		line.value_line_sys_currency = parseFloat(delivered_qty) * parseFloat(item_cost);
		line.std_value_line_sys_currency = parseFloat(delivered_qty) * parseFloat(item_cost_std_stamp);

		this.frm.refresh_field('line_detail')


	}

	// ***************************************************************************************************************************************
	// Fill Period from document date
	// ***************************************************************************************************************************************
	fill_period()
	{
		if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.toString().trim() == '')))
		{
			this.frm.doc.period_id = '';
			this.frm.refresh_field('period_id');
		}else
		{
			let me = this;
			let method = this.path_stock_output + '.ioi_stock_output_get_period_from_date';

			let division = this.journal_division_id;

			if ((!division) || (division.trim() == '')) {
				division = silicon_ioi.ioiAccUtils.get_main_division_id();
			}

			this.frm.doc.division_id = division;

			frappe.call({  	method: method,
							args: {"division_id" : division, "document_date" : this.frm.doc.document_date},
							async: false,
							callback:function(r)	{
															if (r.message.length == 0)
															{
																me.frm.doc.period_id = '';
															}else
															{
																me.frm.doc.period_id = r.message[0].name;
															}
													}
			});

			this.frm.refresh_field('period_id');
		}
	}


	// ***************************************************************************************************************************************
	// Fill Document type
	// ***************************************************************************************************************************************
	fill_document_type()
	{
		let me = this;

		this.frm.set_df_property('document_type', 'options', []);

		let method = this.path_warehouse_journal + '.ioi_warehouse_journal_get_document_types';

		let options = '';

		this.document_type = [];

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {
								options = '[';

								for (var i = 0; i < r.message.length; i++) {

									options += '{ "label": "' + r.message[i].document_type_desc + '", "value": "' + r.message[i].document_type + '"}';

									me.document_type[me.document_type.length] = [r.message[i].document_type , r.message[i].document_type_desc];


									if (i < r.message.length-1) {
										options += ',';
									}

								}

								options += ']';

							}else{
								options = '[]';
							}

					}
		});


		this.frm.set_df_property('document_type', 'options', JSON.parse(options));
		this.frm.refresh_field('document_type');

	}

	// ***************************************************************************************************************************************
	// Assign query to Link Fields
	// ***************************************************************************************************************************************
    assign_query_to_link_fields()
    {
		let site_id = '';
		let division_id = '';
		let me = this;


		if ((this.frm.doc.site_id) && (this.frm.doc.site_id.trim() != '')) {
			site_id = this.frm.doc.site_id;
			division_id = this.frm.doc.division_id;
		}else{

			let method = this.path_user + '.ioi_user_has_site';

			frappe.call({  	method: method,
							args: {},
							async: false,
							callback:function(r)	{ 	if (r.message.has_site != 0)
														{
															me.current_site = r.message.site_id;
														}
													}
			});


			site_id = this.current_site;

			method = this.path_site + '.ioi_site_get_division';

			frappe.call({  	method: method,
							args: {"name": site_id},
							async: false,
							callback:function(r)	{
								division_id = r.message[0].division_id;
							}
			});
		}


		this.set_query('dossier_id', '', this.path_dossier + '.ioi_dossier_get_enabled_for_output', '{"division_id":"' + division_id + '"}');
		this.set_query('period_id', '', this.path_stock_output + '.ioi_stock_output_get_enabled_period', '{"division":"' + division_id + '"}');

		this.set_query('production_id', '', this.path_production + '.ioi_production_get_enabled');
		this.set_query('operator_id', '', this.path_staff + '.ioi_staff_get_enabled');


		this.set_query('delivery_customer_id', '', this.path_search_definition + '.set_search_definition_filters');

		this.set_query('item_id', 'line_detail', this.path_search_definition + '.set_search_definition_filters');

		//this.set_query('item_id', 'line_detail', this.path_item + '.ioi_item_get_enabled');



		if ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() != ''))
		{
			this.set_query('delivery_customer_contact_id', '', this.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + this.frm.doc.delivery_customer_id + '"}');
		}else
		{	this.set_query('delivery_customer_contact_id', '', this.path_customer + '.ioi_contact_get_none');
		}


		this.set_query('warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');
		this.set_query('currency_id', '', this.path_currency + '.ioi_currency_get_enabled');
		this.set_query('detail_postal_code_id', 'address_detail', this.path_postal_code + '.ioi_postal_code_get_enabled');
		this.set_query('detail_country_id', 'address_detail', this.path_country + '.ioi_country_get_enabled');


		this.frm.fields_dict['line_detail'].grid.update_docfield_property("stored_qty_mode", "options", [	{ label: __("Good"), value: 0},
																											{ label: __("To check"), value: 1},
																											{ label: __("Bad"), value: 2}
																										]);

		frappe.meta.get_docfield('ioi Stock Output Detail', 'stored_qty_mode').options = [
			{ label: __("Good"), value: 0},
			{ label: __("To check"), value: 1},
			{ label: __("Bad"), value: 2}
		];

		this.set_query('analytic1_id', 'line_detail', this.path_analytic_1 + '.ioi_analytic_1_get_enabled');
		this.set_query('analytic2_id', 'line_detail', this.path_analytic_2 + '.ioi_analytic_2_get_enabled');
		this.set_query('analytic3_id', 'line_detail', this.path_analytic_3 + '.ioi_analytic_3_get_enabled');
		this.set_query('analytic4_id', 'line_detail', this.path_analytic_4 + '.ioi_analytic_4_get_enabled');
		this.set_query('unit_id', 'line_detail', this.path_item_unit + '.ioi_item_unit_get_enabled');
		this.set_query('warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled');
		this.set_query('dossier_id', 'line_detail', this.path_dossier + '.ioi_dossier_get_enabled_for_output', '{"division_id":"' + division_id + '"}');
		this.set_query('production_id', 'line_detail', this.path_production + '.ioi_production_get_enabled');
		this.set_query('ext_unit_id', 'line_detail', this.path_item_unit + '.ioi_item_unit_get_enabled');
		this.set_query('warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');



		let fct_search_delivery_customer = function () { me.search_delivery_customer(); };



		this.frm.fields_dict['delivery_customer_id'].open_advanced_search = fct_search_delivery_customer;
		let fct_search_delivery_customer_contact = function () { me.search_delivery_customer_contact(); };



		this.frm.fields_dict['delivery_customer_contact_id'].open_advanced_search = fct_search_delivery_customer_contact;



		// item advanced search is now defined in ioi_link



		let fct_search_dossier = function() {

			let title = __("Select a dossier");
			let form_width_pixel = '710px';
			let table = "ioi Dossier";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = 'Identification, Description';
			let where = "ioistatus <> 0 and division_id = '" + division_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('dossier_id', return_value);
				me.frm.refresh_field('dossier_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['dossier_id'].open_advanced_search = fct_search_dossier;

		let fct_search_production = function() {

			let title = __("Select a production");
			let form_width_pixel = '960px';
			let table = "ioi Production";
			let fields = 'name, item_id, item_description';
			let fields_len = '250, 250, 380';
			let fields_desc = 'Identification, Item, Description';
			let where = 'ioistatus = 1 or ioistatus = 2 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('production_id', return_value);
				me.frm.refresh_field('production_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['production_id'].open_advanced_search = fct_search_production;


		let fct_search_operator = function() {

			let title = __("Select an operator");
			let form_width_pixel = '830px';
			let table = "ioi Staff";
			let fields = 'name, initial, lastname, firstname ';
			let fields_len = '250, 100, 200, 200';
			let fields_desc = 'Identification, Initial, Lastname, Firstname';
			let where = 'ioistatus = 2 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('operator_id', return_value);
				me.frm.refresh_field('operator_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['operator_id'].open_advanced_search = fct_search_operator;


		let fct_search_warehouse = function() {

			let title = __("Select a warehouse");
			let form_width_pixel = '710px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('warehouse_id', return_value);
				me.frm.refresh_field('warehouse_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['warehouse_id'].open_advanced_search = fct_search_warehouse;


		let fct_search_period = function() {

			let title = __("Select a period");
			let form_width_pixel = '600px';
			let table = "ioi Period";
			let fields = 'name';
			let fields_len = '500';
			let fields_desc = __('Period');
			let where = "division = '" + me.frm.doc.division_id.replaceAll("'", "''") + "' and enabled = 1 ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('period_id', return_value);
				me.frm.refresh_field('period_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['period_id'].open_advanced_search = fct_search_period;

		let fct_search_currency = function() {

			let title = __("Select a currency");
			let form_width_pixel = '710px';
			let table = "ioi Currency";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('currency_id', return_value);
				me.frm.refresh_field('currency_id');
			}

			me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['currency_id'].open_advanced_search = fct_search_currency;



		let fct_search_detail_postal_code = function () {

			let title = __("Select a postal code");
			let form_width_pixel = '800px';
			let table = "ioi Postal Code";
			let fields = 'name, country_code, code, locality';
			let fields_len = '250, 100, 100, 250';
			let fields_desc = __('Identification') + ',' + __('Country') + ',' + __('Code') + ',' + __('Locality');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.detail_postal_code_id = return_value;

				let script_manager = me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("detail_postal_code_id", me.frm.fields_dict['address_detail'].grid.doctype, me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('address_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("address_detail").grid.get_field("detail_postal_code_id").open_advanced_search = fct_search_detail_postal_code;

		let fct_search_detail_country = function() {

			let title = __("Select a country");
			let form_width_pixel = '600px';
			let table = "ioi Country";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.detail_country_id = return_value;

				let script_manager = me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("detail_country_id", me.frm.fields_dict['address_detail'].grid.doctype, me.frm.fields_dict['address_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('address_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);

		}

		this.frm.get_field("address_detail").grid.get_field("detail_country_id").open_advanced_search = fct_search_detail_country;


		let fct_search_detail_analytic1 = function () {

			let title = __("Select an analytic 1");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 1";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic1_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic1_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic1_id").open_advanced_search = fct_search_detail_analytic1;


		let fct_search_detail_analytic2 = function () {

			let title = __("Select an analytic 2");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 2";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic2_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic2_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic2_id").open_advanced_search = fct_search_detail_analytic2;



		let fct_search_detail_analytic3 = function () {

			let title = __("Select an analytic 3");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 3";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic3_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic3_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic3_id").open_advanced_search = fct_search_detail_analytic3;



		let fct_search_detail_analytic4 = function () {

			let title = __("Select an analytic 4");
			let form_width_pixel = '580px';
			let table = "ioi Analytic 4";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = 'enabled = 1';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.analytic4_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("analytic4_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("analytic4_id").open_advanced_search = fct_search_detail_analytic4;


		let fct_search_detail_dossier = function () {

			let title = __("Select a dossier");
			let form_width_pixel = '580px';
			let table = "ioi Dossier";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "ioistatus <> 0 and division_id = '" + division_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.dossier_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("dossier_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');

				me.select_dossier(cdt, cdn);
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("dossier_id").open_advanced_search = fct_search_detail_dossier;


		let fct_search_detail_production = function () {

			let title = __("Select a production");
			let form_width_pixel = '960px';
			let table = "ioi Production";
			let fields = 'name, item_id, item_description';
			let fields_len = '250, 250, 380';
			let fields_desc = 'Identification, Item, Description';
			let where = 'ioistatus = 1 or ioistatus = 2 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.production_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("production_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');

				me.select_production(cdt, cdn);
			}


			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("production_id").open_advanced_search = fct_search_detail_production;


		let fct_search_detail_warehouse = function () {

			let title = __("Select a warehouse");
			let form_width_pixel = '580px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.warehouse_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("warehouse_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("warehouse_id").open_advanced_search = fct_search_detail_warehouse;




		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {

			this.frm.fields_dict['bt_delivery_new_contact'].wrapper.style.display = 'block';

		}else{

			this.frm.fields_dict['bt_delivery_new_contact'].wrapper.style.display = 'none';
		}
    }

	// ***************************************************************************************************************************************
	// Set query
	// ***************************************************************************************************************************************
	set_query(fieldname, childfieldname, method, filter = '')
	{
		if (childfieldname.trim() == '')
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method,
								filters : JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method
							}
				});

			}
		}else
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method,
								filters: JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method
							}
				});

			}
		}
	}

	// ***************************************************************************************************************************************
	// Fill Prefixes
	// ***************************************************************************************************************************************
	fill_prefixes()
	{
		let me = this;

		let amethod = '';
		let tp = '';

		if (this.frm.is_new()) {
			if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {

				this.frm.doc.prefix_id = '';
				this.frm.doc.id_prefix = '';
				this.frm.doc.id_number = 0;
				this.frm.doc.identification = '?';
			}
		}

		this.frm.refresh_field('journal_id');
		this.frm.refresh_field('prefix_id');
		this.frm.refresh_field('id_prefix');
		this.frm.refresh_field('id_number');
		this.frm.refresh_field('identification');
		this.load_warehouse_journal_data();


        amethod = this.path_warehouse_journal + '.ioi_warehouse_journal_get_outputs_prefixes';
        tp = 'STOCK_OUTPUT';
		let prefix_enabled = 0

		if (this.frm.is_new())
		{	prefix_enabled = 1
		}

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "prefix_enabled" : prefix_enabled, "tp":tp, "current_prefix": this.current_prefix},
						async: false,
						callback:function(r){
												let options = '[';

                                                me.prefixes = [];

												for (var i = 0; i < r.message.length; i++)
												{
													me.prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9]];
													options += '{"label": "' + me.prefixes[i][1] + '", "value": "' + me.prefixes[i][1] + '"}';

													if (i != r.message.length-1)
													{	options += ',';
													}
												}

												options += ']';

												me.frm.set_df_property('prefix_id', 'options', JSON.parse(options));
												me.frm.refresh_field("prefix_id");

                                                if (me.frm.is_new())
                                                {		if (me.prefixes.length > 0)
														{
															let mtd = 'silicon_ioi.ioi_system.doctype.ioi_user_module_params.ioi_user_module_params.ioi_user_module_params_get_prefix';

															let selected_pref = ''

															frappe.call({
																method: mtd,
																args: {"doctype":  me.frm.doctype},
																async: false,
																callback:function(r){
																	selected_pref = r.message;

																	if (!selected_pref) {
																		selected_pref = '';
																	}
																}
															});


															let found = false;

															for (var w = 0; w < me.prefixes.length; w++) {

																if (me.prefixes[w][1].toUpperCase() == selected_pref.toUpperCase()) {
																	found = true;
																	break;
																}
															}

															if (!found) {
																me.frm.set_value('prefix_id', me.prefixes[0][1]);
															}else{
																me.frm.set_value('prefix_id', selected_pref);
															}

														}
                                                }

											}
		});

	}

	// ***************************************************************************************************************************************
	// Refresh Prefixes
	// ***************************************************************************************************************************************
	refresh_prefixes()
	{
		this.fill_prefixes();
	}

	// ***************************************************************************************************************************************
	// Refresh ioistatus (Status)
	// ***************************************************************************************************************************************
	refresh_ioistatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioistatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (this.frm.doc.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioistatus'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Detail : Refresh ioistatus (Status)
	// ***************************************************************************************************************************************
	#refresh_detail_ioistatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (item.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}


	// ***************************************************************************************************************************************
	// Refresh info operator
	// ***************************************************************************************************************************************
	refresh_operator()
	{
		this.frm.fields_dict['html_operator'].$wrapper.empty();

		let me = this;

		let data = [];

		if ((this.frm.doc.operator_id) && (this.frm.doc.operator_id.trim() != '')) {

			let method = this.path_staff + '.ioi_staff_get_data';

			frappe.call({  	method: method,
							args: {	"staff_id": this.frm.doc.operator_id},
							async: false,
							callback:function(r)	{

								data = r.message

							}
			});
		}

		let html = '';
		html += '<table width=100%>';
		html += '<tr>';

		if (data.length > 0) {
			let s = ''

			if ((data[0].initial != null) && (data[0].initial != '')) {
				s += data[0].initial + ' - '
			}

			if ((data[0].lastname != null) && (data[0].lastname != '')) {

				if (s.trim() != '') {
					s += ' '
				}

				s += data[0].lastname + ' '
			}

			if ((data[0].firstname != null) && (data[0].firstname != '')) {


				if (s.trim() != '') {
					s += ' '
				}

				s += data[0].firstname
			}

			html += '<td width=100%>' + s + '</td>';
		}else{
			html += '<td width=100%>&nbsp;</td>';
		}
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_operator'].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************
	// New document
	// ***************************************************************************************************************************************
    new_document()
    {
		this.init_values_when_new_or_duplicate();

        if ((!this.frm.doc.document_type) || ((this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == '')))
        {
			this.frm.set_value('document_type', 'STOCK_OUTPUT');
        }


        if ((this.frm.doc.prefix_id) && (this.frm.doc.prefix_id.trim() != ''))
        {	this.frm.set_df_property('document_type', 'read_only', 1);
            this.frm.set_df_property('prefix_id', 'read_only', 1);
        }else
        {	this.frm.set_df_property('document_type', 'read_only', 0);
            this.frm.set_df_property('prefix_id', 'read_only', 0);
        }

        if ((!this.frm.doc.identification) || ((this.frm.doc.identification) && (this.frm.doc.identification.trim() == '')))
        {	this.frm.set_value('identification', '?');
        }


        this.frm.set_df_property('identification', 'read_only', 1);

        this.frm.set_value('ioistatus', 0);
        this.frm.set_value('lib_ioistatus', __('In preparation'));

        this.frm.set_value('warehouse_id', this.journal_warehouse_id);
        this.frm.set_value('currency_id', this.system_currency);
        this.frm.set_value('currency_rate', this.system_currency_rate);
        this.frm.set_value('currency_rate_inv', this.system_currency_rate_inv);
        this.frm.set_value('currency_digit_rounding', this.system_currency_digit_rounding);


    }


	// ***************************************************************************************************************************************
	// Init value when new document or duplicate
	// ***************************************************************************************************************************************

	init_values_when_new_or_duplicate()
	{

		if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {

			let me = this;

			let amethod = this.path_stock_output + '.ioi_stock_output_get_fields_for_initialization'
			frappe.call({  	method: amethod,
							args: {"doctype": this.frm.doctype, "doctype_detail" : this.frm.doctype + ' Detail'},
							async: false,
							callback:function(r){

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										if (me.frm.doctype == r.message[i].parent) {
											me.frm.set_value(r.message[i].fieldname, r.message[i].default_value);
											me.frm.refresh_field(r.message[i].fieldname);
										}
									}

									if (me.frm.doc.line_detail)
									{
										for (var j = 0; j < me.frm.doc.line_detail.length; j++) {

											for (var i = 0; i < r.message.length; i++) {

												if (me.frm.doctype + ' Detail' == r.message[i].parent) {

													me.frm.doc.line_detail[j][r.message[i].fieldname] =  r.message[i].default_value;
												}
											}
										}

										me.frm.refresh_field('line_detail');
									}
								}
							}
			});

		}

	}




	// ***************************************************************************************************************************************
	// Create subdocument (same level)
	// ***************************************************************************************************************************************
	create_subdocument_same_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_same_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_same_level();
		}
	}

	do_create_subdocument_same_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = this.path_stock_output + '.ioi_stock_output_get_next_subdoc_samelevel';

		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});

		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.parent_id;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.parent_id;
		}else
		{
			new_root = me.frm.doc.root_id;
		}



		frappe.call({  	method: this.path_stock_output + '.ioi_stock_output_get_document',
						args: {"doctype": this.frm.doctype, "name": me.frm.doc.parent_id},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;

								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 1;

								for (var i = 0; i < keys.length; i++)
								{
									if ((keys[i].toUpperCase() != 'NAME')                  && (keys[i].toUpperCase() != 'OWNER')                    && (keys[i].toUpperCase() != 'CREATION')               &&
										(keys[i].toUpperCase() != 'MODIFIED')              && (keys[i].toUpperCase() != 'MODIFIED_BY')              && (keys[i].toUpperCase() != 'DOCSTATUS')              &&
										(keys[i].toUpperCase() != 'IDX')                   && (keys[i].toUpperCase() != 'DOCUMENT_TYPE')            && (keys[i].toUpperCase() != 'IOISTATUS')              &&
										(keys[i].toUpperCase() != 'LIB_IOISTATUS')         && (keys[i].toUpperCase() != 'DOCTYPE')                  && (keys[i].toUpperCase() != 'JOURNAL_ID')             &&
										(keys[i].toUpperCase() != 'PREFIX_ID')             && (keys[i].toUpperCase() != 'IDENTIFICATION')           && (keys[i].toUpperCase() != 'PARENT_ID')              &&
										(keys[i].toUpperCase() != 'ROOT_ID')               && (keys[i].toUpperCase() != 'LEVEL_TP')                 && (keys[i].toUpperCase() != 'LINE_DETAIL')            &&
										(keys[i].toUpperCase() != 'ADDRESS_DETAIL')        && (keys[i].toUpperCase() != 'LOG_DETAIL')	            && (keys[i].toUpperCase() != 'ID_PREFIX')              &&
										(keys[i].toUpperCase() != 'ID_NUMBER'))
									{
										doc[keys[i]] = values[i];
									}
								}


							});
						}
		});

	}


	// ***************************************************************************************************************************************
	// Create subdocument (sub level)
	// ***************************************************************************************************************************************
	create_subdocument_sub_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_sub_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_sub_level();
		}
	}
	do_create_subdocument_sub_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = this.path_stock_output + '.ioi_stock_output_get_next_subdoc_sublevel';

		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});


		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.name;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.name;
		}else
		{
			new_root = me.frm.doc.root_id;
		}



		frappe.call({  	method: this.path_stock_output + '.ioi_stock_output_get_document',
						args: {"doctype": this.frm.doctype, "name": this.frm.doc.name},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;



								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 2;

								for (var i = 0; i < keys.length; i++)
								{
									if ((keys[i].toUpperCase() != 'NAME')                  && (keys[i].toUpperCase() != 'OWNER')                    && (keys[i].toUpperCase() != 'CREATION')               &&
										(keys[i].toUpperCase() != 'MODIFIED')              && (keys[i].toUpperCase() != 'MODIFIED_BY')              && (keys[i].toUpperCase() != 'DOCSTATUS')              &&
										(keys[i].toUpperCase() != 'IDX')                   && (keys[i].toUpperCase() != 'DOCUMENT_TYPE')            && (keys[i].toUpperCase() != 'IOISTATUS')              &&
										(keys[i].toUpperCase() != 'LIB_IOISTATUS')         && (keys[i].toUpperCase() != 'DOCTYPE')                  && (keys[i].toUpperCase() != 'JOURNAL_ID')             &&
										(keys[i].toUpperCase() != 'PREFIX_ID')             && (keys[i].toUpperCase() != 'IDENTIFICATION')           && (keys[i].toUpperCase() != 'PARENT_ID')              &&
										(keys[i].toUpperCase() != 'ROOT_ID')               && (keys[i].toUpperCase() != 'LEVEL_TP')                 && (keys[i].toUpperCase() != 'LINE_DETAIL')            &&
										(keys[i].toUpperCase() != 'ADDRESS_DETAIL')        && (keys[i].toUpperCase() != 'LOG_DETAIL')	            && (keys[i].toUpperCase() != 'ID_PREFIX')              &&
										(keys[i].toUpperCase() != 'ID_NUMBER'))
									{
										doc[keys[i]] = values[i];
									}
								}

							});
						}
		});
	}

	// ***************************************************************************************************************************************
	// Get next number
	// ***************************************************************************************************************************************
	get_next_number()
	{
		let me = this;

		if (this.frm.is_new())
		{
			if (!this.frm.doc.level_tp)
			{	this.frm.doc.level_tp = 0;
			}

			if ((this.frm.doc.level_tp == 0) || (this.frm.doc.level_tp == 3))
			{
				this.frm.set_intro('', '');

				for (var i = 0; i < this.prefixes.length; i++)
				{
					if (this.prefixes[i][1] == this.frm.doc.prefix_id)
					{

						this.frm.set_value('identification', this.prefixes[i][2]+1);

						let id_journal = '';
						let id_prefix = '';
						let id_last_number = 0;
						let id_number_digits = 0;
						let id_formatted = '';

						id_journal = this.prefixes[i][0];

						this.frm.doc.journal_id = id_journal;

						this.frm.refresh_field('journal_id');
						this.frm.refresh_field('prefix_id');
						this.frm.refresh_field('id_prefix');
						this.frm.refresh_field('id_number');
						this.frm.refresh_field('identification');

						let me = this;



						this.load_warehouse_journal_data();

						id_prefix = this.prefixes[i][1];

						this.frm.doc.division_id = this.prefixes[i][6];
						this.frm.doc.site_id = this.prefixes[i][7];
						this.frm.refresh_field('division_id');
						this.frm.refresh_field('site_id');

						this.fill_period();
						this.set_query('period_id', '', this.path_stock_output + '.ioi_stock_output_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');

						this.frm.set_value('qty_to_prepare_mode', this.journal_wms_output_qty_to_prepare_mode);

						this.set_query('warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

						let journal_period_break_numbering = 0;
						let journal_period_break_position = 0;
						let mode_break = false;

						journal_period_break_numbering = this.journal_wms_output_period_break_numbering;
						journal_period_break_position = this.journal_wms_output_period_break_position;


						if (journal_period_break_numbering == 1)
						{
							mode_break = true;
						}

						if (!mode_break)
						{
							id_last_number = this.prefixes[i][2]+1;

							id_number_digits = this.prefixes[i][3];

							if (id_number_digits != 0)
							{
								let n = id_number_digits - id_last_number.toString().trim().length;

								if (n >= 0)
								{
									for (var j = 0; j < n; j++)
									{	id_formatted += '0';
									}
								}
							}

							id_formatted += id_last_number.toString().trim();

							this.frm.doc.level_tp = '0';
						}else
						{
							let amethod = '';
							let tp = ''
							let period_year = ''
							let period_month = ''



							if ((!this.frm.doc.period_id) || ((this.frm.doc.period_id) && (this.frm.doc.period_id.toString().trim() == '')))
							{
								if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() == '')))
								{
									frappe.msgprint({title: __("Message"), message: __('Document date is mandatory'), indicator: "red"});
									raise;
								}
								let dt = new Date(this.frm.doc.document_date)
								period_year = dt.getFullYear();
								period_month = dt.getMonth()+1;

								if (Number(period_month) < 10)
								{	period_month = '0' + period_month
								}

							}else
							{
								let me = this;
								let method = this.path_stock_output + '.ioi_stock_output_get_period';

								frappe.call({  	method: method,
												args: {"period_id" : this.frm.doc.period_id.toString()},
												async: false,
												callback:function(r)	{
																			if (r.message.length == 0)
																			{
																				frappe.msgprint({title: __("Message"), message: __('No Period found'), indicator: "red"});
																				raise;
																			}

																			period_year = Number(r.message[0].year);

																			if (Number(r.message[0].month) < 10)
																			{
																				period_month = '0' + r.message[0].month;
																			}else
																			{
																				period_month = r.message[0].month;
																			}
																		}
								});

							}

							amethod = this.path_warehouse_journal + '.ioi_warehouse_journal_get_outputs_period_break_last_number';
							tp = this.frm.doc.document_type.toUpperCase();

							let me = this;

							frappe.call({  	method: amethod,
											args: {"tp":tp, "current_prefix": this.frm.doc.prefix_id, "period_year" : period_year, "period_month": period_month},
											async: false,
											callback:function(r){
																	id_last_number = r.message[0][0];
																	id_number_digits = me.prefixes[i][3];
																	id_formatted = r.message[0][0];
																}
							});

							this.frm.doc.level_tp = '3';
						}

						this.frm.set_value('journal_id', id_journal);

						this.frm.set_value('id_prefix', id_prefix);
						this.frm.set_value('id_number', id_last_number);
						this.frm.set_value('identification', id_formatted);
						this.frm.set_df_property('identification', 'read_only', 1);



						this.frm.set_value('warehouse_id', this.journal_warehouse_id);

					}
				}
			}


		}
	}

	// ***************************************************************************************************************************************
	// Set the label with currency
	// ***************************************************************************************************************************************
	#set_original_caption_with_currency(fieldname)
	{
		if (this.frm.fields_dict[fieldname].disp_status != 'None')
		{
			let caption = '';

			caption = __(this.frm.fields_dict[fieldname]._label);

			if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != ''))
			{
				caption += '&nbsp;<b>( ' + this.frm.doc.currency_id + ' )</b>';
			}

			this.frm.fields_dict[fieldname].wrapper.children[0].children[0].children[0].innerHTML = caption;

			this.#format_float_value(fieldname);
		}
	}


	// ***************************************************************************************************************************************
	// Format float value
	// ***************************************************************************************************************************************
	#format_float_value(fieldname, nb_decimal=0)
	{
		if (this.frm.fields_dict[fieldname].disp_status != 'None')
		{
			this.frm.fields_dict[fieldname].wrapper.children[0].children[1].children[1].align = 'right';

			let value = this.frm.fields_dict[fieldname].value;

			let n = 0;

			if (nb_decimal == 0)
			{
				if ((this.frm.doc.currency_digit_rounding) && (this.frm.doc.currency_digit_rounding.toString().trim() != ''))
				{
					n = parseInt(this.frm.doc.currency_digit_rounding);
				}
			}else
			{
				n = nb_decimal;
			}

			let number_format = frappe.sys_defaults.number_format;
			let idx_point = -1;
			let idx_coma = -1;
			let str_value = '';

			for(var i = 0; i < number_format.length; i++)
			{
				if (number_format[i] == ',')
				{	idx_coma = i;
				}else if (number_format[i] == '.')
				{	idx_point = i;
				}
			}

			if (!value) {
				value = 0;
			}

			if (value != 0)
			{	str_value = parseFloat(value.toFixed(n)).toString();

				if (idx_coma >= idx_point)
				{	str_value = str_value.replace('.', ',');

					if (str_value.indexOf(',') == -1)
					{
						if (n > 0)
						{
							str_value += ',';

							for (var i = 0; i < n; i++)
							{
								str_value += '0';
							}
						}
					}else
					{
						if (n > 0)
						{
							for (var i = str_value.substring(str_value.indexOf(',')+1, str_value.length).length; i < n; i++)
							{
								str_value += '0';
							}
						}
					}
				}else
				{
					if (str_value.indexOf('.') == -1)
					{
						if (n > 0)
						{
							str_value += '.';

							for (var i = 0; i < n; i++)
							{
								str_value += '0';
							}
						}
					}else
					{
						if (n > 0)
						{
							for (var i = str_value.substring(str_value.indexOf('.')+1, str_value.length).length; i < n; i++)
							{
								str_value += '0';
							}
						}
					}
				}

				this.frm.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = str_value;
			}else
			{	str_value = '0';

				if (n > 0)
				{
					str_value += '.';

					for (var i = 0; i < n; i++)
					{
						str_value += '0';
					}

					if (idx_coma >= idx_point)
					{	str_value = str_value.replace('.', ',');
					}
				}

				this.frm.fields_dict[fieldname].wrapper.children[0].children[1].children[1].innerText = str_value;
			}
		}
	}

	// ***************************************************************************************************************************************
	// Lien Detail :Set the label with currency
	// ***************************************************************************************************************************************
	#detail_set_original_caption_with_currency(item, detail, fieldname, format_float = false)
	{
		if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form)
		{
			if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].disp_status)
			{
				if (this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].disp_status != 'None')
				{
					let caption = '';

					caption = __(this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname]._label);

					if ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() != ''))
					{
						caption += '&nbsp;<b>( ' + this.frm.doc.currency_id + ' )</b>';
					}

					this.frm.get_field(detail).grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].wrapper.children[0].children[0].children[0].innerHTML = caption;

					if (format_float)
					{
						this.#format_float_value(fieldname);
					}
				}
			}
		}
	}



	// ***************************************************************************************************************************************
	// Set field's labels with (!) when error found
	// ***************************************************************************************************************************************
	set_label_when_error()
	{
		this.#disable_log_grid_actions();

		this.frm.refresh_field('log_detail');

		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('delivery_customer_contact_id');
		this.frm.refresh_field('currency_id');
		this.frm.fields_dict['delivery_customer_id'].label_area.innerHTML = __(this.frm.fields_dict['delivery_customer_id']._label);
		this.frm.fields_dict['delivery_customer_contact_id'].label_area.innerHTML = __(this.frm.fields_dict['delivery_customer_contact_id']._label);


		this.frm.fields_dict['currency_id'].label_area.innerHTML = __(this.frm.fields_dict['currency_id']._label);

		if (this.frm.doc.log_detail)
		{
			for (var i = 0; i < this.frm.doc.log_detail.length; i++)
			{
				if ((this.frm.doc.log_detail[i].fieldname.toUpperCase() != 'ITEM_ITEM_ID'))
				{
					this.frm.fields_dict[this.frm.doc.log_detail[i].fieldname].label_area.innerHTML = __(this.frm.fields_dict[this.frm.doc.log_detail[i].fieldname]._label) + '&nbsp;<font color = "#FF5338"><b>(!)</b></font>';
				}
			}

			if (this.frm.doc.log_detail.length > 0)
			{
				for (var i = 0; i < this.frm.layout.tabs.length; i++)
				{
					document.getElementById('ioi-stock-output-stock_output_tab_warnings-tab').innerHTML = '<font color = "#FF5959"><b>' + __(this.frm.layout.tabs[i].label) + ' (!)</b><font>'; // #FF5338
					document.getElementById('ioi-stock-output-stock_output_tab_warnings-tab').hidden = false;
				}
			}else
			{
				for (var i = 0; i < this.frm.layout.tabs.length; i++)
				{
					document.getElementById('ioi-stock-output-stock_output_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
					document.getElementById('ioi-stock-output-stock_output_tab_warnings-tab').hidden = true;
				}

			}

		}else
		{
			for (var i = 0; i < this.frm.layout.tabs.length; i++)
			{
				document.getElementById('ioi-stock-output-stock_output_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
				document.getElementById('ioi-stock-output-stock_output_tab_warnings-tab').hidden = true;
			}
		}



		this.frm.refresh_field('delivery_customer_id');
		this.frm.refresh_field('delivery_customer_contact_id');

		this.frm.refresh_field('currency_id');
		this.set_grid_layout();
	}

	// ***************************************************************************************************************************************
	// Log grid : disable all actions
	// ***************************************************************************************************************************************
	#disable_log_grid_actions()
	{
		for (var i = 0; i < this.frm.fields_dict['log_detail'].grid.grid_buttons.length; i++)
		{
			this.frm.fields_dict['log_detail'].grid.grid_buttons[i].hidden = true;
		}

		for (var i = 0; i < this.frm.fields_dict['log_detail'].grid.grid_rows.length; i++)
		{
			// this.frm.fields_dict['log_detail'].grid.grid_rows[i].row_check[0].hidden = true;
			this.frm.fields_dict['log_detail'].grid.grid_rows[i].open_form_button[0].hidden = true;
		}

		// this.frm.fields_dict['log_detail'].grid.header_row.row[0].children[0].hidden = true
		// this.frm.fields_dict['log_detail'].grid.header_row.row_check[0].hidden = true;
	}

	// ***************************************************************************************************************************************
	// Set Grid layout
	// ***************************************************************************************************************************************
	set_grid_layout()
	{

		if (this.frm.doc.line_detail)
		{
			for(var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				let bgcolor = '';

				for (var k = 0; k < document.getElementById("ioistatus_id").length; k++)
				{
					if (this.frm.doc.line_detail[i].ioistatus == document.getElementById("ioistatus_id").options[k].value)
					{
						bgcolor = document.getElementById("ioistatus_color").options[k].value;
						break;
					}
				}


				if (this.frm.fields_dict['line_detail'].grid.grid_rows[i])
				{
					this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
					this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.color = "black";

					for (var m = 0; m < this.frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
					{
						if (this.is_dark_mode == 0) {
							this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
						}else{
							this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
						}

					}


					if (this.frm.doc.qty_to_prepare_mode == 1) {

						if (this.frm.doc.line_detail[i].delivered_qty != this.frm.doc.line_detail[i].qty_to_prepare) {

							for (var m = 0; m < this.frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

								if (this.frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') {

									if (this.frm.doc.line_detail[i].qty_to_prepare != 0)
									{
										if (this.frm.doc.line_detail[i].delivered_qty < this.frm.doc.line_detail[i].qty_to_prepare) {
											this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FBCEB1';
										}else if (this.frm.doc.line_detail[i].delivered_qty > this.frm.doc.line_detail[i].qty_to_prepare) {
											this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA3A3';
										}

										this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
									}
								}
							}
						}
					}

					if ((this.frm.doc.line_detail[i].item_id) && (this.frm.doc.line_detail[i].item_id.trim() != '')) {

						let qt = 0;

						if ((this.frm.doc.line_detail[i].delivered_qty != null) && (this.frm.doc.line_detail[i].delivered_qty != 0)) {
							qt = this.frm.doc.line_detail[i].delivered_qty;
						}

						if (qt != 0) {

							let color_cell = false;

							if ((!this.frm.doc.line_detail[i].warehouse_id) || ((this.frm.doc.line_detail[i].warehouse_id) && (this.frm.doc.line_detail[i].warehouse_id.trim() == ''))) {
								color_cell = true;
							}

							if ((!color_cell) && (this.frm.doc.line_detail[i].location_active == 1) && ((!this.frm.doc.line_detail[i].warehouse_location_id) || ((this.frm.doc.line_detail[i].warehouse_location_id) && (this.frm.doc.line_detail[i].warehouse_location_id.trim() == '')))) {
								color_cell = true;
							}

							if ((!color_cell) && ((this.frm.doc.line_detail[i].item_mode == 3) || (this.frm.doc.line_detail[i].item_mode == 4)) && ((!this.frm.doc.line_detail[i].batch_sn_id) || ((this.frm.doc.line_detail[i].batch_sn_id) && (this.frm.doc.line_detail[i].batch_sn_id.trim() == '')))) {
								color_cell = true;
							}


							if (color_cell) {

								for (var m = 0; m < this.frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

									if (this.frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') {

										this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFE7AB';
										this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
									}
								}
							}
						}
					}




					if ((this.frm.doc.line_detail[i].is_warning) && (this.frm.doc.line_detail[i].is_warning != 0))
					{
						for (var m = 0; m < this.frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (this.frm.doc.line_detail[i].is_warning == 1)
							{	this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA874';
							}else
							{	this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FEADAD';
							}

							this.frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
						}
					}

				}
			}
		}

	}

	// ***************************************************************************************************************************************
	// Set static Grid layout : used outside de class without instanciate an object
	// ***************************************************************************************************************************************
	static set_static_grid_layout(dm = null)
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {
			if (dm == null)
			{
				dm = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
			}

			if (cur_frm.doc.line_detail)
			{

				for(var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					let bgcolor = '';

					for (var k = 0; k < document.getElementById("ioistatus_id").length; k++)
					{
						if (cur_frm.doc.line_detail[i].ioistatus == document.getElementById("ioistatus_id").options[k].value)
						{
							bgcolor = document.getElementById("ioistatus_color").options[k].value;
							break;
						}
					}


					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.color = "black";

						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (dm == 0) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
							}else{
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
							}
						}




						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{


							if (dm == 0) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
							}else{
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
							}

						}




						if (cur_frm.doc.qty_to_prepare_mode == 1) {

							if (cur_frm.doc.line_detail[i].delivered_qty != cur_frm.doc.line_detail[i].qty_to_prepare) {

								for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

									if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') {

										if (cur_frm.doc.line_detail[i].qty_to_prepare != 0) {

											if (cur_frm.doc.line_detail[i].delivered_qty < cur_frm.doc.line_detail[i].qty_to_prepare) {
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FBCEB1';
											}else if (cur_frm.doc.line_detail[i].delivered_qty > cur_frm.doc.line_detail[i].qty_to_prepare) {
												cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA3A3';
											}

											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
										}
									}
								}
							}
						}

						if ((cur_frm.doc.line_detail[i].item_id) && (cur_frm.doc.line_detail[i].item_id.trim() != '')) {

							let qt = 0;

							if ((cur_frm.doc.line_detail[i].delivered_qty != null) && (cur_frm.doc.line_detail[i].delivered_qty != 0)) {
								qt = cur_frm.doc.line_detail[i].delivered_qty;
							}

							if (qt != 0) {

								let color_cell = false;

								if ((!cur_frm.doc.line_detail[i].warehouse_id) || ((cur_frm.doc.line_detail[i].warehouse_id) && (cur_frm.doc.line_detail[i].warehouse_id.trim() == ''))) {
									color_cell = true;
								}

								if ((!color_cell) && (cur_frm.doc.line_detail[i].location_active == 1) && ((!cur_frm.doc.line_detail[i].warehouse_location_id) || ((cur_frm.doc.line_detail[i].warehouse_location_id) && (cur_frm.doc.line_detail[i].warehouse_location_id.trim() == '')))) {
									color_cell = true;
								}

								if ((!color_cell) && ((cur_frm.doc.line_detail[i].item_mode == 3) || (cur_frm.doc.line_detail[i].item_mode == 4)) && ((!cur_frm.doc.line_detail[i].batch_sn_id) || ((cur_frm.doc.line_detail[i].batch_sn_id) && (cur_frm.doc.line_detail[i].batch_sn_id.trim() == '')))) {
									color_cell = true;
								}


								if (color_cell) {

									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

										if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') {

											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFE7AB';
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
										}
									}
								}
							}
						}


						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (

								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'DELIVERED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'MIN_ORDER_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'MULTIPLE_ORDER_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'RESERVED_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'QTY_TO_PREPARE') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'AVAILABLE_SCHEDULED_QTY'))
							{
								let fn = cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].children[0];

								let gridcell = null;

								if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0]) {


									if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0]) {

										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0]) {
											gridcell = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0];
										}
									}
								}

								silicon_ioi.doctype.ioiStockOutputDocumentDocType.grid_format_float_qty(gridelement, gridcell);

							}

							if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'STORED_QTY_MODE') {

								let s = '';

								if ((!cur_frm.doc.line_detail[i].stored_qty_mode) || ((cur_frm.doc.line_detail[i].stored_qty_mode) && (cur_frm.doc.line_detail[i].stored_qty_mode == '0'))) {
									s = __('Good');
								}else if (cur_frm.doc.line_detail[i].stored_qty_mode == '1') {
									s = __('To check');
								}else if (cur_frm.doc.line_detail[i].stored_qty_mode == '2') {
									s = __('Bad');
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].innerText = s;
							}

						}

						if ((cur_frm.doc.line_detail[i].is_warning) && (cur_frm.doc.line_detail[i].is_warning != 0))
						{
							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
							{
								if (cur_frm.doc.line_detail[i].is_warning == 1)
								{	cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA874';
								}else
								{	cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FEADAD';
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
							}
						}
					}
				}
			}

			let fct_grid = function () { silicon_ioi.doctype.ioiStockOutputDocumentDocType.set_static_grid_layout(dm); };
			setTimeout(fct_grid, 500);
		}

	}

	static set_vt_button_in_grid()
	{
		if (cur_frm.fields_dict["line_detail"].grid) {
			if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0]) {

				let from_idx = (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_index) * parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length)) - (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length))

				for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

					if (document.getElementById('vt_button_' + (i + from_idx).toString())) {
						document.getElementById('vt_button_' + (i + from_idx).toString()).remove();
					}

					let item_id = '';

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.item_id) {
						item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.item_id;
					}

					let to_execute = 0;

					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.to_execute) {
						to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[i + from_idx].doc.to_execute;
					}

					if ((item_id != '') && (to_execute == 1)) {
						// Add button VT
						let html = '';
						html += '<button id="vt_button_' + (i + from_idx).toString() + '" title="' + __("Time Vector") + '" style="width:30px; height:25px; border: none;">...</button>';
						cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].insertAdjacentHTML('afterbegin', html);

						// Remove CheckBox
						if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1]) {
							cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1].remove();
						}
					}else {
						// Remove CheckBox
						if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0]) {
							cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0].remove();
						}
					}
				}


				silicon_ioi.doctype.ioiStockOutputDocumentDocType.sleep_static(200).then(() => {

					let fct_click = function() {
						let s = this.id;

						while (s.indexOf('_') != -1) {
							s = s.substring(s.indexOf('_')+1, s.length)
						}

						s = s.trim();

						let item_id = '';

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id) {
							item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id;
						}

						let to_execute = 0;

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute) {
							to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute;
						}

						document.getElementById('sb_vt_data_header_title').innerHTML = '<b>' + __("Time Vector") + '</b> ' + item_id;

						silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;
						silicon_ioi.doctype.ioiTimeVectorFrame.doctype = '';
						silicon_ioi.doctype.ioiTimeVectorFrame.origin = 'IOI SALES ORDER';
						silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
						silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference = cur_frm.doc.name + ' | ' + (parseInt(s)+1).toString();

						silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

						if (item_id != '') {
							if (to_execute == 1) {
								document.getElementById('sb_vt_data').style.display = 'block';
							}else{
								document.getElementById('sb_vt_data').style.display = 'none';
							}
						}else{
							document.getElementById('sb_vt_data').style.display = 'none';
						}

						cur_frm.fields_dict['line_detail'].grid.wrapper[0].getElementsByClassName('rows')[0].children[s].children[0].click();
					}

					from_idx = (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_index) * parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length)) - (parseInt(cur_frm.fields_dict['line_detail'].grid.grid_pagination.page_length))

					for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

						if (document.getElementById('vt_button_' + (i + from_idx).toString())) {
							document.getElementById('vt_button_' + (i + from_idx).toString()).onclick = fct_click;
						}
					}
				});
			}
		}
	}


	static build_vt_section()
	{
		if (cur_frm.doc.ioistatus != 0) {

			if (cur_frm.fields_dict["line_detail"]) {

				if (cur_frm.fields_dict["line_detail"].grid) {

					cur_frm.fields_dict["line_detail"].grid.wrapper[0].onchange = silicon_ioi.doctype.ioiStockOutputDocumentDocType.set_vt_button_in_grid;

					if (document.getElementById('sb_vt_start_move')) {
						document.getElementById('sb_vt_start_move').remove();
					}


					if (document.getElementById('sb_vt_data')) {
						document.getElementById('sb_vt_data').remove();
					}

					if (document.getElementById('sb_vt_data_header')) {
						document.getElementById('sb_vt_data_header').remove();
					}

					if (document.getElementById('sb_vt_data_header_title')) {
						document.getElementById('sb_vt_data_header_title').remove();
					}

					if (document.getElementById('sb_vt_data_header_updown_section')) {
						document.getElementById('sb_vt_data_header_updown_section').remove();
					}

					if (document.getElementById('sb_vt_data_updown')) {
						document.getElementById('sb_vt_data_updown').remove();
					}



					if (document.getElementById('sb_vt_data_header_close_section')) {
						document.getElementById('sb_vt_data_header_close_section').remove();
					}

					if (document.getElementById('sb_vt_data_close')) {
						document.getElementById('sb_vt_data_close').remove();
					}

					if (document.getElementById('sb_vt_data_separator')) {
						document.getElementById('sb_vt_data_separator').remove();
					}


					if (document.getElementById('sb_vt_data_margin')) {
						document.getElementById('sb_vt_data_margin').remove();
					}

					if (document.getElementById('sb_vt_data_content')) {
						document.getElementById('sb_vt_data_content').remove();
					}

					silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up = true;



					let content_bgcolor = "#FFFFFF";

					if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.static_is_dark_mode != 0) {
						content_bgcolor = "#1C2126";
					}

					let title_font_color = '#000000';

					let div_height = 560;
					let content_height = 520;

					if (!silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up) {
						div_height = 30;
					}

					if (document.body.clientHeight <= div_height)
					{
						div_height = document.body.clientHeight - 100;
						content_height = div_height - 38;
					}

					let fct_window_resize = function() {

						let div_height = 560;
						let content_height = 520;

						if (!silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up) {
							div_height = 30;
						}

						if (document.body.clientHeight <= div_height)
						{
							div_height = document.body.clientHeight - 100;
							content_height = div_height - 38;
						}

						if (document.getElementById('sb_vt_data')) {
							document.getElementById('sb_vt_data').style.height = div_height.toString() + 'px';
							document.getElementById('sb_vt_data_detail').style.height = content_height.toString() + 'px';

							document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
						}

					};

					window.onresize = fct_window_resize;

					let div_top = document.body.clientHeight - div_height + window.scrollY;

					let html = '';
					html += '<input id="sb_vt_start_move" type="hidden" value="0">';
					html += '<div id="sb_vt_data" style="position:absolute;top:' + div_top + 'px; left:0px; width:100%;height:' + div_height.toString() + 'px;background-color:' + content_bgcolor + ';z-index:1000;display:none">';
					html += '	<div id="sb_vt_data_header" style="width:100%;height:30px;background-color:#D0E7FB;">';
					html += '		<label id="sb_vt_data_header_title" style="position:absolute;top:4px; left: 8px; width: 300px; color:' + title_font_color + '"><b>' + __("Time Vector") + '</b></label>';

					html += '		<div id="sb_vt_data_header_close_section" style="float: right; width:30px;height:30px;background-color:#D0E7FB;">';
					html += '			<img id="sb_vt_data_close" src="/assets/silicon_ioi/images/buttons/close.png" width="20px" height="20px" style="position:relative;top:4px; left:4px;"></img>';
					html += '		</div>';

					html += '		<div id="sb_vt_data_header_updown_section" style="float: right; width:30px;height:30px;background-color:#D0E7FB;">';
					html += '			<img id="sb_vt_data_updown" src="/assets/silicon_ioi/images/buttons/arrow_down.png" width="20px" height="20px" style="display:none;position:relative;top:4px; left:4px;"></img>';
					html += '		</div>';

					html += '	</div>';



					html += '	<div id="sb_vt_data_separator" style="overflow: auto; overflow-x: auto; height:8px;">';
					html += '	</div>';


					html += '	<div id="sb_vt_data_detail" style="width:100%;height:' + content_height.toString() + 'px;background-color:' + content_bgcolor + ';overflow-x: auto;">';

					html += '		<div id="sb_vt_data_margin" style="float:left;width:1%;height:100%;background-color:' + content_bgcolor + ';overflow-x: auto;">';
					html += '		</div>';

					html += '		<div id="sb_vt_data_content" style="float:left;width:99%;height:100%;background-color:' + content_bgcolor + ';overflow-x: auto;">';
					html += '		</div>';

					html += '	</div>';

					html += '</div>';

					parent.body.insertAdjacentHTML('beforeend', html);


					for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

						if (document.getElementById('vt_button_' + i.toString())) {
							document.getElementById('vt_button_' + i.toString()).remove();
						}

						let item_id = '';

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.item_id) {
							item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.item_id;
						}

						let to_execute = 0;

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.to_execute) {
							to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].doc.to_execute;
						}

						if ((item_id != '') && (to_execute == 1)) {
							// Add button VT
							html = '';
							html += '<button id="vt_button_' + i.toString() + '" title="' + __("Time Vector") + '" style="width:30px; height:25px; border: none;">...</button>';
							cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].insertAdjacentHTML('afterbegin', html);

							// Remove CheckBox
							if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1]) {
								cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[1].remove();
							}
						}else {
							// Remove CheckBox
							if (cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0]) {
								cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].children[i].getElementsByClassName('data-row row')[0].children[0].children[0].remove();
							}
						}
					}


					silicon_ioi.doctype.ioiStockOutputDocumentDocType.sleep_static(200).then(() => {

						let fct_header_mousedown = function() {
							document.getElementById('sb_vt_start_move').value = 1;
							this.style.cursor = 'n-resize';
						};

						document.getElementById('sb_vt_data_header').onmousedown = fct_header_mousedown;


						let fct_window_mouseup = function() {
							if (document.getElementById('sb_vt_start_move')) {
								document.getElementById('sb_vt_start_move').value = 0;
							}

							if (document.getElementById('sb_vt_data_header')) {
								document.getElementById('sb_vt_data_header').style.cursor = 'default';
							}
						};

						window.onmouseup = fct_window_mouseup;


						let fct_window_mousemove = function(e) {

							if (document.getElementById('sb_vt_start_move')) {

								if (document.getElementById('sb_vt_start_move').value == 1) {

									let top = e.clientY + window.pageYOffset;

									if (top < (document.body.clientHeight + window.scrollY - 38)) {

										div_height = document.body.clientHeight - top +  window.scrollY;
										document.getElementById('sb_vt_data').style.height = (document.body.clientHeight - top +  window.scrollY).toString() + 'px';
										document.getElementById('sb_vt_data').style.top = top.toString() + 'px';
									}

								}
							}

						};

						window.onmousemove = fct_window_mousemove;

						if (!silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up) {
							document.getElementById('sb_vt_data_separator').style.display = 'none';
							document.getElementById('sb_vt_data_detail').style.display = 'none';
						}else{
							document.getElementById('sb_vt_data_separator').style.display = 'block';
							document.getElementById('sb_vt_data_detail').style.display = 'block';
						}

						silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
						silicon_ioi.doctype.ioiTimeVectorFrame.refresh_time_vector('', 'sb_vt_data_content', '');


						document.onscroll = function() {
							document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
						};

						let fct_hide = function() {
							document.getElementById('sb_vt_data').style.display = 'none';
						};

						let fct_mouseover = function() {
							this.style.cursor = 'pointer';
						}

						let fct_mouseleave = function() {
							this.style.cursor = 'none';
						}

						let fct_updown = function () {

							silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up = !silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up;

							if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up) {
								this.src = '/assets/silicon_ioi/images/buttons/arrow_down.png';
							}else{
								this.src = '/assets/silicon_ioi/images/buttons/arrow_up.png';
							}

							if (!silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up) {
								document.getElementById('sb_vt_data_separator').style.display = 'none';
								document.getElementById('sb_vt_data_detail').style.display = 'none';
							}else{
								document.getElementById('sb_vt_data_separator').style.display = 'block';
								document.getElementById('sb_vt_data_detail').style.display = 'block';
							}

							let go = false;

							if (!document.getElementById('sb_vt_start_move')) {
								go = true;
							}else{
								if (document.getElementById('sb_vt_start_move').value == 0) {
									go = true;
								}
							}

							if (go) {

								if (!silicon_ioi.doctype.ioiStockOutputDocumentDocType.vt_data_up) {
									div_height = 30;
								}

								if (document.body.clientHeight <= div_height)
								{
									div_height = document.body.clientHeight - 100;

								}

								content_height = div_height - 38;

								if (document.getElementById('sb_vt_data')) {
									document.getElementById('sb_vt_data').style.height = div_height.toString() + 'px';
									document.getElementById('sb_vt_data_detail').style.height = content_height.toString() + 'px';

									document.getElementById('sb_vt_data').style.top = document.body.clientHeight - div_height + window.scrollY + 'px';
								}
							}

						}

						document.getElementById('sb_vt_data_updown').onclick = fct_updown;
						document.getElementById("sb_vt_data_updown").onmouseover = fct_mouseover;
						document.getElementById("sb_vt_data_updown").onmouseleave = fct_mouseleave;


						document.getElementById('sb_vt_data_close').onclick = fct_hide;
						document.getElementById("sb_vt_data_close").onmouseover = fct_mouseover;
						document.getElementById("sb_vt_data_close").onmouseleave = fct_mouseleave;


						let fct_click = function() {
							let s = this.id;

							while (s.indexOf('_') != -1) {
								s = s.substring(s.indexOf('_')+1, s.length)
							}

							s = s.trim();

							let item_id = '';

							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id) {
								item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.item_id;
							}

							let to_execute = 0;

							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute) {
								to_execute = cur_frm.fields_dict['line_detail'].grid.grid_rows[s].doc.to_execute;
							}

							document.getElementById('sb_vt_data_header_title').innerHTML = '<b>' + __("Time Vector") + '</b> ' + item_id;

							silicon_ioi.doctype.ioiTimeVectorFrame.item_id = item_id;
							silicon_ioi.doctype.ioiTimeVectorFrame.doctype = '';
							silicon_ioi.doctype.ioiTimeVectorFrame.origin = 'IOI STOCK OUTPUT';
							silicon_ioi.doctype.ioiTimeVectorFrame.pk_reference = cur_frm.doc.name + ' | ' + (parseInt(s)+1).toString();
							silicon_ioi.doctype.ioiTimeVectorFrame.frame_height = 520;
							silicon_ioi.doctype.ioiTimeVectorFrame.time_vector_refresh();

							if (item_id != '') {
								if (to_execute == 1) {
									document.getElementById('sb_vt_data').style.display = 'block';
								}else{
									document.getElementById('sb_vt_data').style.display = 'none';
								}
							}else{
								document.getElementById('sb_vt_data').style.display = 'none';
							}

							cur_frm.fields_dict['line_detail'].grid.wrapper[0].getElementsByClassName('rows')[0].children[s].children[0].click();
						}

						for (var i = 0; i < cur_frm.fields_dict["line_detail"].grid.wrapper[0].getElementsByClassName('rows')[0].childElementCount; i++) {

							if (document.getElementById('vt_button_' + i.toString())) {
								document.getElementById('vt_button_' + i.toString()).onclick = fct_click;
							}
						}
					});
				}
			}
		}
	}

	create_new_contact(fieldname, reference_fieldname)
	{
		if ((!cur_frm.doc.ioistatus) || (cur_frm.doc.ioistatus == 0)) {

			if ((!cur_frm.doc[reference_fieldname]) || (cur_frm.doc[reference_fieldname] == '')) {
				frappe.msgprint({title: __("Message"), message: __('Customer is mandatory'), indicator: "red"});
				raise;
			}

			frappe.new_doc("Contact",{"customer": cur_frm.doc[reference_fieldname]});
		}
	}

	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************
	#sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Sleep static
	// ***************************************************************************************************************************************
	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	// ***************************************************************************************************************************************
	// Select Data from table : advanced search
	// ***************************************************************************************************************************************
	search_select_form(title, form_width_pixel, table,fields, fields_len, fields_desc, where, order_by, fct_callback)
	{
		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback);
	}


	// ***************************************************************************************************************************************
	// Clear all delivery customer fields
	// ***************************************************************************************************************************************
	#clear_all_delivery_customer_fields(me)
	{
		me.frm.set_intro('', 'red');
		me.frm.doc.delivery_customer_id = '';
		me.frm.doc.delivery_customer_name = '';
		me.frm.doc.delivery_customer_contact_id = '';
		me.frm.doc.delivery_customer_contact_name = '';

		me.frm.refresh_field('address_detail');

		if (me.frm.doc.address_detail)
		{
			for(var i = me.frm.get_field("address_detail").grid.grid_rows.length-1; i >= 0; i--) {
				if (me.frm.get_field("address_detail").grid.grid_rows[i].doc.detail_mode.toUpperCase() == 'DELIVERY') {
					me.frm.doc.address_detail[i].detail_mode = '';
					me.frm.get_field("address_detail").grid.grid_rows[i].remove();
					break;
				}
			}

			me.frm.refresh_field('address_detail');
		}

		if (me.frm.doc.log_detail)
		{
			let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

			while (i >= 0)
			{
				if ((me.frm.doc.log_detail[i].from_fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_ID'))
				{
					me.frm.get_field("log_detail").grid.grid_rows[i].remove();
				}
				i--;
			}
		}

		me.frm.refresh_field('delivery_customer_id');
		me.frm.refresh_field('delivery_customer_name');
		me.frm.refresh_field('delivery_customer_contact_id');
		me.frm.refresh_field('delivery_customer_contact_name');
		me.frm.refresh_field('address_detail');
		me.frm.refresh_field('log_detail');
	}


	// ***************************************************************************************************************************************
	// Select delivery customer
	// ***************************************************************************************************************************************
	select_delivery_customer_id()
	{

		this.frm.refresh_field('delivery_customer_id');

		if ((this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() != ''))
		{
			let me = this;

			frappe.call({  	method: this.path_stock_output + '.ioi_stock_output_select_delivery_customer',
							args: 	{	"delivery_customer_id": this.frm.doc.delivery_customer_id
							},
							async: false,
			   				callback: function(r)	{
														me.#clear_all_delivery_customer_fields(me);

								   						if (r.message.error == 1)
														{
															me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
														}


														me.frm.doc.delivery_customer_id = r.message.delivery_customer_id;
														me.frm.doc.delivery_customer_name = r.message.delivery_customer_name;
														me.frm.doc.delivery_customer_contact_id = '';
														me.frm.doc.delivery_customer_contact_name = '';


														if ((r.message.currency_id) && (r.message.currency_id.trim() != ''))
														{
															me.frm.doc.currency_id = r.message.currency_id;
															me.frm.doc.currency_rate = r.message.currency_rate;
															me.frm.doc.currency_rate_inv = r.message.currency_rate_inv;
															me.frm.doc.currency_digit_rounding = r.message.currency_digit_rounding;
														}

														me.frm.refresh_field('address_detail');

														me.frm.refresh_field('address_detail');
														let delivery_address = me.frm.add_child('address_detail');
														delivery_address.detail_mode = 'DELIVERY';
														delivery_address.full_name = r.message.delivery_customer_name;
														delivery_address.detail_address = r.message.delivery_customer_address;
														delivery_address.detail_postal_code_id = r.message.delivery_customer_postal_code_id;
														delivery_address.detail_postal_code = r.message.delivery_customer_postal_code;
														delivery_address.detail_city = r.message.delivery_customer_city;
														delivery_address.detail_country_id = r.message.delivery_customer_country_id;
														delivery_address.detail_phone_1 = r.message.delivery_customer_phone1;
														delivery_address.detail_phone_2 = r.message.delivery_customer_phone2;
														delivery_address.detail_email = r.message.delivery_customer_email;
														delivery_address.latitude = r.message.delivery_customer_latitude
														delivery_address.longitude = r.message.delivery_customer_longitude

														me.frm.refresh_field('address_detail');

														me.frm.refresh_field('delivery_customer_id');
														me.frm.refresh_field('delivery_customer_name');
														me.frm.refresh_field('delivery_customer_contact_id');
														me.frm.refresh_field('delivery_customer_contact_name');


														me.frm.refresh_field('currency_id');
														me.frm.refresh_field('currency_rate');
														me.frm.refresh_field('currency_rate_inv');
														me.frm.refresh_field('currency_digit_rounding');
														me.frm.refresh_field('address_detail');



														if ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() != ''))
														{
															me.set_query('delivery_customer_contact_id', '', me.path_search_definition + '.set_search_definition_filters', '{"customer" : "' + me.frm.doc.delivery_customer_id + '"}');
														}else
														{	me.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
														}

													}
			});
		}else
		{	let me = this;
			this.#clear_all_delivery_customer_fields(me);
			this.set_query('delivery_customer_contact_id', '', me.path_customer + '.ioi_contact_get_none');
		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}

	// ***************************************************************************************************************************************
	// Delivery Customer : advanced search
	// ***************************************************************************************************************************************
	search_delivery_customer()
	{
		let me = this;

		let fields = 'name, full_name, address, postal_code, city, country_id, phone_1, phone_2, email, alternate_id';
		let fields_len = '200, 300, 350, 150, 250, 250, 150, 150, 400, 250';
		let fields_desc = 'Identification, Name, Address, Postal code, City, Country, Phone 1, Phone 2, Email, Alternate id';
		let where = 'ioistatus = 2';
		let order_by = 'name asc';

		let fct_callback = function (return_value) {

			me.frm.doc.delivery_customer_id = return_value;
			me.frm.refresh_field('delivery_customer_id');
			me.select_delivery_customer_id();
		}

		silicon_ioi.ioiCommon.select_customer(fields, fields_len, fields_desc, where, order_by, fct_callback);

	}


	// ***************************************************************************************************************************************
	// Select delivery customer contact
	// ***************************************************************************************************************************************
	select_delivery_customer_contact_id()
	{
		this.frm.set_intro('', 'red');

		if ((!this.frm.doc.delivery_customer_id) || (this.frm.doc.delivery_customer_id) && (this.frm.doc.delivery_customer_id.trim() == ''))
		{
			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_CONTACT_ID')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#set_trace(this, 'log_detail', "delivery_customer_id", "delivery_customer_contact_id", __('Delivery customer is mandatory for selecting a contact'), 'N');

			this.frm.doc.delivery_customer_contact_id = '';
			this.frm.refresh_field('delivery_customer_contact_id');

		}else
		{
			if ((this.frm.doc.delivery_customer_contact_id) && (this.frm.doc.delivery_customer_contact_id.trim() != ''))
			{
				let me = this;

				frappe.call({  	method: this.path_customer + '.ioi_customer_is_contact_for_customer',
								args: 	{	"customer_id": this.frm.doc.delivery_customer_id,
											"contact_id": this.frm.doc.delivery_customer_contact_id
										},
								async: false,
			   					callback: function(r)	{
															if (me.frm.doc.log_detail)
															{
																let i = me.frm.get_field("log_detail").grid.grid_rows.length-1;

																while (i >= 0)
																{
																	if (me.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DELIVERY_CUSTOMER_CONTACT_ID')
																	{
																		me.frm.get_field("log_detail").grid.grid_rows[i].remove();
																		break;
																	}
																	i--;
																}
															}

															if (r.message == 0)
															{
																me.#set_trace(me, 'log_detail', "delivery_customer_id", "delivery_customer_contact_id", __('This delivery contact is not linked to the delivery customer'), 'N');

																me.frm.doc.delivery_customer_contact_id = '';
																me.frm.refresh_field('delivery_customer_contact_id');
															}
							   							}
				});
			}
		}

		this.#sleep(1000).then(() => { this.set_label_when_error(); });

	}

	search_delivery_customer_contact()
	{
		let me = this;

		if ((!me.frm.doc.delivery_customer_id) || ((me.frm.doc.delivery_customer_id) && (me.frm.doc.delivery_customer_id.trim() == ''))) {

			frappe.msgprint({title: __("Message"), message: __('Delivery customer is mandatory'), indicator: "red"});
			raise;
		}


		let fields = 'name, customer, first_name, last_name, address, function, phone, email_id';
		let fields_len = '200, 200, 300, 300, 350, 250, 150, 400, 200';
		let fields_desc = 'Identification, Customer, First name, Last name, Address, Function, Phone, Email';
		let where = '';
		let order_by = 'name asc';

		let what = 'CUSTOMER';
		let parent = me.frm.doc.delivery_customer_id;


		let fct_callback = function (parent_value, return_value) {

			me.frm.set_value('delivery_customer_contact_id', return_value);
		}

		silicon_ioi.ioiCommon.select_contact(fields, fields_len, fields_desc, where, order_by, fct_callback, what, parent);

	}



	// ***************************************************************************************************************************************
	// Select document date
	// ***************************************************************************************************************************************
	select_document_date()
	{
		if ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() != ''))
		{
			this.frm.set_intro('', 'red');

			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DOCUMENT_DATE')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#sleep(1000).then(() => { this.set_label_when_error(); });
		}
	}



	// ***************************************************************************************************************************************
	// Format Child Address form
	// ***************************************************************************************************************************************
	format_address_form(frm, cdt, cdn)
	{
		this.#fill_address_detail_mode();

		this.#build_banner_detail(frm, cdt, cdn, 'address_detail', 'html_address', 'Address');
		this.#build_banner_detail(frm, cdt, cdn, 'address_detail', 'html_phone', 'Phone & email');
		this.#build_banner_detail(frm, cdt, cdn, 'address_detail', 'html_remark', 'Remark');

		if (!this.detail_mode_readonly)
		{	this.frm.fields_dict['address_detail'].grid.update_docfield_property('detail_mode', 'read_only', 0);
		}else
		{ 	this.frm.fields_dict['address_detail'].grid.update_docfield_property('detail_mode', 'read_only', 1);
		}
		this.detail_mode_readonly = true;

		this.#refresh_spacer(cdt, cdn);

		let item = locals[cdt][cdn];

		if (this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form)
		{
			for (var i = 0; i < this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					//this.frm.get_field('address_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
				}
			}

		}


		silicon_ioi.doctype.ioiStockOutputDocumentDocType.refresh_map(item, cdt, cdn, 1000);

	}

	// ***************************************************************************************************************************************
	// Format spacer
	// ***************************************************************************************************************************************
	#refresh_spacer(cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let html = '<div id="spacer" style="width:100%; height:150px"></div>';

		let selected_row = this.frm.get_field('address_detail').grid.grid_rows_by_docname[item.name];
		selected_row.get_field('html_spacer').set_value(html);
	}

	static refresh_map(item, cdt, cdn, timer)
	{
		if (((item.latitude) && (item.latitude != 0)) && ((item.longitude) && (item.longitude != 0)))
		{
			silicon_ioi.ioiCommon.show_map_detail(item, cdt, cdn, 'address_detail', 'html_map', timer, item.latitude, item.longitude);
		}else
		{
			silicon_ioi.ioiCommon.clear_map_detail(item, cdt, cdn, 'address_detail', 'html_map');
		}
	}


	// ***************************************************************************************************************************************
	// Callback : get coordinates from address
	// ***************************************************************************************************************************************
	static get_coordinates_from_address_callback(item, cdt, cdn, latitude, longitude)
	{
		if (!cur_frm.is_dirty())
		{
			cur_frm.dirty();
		}

		item.latitude = latitude;
		item.longitude = longitude;

		cur_frm.refresh_field('address_detail');


		silicon_ioi.doctype.ioiStockOutputDocumentDocType.refresh_map(item, 500);
	}

	// ***************************************************************************************************************************************
	// Callback : get address from coordinates
	// ***************************************************************************************************************************************
	static get_address_from_coordinates_callback(item, cdt, cdn, address, postalcode, city, countrycode)
	{
		if (!cur_frm.is_dirty())
		{
			cur_frm.dirty();
		}

		item.detail_address = address;
		item.detail_city = city;
		item.detail_postal_code = postalcode;
		item.detail_country_id = countrycode;

		item.detail_postal_code_id = countrycode + ' • ' + postalcode + ' • ' + city;

		cur_frm.refresh_field('address_detail');

		silicon_ioi.doctype.ioiStockOutputDocumentDocType.refresh_map(item, 500);
	}



	#fill_address_detail_mode()
	{
		this.address_mode = [];
		this.address_mode[0] = ['DELIVERY'];

		let options = '[';

		for (var i = 0; i < this.address_mode.length; i++)
		{
			options += '{"label": "' + this.address_mode[i] + '", "value": "' + this.address_mode[i] + '"}';

			if (i != this.address_mode.length-1)
			{
				options += ',';
			}
		}

		options += ']';

		this.frm.fields_dict.address_detail.grid.update_docfield_property("detail_mode", "options", JSON.parse(options));
	}

	// ***************************************************************************************************************************************
	// Format banner detail
	// ***************************************************************************************************************************************
	#build_banner_detail(frm, cdt, cdn, childdoc, fieldname, description, br=true)
	{
		let html = '';
		let item = locals[cdt][cdn];
		let selected_row = this.frm.get_field(childdoc).grid.grid_rows_by_docname[item.name];

		html += '<div style="height:30px; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;" data-custom-section-head="true">';
		html += '<b><font style="font-size:14px;color: #1F272E">&nbsp;'+ __(description) +'</b></font>';
		html += '</div>';

		if (br)
		{
			html += '<div style="height:8px;"></div>';
		}

		selected_row.get_field(fieldname).set_value(html);
	}


	// ***************************************************************************************************************************************
	// Addresses : get city and country of detail_postal_code_id to fill detail_city and detail_country_id
	// ***************************************************************************************************************************************
	get_addresses_city_country(frm, cdt, cdn)
	{
		let address_item = locals[cdt][cdn];

		if ((address_item.detail_postal_code_id) && (address_item.detail_postal_code_id.trim() != ''))
		{
			let s = address_item.detail_postal_code_id;
			let country = '';
			let postal_code = '';
			let city = '';

			country = s.substring(0, s.indexOf('•'));

			s = s.substring(s.indexOf('•')+1, s.length);
			postal_code = s.substring(0, s.indexOf('•'));

			s = s.substring(s.indexOf('•')+1, s.length);

			city = s.trim();

			address_item.detail_country_id = country.trim();
			address_item.detail_city = city.trim();
			address_item.detail_postal_code = postal_code.trim();
		}

		this.frm.refresh_field('address_detail');
	}

	// ***************************************************************************************************************************************
	// Addresses : new record
	// ***************************************************************************************************************************************
	addresses_new_record(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];
		item.detail_mode = 'DELIVERY';
		this.detail_mode_readonly = false;

		if (document.getElementById('hidden_can_edit_document').value == '0')
		{	item.can_remove = 2;
			this.frm.get_field("address_detail").grid.grid_rows[item.idx-1].remove();
		}

	}
	// ***************************************************************************************************************************************
	// Addresses : before delete record
	// ***************************************************************************************************************************************
	addresses_detail_before_remove(frm, cdt, cdn)
	{
		if (document.getElementById('hidden_can_edit_document').value == '0')
		{
			let item = locals[cdt][cdn];

			if (item.can_remove != 2)
			{	raise;
			}
		}
	}

	// ***************************************************************************************************************************************
	// Error Log : cancel add row
	// ***************************************************************************************************************************************
	error_log_cancel_add(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];
		this.frm.get_field("log_detail").grid.grid_rows_by_docname[item.name].remove();
		this.frm.refresh_field('log_detail');
	}

	// ***************************************************************************************************************************************
	// Currency : get rate
	// ***************************************************************************************************************************************
	currency_get_rate()
	{
		if ((!this.frm.doc.currency_id) || ((this.frm.doc.currency_id) && (this.frm.doc.currency_id.trim() == '')))
		{
			this.frm.doc.currency_id = this.currency_id;
			this.frm.doc.currency_rate = this.currency_rate;
			this.frm.doc.currency_rate_inv = this.currency_rate_inv;
			this.frm.doc.currency_digit_rounding = this.currency_digit_rounding;
		}else
		{	let me = this;
			let method = this.path_currency + '.ioi_currency_get_data';

			frappe.call({  	method: method,
							args: {"currency_id": this.frm.doc.currency_id},
							async: false,
							callback:function(r)	{
														me.frm.doc.currency_rate = r.message.sales_rate;
														me.check_currency_rate();
														me.frm.doc.currency_rate_inv = r.message.sales_rate_inv;
														me.check_currency_rate_inv();
														me.frm.doc.currency_digit_rounding = r.message.decimals;
													}
			});
		}

		this.frm.refresh_field('currency_id');
		this.frm.refresh_field('currency_rate');
		this.frm.refresh_field('currency_rate_inv');
		this.frm.refresh_field('currency_digit_number');

	}

	// ***************************************************************************************************************************************
	// Currency : check curency rate
	// ***************************************************************************************************************************************
	check_currency_rate()
	{
		if ((!this.frm.doc.currency_rate) || ((this.frm.doc.currency_rate) && (this.frm.doc.currency_rate == 0)))
		{
			this.frm.doc.currency_rate = 1;
		}
		this.check_currency_rate_inv();
		this.frm.refresh_field('currency_rate');
	}

	// ***************************************************************************************************************************************
	// Currency : check curency rate (inv)
	// ***************************************************************************************************************************************
	check_currency_rate_inv()
	{
		if ((!this.frm.doc.currency_rate) || ((this.frm.doc.currency_rate) && (this.frm.doc.currency_rate == 0)))
		{
			this.frm.doc.currency_rate = 1;
		}

		if ((!this.frm.doc.currency_rate_inv) || ((this.frm.doc.currency_rate_inv) && (this.frm.doc.currency_rate_inv == 0)))
		{
			this.frm.doc.currency_rate_inv = 1;
		}
		this.frm.doc.currency_rate_inv = 1 / this.frm.doc.currency_rate;

		this.frm.refresh_field('currency_rate');
		this.frm.refresh_field('currency_rate_inv');
	}

	// ***************************************************************************************************************************************
	// Populate line
	// ***************************************************************************************************************************************
	populate_line(frm, cdt, cdn, source_type = 'ITEM_ID', unit_id = '')
	{
		this.frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];
		let can_populate_line = false;

		this.frm.set_intro('', 'red');

		let item_id = ''
		let barcode_ref = ''

		if ((source_type.toUpperCase() == 'ITEM_ID') || (source_type.toUpperCase() == 'QTY'))
		{
			if ((item.item_id) && (item.item_id.trim() != ''))
			{	can_populate_line = true;
				item_id = item.item_id;

			}else
			{
				item.item_mode = 0;
				item.min_order_qty_source = '';
				item.multiple_order_qty_source = '';
				item.decimals_allowed = 1;
				this.format_detail_form(frm, cdt, cdn)
			}

		}else
		{
			if ((item.barcode_reference) && (item.barcode_reference.trim() != ''))
			{	can_populate_line = true;
				barcode_ref = item.barcode_reference;
			}

		}

		if (can_populate_line)
		{

			let dc_type = '';
			let qty = 0;

			dc_type = 'STOCK_OUTPUT';

			if (!item.delivered_qty)
			{
				item.delivered_qty = 0;
				qty = 0;
			}else
			{
				qty = item.delivered_qty;
			}


			let me = this;

			frappe.call({  	method: this.path_stock_output + '.ioi_stock_output_populate_line',
							args: 	{
										"source_type": source_type,
										"item_id": item_id,
										"document_date": this.frm.doc.document_date,
										"document_language": this.user_language
							},
							async: false,
							callback: function(r)	{

														if (source_type.toUpperCase() != 'QTY')
														{
															item.item_id = '';
															item.item_description = '';
															item.item_mode = 0
															item.analytic1_id = '';
															item.analytic2_id = '';
															item.analytic3_id = '';
															item.analytic4_id = '';
															item.unit_id = '';
														}


														item.min_order_qty_source = '';
														item.multiple_order_qty_source = '';

														item.manual_price = 0;

														item.decimals_allowed = 1;

														if (r.message.error == 1)
														{	me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
														}else
														{
															if (source_type.toUpperCase() != 'QTY')
															{
																item.item_id = r.message.item_id;
																item.item_description = r.message.item_description;
																item.item_mode = r.message.mode

																item.unit_id = r.message.unit_id;
																item.weight = r.message.weight;
																item.tare = r.message.tare;
																item.volume = r.message.volume;

															}


															item.decimals_allowed = r.message.decimals_allowed;

															if (source_type.toUpperCase() != 'QTY')
															{
																item.analytic1_id = r.message.analytic1_id;
																item.analytic2_id = r.message.analytic2_id;
																item.analytic3_id = r.message.analytic3_id;
																item.analytic4_id = r.message.analytic4_id;
															}


															item.item_cost_std_stamp = r.message.item_cost_std_stamp;
															item.item_cost = r.message.item_cost;

															if (source_type.toUpperCase() != 'QTY')
															{
																if ((r.message.warehouse_id) && (r.message.warehouse_id.trim() != ''))
																{
																	item.warehouse_id = r.message.warehouse_id;
																	item.warehouse_location_id = r.message.warehouse_location_id;
																}
															}



															if (source_type.toUpperCase() != 'QTY')
															{
																if ((r.message.price_description) && (r.message.price_description.trim() != ''))
																{
																	if (r.message.price_description[0] == '&')
																	{
																		item.item_description = item.item_description + r.message.price_description.substr(1, r.message.price_description.length);
																	}
																}
															}

														}

														me.frm.refresh_field('line_detail');


														}
			});
		}else
		{
			this.frm.refresh_field('line_detail');

			if ((item.item_id) && (item.item_id.trim() != '')) {
				item.item_description = '';
			}
			item.decimals_allowed = 1;


		}

		const grid_row = this.frm.fields_dict["line_detail"].grid.grid_rows_by_docname[item.name];
		const item_description = grid_row.docfields.filter(f => f.fieldname == "item_description")[0];
		const item_unit = grid_row.docfields.filter(f => f.fieldname == "unit_id")[0];

		if ((item.item_id) && (item.item_id != ''))
		{
			item_description.read_only = true;
		}else
		{
			item_description.read_only = false;
		}
		this.frm.refresh_field('line_detail');
		this.set_grid_layout();

		this.#sleep(1000).then(() => { this.set_label_when_error(); });
	}




	// ***************************************************************************************************************************************
	// Format qty
	// ***************************************************************************************************************************************
	format_qty(frm, cdt, cdn, fieldname)
	{
		let item = locals[cdt][cdn]

		if ((!item.decimals_allowed) || (item.decimals_allowed == 0))
		{
			if ((item.item_id) && (item.item_id.trim() != ''))
			{
				item[fieldname] = Math.round(item[fieldname]);
				frm.refresh_field('line_detail');
			}
		}
	}

	// ***************************************************************************************************************************************
	// Set a trace to the log
	// ***************************************************************************************************************************************
	#set_trace(obj, log_detail, from_fieldname, fieldname, error_message, db_save)
	{
		let error_row = obj.frm.add_child(log_detail);
		error_row.from_fieldname = from_fieldname;
		error_row.fieldname = fieldname;
		error_row.error_message = error_message;
		error_row.db_save = db_save;
		obj.frm.refresh_field(log_detail);

		obj.frm.set_intro(error_message, 'red');
		frappe.show_alert({ message:error_message, indicator:'red'}, 5);
	}

	// ***************************************************************************************************************************************
	// Format Child Detail form
	// ***************************************************************************************************************************************
	format_detail_form(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		if ((item.is_warning) && (item.is_warning != 0))
		{
			let msg_bgcolor = '';
			let msg_fontcolor = ''

			if (item.is_warning == 1)
			{	msg_bgcolor = '#FFF5F0';
				msg_fontcolor = '#F57231';
			}else
			{	msg_bgcolor = '#FEADAD';
				msg_fontcolor = 'black';
			}

			if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();

					let html = '';

					html = '<br><div align="center" style="overflow:auto; vertical-align: middle; height:28px;border-radius:6px;padding:4px;background-color:' + msg_bgcolor + ';">';
					html += '<font color="' + msg_fontcolor +'">' + item.warninfo + '</font>';
					html += '</div>';

					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.append(html);
				}
			}
		}else
		{
			if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();
				}
			}
		}


		let me = this;

		this.#refresh_detail_ioistatus(frm, cdt, cdn);


		this.form_format_all_qties(item.idx);



		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'item_cost_std_stamp');
		this.#detail_set_original_caption_with_currency(item, 'line_detail', 'item_cost');

		let fct_whs = function () {	me.set_query('warehouse_id', 'line_detail', me.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + me.frm.doc.site_id + '"}'); };
		setTimeout(fct_whs(), 200)

		if (cur_frm.doc.ioistatus != 0) {

			let stored_qty_mode = cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').value;

			if ((!stored_qty_mode) || ((stored_qty_mode) && (stored_qty_mode == 0))) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Good');
			}else if (stored_qty_mode == 1) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').$input_wrapper[0].children[1].innerText = __('To check');
			}else{
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Bad');
			}
		}

		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
		{
			this.#build_html_production_bom_buttons('html_production_bom_buttons', cdt, cdn);
			this.build_html_production_bom_data(cdt, cdn);

			this.#build_html_dossier_bom_buttons('html_dossier_bom_buttons', cdt, cdn);
			this.build_html_dossier_bom_data(cdt, cdn);

			this.#build_html_location_buttons('html_location_buttons', cdt, cdn);
			this.#build_html_batch_buttons('html_batch_buttons', cdt, cdn);

			this.build_html_sales_invoice_data(cdt, cdn);



			for (var i = 0; i < this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].is_collapsed())
					//if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head[0].className == 'section-head collapsed')
					{	this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}
					//this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
				}

			}
		}
	}


	static grid_format_float_qty(element, gridcell)
	{

		let original_value = element.innerText.trim()

		if (gridcell != null) {

			let fct_blur = function() {
				silicon_ioi.doctype.ioiStockOutputDocumentDocType.grid_format_float_qty(element, gridcell);
			}

			gridcell.onblur = fct_blur;
		}


		if ((original_value != '') && (original_value != 0)) {

			original_value = original_value.replaceAll(' ', '');

			let idx_coma = -1;
			let idx_point = -1;

			if (original_value.indexOf(',') != -1) {
				idx_coma = original_value.indexOf(',');
			}

			if (original_value.indexOf('.') != -1) {
				idx_point = original_value.indexOf('.');
			}

			if ((idx_coma != -1) || (idx_point != -1)) {

				let is_point = false;

				if (idx_coma < idx_point) {

					if (idx_coma != -1) {
						original_value = original_value.replaceAll(',', '');
						is_point = true;
					}

				}else if (idx_coma > idx_point)
				{
					if (idx_point != -1) {
						original_value = original_value.replaceAll('.', '');
					}
				}

				let can_continue = false;

				if (is_point)
				{
					if (original_value.indexOf('.') != -1) {
						can_continue = true;
					}

				}else{
					if (original_value.indexOf(',') != -1) {
						can_continue = true;
					}

				}

				if (can_continue) {

					can_continue = true;

					let is_number = true;

					if (isNaN(original_value)) {

						if (original_value.indexOf('.') != -1) {
							original_value = original_value.replaceAll('.', ',');
						}else if (original_value.indexOf(',') != -1) {
							original_value = original_value.replaceAll(',', '.');
						}else{
							can_continue = false;
						}

						is_number = false;
					}

					if (can_continue) {

						let modified_value = parseFloat(original_value);

						if (!is_number) {

							if (modified_value.toString().indexOf('.') != -1) {
								modified_value = modified_value.toString().replaceAll('.', ',');
							}else if (modified_value.toString().indexOf(',') != -1) {
								modified_value = modified_value.toString().replaceAll(',', '.');
							}
						}

						element.innerText = modified_value;
					}
				}
			}
		}

	}


	form_format_qty_float(fieldname, grid_idx)
	{
		if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form) {

			let original_value = '';

			if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
				original_value = cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value.trim();

				let me = this;
				let fct_blur = function() {
					me.form_format_qty_float(fieldname, grid_idx);
				}
				cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.onblur = fct_blur;

			}else{
				original_value = cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText.trim();
			}


			if ((original_value != '') && (original_value != 0)) {

				original_value = original_value.replaceAll(' ', '');

				let idx_coma = -1;
				let idx_point = -1;

				if (original_value.indexOf(',') != -1) {
					idx_coma = original_value.indexOf(',');
				}

				if (original_value.indexOf('.') != -1) {
					idx_point = original_value.indexOf('.');
				}

				if ((idx_coma != -1) || (idx_point != -1)) {

					let is_point = false;

					if (idx_coma < idx_point) {

						if (idx_coma != -1) {
							original_value = original_value.replaceAll(',', '');
							is_point = true;
						}

					}else if (idx_coma > idx_point)
					{
						if (idx_point != -1) {
							original_value = original_value.replaceAll('.', '');
						}
					}

					let can_continue = false;

					if (is_point)
					{
						if (original_value.indexOf('.') != -1) {
							can_continue = true;
						}

					}else{
						if (original_value.indexOf(',') != -1) {
							can_continue = true;
						}

					}

					if (can_continue) {

						can_continue = true;

						let is_number = true;

						if (isNaN(original_value)) {

							if (original_value.indexOf('.') != -1) {
								original_value = original_value.replaceAll('.', ',');
							}else if (original_value.indexOf(',') != -1) {
								original_value = original_value.replaceAll(',', '.');
							}else{
								can_continue = false;
							}

							is_number = false;
						}

						if (can_continue) {

							let modified_value = parseFloat(original_value);

							if (!is_number) {

								if (modified_value.toString().indexOf('.') != -1) {
									modified_value = modified_value.toString().replaceAll('.', ',');
								}else if (modified_value.toString().indexOf(',') != -1) {
									modified_value = modified_value.toString().replaceAll(',', '.');
								}
							}


							if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
								cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value = modified_value;
							}else{
								cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = modified_value;
							}

						}
					}
				}
			}
		}
	}

	form_format_all_qties(grid_idx)
	{
		this.form_format_qty_float('delivered_qty', grid_idx);
		this.form_format_qty_float('reserved_qty', grid_idx);
		this.form_format_qty_float('allocated_qty', grid_idx);
		this.form_format_qty_float('qty_to_prepare', grid_idx);
		this.form_format_qty_float('min_order_qty', grid_idx);
		this.form_format_qty_float('multiple_order_qty', grid_idx);
	}




	// ***************************************************************************************************************************************
	// Line detail : add new line
	// ***************************************************************************************************************************************
	line_detail_add(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		item.guid = this.#createGUID();
		item.ioistatus = this.frm.doc.ioistatus;

		item.warehouse_id = this.frm.doc.warehouse_id;
		this.set_query('warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

		item.location_active = 0;

		if ((item.warehouse_id) && (item.warehouse_id.trim() != '')) {

			let me = this;

			let method = this.path_warehouse + '.ioi_warehouse_get_data';

			frappe.call({  	method: method,
							args: {	"warehouse_id" : item.warehouse_id
						},
							async: false,
							callback:function(r)	{

								item.location_active = 0;

								if (r.message[0].location_active) {
									item.location_active = r.message[0].location_active;
								}


							}
			});
		}


		if ((this.frm.doc.production_id) && (this.frm.doc.production_id.trim() != '')) {
			item.production_id = this.frm.doc.production_id;
		}else if ((this.frm.doc.dossier_id) && (this.frm.doc.dossier_id.trim() != '')) {
			item.dossier_id = this.frm.doc.dossier_id;
		}

		item.delivered_qty = 0;
		item.reserved_qty = 0;
		item.allocated_qty = 0;
		item.stored_qty_mode = 0;

		item.min_qty_for_price = 0;

		item.item_cost_std_stamp = 0;
		item.item_cost = 0;

		item.document_date = this.frm.doc.document_date;
		item.period_id = this.frm.doc.period_id;


		if (document.getElementById('hidden_can_edit_document').value == '0')
		{	item.can_remove = 2;
			this.frm.get_field("line_detail").grid.grid_rows[item.idx-1].remove();
		}


		this.frm.refresh_field('line_detail');





		this.set_grid_layout();

		silicon_ioi.doctype.ioiStockOutputDocumentDocType.sleep_static(100).then(() => {
			if (cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				for (var i = 0; i < cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
				{
					if (cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
					{
						cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}

				}
			}
		});




	}

	// ***************************************************************************************************************************************
	// Line detail : remove line
	// ***************************************************************************************************************************************
	line_detail_before_remove(frm, cdt, cdn)
	{

		let item = locals[cdt][cdn];

		if (document.getElementById('hidden_can_edit_document').value == '0')
		{
			if (item.can_remove != 2)
			{	raise;
			}
		}

	}

	// ***************************************************************************************************************************************
	// Create GUID
	// ***************************************************************************************************************************************
	#createGUID()
	{
		function random()
		{
		  	return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}

		return random() + random() + '-' + random() + '-' + random() + '-' + random() + '-' + random() + random() + random();
	}

	// ***************************************************************************************************************************************
	// Disable frm except fields
	// ***************************************************************************************************************************************
	disable_frm_except_fields()
	{

		if ((document.getElementById('hidden_can_edit_document').value == '0') || (this.frm.doc.frozen_data == 1))
		{
			this.frm.fields.forEach((field) => {
				this.frm.set_df_property(field.df.fieldname, "read_only", "1");
			  });

			this.frm.disable_save();

			let me = this;

			let method = this.path_module_status_accessible_fields + '.ioi_module_status_accessible_fields_get_status_fields';

			frappe.call({  	method: method,
							args: {"doctype":this.frm.doctype, "ioistatus":this.frm.doc.ioistatus},
							async: false,
							callback:function(r)	{

														if (r.message.length > 0)
														{
															let field_child = [];

															for(var i = 0; i < r.message.length; i++)
															{
																// 0 : doctype_id, 1 : field_id, 2 : fieldname, 3 :fieldtype

																if (r.message[i][0] == me.frm.doctype)
																{
																	me.frm.set_df_property(r.message[i][1], 'read_only', 0);
																}else
																{	if (r.message[i][2] != null)
																	{
																		me.frm.set_df_property(r.message[i][2], 'read_only', 0);

																		if (r.message[i][3].toUpperCase() == 'TABLE')
																		{
																			me.frm.fields_dict[r.message[i][2]].grid.docfields.forEach((field) => {
																				if (field_child.indexOf(r.message[i][2] + "|" + field.fieldname) == -1)
																				{
																					me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(field.fieldname, "read_only", 1);
																				}
																			});

																			if (field_child.indexOf(r.message[i][2] + "|" + r.message[i][1]) == -1)
																			{
																				field_child.push(r.message[i][2] + "|" + r.message[i][1]);
																			}

																			me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(r.message[i][1], "read_only", 0);
																		}
																	}
																}
															}

															me.frm.enable_save();
														}
													}
			});

		}

	}

	// ***************************************************************************************************************************************
	// Display select label when readonly
	// ***************************************************************************************************************************************
	display_select_label()
	{
		for (var i = 0; i < this.frm.fields.length; i++)
		{
			if (this.frm.fields[i].df)
			{
				if (this.frm.fields[i].df.read_only)
				{
					if (this.frm.fields[i].df.read_only == 1)
					{
						let display = true;

						if (this.frm.fields[i].df.hidden)
						{
							if (this.frm.fields[i].df.hidden == 1)
							{
								display = false;
							}
						}

						if (display)
						{
							if (this.frm.fields[i].df.fieldname)
							{
								if (this.frm.fields_dict[this.frm.fields[i].df.fieldname])
								{
									if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df)
									{
										if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df.fieldtype.toUpperCase() == 'SELECT')
										{
											if (this.frm.fields[i].last_options)
											{
												let t = JSON.parse(this.frm.fields[i].last_options);

												for (var j = 0; j < t.length; j++)
												{
													if (t[j].value == this.frm.fields[i].value)
													{
														this.frm.fields_dict[this.frm.fields[i].df.fieldname].$wrapper[0].children[0].children[1].children[1].innerText = __(t[j].label);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Select Item : advanced search
	// ***************************************************************************************************************************************
	select_item(frm)
	{
		let me = this;

		let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
		let fields_len = '300, 500, 125, 150, 150, 300';
		let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
		let where = 'ioistatus = 2';
		let order_by = 'a.name asc';
		let parameter_with_stock = true;
		let site_id = this.frm.doc.site_id;

		let fct_callback = function (return_value) {

			let cdt = me.frm.selected_doc.doctype;
			let cdn = me.frm.selected_doc.name;

			let item = locals[cdt][cdn];
			item.item_id = return_value;

			me.frm.refresh_field('line_detail');
			me.populate_line(frm, cdt, cdn, 'ITEM_ID');
			me.format_detail_form(frm, cdt, cdn);
		};


		silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

	}

	// ***************************************************************************************************************************************
	// Fill available status
	// ***************************************************************************************************************************************
	fill_available_status()
	{
		let available_status_desc = '';
		let available_status_bgcolor = '';

		if ((!this.frm.doc.available_status) || ((this.frm.doc.available_status) && (this.frm.doc.available_status == 0)))
		{
			available_status_desc = __("Uncalculated");
			available_status_bgcolor = '#DCDCDC';

		}else if (this.frm.doc.available_status == 1)
		{
			available_status_desc = __("Nothing");
			available_status_bgcolor = '#FA6E6B';

		}else if (this.frm.doc.available_status == 2)
		{
			available_status_desc = __("Partiel");
			available_status_bgcolor = '#759CFF';

		}else if (this.frm.doc.available_status == 3)
		{
			available_status_desc = __("Full");
			available_status_bgcolor = '#5DE861';

		}

		let html = '';
		this.frm.fields_dict['html_available_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:100px;">';
		html += '	<label id="ioi_available_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_status_desc + '</font></label>';
		html += '	</div>';


		let available_need_recompute = false;

		if (this.frm.doc.line_detail)
		{
			for(var i = 0; i < this.frm.doc.line_detail.length; i++)
			{
				if (this.frm.doc.line_detail[i].to_execute == 1) {
					available_need_recompute = true;
					break;
				}

			}
		}

		if (available_need_recompute)
		{
			available_need_recompute = false;

			if ((!this.frm.doc.available_calc_datetime) || ((this.frm.doc.available_calc_datetime) && (this.frm.doc.available_calc_datetime == ''))) {
				available_need_recompute = true;
			}else {

				if ((!this.frm.doc.ioistatus_change_dt) || ((this.frm.doc.ioistatus_change_dt) && (this.frm.doc.ioistatus_change_dt == ''))) {
					available_need_recompute = true;
				}

				if (!available_need_recompute) {

					if (this.frm.doc.available_calc_datetime < this.frm.doc.ioistatus_change_dt) {
						available_need_recompute = true;
					}
				}
			}

			if (available_need_recompute) {
				if (this.is_dark_mode == 0) {
					html += '	<label id="ioi_available_need_recompile" style="position: absolute; top: 65px; left: 2px;"><font color="blue">' + __("Availables will be recomputed") + '</font></label>';
				}else{
					html += '	<label id="ioi_available_need_recompile" style="position: absolute; top: 65px; left: 2px;"><font color="#FFFFFF">' + __("Availables will be recomputed") + '</font></label>';

				}
				this.frm.fields_dict['available_calc_datetime'].$wrapper[0].children[0].children[1].children[1].style.backgroundColor = '#FAD487';
			}

		}

		html += '</div>';


		this.frm.fields_dict['html_available_status'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Fill available scheduled status
	// ***************************************************************************************************************************************
	fill_available_scheduled_status()
	{
		let available_scheduled_status_desc = '';
		let available_scheduled_status_bgcolor = '';

		if ((!this.frm.doc.available_scheduled_status) || ((this.frm.doc.available_scheduled_status) && (this.frm.doc.available_scheduled_status == 0)))
		{
			available_scheduled_status_desc = __("Uncalculated");
			available_scheduled_status_bgcolor = '#DCDCDC';

		}else if (this.frm.doc.available_scheduled_status == 1)
		{
			available_scheduled_status_desc = __("Nothing");
			available_scheduled_status_bgcolor = '#FA6E6B';

		}else if (this.frm.doc.available_scheduled_status == 2)
		{
			available_scheduled_status_desc = __("Partiel");
			available_scheduled_status_bgcolor = '#759CFF';

		}else if (this.frm.doc.available_scheduled_status == 3)
		{
			available_scheduled_status_desc = __("Full");
			available_scheduled_status_bgcolor = '#5DE861';

		}

		let html = '';
		this.frm.fields_dict['html_available_scheduled_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_scheduled_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available scheduled status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_scheduled_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_scheduled_status_desc + '</font></label>';
		html += '	</div>';
		html += '</div>';

		this.frm.fields_dict['html_available_scheduled_status'].$wrapper.append(html);
	}


	// ***************************************************************************************************************************************
	// Fill Line available status
	// ***************************************************************************************************************************************
	#fill_line_available_status(item)
	{
		let available_status_desc = '';
		let available_status_bgcolor = '';

		let available_status_value = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_status'].doc['available_status']

		if ((!available_status_value) || ((available_status_value) && (available_status_value == 0)))
		{
			available_status_desc = __("Uncalculated");
			available_status_bgcolor = '#DCDCDC';

		}else if (available_status_value == 1)
		{
			available_status_desc = __("Nothing");
			available_status_bgcolor = '#FA6E6B';

		}else if (available_status_value == 2)
		{
			available_status_desc = __("Partiel");
			available_status_bgcolor = '#759CFF';

		}else if (available_status_value == 3)
		{
			available_status_desc = __("Full");
			available_status_bgcolor = '#5DE861';

		}

		let html = '';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:100px;">';
		html += '	<label id="ioi_available_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_status_desc + '</font></label>';
		html += '	</div>';


		let available_need_recompute = false;

		let to_execute = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['to_execute'].doc['to_execute'];

		let calc_datetime = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_calc_datetime'].doc['available_calc_datetime'];

		if ((to_execute == null) || (to_execute == ''))	{
			to_execute = 0;
		}

		if (to_execute == 1) {
			available_need_recompute = true;
		}

		if (available_need_recompute)
		{
			available_need_recompute = false;

			if ((!calc_datetime) || ((calc_datetime) && (calc_datetime == ''))) {
				available_need_recompute = true;
			}else {

				if ((!this.frm.doc.ioistatus_change_dt) || ((this.frm.doc.ioistatus_change_dt) && (this.frm.doc.ioistatus_change_dt == ''))) {
					available_need_recompute = true;
				}

				if (!available_need_recompute) {

					if (calc_datetime < this.frm.doc.ioistatus_change_dt) {
						available_need_recompute = true;
					}
				}
			}

			if (available_need_recompute) {

				let line_idx = item.idx-1;

				if (this.is_dark_mode == 0) {
					html += '	<label id="line_ioi_available_need_recompile_' + line_idx.toString() + '" style="position: absolute; top: 65px; left: 2px;"><font color="blue">' + __("Availables will be recomputed") + '</font></label>';
				}else{
					html += '	<label id="line_ioi_available_need_recompile_' + line_idx.toString() + '" style="position: absolute; top: 65px; left: 2px;"><font color="#FFFFFF">' + __("Availables will be recomputed") + '</font></label>';

				}
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['available_calc_datetime'].wrapper.children[0].children[1].children[1].style.backgroundColor = '#FAD487';
			}

		}


		html += '</div>';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_status'].$wrapper.append(html);

	}

	// ***************************************************************************************************************************************
	// Fill Line available scheduled status
	// ***************************************************************************************************************************************
	#fill_line_available_scheduled_status(item)
	{
		let available_scheduled_status_desc = '';
		let available_scheduled_status_bgcolor = '';

		let available_scheduled_status_value = this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['available_scheduled_status'].doc['available_scheduled_status']

		if ((!available_scheduled_status_value) || ((available_scheduled_status_value) && (available_scheduled_status_value == 0)))
		{
			available_scheduled_status_desc = __("Uncalculated");
			available_scheduled_status_bgcolor = '#DCDCDC';

		}else if (available_scheduled_status_value == 1)
		{
			available_scheduled_status_desc = __("Nothing");
			available_scheduled_status_bgcolor = '#FA6E6B';

		}else if (available_scheduled_status_value == 2)
		{
			available_scheduled_status_desc = __("Partiel");
			available_scheduled_status_bgcolor = '#759CFF';

		}else if (available_scheduled_status_value == 3)
		{
			available_scheduled_status_desc = __("Full");
			available_scheduled_status_bgcolor = '#5DE861';

		}

		let html = '';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_scheduled_status'].$wrapper.empty();

		html = '<div style="overflow: auto; overflow-x: auto; height:70px;">';
		html += '	<label id="ioi_available_scheduled_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Available scheduled status") + '</label>';
		html += '	<div style="position: absolute; top: 23px; left: 2px;height:32px; width:100%; background-color:' + available_scheduled_status_bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '		<label style="position: absolute; top: 7px; left: 10px;"><font color="black">' + available_scheduled_status_desc + '</font></label>';
		html += '	</div>';
		html += '</div>';

		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_available_scheduled_status'].$wrapper.append(html);


	}


	select_dossier(cdt, cdn)
	{

		let line = locals[cdt][cdn];

		if ((!this.frm.doc.dossier_id) || ((this.frm.doc.dossier_id) && (this.frm.doc.dossier_id.trim() == ''))) {
			this.frm.doc.dossier_id = line.dossier_id;
			this.frm.refresh_field('dossier_id');
		}


		line.production_id = '';
		line.production_bom_id = '';
		line.dossier_bom_id = '';

		this.frm.refresh_field('line_detail');

		this.build_html_production_bom_data(cdt, cdn);
		this.build_html_dossier_bom_data(cdt, cdn);


	}

	select_production(cdt, cdn)
	{
		let line = locals[cdt][cdn];

		if ((!this.frm.doc.production_id) || ((this.frm.doc.production_id) && (this.frm.doc.production_id.trim() == ''))) {
			this.frm.doc.production_id = line.production_id;
			this.frm.refresh_field('production_id');
		}


		line.dossier_id = '';
		line.dossier_bom_id = '';
		line.production_bom_id = '';

		this.frm.refresh_field('line_detail');

		this.build_html_production_bom_data(cdt, cdn);
		this.build_html_dossier_bom_data(cdt, cdn);
	}

	// ***************************************************************************************************************************************
	// Build Production BOM Buttons (select and clear)
	// ***************************************************************************************************************************************

	build_html_production_bom_data(cdt, cdn)
	{
		let me = this;

		let method = me.path_stock_output + '.ioi_stock_output_get_production_bom_data';

		let html = '';

		let line = locals[cdt][cdn];

		if ((line.production_id) && (line.production_id.trim() != '') && (line.production_bom_id) && (line.production_bom_id.trim() != '')) {

			frappe.call({  	method: method,
							args: {	"production_id": line.production_id, "name": line.production_bom_id},
							async: false,
							callback:function(r)	{

								let content = r.message[0].position_id + '&nbsp;';

								if (r.message[0].component_id != null) {
									content += r.message[0].component_id + '&nbsp;';
								}else if ((r.message[0].description != null) && (r.message[0].description != null))  {

									if (r.message[0].description.length >= 20) {
										content += r.message[0].description.substring(0, 20);
									}else{
										content += r.message[0].description;
									}
									content += '&nbsp;'
								}

								if ((r.message[0].stock_unit_qty != null) && (r.message[0].stock_unit_id != null)) {
									content += '(&nbsp;' + r.message[0].stock_unit_qty.toString() + '&nbsp;' + r.message[0].stock_unit_id + '&nbsp;)';
								}

								let bgcolor = r.message[0].bgcolor;
								let color = r.message[0].color;

								html += '<table width=100%>';
								html += '<tr>';
								html += '<td>' + __("Production BOM") + '</td>';
								html += '</tr>';

								html += '<tr>';
								html += '<td>';
								html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
								html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ content +'</b></font>';
								html += '</div>';
								html += '</td>';
								html += '</tr>';
								html += '</table>';

							}
			});


		}else{
			html += '<table width=100%>';
			html += '<tr>';
			html += '<td>' + __("Production BOM") + '</td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td>&nbsp;</td>';
			html += '</tr>';
			html += '</table>';

		}

		this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_production_bom_data'].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_production_bom_data'].$wrapper.append(html);



	}

	#build_html_production_bom_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (document.getElementById('bt_select_production_bom'))
		{
			document.getElementById('bt_select_production_bom').remove();
		}

		if (document.getElementById('bt_clear_production_bom'))
		{
			document.getElementById('bt_clear_production_bom').remove();
		}

		if (document.getElementById('production_line_cdt'))
		{
			document.getElementById('production_line_cdt').remove();
		}

		if (document.getElementById('production_line_cdn'))
		{
			document.getElementById('production_line_cdn').remove();
		}


		let html = '';
		html += '<div>';
		html +=	'	<button id="bt_select_production_bom" data-label="Select" class="btn btn-default ellipsis" onclick="">' + __("Select") + '</button> ';
		html +=	'	<button id="bt_clear_production_bom"  data-label="Clear" class="btn btn-default ellipsis" onclick="">' + __("Clear") + '</button> ';
		html += '   <input type="hidden" id="production_line_cdt" value="' + cdt + '"> ';
		html += '   <input type="hidden" id="production_line_cdn" value="' + cdn + '"> ';
		html += '</div>'
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

		let fct_select = function () {	me.select_production_bom(); };
		let fct_clear = function () {	me.clear_production_bom(); };

		document.getElementById('bt_select_production_bom').onclick = fct_select;
		document.getElementById('bt_clear_production_bom').onclick = fct_clear;
	}

	select_production_bom()
	{
		let line = locals[document.getElementById('production_line_cdt').value][document.getElementById('production_line_cdn').value];

		if (line.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_location = false;
		}

		if (this.journal_wms_output_production_allowed == 0) {
			frappe.msgprint({title: __("Message"), message: __("Production is not allowed (see warehouse journal)"), indicator: "red"});
			return false;
		}

		if ((!line.production_id) || ((line.production_id) && (line.production_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Select a Production before, please"), indicator: "red"});
			return false;
		}


		let title = __("Select a production BOM");
		let form_width_pixel = '960px';
		let table = "ioi Production BOM";
		let fields = 'position_id, component_id, description';
		let fields_len = '250, 250, 380';
		let fields_desc = 'Position, Item, Description';
		let where = "parenttype = 'ioi Production' and parent = '" + line.production_id.replaceAll("'", "''") + "' ";
		let order_by = 'position_id asc';

		let me = this;

		let fct_callback = function (return_value) {

			let cdt = me.frm.selected_doc.doctype;
			let cdn = me.frm.selected_doc.name;

			let position_id = return_value;

			let method = me.path_stock_output + '.ioi_stock_output_get_production_bom_data';

			frappe.call({  	method: method,
							args: {	"production_id": line.production_id, "position_id": position_id},
							async: false,
							callback:function(r)	{

								let line = locals[cdt][cdn];
								line.production_bom_id = r.message[0].name;
								line.item_id = r.message[0].component_id;
								line.item_description = r.message[0].description;
								line.unit_id = r.message[0].stock_unit_id;

								if ((line.item_id) && (line.item_id.trim() != '')) {
									me.populate_line(me.frm, cdt, cdn, 'ITEM_ID');
								}


								me.frm.refresh_field('line_detail');
								me.frm.dirty();

								me.build_html_production_bom_data(cdt, cdn);

								me.format_detail_form(me.frm, document.getElementById('production_line_cdt').value, document.getElementById('production_line_cdn').value);
							}
			});

		}


		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

	}

	clear_production_bom()
	{
		let item = locals[document.getElementById('production_line_cdt').value][document.getElementById('production_line_cdn').value];

		if (item.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_location = false;
		}

		if (this.journal_wms_output_production_allowed == 0) {
			frappe.msgprint({title: __("Message"), message: __("Production is not allowed (see warehouse journal)"), indicator: "red"});
			return false;
		}

		item.production_bom_id = '';
		this.frm.refresh_field('line_detail');
		this.frm.dirty();
		this.format_detail_form(this.frm, document.getElementById('production_line_cdt').value, document.getElementById('production_line_cdn').value);
	}


	// ***************************************************************************************************************************************
	// Build Dossier BOM Buttons (select and clear)
	// ***************************************************************************************************************************************

	build_html_dossier_bom_data(cdt, cdn)
	{
		let me = this;

		let method = me.path_stock_output + '.ioi_stock_output_get_dossier_bom_data';

		let html = '';

		let line = locals[cdt][cdn];

		if (this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form) {

			if ((line.dossier_id) && (line.dossier_id.trim() != '') && (line.dossier_bom_id) && (line.dossier_bom_id.trim() != '')) {

				frappe.call({  	method: method,
								args: {	"dossier_id": line.dossier_id, "name": line.dossier_bom_id},
								async: false,
								callback:function(r)	{

									let content = r.message[0].position_id + '&nbsp;';

									if ((r.message[0].component_id) && (r.message[0].component_id.trim() != '')) {
										content += r.message[0].component_id;

									}else if ((r.message[0].description) && (r.message[0].description.trim() != '')) {

										if (r.message[0].description.length >= 20) {
											content += r.message[0].description.substring(0, 20);
										}else{
											content += r.message[0].description;
										}
									}

									content += '&nbsp;(&nbsp;' + r.message[0].stock_unit_qty.toString() + '&nbsp;' + r.message[0].stock_unit_id + '&nbsp;)'

									let bgcolor = r.message[0].bgcolor;
									let color = r.message[0].color;

									html += '<table width=100%>';
									html += '<tr>';
									html += '<td>' + __("Dossier BOM") + '</td>';
									html += '</tr>';

									html += '<tr>';
									html += '<td>';
									html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
									html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ content +'</b></font>';
									html += '</div>';
									html += '</td>';
									html += '</tr>';
									html += '</table>';

								}
				});


			}else{
				html += '<table width=100%>';
				html += '<tr>';
				html += '<td>' + __("Dossier BOM") + '</td>';
				html += '</tr>';

				html += '<tr>';
				html += '<td>&nbsp;</td>';
				html += '</tr>';
				html += '</table>';

			}

			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_dossier_bom_data'].$wrapper.empty();
			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_dossier_bom_data'].$wrapper.append(html);

		}

	}



	#build_html_dossier_bom_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form) {

			if (document.getElementById('bt_select_dossier_bom'))
			{
				document.getElementById('bt_select_dossier_bom').remove();
			}

			if (document.getElementById('bt_clear_dossier_bom'))
			{
				document.getElementById('bt_clear_dossier_bom').remove();
			}

			if (document.getElementById('dossier_line_cdt'))
			{
				document.getElementById('dossier_line_cdt').remove();
			}

			if (document.getElementById('dossier_line_cdn'))
			{
				document.getElementById('dossier_line_cdn').remove();
			}


			let html = '';

			html += '<table width=100%>';
			html += '<tr height=5px>';
			html += '<td></td>';
			html += '</tr>';
			html += '</table>';

			html += '<div>';
			html +=	'	<button id="bt_select_dossier_bom" data-label="Select" class="btn btn-default ellipsis" onclick="">' + __("Select") + '</button> ';
			html +=	'	<button id="bt_clear_dossier_bom"  data-label="Clear" class="btn btn-default ellipsis" onclick="">' + __("Clear") + '</button> ';
			html += '   <input type="hidden" id="dossier_line_cdt" value="' + cdt + '"> ';
			html += '   <input type="hidden" id="dossier_line_cdn" value="' + cdn + '"> ';
			html += '</div>'

			html += '<table width=100%>';
			html += '<tr height=5px>';
			html += '<td></td>';
			html += '</tr>';
			html += '</table>';

			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

			let fct_select = function () {	me.select_dossier_bom(); };
			let fct_clear = function () {	me.clear_dossier_bom(); };

			document.getElementById('bt_select_dossier_bom').onclick = fct_select;
			document.getElementById('bt_clear_dossier_bom').onclick = fct_clear;
		}
	}

	select_dossier_bom()
	{
		let line = locals[document.getElementById('dossier_line_cdt').value][document.getElementById('dossier_line_cdn').value];

		if (line.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_location = false;
		}

		if (this.journal_wms_output_dossier_allowed == 0) {
			frappe.msgprint({title: __("Message"), message: __("Dossier is not allowed (see warehouse journal)"), indicator: "red"});
			return false;
		}

		if ((!line.dossier_id) || ((line.dossier_id) && (line.dossier_id.trim() == ''))) {
			frappe.msgprint({title: __("Message"), message: __("Select a Dossier before, please"), indicator: "red"});
			return false;
		}


		let title = __("Select a dossier BOM");
		let form_width_pixel = '960px';
		let table = "ioi Dossier BOM";
		let fields = 'position_id, component_id, description';
		let fields_len = '250, 250, 380';
		let fields_desc = 'Position, Item, Description';
		let where = "parenttype = 'ioi Dossier' and parent = '" + line.dossier_id.replaceAll("'", "''") + "' ";
		let order_by = 'position_id asc';

		let me = this;

		let fct_callback = function (return_value) {

			let cdt = me.frm.selected_doc.doctype;
			let cdn = me.frm.selected_doc.name;

			let l = locals[cdt][cdn];

			let position_id = return_value;

			let method = me.path_stock_output + '.ioi_stock_output_get_dossier_bom_data';

			frappe.call({  	method: method,
							args: {	"dossier_id": l.dossier_id, "position_id": position_id},
							async: false,
							callback:function(r)	{

								let line = locals[cdt][cdn];
								line.dossier_bom_id = r.message[0].name;
								line.item_id = r.message[0].component_id;
								line.item_description = r.message[0].description;
								line.unit_id = r.message[0].stock_unit_id;

								if ((line.item_id) && (line.item_id.trim() != '')) {
									me.populate_line(me.frm, cdt, cdn, 'ITEM_ID');
								}


								me.frm.refresh_field('line_detail');
								me.frm.dirty();

								me.build_html_dossier_bom_data(cdt, cdn);

								me.format_detail_form(me.frm, document.getElementById('dossier_line_cdt').value, document.getElementById('dossier_line_cdn').value);
							}
			});

		}


		silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)


	}

	clear_dossier_bom()
	{
		let item = locals[document.getElementById('dossier_line_cdt').value][document.getElementById('dossier_line_cdn').value];

		if (item.ioistatus != 0) {
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			return false;
		}

		if (this.journal_wms_output_dossier_allowed == 0) {
			frappe.msgprint({title: __("Message"), message: __("Dossier is not allowed (see warehouse journal)"), indicator: "red"});
			return false;
		}


		item.dossier_bom_id = '';
		this.frm.refresh_field('line_detail');
		this.frm.dirty();
		this.format_detail_form(this.frm, document.getElementById('dossier_line_cdt').value, document.getElementById('dossier_line_cdn').value);

	}


	// ***************************************************************************************************************************************
	// Build Location Buttons (select and clear)
	// **************************************************************************************************************************************

	#build_html_location_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (document.getElementById('bt_select_location'))
		{
			document.getElementById('bt_select_location').remove();
		}

		if (document.getElementById('bt_clear_location'))
		{
			document.getElementById('bt_clear_location').remove();
		}

		if (document.getElementById('line_cdt'))
		{
			document.getElementById('line_cdt').remove();
		}

		if (document.getElementById('line_cdn'))
		{
			document.getElementById('line_cdn').remove();
		}


		let html = '';
		html += '<div>';
		html +=	'	<button id="bt_select_location" data-label="Select a location" class="btn btn-default ellipsis" onclick="">' + __("Select a location") + '</button> ';
		html +=	'	<button id="bt_clear_location"  data-label="Clear location" class="btn btn-default ellipsis" onclick="">' + __("Clear location") + '</button> ';
		html += '   <input type="hidden" id="line_cdt" value="' + cdt + '"> ';
		html += '   <input type="hidden" id="line_cdn" value="' + cdn + '"> ';
		html += '</div>'
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

		let fct_select = function () {	me.select_location(true); };
		let fct_clear = function () {	me.clear_location(true); };

		document.getElementById('bt_select_location').onclick = fct_select;
		document.getElementById('bt_clear_location').onclick = fct_clear;
	}

	// ***************************************************************************************************************************************
	// Clear location
	// **************************************************************************************************************************************
	clear_location(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('line_cdt').value][document.getElementById('line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}

		let can_clear_location = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_location = false;
		}

		if (can_clear_location)
		{
			item.warehouse_location_id = '';
			this.frm.refresh_field('line_detail');
			this.frm.dirty();

			if (form) {
				this.format_detail_form(this.frm, document.getElementById('line_cdt').value, document.getElementById('line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}
	}

	// ***************************************************************************************************************************************
	// Select a location
	// **************************************************************************************************************************************
	select_location(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('line_cdt').value][document.getElementById('line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}


		let can_show_location_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			return false;
		}

		if ((can_show_location_frm) && ((!item.warehouse_id) || ((item.warehouse_id) && (item.warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			return false;
		}

		if (can_show_location_frm)
		{
			let me = this;
			let ccdt = null;
			let ccdn = null;

			if (form) {
				ccdt = document.getElementById('line_cdt').value;
				ccdn = document.getElementById('line_cdn').value;
			}else{
				ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
				ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
			}

			let tp = 'OUT';
			let warehouse_id = item.warehouse_id;
			let warehouse_location_id = '';
			let dossier_id = item.dossier_id;
			let dossier_focus = 0;
			let item_id = item.item_id;
			let batch_sn_id = null;
			let stored_qty_mode = 0;


			if ((item.delivered_qty) && (item.delivered_qty < 0)) {
				tp = 'IN';
			}

			warehouse_location_id = item.warehouse_location_id;
			batch_sn_id = item.batch_sn_id;
			stored_qty_mode = item.stored_qty_mode;


			let callback = function(warehouse_location_id, batch_sn_id, batch_sn_code_ref, batch_sn_origin, batch_sn_cost, dossier_id) {

				item.warehouse_location_id = warehouse_location_id;


				if ((dossier_id != null) && (dossier_id != 'null') && (dossier_id != '')) {
					item.dossier_id = dossier_id;
				}

				if ((batch_sn_id != null) && (batch_sn_id != 'null')) {
					item.batch_sn_id = batch_sn_id;
				}else{
					item.batch_sn_id = '';
				}

				if ((!batch_sn_id) || ((batch_sn_id) && (batch_sn_id.trim() == ''))) {
					item.batch_sn_code_ref = '';
					item.batch_sn_origin = '';
				}else{
					if ((batch_sn_code_ref != null) && (batch_sn_code_ref != 'null')) {
						item.batch_sn_code_ref = batch_sn_code_ref;
					}else{
						item.batch_sn_code_ref = '';
					}

					if ((batch_sn_origin != null) &&(batch_sn_origin != 'null')) {
						item.batch_sn_origin = batch_sn_origin;
					}else{
						item.batch_sn_origin = '';
					}

					if ((batch_sn_cost != null) && (batch_sn_cost != 'null')) {
						item.item_cost = batch_sn_cost;
					}else{
						item.item_cost = 0;
					}
				}

				me.frm.refresh_field('line_detail');
				me.frm.dirty();
				me.format_detail_form(me.frm, ccdt, ccdn);
			}

			silicon_ioi.ioiCommon.select_location(tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, callback);


		}else
		{
			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}
	}


build_html_sales_invoice_data(cdt, cdn)
	{
		let me = this;

		let method = me.path_purchases_document + '.ioi_purchases_document_get_sales_invoice_data';

		let html = '';

		let line = locals[cdt][cdn];

		if (this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form) {

			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_sales_invoice'].$wrapper.empty();

			if ((line.invoiced) && (line.invoiced == 1)) {

				if ((line.sales_invoice_id) && (line.sales_invoice_id.trim() != '') && (line.sales_invoice_detail_id) && (line.sales_invoice_detail_id.trim() != '')) {

					frappe.call({  	method: method,
									args: {	"sales_invoice_id": line.sales_invoice_id, "sales_invoice_detail": line.sales_invoice_detail_id},
									async: false,
									callback:function(r)	{

										if (r.message.length > 0) {

											let content = r.message[0].parent + ' | ' + r.message[0].idx;

											let bgcolor = r.message[0].bgcolor;
											let color = r.message[0].color;



											html += '<table width=100%>';
											html += '<tr>';
											html += '<td>' + __("Sales Invoice") + '</td>';
											html += '</tr>';

											html += '<tr>';
											html += '<td>';
											html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
											html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ content +'</b></font>';
											html += '</div>';
											html += '</td>';
											html += '</tr>';
											html += '</table>';

										}else{

											html += '<table width=100%>';
											html += '<tr>';
											html += '<td>' + __("Sales Invoice") + '</td>';
											html += '</tr>';

											html += '<tr>';
											html += '<td>&nbsp;</td>';
											html += '</tr>';
											html += '</table>';


										}

									}
					});


				}else{
					html += '<table width=100%>';
					html += '<tr>';
					html += '<td>' + __("Sales invoice") + '</td>';
					html += '</tr>';

					html += '<tr>';
					html += '<td>&nbsp;</td>';
					html += '</tr>';
					html += '</table>';

				}

				html += '<table width=100%>';
				html += '<tr height=5px>';
				html += '<td></td>';
				html += '</tr>';
				html += '</table>';


				this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_sales_invoice'].$wrapper.append(html);
			}
		}

	}

	// ***************************************************************************************************************************************
	// Build Batch Buttons (select, clear and FIFO proposal)
	// **************************************************************************************************************************************
	#build_html_batch_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (document.getElementById('bt_select_batch'))
		{
			document.getElementById('bt_select_batch').remove();
		}

		if (document.getElementById('bt_clear_batch'))
		{
			document.getElementById('bt_clear_batch').remove();
		}

		if (document.getElementById('bt_batch_fifo'))
		{
			document.getElementById('bt_batch_fifo').remove();
		}


		if (document.getElementById('batch_line_cdt'))
		{
			document.getElementById('batch_line_cdt').remove();
		}

		if (document.getElementById('batch_line_cdn'))
		{
			document.getElementById('batch_line_cdn').remove();
		}


		let html = '';
		html += '<div>';
		html +=	'	<button id="bt_select_batch" data-label="Select a batch/SN" class="btn btn-default ellipsis" onclick="">' + __("Select a batch / SN") + '</button> ';
		html +=	'	<button id="bt_clear_batch"  data-label="Clear batch/SN" class="btn btn-default ellipsis" onclick="">' + __("Clear batch / SN") + '</button> ';
//		html +=	'	<button id="bt_batch_fifo"  data-label="FIFO proposal" class="btn btn-default ellipsis" onclick="">' + __("FIFO proposal") + '</button> ';
		html += '   <input type="hidden" id="batch_line_cdt" value="' + cdt + '"> ';
		html += '   <input type="hidden" id="batch_line_cdn" value="' + cdn + '"> ';
		html += '</div>'
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

		let fct_select = function () {	me.select_batch(true); };
		let fct_clear = function () {	me.clear_batch(true); };
		let fct_fifo = function () {	me.batch_fifo_proposal(); };

		document.getElementById('bt_select_batch').onclick = fct_select;
		document.getElementById('bt_clear_batch').onclick = fct_clear;
//		document.getElementById('bt_batch_fifo').onclick = fct_fifo;
	}

	// ***************************************************************************************************************************************
	// Clear batch
	// **************************************************************************************************************************************
	clear_batch(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}

		let can_clear_batch = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_batch = false;
		}

		if (can_clear_batch)
		{	cur_frm.dirty();
			item.batch_sn_id = '';
			item.batch_sn_code_ref = '';
			item.batch_sn_origin = '';

			this.frm.refresh_field('line_detail');

			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}

	}

	// ***************************************************************************************************************************************
	// Select a batch
	// **************************************************************************************************************************************
	select_batch(form  = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}



		let can_show_batch_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_show_batch_frm = false;
		}

		if ((can_show_batch_frm) && ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("item has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_show_batch_frm = false;
		}


		if ((can_show_batch_frm) && ((!item.warehouse_id) || ((item.warehouse_id) && (item.warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_show_batch_frm = false;
		}

		if (can_show_batch_frm)
		{
			let me = this;
			let ccdt = null;
			let ccdn = null;

			if (form) {
				ccdt = document.getElementById('batch_line_cdt').value;
				ccdn = document.getElementById('batch_line_cdn').value;
			}else{
				ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
				ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
			}


			var z = new frappe.ui.Dialog({
				'title': __("Select a batch / SN in " + item.warehouse_id),
				'fields': [
					{'fieldname': 'html_select_batch', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					let go = true;

					if (document.getElementById('batch_nb_record').value == 0)
					{
						go = false;
					}

					if ((go) && (document.getElementById('batch_nb_record').value != 0))
					{	go = false
						for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
						{
							if (document.getElementById('batch_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_batch_id').value = document.getElementById('batch_name_id_' + i.toString()).value;
								document.getElementById('selected_batch_location_id').value = document.getElementById('batch_location_id_' + i.toString()).value;

								go = true;
								break;
							}
						}
					}

					if (go)
					{
						z.hide();
						cur_frm.dirty();
						item.batch_sn_id = document.getElementById('selected_batch_id').value;
						item.warehouse_location_id = document.getElementById('selected_batch_location_id').value;
						item.batch_sn_code_ref = '';
						item.batch_sn_origin = '';



						if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id)
						{
							for (var i = 0; i < silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id.length; i++)
							{
								if (item.batch_sn_id == silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0])
								{
									if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3] != null)
									{
										item.batch_sn_code_ref = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3];
									}

									if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4] != null)
									{
										item.batch_sn_origin = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4];
									}

								}
							}
						}

						me.frm.refresh_field('line_detail');
						me.format_detail_form(me.frm, ccdt, ccdn);
					}
				},
				secondary_action: function(){
					z.hide();
				}

			});

			if (document.getElementById('batch_nb_record'))
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batch_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

				}

				document.getElementById('batch_nb_record').remove();
			}

			if (document.getElementById("table_batch"))
			{
				document.getElementById("table_batch").remove();
			}

			if (document.getElementById('batch_filter'))
			{
				document.getElementById('batch_filter').remove();
			}

			if (document.getElementById('selected_batch_id'))
			{
				document.getElementById('selected_batch_id').remove();
			}



			if (document.getElementById('selected_batch_location_id'))
			{
				document.getElementById('selected_batch_location_id').remove();
			}


			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
			html += '	<div style="position: relative; left: 0px; width:600px;">';
			html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 598px; height: 25px;"> ';
			html += '			<input type="text" id="batch_filter" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1410px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Batch") + '</b></td>';
			html += '<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Qty") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Code ref") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Origin") + '</b></td>';
			html += '<td width=450px style="vertical-align: middle;">&nbsp;<b>' + __("QC ref") + '</b></td>';
			html += '</tr>';
			html += '</table>';


			let item_mode = 0;
			let item_stored_qty_mode = 0;

			frappe.db.get_list('ioi Item', {fields:["mode"], filters:{"name": item.item_id}}).then((r) => {

				for (var i = 0; i < r.length; i++)
				{
					item_mode = r[i].mode;
				}

				if (item.stored_qty_mode)
				{
					item_stored_qty_mode = item.stored_qty_mode;
				}



				let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
				let method_bis = pathbatchlocation + '.ioi_batch_sn_get_enabled_batch_for_item_warehouse';


				let sens = '+';

				if (item.delivered_qty < 0)
				{
					sens = '-';
				}


				let location_id = '';

				if ((item.warehouse_location_id) && (item.warehouse_location_id.trim() != ''))
				{
					location_id = item.warehouse_location_id;
				}


				frappe.call({  	method: method_bis,
								args: {	"warehouse_id": item.warehouse_id, "item_id": item.item_id, "stored_qty_mode": item_stored_qty_mode, "item_mode": item_mode, "sens" : sens, "location_id": location_id},
								async: false,
								callback:function(r)	{

															silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id = [];

															if (r.message.length > 0)
															{
																html += '<input type="hidden" id="batch_nb_record" value="' + r.message.length + '">';
																html += '<table id="table_batch" border=1 style="border: 1px solid #E8EAEB" width=1410px>';

																for (var i = 0; i < r.message.length; i++)
																{
																	silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i] = [r.message[i].name, r.message[i].qt, r.message[i].location_id, r.message[i].code_ref, r.message[i].origin, r.message[i].qcref];


																	html += '<tr id="batch_row_' + i.toString() + '" style="height:30px">';
																	html += '<td width=30px align="center" style="vertical-align: middle;">';
																	html += '<input type="checkbox" id="batch_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																	html += '       onclick=" ';
																	html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
																	html += '					{';
																	html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
																	html += '						{ ';
																	html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
																	html += '						} ';
																	html += '					} ';
																	html += '" ';

																	if (item.batch_sn_id == silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0])
																	{
																		html += ' checked ';
																	}
																	html += '>';
																	html += '<input type="hidden" id="batch_name_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0] + '">';
																	html += '<input type="hidden" id="batch_qt_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][1] + '">';
																	html += '<input type="hidden" id="batch_location_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][2] + '">';
																	html += '</td>';

																	let v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0];

																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';

																	html += '<td width=120px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][1] + '&nbsp;</td>'

																	if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3] != null)
																	{
																		v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}

																	if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4] != null)
																	{
																		v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4];
																		html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																	}else
																	{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																	}


																	if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][5] != null)
																	{
																		v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][5];
																		html += '<td width=450px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																	}else
																	{ 	html += '<td width=450px style="vertical-align: middle;">&nbsp;</td>';
																	}
																	html += '</tr>';

																}
																html += '</table>';
															}else
															{	html += '<input type="hidden" id="batch_nb_record" value="0">';
															}
														}
				});

				html += '<input type="hidden" id="selected_batch_id" value="">';
				html += '<input type="hidden" id="selected_batch_location_id" value="">';


				html += '</div>';


				z.fields_dict.html_select_batch.$wrapper.html(html);
				z.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "50%");
				z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

				z.show();

				me.#sleep(250).then(() => {
					document.getElementById('batch_filter').onkeyup = this.#batch_filter_keyup;

				});
			});

		}else
		{
			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}


		}

	}

	#batch_filter_keyup(event)
	{
		silicon_ioi.doctype.ioiStockOutputDocumentDocType.refresh_batches()
	}

	static refresh_batches()
	{
		if (document.getElementById('batch_nb_record'))
		{
			if (document.getElementById('batch_nb_record').value > 0)
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batc((can_fifo) &&h_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

				}
			}
		}

		let table = document.getElementById("table_batch");

		let cpt = 0;
		let create_row = false;


		for (var i = 0; i < silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id.length; i++)
		{
			create_row = false;
			if (document.getElementById('batch_filter').value.toString().trim() == '')
			{
				create_row = true;
			}else
			{
				let search = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				if ((silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0].toString().toUpperCase().search(search) != -1) ||
					((silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][5] != null) && (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][5].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][1] != null) && (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][1].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3] != null) && (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4] != null) && (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4].toString().toUpperCase().search(search) != -1)))
				{	create_row = true;
				}
			}

			if (create_row)
			{
				let row = table.insertRow(cpt);
				row.id = 'batch_row_' + cpt.toString();
				row.style = 'height:30px';

				let cell1 = row.insertCell(0);
				cell1.width = '30px;'
				cell1.align = 'center';
				cell1.style = 'vertical-align: middle';

				let html = '';
				html += '<input type="checkbox" id="batch_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
				html += '       onclick=" ';
				html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
				html += '					{';
				html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
				html += '						{ ';
				html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
				html += '						} ';
				html += '					} ';
				html += '">';
				html += '<input type="hidden" id="batch_name_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0] + '">';
				html += '<input type="hidden" id="batch_qt_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][1] + '">';
				html += '<input type="hidden" id="batch_location_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][2] + '">';

				cell1.innerHTML = html;

				let cell2 = row.insertCell(1);
				cell2.width = '270px;'
				cell2.style = 'vertical-align: middle';

				let v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][0];

				let search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				let formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell2.innerHTML = html;

				let cell3 = row.insertCell(2);
				cell3.width = '270px;'
				cell3.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3] != null)
				{
					v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][3];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;



				cell3.innerHTML = html;

				let cell4 = row.insertCell(3);
				cell4.width = '270px;'
				cell4.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4] != null)
				{
					v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][4];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;
				cell4.innerHTML = html;


				let cell5 = row.insertCell(4);
				cell5.width = '270px;'
				cell5.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][5] != null)
				{
					v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][5];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;
				cell5.innerHTML = html;


				let cell6 = row.insertCell(5);
				cell6.width = '300px;'
				cell6.style = 'vertical-align: middle';

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();
				v = silicon_ioi.doctype.ioiStockOutputDocumentDocType.batch_id[i][1].toString();
				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;

				cell6.innerHTML = html;

				cpt++;


			}
		}
		document.getElementById('batch_nb_record').value = cpt.toString();

	}


	batch_fifo_proposal()
	{
		let me = this;


		let item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];

		let can_fifo = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_fifo = false;
		}

		if ((can_fifo) && ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("item has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_fifo = false;
		}


		if ((can_fifo) && ((!item.warehouse_id) || ((item.warehouse_id) && (item.warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_fifo = false;
		}

		if ((can_fifo) && ((item.batch_sn_id) && (item.batch_sn_id.trim() != '')))
		{
			can_fifo = false;
		}

		if ((can_fifo) && ((item.delivered_qty) && (item.delivered_qty == 0)))
		{
			can_fifo = false;
		}

		if (can_fifo)
		{
			let item_mode = 0;
			let item_stored_qty_mode = 0;

			frappe.db.get_list('ioi Item', {fields:["mode"], filters:{"name": item.item_id}}).then((r) => {

				for (var i = 0; i < r.length; i++)
				{
					item_mode = r[i].mode;
				}

				if (item.stored_qty_mode)
				{
					item_stored_qty_mode = item.stored_qty_mode;
				}


				let pathbatchlocation = 'silicon_ioi.ioi_wms.doctype.ioi_batch_sn.ioi_batch_sn';
				let method = pathbatchlocation + '.ioi_batch_sn_get_enabled_batch_for_item_warehouse_fifo';

				let sens = '+';

				if (item.delivered_qty < 0)
				{
					sens = '-';
				}


				frappe.call({  	method: method,
								args: {	"warehouse_id": item.warehouse_id, "item_id": item.item_id, "stored_qty_mode": item_stored_qty_mode, "item_mode": item_mode, "sens" : sens},
								async: false,
								callback:function(r)	{
															if (r.message.length > 0)
															{
																if (item_mode == 3)
																{
																	// Batch
																	let current_qty = item.delivered_qty;

																	if (!cur_frm.is_dirty())
																	{
																		cur_frm.is_dirty();
																	}

																	for (var i = 0; i < r.message.length; i++)
																	{
																		if (current_qty <= r.message[i].qt)
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;

																			item.delivered_qty = current_qty;

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}
																			break;
																		}else
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;

																			item.delivered_qty = r.message[i].qt;

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}

																			// duplicate line
																			me.frm.refresh_field('line_detail');
																			let row = me.frm.add_child('line_detail');

																			for (var it in item)
																			{
																				if ((it.toUpperCase() != 'NAME')     && (it.toUpperCase() != 'OWNER')       && (it.toUpperCase() != 'CREATION')  &&
																					(it.toUpperCase() != 'MODIFIED') && (it.toUpperCase() != 'MODIFIED_BY') && (it.toUpperCase() != 'DOCSTATUS') &&
																					(it.toUpperCase() != 'IDX'))
																				{
																					row[it] = item[it];
																				}

																			}

																			item = row;

																			current_qty -= r.message[i].qt;
																		}
																	}
																	if (current_qty != 0)
																	{

																		item.delivered_qty = current_qty;
																		item.batch_sn_id = '';
																		item.batch_sn_code_ref = '';
																		item.batch_sn_origin = '';

																	}


																	cur_frm.save();
																	cur_frm.refresh();

																	cur_frm.refresh_field('line_detail');
																	me.format_detail_form(me.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);

																}else
																{	// SN
																	let current_qty = item.delivered_qty;

																	if (!cur_frm.is_dirty())
																	{
																		cur_frm.is_dirty();
																	}

																	for (var i = 0; i < r.message.length; i++)
																	{
																		if (current_qty <= r.message[i].qt)
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;


																			item.delivered_qty = current_qty;

																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}

																			current_qty -= r.message[i].qt;

																			break;
																		}else
																		{
																			item.batch_sn_id = r.message[i].name;
																			item.batch_sn_code_ref = r.message[i].code_ref;
																			item.batch_sn_origin = r.message[i].origin;


																			item.delivered_qty = r.message[i].qt;


																			if ((!item.warehouse_location_id) || ((item.warehouse_location_id) && (item.warehouse_location_id.trim() == '')))
																			{
																				if ((r.message[i].location_id != null) && (r.message[i].location_id.trim() != ''))
																				{
																					item.warehouse_location_id = r.message[i].location_id;
																				}
																			}

																			// duplicate line
																			me.frm.refresh_field('line_detail');
																			let row = me.frm.add_child('line_detail');

																			for (var it in item)
																			{
																				if ((it.toUpperCase() != 'NAME')     && (it.toUpperCase() != 'OWNER')       && (it.toUpperCase() != 'CREATION')  &&
																					(it.toUpperCase() != 'MODIFIED') && (it.toUpperCase() != 'MODIFIED_BY') && (it.toUpperCase() != 'DOCSTATUS') &&
																					(it.toUpperCase() != 'IDX'))
																				{
																					row[it] = item[it];
																				}

																			}

																			item = row;

																			current_qty -= r.message[i].qt;
																		}
																	}

																	if (current_qty != 0)
																	{

																		item.delivered_qty = current_qty;

																		item.batch_sn_id = '';
																		item.batch_sn_code_ref = '';
																		item.batch_sn_origin = '';

																	}



																	cur_frm.save();
																	cur_frm.refresh();

																	cur_frm.refresh_field('line_detail');
																	me.format_detail_form(me.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);


																}
															}
								}
				});

			});

		}

	}

	// ***************************************************************************************************************************************
	// Action after change status
	// **************************************************************************************************************************************
	action_after_change_status()
	{
/*		if (this.frm.doctype.toUpperCase() == 'IOI SALES DELIVERY')
		{
			if (this.frm.doc.ioistatus == 1)
			{
				this.module_execute_action('INTERSITE_ASK_TO_CREATE_PR');
			}

		}
*/
	}

	// ***************************************************************************************************************************************
	// Module execute action
	// **************************************************************************************************************************************
	module_execute_action(action)
	{
		let me = this;

		let method = this.path_user_change_status_action + '.ioi_user_change_status_action_exists';

		frappe.call({  	method: method,
						args: {"doctype" : this.frm.doctype, "name" : this.frm.doc.name, "to_status" : this.frm.doc.ioistatus, "action" : action},
						async: false,
						callback:function(r)	{
													if (r.message == 1)
													{
													}
						}
		});

	}


	static assign_event_on_grid_first_column()
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {

			if (cur_frm.doc.line_detail)
			{
				for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					if (cur_frm.fields_dict['line_detail'].grid)
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0])
							{
								let idx = i;

								let fct_enter = function() {
									silicon_ioi.doctype.ioiStockOutputDocumentDocType.display_custom_info(idx);
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0].onmouseenter = fct_enter;
							}
						}
					}

				}
			}


			silicon_ioi.doctype.ioiStockOutputDocumentDocType.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiStockOutputDocumentDocType.assign_event_on_grid_first_column();
			});
		}
	}

	static display_custom_info(idx)
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI STOCK OUTPUT') {

			if (cur_frm.fields_dict['line_detail'].grid)
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
				{
					let name = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['name'];

					let method = 'silicon_ioi.common.common.ioi_common_get_tooltip';

					frappe.call({  	method: method,
									args: {"tooltip_id": 1, "doctype": cur_frm.doctype, "parent": cur_frm.doc.name, "name": name},
									async: false,
									callback:function(r)	{
																let html = r.message.content;
																let time_display = r.message.time_display;

																if (html.trim() != '') {
																	silicon_ioi.ioiCommon.show_grid_info_popup(html, time_display);
																}
									}
					});
				}
			}
		}
	}



	static assign_event_on_grid_qty()
	{
		let qty_field = 'delivered_qty';

		if (qty_field == '') {
			return;
		}

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiStockOutputDocumentDocType.display_stock_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiStockOutputDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiStockOutputDocumentDocType.assign_event_on_grid_qty();
		});


	}

	static display_stock_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'])
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'].trim() != '')
					{
						let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id']

						let warehouse_id = ''

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['warehouse_id'])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['warehouse_id'].trim() != '')
							{
								warehouse_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['warehouse_id'];
							}else
							{
								if (cur_frm.doc.warehouse_id)
								{
									if (cur_frm.doc.warehouse_id.trim() != '')
									{
										warehouse_id = cur_frm.doc.warehouse_id;
									}
								}
							}

						}else
						{
							if (cur_frm.doc.warehouse_id)
							{
								if (cur_frm.doc.warehouse_id.trim() != '')
								{
									warehouse_id = cur_frm.doc.warehouse_id;
								}
							}

						}


						let me = this;

						let method = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock.ioi_warehouse_stock.ioi_warehouse_stock_get_site_whs_stock_for_an_item';


						frappe.call({  	method: method,
										args: {"item_id": item_id, "site_id": cur_frm.doc.site_id, "warehouse_id": warehouse_id},
										async: false,
										callback:function(r)	{
											let html = '';



											if (r.message.length > 0) {

												html += '<table width=560px>';

												html += '<tr height=20px>';
												html += '<td width=60px><u><b>' + __('Site') + '</b></u></td>';
												html += '<td width=120px><u><b>' + __('Site qty') + '</b></u></td>';
												html += '<td width=80px><u><b>' + __('Unit') + '</b></u></td>';
												html += '<td width=100px><u><b>' + __('Whs') + '</b></u></td>';
												html += '<td width=120px><u><b>' + __('Whs qty') + '</b></u></td>';
												html += '<td width=80px><u><b>' + __('Unit') + '</b></u></td>';
												html += '</tr>';

												let is_obsolete = 0;


												for (var i = 0; i < r.message.length; i++) {

													for(var j = 0; j < r.message[i].length; j++) {

														if (r.message[i][j].is_obsolete == 1) {
															is_obsolete = 1;
														}



														html += '<tr height=20px>';

														html += '<td width=60px>';

														if (r.message[i][j].is_current == '1') {
															html += '<b>';
														}

														html += r.message[i][j].site_id;

														if (r.message[i][j].is_current == '1') {
															html += '</b>';
														}



														html += '</td>';
														html += '<td width=130px>';

														if (r.message[i][j].is_current == '1') {
															html += '<b>';
														}

														html += r.message[i][j].site_qty;

														if (r.message[i][j].is_current == '1') {
															html += '</b>';
														}

														html += '</td>';

														html += '<td width=80px>';

														if (r.message[i][j].is_current == '1') {
															html += '<b>';
														}

														html += r.message[i][j].site_unit;

														if (r.message[i][j].is_current == '1') {
															html += '</b>';
														}

														html += '</td>';

														html += '<td width=100px>';

														if (r.message[i][j].is_current == '1') {
															html += '<b>';
														}

														html +=r.message[i][j].whs_id;

														if (r.message[i][j].is_current == '1') {
															html += '</b>';
														}

														html += '</td>';

														html += '<td width=130px>';

														if (r.message[i][j].is_current == '1') {
															html += '<b>';
														}

														html += r.message[i][j].whs_qty;

														if (r.message[i][j].is_current == '1') {
															html += '</b>';
														}

														html += '</td>';

														html += '<td width=80px>';

														if (r.message[i][j].is_current == '1') {
															html += '<b>';
														}

														html += r.message[i][j].whs_unit;

														if (r.message[i][j].is_current == '1') {
															html += '</b>';
														}

														html += '</td>';

														html += '</tr>';
													}
												}
												html += '</table>';

												if (is_obsolete == 1) {
													let s = '';

													s += '<div style="position: relative; top: 0px; left: 0px; width: 100%">';
													s += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This item is obsolete") + '</font></label>';
													s += '</div><br>';

													html = s + html;
												}

											}

											silicon_ioi.ioiCommon.show_grid_info_popup(html)
										}
						});

					}
				}
			}
		}


	}

	static assign_event_on_grid_description()
	{
		let description_field = 'item_description';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[description_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiStockOutputDocumentDocType.display_description_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[description_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiStockOutputDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiStockOutputDocumentDocType.assign_event_on_grid_description();
		});
	}

	static display_description_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
				let manufacturer_ref = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];

				let what = ''
				let identification = ''

				if ((item_id) && (item_id.trim() != '')) {
					what = 'ITEM_ID';
					identification = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
				}else if ((manufacturer_ref) && (manufacturer_ref.trim() != '')) {
					what = 'MANUFACTURER_REF';
					identification = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];
				}

				if (what != '') {

					let method = 'silicon_ioi.common.sales_document.ioi_sales_document_tooltip_item_description';

					let data = {};

					frappe.call({  	method: method,
									args: {"what": what, "identification": identification},
									async: false,
									callback:function(r)	{
										data = r.message
									}
								});

					let html = '';

					if (data.customized == 0) {

						if (data.content.trim() != '') {
							html = '<table width=700px>';
							html += '<tr height=20px>';
							if (what == 'ITEM_ID')  {
								html += '<td width=250px><b><u>' + __("Item") + '</u></b></td>';
							}else{
								html += '<td width=250px><b><u>' + __("Manufacturer ref") + '</u></b></td>';
							}
							html += '<td width=450px><b><u>' + __("Description") + '</u></b></td>';
							html += '</tr>';
							html += '<tr height=20px>';
							html += '<td width=250px>' + identification + '</td>';
							html += '<td width=450px%>' + data.content + '</td>';
							html += '</tr>';
							html += '</table>';
						}
					}else{
						if (data.content.trim() != '') {
							html = data.content;
						}
					}

					if (html.trim() != '') {
						silicon_ioi.ioiCommon.show_grid_info_popup(html);
					}

				}

			}
		}
	}

	build_production_action_detail()
	{
		this.frm.fields_dict['html_detail_action_production_dossier'].$wrapper.empty();

		if (this.frm.doc.ioistatus == 0) {

			if (document.getElementById('stock_output_action_production_button_from_bom')) {
				document.getElementById('stock_output_action_production_button_from_bom').remove();
			}

			if (document.getElementById('stock_output_action_production_button_qty_from_bom')) {
				document.getElementById('stock_output_action_production_button_qty_from_bom').remove();
			}

			if (document.getElementById('stock_output_action_dossier_button_from_bom')) {
				document.getElementById('stock_output_action_dossier_button_from_bom').remove();
			}

			if (document.getElementById('stock_output_action_dossier_button_qty_from_bom')) {
				document.getElementById('stock_output_action_dossier_button_qty_from_bom').remove();
			}

			if (document.getElementById('stock_output_action_dossier_button_from_dedicated_location')) {
				document.getElementById('stock_output_action_dossier_button_from_dedicated_location').remove();
			}


			if (document.getElementById('stock_output_action_production_dossier')) {
				document.getElementById('stock_output_action_production_dossier').remove();
			}


			let html = '';

			let top = 2;
			let left = 2;

			html += '<div id="stock_output_action_production_dossier" style="overflow: auto; overflow-x: auto; overflow-y: hidden; height:40px; border: 0px solid #E6E9EC;">';

			if (this.journal_wms_output_production_allowed == 1) {

				html += '	<div style="position: relative; top: ' + top.toString() + 'px; left:' + left.toString() + 'px; width:200px;">';
				html +='		<button id="stock_output_action_production_button_from_bom" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("From Prod BOM") + '</button>';
				html += '	</div>';

				top -= 30;
				left += 210;

				html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: ' + left.toString() + 'px; width:200px;">';
				html +='		<button id="stock_output_action_production_button_qty_from_bom" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("Qty from Prod BOM") + '</button>';
				html += '	</div>';

				top -= 30;
				left += 210;

			}

			if (this.journal_wms_output_dossier_allowed == 1) {

				html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: ' + left.toString() + 'px; width:200px;">';
				html +='		<button id="stock_output_action_dossier_button_from_bom" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("From Dossier BOM") + '</button>';
				html += '	</div>';

				top -= 30;
				left += 210;

				html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: ' + left.toString() + 'px; width:200px;">';
				html +='		<button id="stock_output_action_dossier_button_qty_from_bom" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="" hidden>' + __("Qty from Dossier BOM") + '</button>';
				html += '	</div>';

				html += '	<div style="position: relative; top: ' + top.toString() + 'px; left: ' + left.toString() + 'px; width:200px;">';
				html +='		<button id="stock_output_action_dossier_button_from_dedicated_location" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("From dedicated location") + '</button>';
				html += '	</div>';


			}

			html += '</div>';

			this.frm.fields_dict['html_detail_action_production_dossier'].$wrapper.append(html);

			let me = this;

			this.#sleep(200).then(() => {

				if (document.getElementById('stock_output_action_production_button_from_bom')) {

					let fct_click = function() {
						me.action_from_production_bom();
					}

					document.getElementById('stock_output_action_production_button_from_bom').onclick = fct_click;
				}

				if (document.getElementById('stock_output_action_production_button_qty_from_bom')) {

					let fct_click = function() {
						me.action_qty_from_production_bom();
					}

					document.getElementById('stock_output_action_production_button_qty_from_bom').onclick = fct_click;
				}


				if (document.getElementById('stock_output_action_dossier_button_from_bom')) {

					let fct_click = function() {
						me.action_from_dossier_bom();
					}

					document.getElementById('stock_output_action_dossier_button_from_bom').onclick = fct_click;
				}

				if (document.getElementById('stock_output_action_dossier_button_qty_from_bom')) {

					let fct_click = function() {
						me.action_qty_from_dossier_bom();
					}

					document.getElementById('stock_output_action_dossier_button_qty_from_bom').onclick = fct_click;
				}

				if (document.getElementById('stock_output_action_dossier_button_from_dedicated_location')) {

					let fct_click = function() {
						me.action_from_dedicated_location();
					}

					document.getElementById('stock_output_action_dossier_button_from_dedicated_location').onclick = fct_click;
				}



			});
		}
	}

	action_from_production_bom(tp)
	{
		this.do_from_production_bom('BOM');
	}

	action_qty_from_production_bom()
	{
		this.do_from_production_bom('QTY_BOM');
	}


	do_from_production_bom(tp)
	{
		if (this.frm.doc.ioistatus != 0) {
			return false;
		}

		let me = this;

		let title = __("From production BOM");

		if (tp.toUpperCase() == 'QTY_BOM') {
			title = __("Qty from production BOM")
		}


		var fromProductionBOMDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [
						{	fieldname: 'from_production_bom_content',	fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){


				let rows = me.ioiTable.getRows();

				if (!rows) {

					frappe.msgprint({title: __("Message"), message: __("No selected data"), indicator: "red"});
					return false;
				}


				let row_checked = false;

				for (var i = 0; i < rows.length; i++) {

					if (rows[i].getCell('checkbox').getValue() == 1) {
						row_checked = true;
						break;
					}
				}

				if (!row_checked) {
					frappe.msgprint({title: __("Message"), message: __("No selected data"), indicator: "red"});
					return false;

				}

				let parents = []
				let names = []
				let qties = []

				for (var i = 0; i < rows.length; i++) {

					if (rows[i].getCell('checkbox').getValue() == 1) {

						let qty_to_out = 0;

						if ((rows[i].getCell('qty_to_out').getValue() != null) && (rows[i].getCell('qty_to_out').getValue() != '')) {
							qty_to_out = parseFloat(rows[i].getCell('qty_to_out').getValue());
						}

						let remaining_qty = 0;

						if ((rows[i].getCell('remaining_qty').getValue() != null) && (rows[i].getCell('remaining_qty').getValue() != '')) {
							remaining_qty = parseFloat(rows[i].getCell('remaining_qty').getValue());
						}

						let q_stock = 0;

						if ((rows[i].getCell('q_stock').getValue() != null) && (rows[i].getCell('q_stock').getValue() != '')) {
							q_stock = parseFloat(rows[i].getCell('q_stock').getValue());
						}


						if (Math.abs(parseFloat(qty_to_out)) > Math.abs(parseFloat(remaining_qty))) {
							frappe.msgprint({title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ' : ' + __("Qty to out has to be lower than or equal to the remaining qty"), indicator: "red"});
							return false;
						}


						if (document.getElementById('from_production_bom_content_filters_limit_to_stock').checked) {


							if (Math.abs(parseFloat(qty_to_out)) > Math.abs(parseFloat(q_stock))) {
								frappe.msgprint({title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ' : ' + __("Qty to out has to be lower than or equal to the stock qty"), indicator: "red"});
								return false;
							}

						}


						parents[parents.length] = rows[i].getCell('parent').getValue();
						names[names.length] = rows[i].getCell('name').getValue();
						qties[qties.length] = rows[i].getCell('qty_to_out').getValue();
					}
				}


				me.#sleep(200).then(() => {

					fromProductionBOMDialog.hide();

					let method = me.path_stock_output + '.ioi_stock_output_get_selected_production_bom';

					frappe.call({  	method: method,
									args: {	"parents": parents,
											"names": names,
											"qties": qties,
											"document_date": me.frm.doc.document_date,
											"document_language": me.user_language,

									},
									async: false,
									callback:function(r)	{

										if (r.message.length > 0) {

											let data = r.message;

											for (var i = 0; i < data.length; i++) {

												let line = cur_frm.add_child('line_detail');

												line.item_id = data[i].component_id;
												line.ioistatus = 0;

												if (me.journal_wms_output_qty_to_prepare_mode != 0) {
													line.qty_to_prepare = data[i].qty;

												}else{
													line.delivered_qty = data[i].qty;
												}

												line.stored_qty_mode = data[i].stored_qty_mode;
												line.production_id = data[i].parent;
												line.production_bom_id = data[i].name;

												line.item_description = data[i].item_description;
												line.item_mode = data[i].mode;
												line.unit_id = data[i].unit_id;
												line.weight = data[i].weight;
												line.tare = data[i].tare;
												line.volume = data[i].volume;
												line.decimals_allowed = data[i].decimals_allowed;
												line.analytic1_id = data[i].analytic1_id;
												line.analytic2_id = data[i].analytic2_id;
												line.analytic3_id = data[i].analytic3_id;
												line.analytic4_id = data[i].analytic4_id;
												line.item_cost_std_stamp = data[i].item_cost_std_stamp;
												line.item_cost = data[i].item_cost;
												line.warehouse_id = data[i].warehouse_id;
												line.location_active = data[i].location_active;

												me.compute_line(line.doctype, line.name);

											}

											cur_frm.refresh_field('line_detail');


										}
									}
					});

				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				fromProductionBOMDialog.hide();
			}

		});

		this.remove_html_element('from_production_bom_content_panel_filters_title');
		this.remove_html_element('from_production_bom_content_panel_filters');

		this.remove_html_element('from_production_bom_content_filters_production_label');
		this.remove_html_element('from_production_bom_content_filters_production_id');
		this.remove_html_element('from_production_bom_content_filters_production_button');


		this.remove_html_element('from_production_bom_content_filters_production_routing_step_label');
		this.remove_html_element('from_production_bom_content_filters_production_routing_step_id');
		this.remove_html_element('from_production_bom_content_filters_production_routing_step_button');

		this.remove_html_element('from_production_bom_content_filters_item_kind_label');
		this.remove_html_element('from_production_bom_content_filters_item_kind');

		this.remove_html_element('from_production_bom_content_filters_stored_qty_mode_label');
		this.remove_html_element('from_production_bom_content_filters_stored_qty_mode');

		this.remove_html_element('from_production_bom_content_filters_warehouse_label');
		this.remove_html_element('from_production_bom_content_filters_warehouse_id');
		this.remove_html_element('from_production_bom_content_filters_warehouse_button');

		this.remove_html_element('from_production_bom_content_filters_limit_to_stock_label');
		this.remove_html_element('from_production_bom_content_filters_limit_to_stock');

		this.remove_html_element('from_production_bom_content_filters_search_label');
		this.remove_html_element('from_production_bom_content_filters_search');

		this.remove_html_element('from_production_bom_content_filters_qty_label');
		this.remove_html_element('from_production_bom_content_filters_qty');

		this.remove_html_element('from_production_bom_content_filters_limit_to_goal_label');
		this.remove_html_element('from_production_bom_content_filters_limit_to_goal');

		this.remove_html_element('from_production_bom_content_filters_button_search');
		this.remove_html_element('from_production_bom_content_filters_button_clear');


		this.remove_html_element('from_production_bom_content_filters');

		this.remove_html_element('from_production_bom_content_panel_result_title');
		this.remove_html_element('from_production_bom_content_panel_result');


		this.remove_html_element('from_production_bom_content_panel_result_content');


		this.remove_html_element('from_production_bom_action_grid_button');
		this.remove_html_element('from_production_bom_action_grid_button_all');
		this.remove_html_element('from_production_bom_action_grid_button_none');



		let html = '';

		html += '<div id="from_production_bom_content_panel_filters" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="from_production_bom_content_panel_filters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'


		html += '<div id="from_production_bom_content_filters" style="overflow-x: auto;height:140px;">';

		let production_value = '';

		if ((this.frm.doc.production_id) && (this.frm.doc.production_id.trim() != '')) {
			production_value = this.frm.doc.production_id;
		}


		// Production
		html += '	<div style="position: relative; top: 0px; left: 2px; width:220px;">';
		html += '		<label id="from_production_bom_content_filters_production_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Production") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_production_bom_content_filters_production_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="' + production_value + '">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_production_bom_content_filters_production_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Production routing
		html += '	<div style="position: relative; top: 0px; left: 272px; width:220px;">';
		html += '		<label id="from_production_bom_content_filters_production_routing_step_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Routing (step)") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_production_bom_content_filters_production_routing_step_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_production_bom_content_filters_production_routing_step_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Item kind
		html += '	<div style="position: relative; top: 0px; left: 542px; width:170px;">';
		html += '		<label id="from_production_bom_content_filters_item_kind_label" style="position: absolute; top: 0px; left: 2px;">' + __("Item kind") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '		<select id="from_production_bom_content_filters_item_kind" class="input-with-feedback form-control bold" style="width:170px"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Normal") + '</option> ';
		html += '			<option value="1">' + __("Consumable") + '</option> ';
		html += '			<option value="2">' + __("Packing") + '</option> ';
		html += '			<option value="3">' + __("Subproduct") + '</option> ';
		html += '			<option value="4">' + __("Waste") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';


		// Stored qty mode
		html += '	<div style="position: relative; top: 0px; left: 722px; width:170px;">';
		html += '		<label id="from_production_bom_content_filters_stored_qty_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Stored qty mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '		<select id="from_production_bom_content_filters_stored_qty_mode" class="input-with-feedback form-control bold" style="width:170px"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Good") + '</option> ';
		html += '			<option value="1">' + __("To check") + '</option> ';

		if (this.journal_whs_output_allow_bad_qty == 1) {
			html += '			<option value="2">' + __("Bad") + '</option> ';
		}

		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';


		// Warehouse
		html += '	<div style="position: relative; top: 0px; left: 902px; width:220px;">';
		html += '		<label id="from_production_bom_content_filters_warehouse_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_production_bom_content_filters_warehouse_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_production_bom_content_filters_warehouse_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Limit to stock
		html += '	<div style="position: relative; top: 0px; left: 1172px; width:200px;">';
		html += '		<label id="from_production_bom_content_filters_limit_to_stock_label" style="position: absolute; top: 33px; left: 22px;z-index:5;">' + __("Limit to stock") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 35px; left: 2px; width: 200px; height: 25px;"> ';
		html += '			<input id="from_production_bom_content_filters_limit_to_stock" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: relative; top: 65px; left: 2px; width:530px;">';
		html += '		<label id="from_production_bom_content_filters_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 530px; height: 25px;"> ';
		html += '			<input id="from_production_bom_content_filters_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		if (tp.toUpperCase() == 'QTY_BOM') {

			// For a produced qty
			html += '	<div style="position: relative; top: 65px; left: 542px; width:300px;">';
			html += '		<label id="from_production_bom_content_filters_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("For a produced qty (stock unit)") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
			html += '			<input id="from_production_bom_content_filters_qty" type="number" step="any" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';

			// Limit to goal
			html += '	<div style="position: relative; top: 65px; left: 722px; width:200px;">';
			html += '		<label id="from_production_bom_content_filters_limit_to_goal_label" style="position: absolute; top: 33px; left: 22px;z-index:5;">' + __("Limit to goal") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 35px; left: 2px; width: 200px; height: 25px;"> ';
			html += '			<input id="from_production_bom_content_filters_limit_to_goal" type="checkbox" class="input-with-feedback form-control bold" checked>';
			html += '		</div>';
			html += '	</div>';

		}


		// Search
		html += '	<div style="position: relative; top: 90px; left: 1132px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="from_production_bom_content_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '	<div style="position: relative; top: 90px; left: 1262px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="from_production_bom_content_filters_button_clear" title="' + __("Clear") +'" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Clear") + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';

		html += '<div id="from_production_bom_content_panel_result" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="from_production_bom_content_panel_result_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Result") + '</b></label>';
		html += '</div>'

		html += '<div id="from_production_bom_content_panel_result_content" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;">';
		html += '</div>';



		html += '<div id="from_production_bom_action_grid_button" style="overflow-x: auto;height:50px;">';

		// All
		html += '	<div style="position: relative; top: 2px; left: 2px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="from_production_bom_action_grid_button_all" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("All") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// None
		html += '	<div style="position: relative; top: 2px; left: 110px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="from_production_bom_action_grid_button_none" title="' + __("Clear") +'" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("None") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';



		fromProductionBOMDialog.fields_dict.from_production_bom_content.$wrapper.html(html);
		fromProductionBOMDialog.$wrapper.find('.modal-dialog').css("max-width", "1420px").css("width", "1420px");
		fromProductionBOMDialog.show();

		this.#sleep(200).then(() => {

			var checkEditor = (cell, onRendered, success, cancel, editorParams) => {

				var input = document.createElement("input");
				input.type = 'CHECKBOX';
				input.style.boxSizing = "border-box";

				if ((cell.getValue() == null) || (cell.getValue() == '0')) {
					input.checked = false;
				}else{
					input.checked = true;
				}

				onRendered(function(){
					input.focus();
					input.style.css = "100%";
				});


				function successFunc(){

					let value = 0;


					if (input.checked) {
						value = 1;
					}


					let row = cell.getRow();

					let s = '';

					if ((!value) || ((value) && (value == 0))) {
						s = '{"' + cell.getColumn().getField() + '": 0}';
					}else{
						s = '{"' + cell.getColumn().getField() + '":' + value + '}';
					}

					row.update(JSON.parse(s));


					if ((!value) || ((value) && (value == 0))) {
						success(0);
					}else{
						success(value);
					}
				}


				input.addEventListener("click", successFunc);

				return input;
			};

			let column_editor = checkEditor;

			let cell_format = (cell, formatterParams) => {

				if (cell.getValue() != null) {

					if (cell.getValue() == 1) {
						return "<i class='fa fa-check'></i>"
					}else if (cell.getValue() == 0) {
						return "<i class='fa fa-times'></i>";
					}
				}else{
					return "<i class='fa fa-times'></i>";
				}

			}

			let column_formatter = cell_format;


			let qty_format = (cell, formatterParams) => {

				cell.getElement().style.backgroundColor = '#ebeae8';
				cell.getElement().style.color = '#000000';
				cell.getElement().innerText = cell.getValue();

				return cell.getValue();

			}

			let qty_formatter = qty_format;

			let qty_description = __('Remaining Qty');

			if (tp.toUpperCase() == 'QTY_BOM') {
				qty_description = __('Qty');
			}


			me.ioiTable = new ioi.Tabulator('#from_production_bom_content_panel_result_content', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 1,
				showProfiles: false,
				movableColumns: true,
				resizableColumns: true,
				autoRedraw: true,

				columns: [

					{title: __(''), field: 'checkbox', width: 50, hozAlign: "center", editor:column_editor, formatter: column_formatter},
					{title: __('Parent'), field: 'parent', width: 125, visible: false},
					{title: __('Name'), field: 'name', width: 125, visible: false},
					{title: __('Position'), field: 'position_id', width: 150},
					{title: __('Component'), field: 'component_id', width: 250},
					{title: __('Description'), field: 'description', width: 300},
					{title: qty_description, field: 'remaining_qty', width: 150, hozAlign: "right", formatter: qty_formatter},
					{title: __('Stock qty'), field: 'q_stock', width: 150, hozAlign: "right", formatter: qty_formatter},
					{title: __('Qty to out'), field: 'qty_to_out', width: 150, hozAlign: "right", editor:true},
					{title: __('Unit'), field: 'stock_unit_id', width: 100},
					{title: __('Step'), field: 'step_id', width: 150},
					{title: __('Warehouse'), field: 'warehouse_id', width: 150},
					{title: __('Item kind'), field: 'item_kind_description', width: 150},
					{title: __('Stored qty mode'), field: 'stored_qty_mode_description', width: 150}
				]

			})


			let fct_keydown = function(event) {
				if (event.keyCode == 13) {
					me.action_production_bom_search(tp);
					return false;
				}
			}

			// Production

			document.getElementById('from_production_bom_content_filters_production_id').onkeydown = fct_keydown;

			let fct_production_button = function() {

				let title = __("Select a production");
				let form_width_pixel = '960px';
				let table = "ioi Production";
				let fields = 'name, item_id, item_description';
				let fields_len = '250, 250, 380';
				let fields_desc = 'Identification, Item, Description';
				let where = 'ioistatus = 1 or ioistatus = 2 ';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_production_bom_content_filters_production_id').value = return_value;
					document.getElementById('from_production_bom_content_filters_production_id').focus();
					me.action_production_bom_search(tp);
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
			}

			document.getElementById('from_production_bom_content_filters_production_button').onclick = fct_production_button;


			// Production routing step

			document.getElementById('from_production_bom_content_filters_production_routing_step_id').onkeydown = fct_keydown;


			let fct_production_routing_step_button = function() {

				if ((!document.getElementById('from_production_bom_content_filters_production_id').value) || ((document.getElementById('from_production_bom_content_filters_production_id').value) && (document.getElementById('from_production_bom_content_filters_production_id').value.trim() == ''))) {
					frappe.msgprint({title: __("Message"), message: __("Select a Production before, please"), indicator: "red"});
					return false;
				}


				let title = __("Select a production Routing");
				let form_width_pixel = '960px';
				let table = "ioi Production Routing";
				let fields = 'step_id, workcenter_id, description';
				let fields_len = '250, 250, 380';
				let fields_desc = 'Step, Workcenter, Description';
				let where = "parenttype = 'ioi Production' and parent = '" + document.getElementById('from_production_bom_content_filters_production_id').value.toUpperCase().replaceAll("'", "''") + "' ";
				let order_by = 'step_id asc';


				let fct_callback = function (return_value) {

					document.getElementById('from_production_bom_content_filters_production_routing_step_id').value = return_value;
					document.getElementById('from_production_bom_content_filters_production_routing_step_id').focus();
					me.action_production_bom_search(tp);

				}


				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)



			}

			document.getElementById('from_production_bom_content_filters_production_routing_step_button').onclick = fct_production_routing_step_button;


			let fct_change = function() {
				me.action_production_bom_search(tp);
			}

			// Item kind
			document.getElementById('from_production_bom_content_filters_item_kind').onchange = fct_change;

			// Stored qty mode
			document.getElementById('from_production_bom_content_filters_stored_qty_mode').onchange = fct_change;


			// Warehouse
			document.getElementById('from_production_bom_content_filters_warehouse_id').onkeydown = fct_keydown;


			let fct_warehouse_button = function() {

				let title = __("Select a warehouse");
				let form_width_pixel = '710px';
				let table = "ioi Warehouse";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_production_bom_content_filters_warehouse_id').value = return_value;
					document.getElementById('from_production_bom_content_filters_warehouse_id').focus();
					me.action_production_bom_search(tp);
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

			}

			document.getElementById('from_production_bom_content_filters_warehouse_button').onclick = fct_warehouse_button;



			// Search
			document.getElementById('from_production_bom_content_filters_search').onkeydown = fct_keydown;

			// For a produced qty
			if (document.getElementById('from_production_bom_content_filters_qty')) {
				document.getElementById('from_production_bom_content_filters_qty').onkeydown = fct_keydown;
			}


			// Limit to stock

			let fct_limit_click = function() {
				me.action_production_bom_search(tp);
			}

			document.getElementById('from_production_bom_content_filters_limit_to_stock').onclick = fct_limit_click;
			document.getElementById('from_production_bom_content_filters_limit_to_stock').keydown = fct_keydown;

			// Limit to goal
			if (document.getElementById('from_production_bom_content_filters_limit_to_goal')) {
				document.getElementById('from_production_bom_content_filters_limit_to_goal').onclick = fct_limit_click;
				document.getElementById('from_production_bom_content_filters_limit_to_goal').keydown = fct_keydown;
			}



			let fct_search = function() {
				me.action_production_bom_search(tp);
			}

			document.getElementById('from_production_bom_content_filters_button_search').onclick = fct_search;

			let fct_clear = function() {
				me.action_production_bom_clear();
			}

			document.getElementById('from_production_bom_content_filters_button_clear').onclick = fct_clear;



			let fct_all = function() {
				me.action_production_bom_salect_all();
			}

			document.getElementById('from_production_bom_action_grid_button_all').onclick = fct_all;



			let fct_none = function() {
				me.action_production_bom_salect_none();
			}

			document.getElementById('from_production_bom_action_grid_button_none').onclick = fct_none;

			this.#sleep(200).then(() => {
				me.action_production_bom_search(tp);
			});

		});
	}

	action_production_bom_salect_all()
	{
		let me = this;

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({checkbox: '1'});
			}
		}
	}

	action_production_bom_salect_none()
	{
		let me = this;

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({checkbox: '0'});
			}
		}
	}

	action_production_bom_search(tp)
	{
		let me = this;

		if ((!document.getElementById('from_production_bom_content_filters_production_id').value) || ((document.getElementById('from_production_bom_content_filters_production_id').value) && (document.getElementById('from_production_bom_content_filters_production_id').value.trim() == ''))) {
			return false;
		}

		let limit_to_stock = 0;

		if (document.getElementById('from_production_bom_content_filters_limit_to_stock').checked) {
			limit_to_stock = 1;
		}

		let data = []
		let error_found = false;

		let for_produced_qty = null;

		if (document.getElementById('from_production_bom_content_filters_qty')) {

			if (document.getElementById('from_production_bom_content_filters_qty').value) {
				for_produced_qty = parseFloat(document.getElementById('from_production_bom_content_filters_qty').value);
			}

		}

		if (for_produced_qty != null) {

			if (parseFloat(for_produced_qty) < 0) {
				frappe.msgprint({title: __("Message"), message: __('The produced qty has to be greater than or equal to zero'), indicator: "red"});
				return false;
			}

		}


		let limit_to_goal = 0;

		if (document.getElementById('from_production_bom_content_filters_limit_to_goal')) {

			if (document.getElementById('from_production_bom_content_filters_limit_to_goal').checked) {

				limit_to_goal = 1;
			}
		}



		let method = this.path_stock_output + '.ioi_stock_output_get_production_bom_list';

		frappe.call({  	method: method,
						args: {	"production_id": document.getElementById('from_production_bom_content_filters_production_id').value,
								"step_id": document.getElementById('from_production_bom_content_filters_production_routing_step_id').value,
								"item_kind": document.getElementById('from_production_bom_content_filters_item_kind').value,
								"stored_qty_mode": document.getElementById('from_production_bom_content_filters_stored_qty_mode').value,
								"warehouse_id": document.getElementById('from_production_bom_content_filters_warehouse_id').value,
								"search": document.getElementById('from_production_bom_content_filters_search').value,
								"limit_to_stock": limit_to_stock,
								"allow_bad_qty": me.journal_whs_output_allow_bad_qty,
								"tp": tp,
								"for_produced_qty": for_produced_qty,
								"limit_to_goal": limit_to_goal
						},
						async: false,
						callback:function(r)	{

							if (r.message.error != 0) {
								data = [];
								error_found = true;
								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
							}else{
								data = r.message.data;
							}
						}
		});

		if (error_found) {
			return false;
		}

		me.ioiTable.setData(data);

	}

	action_production_bom_clear()
	{
		document.getElementById('from_production_bom_content_filters_production_id').value = '';
		document.getElementById('from_production_bom_content_filters_production_routing_step_id').value = '';
		document.getElementById('from_production_bom_content_filters_item_kind').selectedIndex = 0;
		document.getElementById('from_production_bom_content_filters_stored_qty_mode').selectedIndex = 0;
		document.getElementById('from_production_bom_content_filters_warehouse_id').value = '';
		document.getElementById('from_production_bom_content_filters_search').value = '';
		document.getElementById('from_production_bom_content_filters_limit_to_stock').checked = false;

		if (document.getElementById('from_production_bom_content_filters_qty')) {
			document.getElementById('from_production_bom_content_filters_qty').value = '';
		}

		if (document.getElementById('from_production_bom_content_filters_limit_to_goal')) {
			document.getElementById('from_production_bom_content_filters_limit_to_goal').checked = true;
		}

		this.ioiTable.clearData();
	}


	action_from_dossier_bom()
	{
		this.do_from_dossier_bom('BOM');
	}

	action_qty_from_dossier_bom()
	{
		this.do_from_dossier_bom('QTY_BOM');
	}


	do_from_dossier_bom(tp)
	{

		if (this.frm.doc.ioistatus != 0) {
			return false;
		}

		let me = this;

		let title = __("From dossier BOM");

		if (tp.toUpperCase() == 'QTY_BOM') {
			title = __("Qty from dossier BOM")
		}


		var fromDossierBOMDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [
						{	fieldname: 'from_dossier_bom_content',	fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){


				let rows = me.ioiTable.getRows();

				if (!rows) {

					frappe.msgprint({title: __("Message"), message: __("No selected data"), indicator: "red"});
					return false;
				}


				let row_checked = false;

				for (var i = 0; i < rows.length; i++) {

					if (rows[i].getCell('checkbox').getValue() == 1) {
						row_checked = true;
						break;
					}
				}

				if (!row_checked) {
					frappe.msgprint({title: __("Message"), message: __("No selected data"), indicator: "red"});
					return false;

				}

				let parents = []
				let names = []
				let qties = []

				for (var i = 0; i < rows.length; i++) {

					if (rows[i].getCell('checkbox').getValue() == 1) {

						let qty_to_out = 0;

						if ((rows[i].getCell('qty_to_out').getValue() != null) && (rows[i].getCell('qty_to_out').getValue() != '')) {
							qty_to_out = parseFloat(rows[i].getCell('qty_to_out').getValue());
						}

						let remaining_qty = 0;

						if ((rows[i].getCell('remaining_qty').getValue() != null) && (rows[i].getCell('remaining_qty').getValue() != '')) {
							remaining_qty = parseFloat(rows[i].getCell('remaining_qty').getValue());
						}

						let q_stock = 0;

						if ((rows[i].getCell('q_stock').getValue() != null) && (rows[i].getCell('q_stock').getValue() != '')) {
							q_stock = parseFloat(rows[i].getCell('q_stock').getValue());
						}


						if (Math.abs(parseFloat(qty_to_out)) > Math.abs(parseFloat(remaining_qty))) {
							frappe.msgprint({title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ' : ' + __("Qty to out has to be lower than or equal to the remaining qty"), indicator: "red"});
							return false;
						}


						if (document.getElementById('from_dossier_bom_content_filters_limit_to_stock').checked) {


							if (Math.abs(parseFloat(qty_to_out)) > Math.abs(parseFloat(q_stock))) {
								frappe.msgprint({title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ' : ' + __("Qty to out has to be lower than or equal to the stock qty"), indicator: "red"});
								return false;
							}

						}


						parents[parents.length] = rows[i].getCell('parent').getValue();
						names[names.length] = rows[i].getCell('name').getValue();
						qties[qties.length] = rows[i].getCell('qty_to_out').getValue();
					}
				}


				me.#sleep(200).then(() => {

					fromDossierBOMDialog.hide();

					let method = me.path_stock_output + '.ioi_stock_output_get_selected_dossier_bom';

					frappe.call({  	method: method,
									args: {	"parents": parents,
											"names": names,
											"qties": qties,
											"document_date": me.frm.doc.document_date,
											"document_language": me.user_language,

									},
									async: false,
									callback:function(r)	{

										if (r.message.length > 0) {

											let data = r.message;

											for (var i = 0; i < data.length; i++) {

												let line = cur_frm.add_child('line_detail');

												line.item_id = data[i].component_id	;
												line.ioistatus = 0;

												if (me.journal_wms_output_qty_to_prepare_mode != 0) {
													line.qty_to_prepare = data[i].qty;

												}else{
													line.delivered_qty = data[i].qty;
												}

												line.stored_qty_mode = data[i].stored_qty_mode;
												line.dossier_id = data[i].parent;
												line.dossier_bom_id = data[i].name;

												line.item_description = data[i].item_description;
												line.item_mode = data[i].mode;
												line.unit_id = data[i].unit_id;
												line.weight = data[i].weight;
												line.tare = data[i].tare;
												line.volume = data[i].volume;
												line.decimals_allowed = data[i].decimals_allowed;
												line.analytic1_id = data[i].analytic1_id;
												line.analytic2_id = data[i].analytic2_id;
												line.analytic3_id = data[i].analytic3_id;
												line.analytic4_id = data[i].analytic4_id;
												line.item_cost_std_stamp = data[i].item_cost_std_stamp;
												line.item_cost = data[i].item_cost;
												line.warehouse_id = data[i].warehouse_id;

												me.compute_line(line.doctype, line.name);

											}

											cur_frm.refresh_field('line_detail');


										}
									}
					});

				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				fromDossierBOMDialog.hide();
			}

		});

		this.remove_html_element('from_dossier_bom_content_panel_filters_title');
		this.remove_html_element('from_dossier_bom_content_panel_filters');

		this.remove_html_element('from_dossier_bom_content_filters_dossier_label');
		this.remove_html_element('from_dossier_bom_content_filters_dossier_id');
		this.remove_html_element('from_dossier_bom_content_filters_dossier_button');


		this.remove_html_element('from_dossier_bom_content_filters_dossier_routing_step_label');
		this.remove_html_element('from_dossier_bom_content_filters_dossier_routing_step_id');
		this.remove_html_element('from_dossier_bom_content_filters_dossier_routing_step_button');

		this.remove_html_element('from_dossier_bom_content_filters_item_kind_label');
		this.remove_html_element('from_dossier_bom_content_filters_item_kind');

		this.remove_html_element('from_dossier_bom_content_filters_stored_qty_mode_label');
		this.remove_html_element('from_dossier_bom_content_filters_stored_qty_mode');

		this.remove_html_element('from_dossier_bom_content_filters_warehouse_label');
		this.remove_html_element('from_dossier_bom_content_filters_warehouse_id');
		this.remove_html_element('from_dossier_bom_content_filters_warehouse_button');

		this.remove_html_element('from_dossier_bom_content_filters_limit_to_stock_label');
		this.remove_html_element('from_dossier_bom_content_filters_limit_to_stock');


		this.remove_html_element('from_dossier_bom_content_filters_search_label');
		this.remove_html_element('from_dossier_bom_content_filters_search');

		this.remove_html_element('from_dossier_bom_content_filters_qty_label');
		this.remove_html_element('from_dossier_bom_content_filters_qty');

		this.remove_html_element('from_dossier_bom_content_filters_limit_to_goal_label');
		this.remove_html_element('from_dossier_bom_content_filters_limit_to_goal');


		this.remove_html_element('from_dossier_bom_content_filters_button_search');
		this.remove_html_element('from_dossier_bom_content_filters_button_clear');


		this.remove_html_element('from_dossier_bom_content_filters');

		this.remove_html_element('from_dossier_bom_content_panel_result_title');
		this.remove_html_element('from_dossier_bom_content_panel_result');


		this.remove_html_element('from_dossier_bom_content_panel_result_content');


		this.remove_html_element('from_dossier_bom_action_grid_button');
		this.remove_html_element('from_dossier_bom_action_grid_button_all');
		this.remove_html_element('from_dossier_bom_action_grid_button_none');



		let html = '';

		html += '<div id="from_dossier_bom_content_panel_filters" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="from_dossier_bom_content_panel_filters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		let dossier_value = '';

		if ((this.frm.doc.dossier_id) && (this.frm.doc.dossier_id.trim() != '')) {
			dossier_value = this.frm.doc.dossier_id;
		}

		html += '<div id="from_dossier_bom_content_filters" style="overflow-x: auto;height:140px;">';

		// Dossier
		html += '	<div style="position: relative; top: 0px; left: 2px; width:220px;">';
		html += '		<label id="from_dossier_bom_content_filters_dossier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Dossier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_dossier_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="' + dossier_value + '">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_dossier_bom_content_filters_dossier_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Dossier routing
		html += '	<div style="position: relative; top: 0px; left: 272px; width:220px;">';
		html += '		<label id="from_dossier_bom_content_filters_dossier_routing_step_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Routing (step)") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_dossier_routing_step_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_dossier_bom_content_filters_dossier_routing_step_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Item kind
		html += '	<div style="position: relative; top: 0px; left: 542px; width:170px;">';
		html += '		<label id="from_dossier_bom_content_filters_item_kind_label" style="position: absolute; top: 0px; left: 2px;">' + __("Item kind") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '		<select id="from_dossier_bom_content_filters_item_kind" class="input-with-feedback form-control bold" style="width:170px"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Normal") + '</option> ';
		html += '			<option value="1">' + __("Consumable") + '</option> ';
		html += '			<option value="2">' + __("Packing") + '</option> ';
		html += '			<option value="3">' + __("Subproduct") + '</option> ';
		html += '			<option value="4">' + __("Waste") + '</option> ';
		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';


		// Stored qty mode
		html += '	<div style="position: relative; top: 0px; left: 722px; width:170px;">';
		html += '		<label id="from_dossier_bom_content_filters_stored_qty_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Stored qty mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '		<select id="from_dossier_bom_content_filters_stored_qty_mode" class="input-with-feedback form-control bold" style="width:170px"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Good") + '</option> ';
		html += '			<option value="1">' + __("To check") + '</option> ';

		if (this.journal_whs_output_allow_bad_qty == 1) {
			html += '			<option value="2">' + __("Bad") + '</option> ';
		}

		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';


		// Warehouse
		html += '	<div style="position: relative; top: 0px; left: 902px; width:220px;">';
		html += '		<label id="from_dossier_bom_content_filters_warehouse_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_warehouse_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_dossier_bom_content_filters_warehouse_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Limit to stock
		html += '	<div style="position: relative; top: 0px; left: 1172px; width:200px;">';
		html += '		<label id="from_dossier_bom_content_filters_limit_to_stock_label" style="position: absolute; top: 33px; left: 22px;z-index:5;">' + __("Limit to stock") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 35px; left: 2px; width: 200px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_limit_to_stock" type="checkbox" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: relative; top: 65px; left: 2px; width:530px;">';
		html += '		<label id="from_dossier_bom_content_filters_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 530px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		if (tp.toUpperCase() == 'QTY_BOM') {

			// For a produced qty
			html += '	<div style="position: relative; top: 65px; left: 542px; width:300px;">';
			html += '		<label id="from_dossier_bom_content_filters_qty_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("For a dossier qty (stock unit)") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
			html += '			<input id="from_dossier_bom_content_filters_qty" type="number" step="any" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';

			// Limit to goal
			html += '	<div style="position: relative; top: 65px; left: 722px; width:200px;">';
			html += '		<label id="from_dossier_bom_content_filters_limit_to_goal_label" style="position: absolute; top: 33px; left: 22px;z-index:5;">' + __("Limit to goal") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 35px; left: 2px; width: 200px; height: 25px;"> ';
			html += '			<input id="from_dossier_bom_content_filters_limit_to_goal" type="checkbox" class="input-with-feedback form-control bold" checked>';
			html += '		</div>';
			html += '	</div>';

		}

		// Search
		html += '	<div style="position: relative; top: 90px; left: 1132px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="from_dossier_bom_content_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '	<div style="position: relative; top: 90px; left: 1262px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="from_dossier_bom_content_filters_button_clear" title="' + __("Clear") +'" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Clear") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';

		html += '<div id="from_dossier_bom_content_panel_result" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="from_dossier_bom_content_panel_result_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Result") + '</b></label>';
		html += '</div>'

		html += '<div id="from_dossier_bom_content_panel_result_content" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;">';
		html += '</div>';



		html += '<div id="from_dossier_bom_action_grid_button" style="overflow-x: auto;height:50px;">';

		// All
		html += '	<div style="position: relative; top: 2px; left: 2px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="from_dossier_bom_action_grid_button_all" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("All") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// None
		html += '	<div style="position: relative; top: 2px; left: 110px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="from_dossier_bom_action_grid_button_none" title="' + __("Clear") +'" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("None") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';



		fromDossierBOMDialog.fields_dict.from_dossier_bom_content.$wrapper.html(html);
		fromDossierBOMDialog.$wrapper.find('.modal-dialog').css("max-width", "1420px").css("width", "1420px");
		fromDossierBOMDialog.show();

		this.#sleep(200).then(() => {

			var checkEditor = (cell, onRendered, success, cancel, editorParams) => {

				var input = document.createElement("input");
				input.type = 'CHECKBOX';
				input.style.boxSizing = "border-box";

				if ((cell.getValue() == null) || (cell.getValue() == '0')) {
					input.checked = false;
				}else{
					input.checked = true;
				}

				onRendered(function(){
					input.focus();
					input.style.css = "100%";
				});


				function successFunc(){

					let value = 0;


					if (input.checked) {
						value = 1;
					}


					let row = cell.getRow();

					let s = '';

					if ((!value) || ((value) && (value == 0))) {
						s = '{"' + cell.getColumn().getField() + '": 0}';
					}else{
						s = '{"' + cell.getColumn().getField() + '":' + value + '}';
					}

					row.update(JSON.parse(s));


					if ((!value) || ((value) && (value == 0))) {
						success(0);
					}else{
						success(value);
					}
				}


				input.addEventListener("click", successFunc);

				return input;
			};

			let column_editor = checkEditor;

			let cell_format = (cell, formatterParams) => {

				if (cell.getValue() != null) {

					if (cell.getValue() == 1) {
						return "<i class='fa fa-check'></i>"
					}else if (cell.getValue() == 0) {
						return "<i class='fa fa-times'></i>";
					}
				}else{
					return "<i class='fa fa-times'></i>";
				}

			}

			let column_formatter = cell_format;


			let qty_format = (cell, formatterParams) => {

				cell.getElement().style.backgroundColor = '#ebeae8';
				cell.getElement().style.color = '#000000';
				cell.getElement().innerText = cell.getValue();

				return cell.getValue();

			}

			let qty_formatter = qty_format;


			let qty_description = __('Remaining Qty');

			if (tp.toUpperCase() == 'QTY_BOM') {
				qty_description = __('Qty');
			}

			me.ioiTable = new ioi.Tabulator('#from_dossier_bom_content_panel_result_content', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 1,
				showProfiles: false,
				movableColumns: true,
				resizableColumns: true,
				autoRedraw: true,

				columns: [

					{title: __(''), field: 'checkbox', width: 50, hozAlign: "center", editor:column_editor, formatter: column_formatter},
					{title: __('Parent'), field: 'parent', width: 125, visible: false},
					{title: __('Name'), field: 'name', width: 125, visible: false},
					{title: __('Position'), field: 'position_id', width: 150},
					{title: __('Component'), field: 'component_id', width: 250},
					{title: __('Description'), field: 'description', width: 300},
					{title: qty_description, field: 'remaining_qty', width: 150, hozAlign: "right", formatter: qty_formatter},
					{title: __('Stock qty'), field: 'q_stock', width: 150, hozAlign: "right", formatter: qty_formatter},
					{title: __('Qty to out'), field: 'qty_to_out', width: 150, hozAlign: "right", editor:true},
					{title: __('Unit'), field: 'stock_unit_id', width: 100},
					{title: __('Step'), field: 'step_id', width: 150},
					{title: __('Warehouse'), field: 'warehouse_id', width: 150},
					{title: __('Item kind'), field: 'item_kind_description', width: 150},
					{title: __('Stored qty mode'), field: 'stored_qty_mode_description', width: 150}
				]

			})


			let fct_keydown = function(event) {
				if (event.keyCode == 13) {
					me.action_dossier_bom_search(tp);
					return false;
				}
			}

			// Dossier

			document.getElementById('from_dossier_bom_content_filters_dossier_id').onkeydown = fct_keydown;

			let fct_dossier_button = function() {

				let title = __("Select a dossier");
				let form_width_pixel = '960px';
				let table = "ioi Dossier";
				let fields = 'name, item_id, description';
				let fields_len = '250, 250, 380';
				let fields_desc = 'Identification, Item, Description';
				let where = 'ioistatus = 1 or ioistatus = 2 ';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_dossier_bom_content_filters_dossier_id').value = return_value;
					document.getElementById('from_dossier_bom_content_filters_dossier_id').focus();
					me.action_dossier_bom_search(tp);
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
			}

			document.getElementById('from_dossier_bom_content_filters_dossier_button').onclick = fct_dossier_button;


			// Dossier routing step

			document.getElementById('from_dossier_bom_content_filters_dossier_routing_step_id').onkeydown = fct_keydown;


			let fct_dossier_routing_step_button = function() {

				if ((!document.getElementById('from_dossier_bom_content_filters_dossier_id').value) || ((document.getElementById('from_dossier_bom_content_filters_dossier_id').value) && (document.getElementById('from_dossier_bom_content_filters_dossier_id').value.trim() == ''))) {
					frappe.msgprint({title: __("Message"), message: __("Select a Dossier before, please"), indicator: "red"});
					return false;
				}


				let title = __("Select a dossier Routing");
				let form_width_pixel = '960px';
				let table = "ioi Dossier Routing";
				let fields = 'step_id, workcenter_id, description';
				let fields_len = '250, 250, 380';
				let fields_desc = 'Step, Workcenter, Description';
				let where = "parenttype = 'ioi Dossier' and parent = '" + document.getElementById('from_dossier_bom_content_filters_dossier_id').value.toUpperCase().replaceAll("'", "''") + "' ";
				let order_by = 'step_id asc';


				let fct_callback = function (return_value) {

					document.getElementById('from_dossier_bom_content_filters_dossier_routing_step_id').value = return_value;
					document.getElementById('from_dossier_bom_content_filters_dossier_routing_step_id').focus();
					me.action_dossier_bom_search(tp);

				}


				silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)



			}

			document.getElementById('from_dossier_bom_content_filters_dossier_routing_step_button').onclick = fct_dossier_routing_step_button;


			let fct_change = function() {
				me.action_dossier_bom_search(tp);
			}

			// Item kind
			document.getElementById('from_dossier_bom_content_filters_item_kind').onchange = fct_change;

			// Stored qty mode
			document.getElementById('from_dossier_bom_content_filters_stored_qty_mode').onchange = fct_change;


			// Warehouse

			document.getElementById('from_dossier_bom_content_filters_warehouse_id').onkeydown = fct_keydown;


			let fct_warehouse_button = function() {

				let title = __("Select a warehouse");
				let form_width_pixel = '710px';
				let table = "ioi Warehouse";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_dossier_bom_content_filters_warehouse_id').value = return_value;
					document.getElementById('from_dossier_bom_content_filters_warehouse_id').focus();
					me.action_dossier_bom_search(tp);
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

			}

			document.getElementById('from_dossier_bom_content_filters_warehouse_button').onclick = fct_warehouse_button;



			// Search
			document.getElementById('from_dossier_bom_content_filters_search').onkeydown = fct_keydown;

			// For a produced qty
			if (document.getElementById('from_dossier_bom_content_filters_qty')) {
				document.getElementById('from_dossier_bom_content_filters_qty').onkeydown = fct_keydown;
			}


			// Limit to stock

			let fct_limit_click = function() {
				me.action_dossier_bom_search(tp);
			}

			document.getElementById('from_dossier_bom_content_filters_limit_to_stock').onclick = fct_limit_click;
			document.getElementById('from_dossier_bom_content_filters_limit_to_stock').keydown = fct_keydown;


			// Limit to goal
			if (document.getElementById('from_dossier_bom_content_filters_limit_to_goal')) {
				document.getElementById('from_dossier_bom_content_filters_limit_to_goal').onclick = fct_limit_click;
				document.getElementById('from_dossier_bom_content_filters_limit_to_goal').keydown = fct_keydown;
			}



			let fct_search = function() {
				me.action_dossier_bom_search(tp);
			}

			document.getElementById('from_dossier_bom_content_filters_button_search').onclick = fct_search;

			let fct_clear = function() {
				me.action_dossier_bom_clear();
			}

			document.getElementById('from_dossier_bom_content_filters_button_clear').onclick = fct_clear;



			let fct_all = function() {
				me.action_dossier_bom_salect_all();
			}

			document.getElementById('from_dossier_bom_action_grid_button_all').onclick = fct_all;



			let fct_none = function() {
				me.action_dossier_bom_salect_none();
			}

			document.getElementById('from_dossier_bom_action_grid_button_none').onclick = fct_none;

			this.#sleep(200).then(() => {
				me.action_dossier_bom_search(tp);
			});

		});
	}

	action_dossier_bom_salect_all()
	{
		let me = this;

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({checkbox: '1'});
			}
		}
	}

	action_dossier_bom_salect_none()
	{
		let me = this;

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({checkbox: '0'});
			}
		}
	}

	action_dossier_bom_search(tp)
	{
		let me = this;

		if ((!document.getElementById('from_dossier_bom_content_filters_dossier_id').value) || ((document.getElementById('from_dossier_bom_content_filters_dossier_id').value) && (document.getElementById('from_dossier_bom_content_filters_dossier_id').value.trim() == ''))) {
			return false;
		}

		let limit_to_stock = 0;

		if (document.getElementById('from_dossier_bom_content_filters_limit_to_stock').checked) {
			limit_to_stock = 1;
		}

		let data = []
		let error_found = false;

		let for_dossier_qty = null;

		if (document.getElementById('from_dossier_bom_content_filters_qty')) {

			if (document.getElementById('from_dossier_bom_content_filters_qty').value) {
				for_dossier_qty = parseFloat(document.getElementById('from_dossier_bom_content_filters_qty').value);
			}

		}

		if (for_dossier_qty != null) {
			if (parseFloat(for_dossier_qty) < 0) {
				frappe.msgprint({title: __("Message"), message: __('The dossier qty has to be greater than or equal to zero'), indicator: "red"});
				return false;
			}
		}

		let limit_to_goal = 0;

		if (document.getElementById('from_dossier_bom_content_filters_limit_to_goal')) {

			if (document.getElementById('from_dossier_bom_content_filters_limit_to_goal').checked) {

				limit_to_goal = 1;
			}
		}



		let method = this.path_stock_output + '.ioi_stock_output_get_dossier_bom_list';

		frappe.call({  	method: method,
						args: {	"dossier_id": document.getElementById('from_dossier_bom_content_filters_dossier_id').value,
								"step_id": document.getElementById('from_dossier_bom_content_filters_dossier_routing_step_id').value,
								"item_kind": document.getElementById('from_dossier_bom_content_filters_item_kind').value,
								"stored_qty_mode": document.getElementById('from_dossier_bom_content_filters_stored_qty_mode').value,
								"warehouse_id": document.getElementById('from_dossier_bom_content_filters_warehouse_id').value,
								"search": document.getElementById('from_dossier_bom_content_filters_search').value,
								"limit_to_stock": limit_to_stock,
								"allow_bad_qty": me.journal_whs_output_allow_bad_qty,
								"tp": tp,
								"for_dossier_qty": for_dossier_qty,
								"limit_to_goal": limit_to_goal

						},
						async: false,
						callback:function(r)	{

							if (r.message.error != 0) {
								data = [];
								error_found = true;
								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
							}else{
								data = r.message.data;
							}
						}
		});

		if (error_found) {
			return false;
		}

		me.ioiTable.setData(data);

	}

	action_dossier_bom_clear()
	{
		document.getElementById('from_dossier_bom_content_filters_dossier_id').value = '';
		document.getElementById('from_dossier_bom_content_filters_dossier_routing_step_id').value = '';
		document.getElementById('from_dossier_bom_content_filters_item_kind').selectedIndex = 0;
		document.getElementById('from_dossier_bom_content_filters_stored_qty_mode').selectedIndex = 0;
		document.getElementById('from_dossier_bom_content_filters_warehouse_id').value = '';
		document.getElementById('from_dossier_bom_content_filters_search').value = '';
		document.getElementById('from_dossier_bom_content_filters_limit_to_stock').checked = false;

		if (document.getElementById('from_dossier_bom_content_filters_qty')) {
			document.getElementById('from_dossier_bom_content_filters_qty').value = '';
		}

		if (document.getElementById('from_dossier_bom_content_filters_limit_to_goal')) {
			document.getElementById('from_dossier_bom_content_filters_limit_to_goal').checked = true;
		}

		this.ioiTable.clearData();
	}

	// **************************************************************************************************************************************
	// From dedicated dossier
	// **************************************************************************************************************************************

	action_from_dedicated_location()
	{
		this.do_from_dedicated_location()
	}

	do_from_dedicated_location()
	{
		if (this.frm.doc.ioistatus != 0) {
			return false;
		}

		let me = this;

		let title = __("From dedicated location");



		var fromDossierBOMDialog = new frappe.ui.Dialog({
			title: title,
			static: true,
			fields: [
						{	fieldname: 'from_dossier_bom_content',	fieldtype: 'HTML'}
					],
			primary_action_label: __("Ok"),
			primary_action: function(){


				let rows = me.ioiTable.getRows();

				if (!rows) {

					frappe.msgprint({title: __("Message"), message: __("No selected data"), indicator: "red"});
					return false;
				}


				let row_checked = false;

				for (var i = 0; i < rows.length; i++) {

					if (rows[i].getCell('checkbox').getValue() == 1) {
						row_checked = true;
						break;
					}
				}

				if (!row_checked) {
					frappe.msgprint({title: __("Message"), message: __("No selected data"), indicator: "red"});
					return false;

				}

				let warehouses = [];
				let warehouse_locations = [];
				let items = [];
				let item_descriptions = [];
				let batchsns = [];
				let stored_qty_modes = [];
				let qties = [];
				let units = [];
				let dossiers = [];

				let weights = [];
				let tares = [];
				let volumes = [];
				let decimals_alloweds = [];

				let analytic1_ids = [];
				let analytic2_ids = [];
				let analytic3_ids = [];
				let analytic4_ids = [];
				let item_cost_std_stamps = [];
				let item_costs = [];
				let batch_code_refs = [];
				let batch_origins = [];

				for (var i = 0; i < rows.length; i++) {

					if (rows[i].getCell('checkbox').getValue() == 1) {

						let qty_to_out = 0;

						if ((rows[i].getCell('qty_to_out').getValue() != null) && (rows[i].getCell('qty_to_out').getValue() != '')) {
							qty_to_out = parseFloat(rows[i].getCell('qty_to_out').getValue());
						}

						let q_stock = 0;

						if ((rows[i].getCell('q_stock').getValue() != null) && (rows[i].getCell('q_stock').getValue() != '')) {
							q_stock = parseFloat(rows[i].getCell('q_stock').getValue());
						}


						if (Math.abs(parseFloat(qty_to_out)) > Math.abs(parseFloat(q_stock))) {
							frappe.msgprint({title: __("Message"), message: __("Line") + ' ' + (i+1).toString() + ' : ' + __("Qty to out has to be lower than or equal to the stock qty"), indicator: "red"});
							return false;
						}

						warehouses[warehouses.length] = rows[i].getCell('warehouse_id').getValue();
						warehouse_locations[warehouse_locations.length] = rows[i].getCell('location_id').getValue();
						items[items.length] = rows[i].getCell('item_id').getValue();
						item_descriptions[item_descriptions.length] = rows[i].getCell('description').getValue();
						batchsns[batchsns.length] = rows[i].getCell('batch_sn_id').getValue();
						stored_qty_modes[stored_qty_modes.length] = rows[i].getCell('stored_qty_mode').getValue();
						qties[qties.length] = rows[i].getCell('qty_to_out').getValue();
						units[units.length] = rows[i].getCell('unit_id').getValue();
						dossiers[dossiers.length] = rows[i].getCell('dossier_id').getValue();

						weights[weights.length] = rows[i].getCell('weight').getValue();
						tares[tares.length] = rows[i].getCell('tare').getValue();
						volumes[volumes.length] = rows[i].getCell('volume').getValue();
						decimals_alloweds[decimals_alloweds.length] = rows[i].getCell('decimals_allowed').getValue();
						analytic1_ids[analytic1_ids.length] = rows[i].getCell('analytic1_id').getValue();
						analytic2_ids[analytic2_ids.length] = rows[i].getCell('analytic2_id').getValue();
						analytic3_ids[analytic3_ids.length] = rows[i].getCell('analytic3_id').getValue();
						analytic4_ids[analytic4_ids.length] = rows[i].getCell('analytic4_id').getValue();
						item_cost_std_stamps[item_cost_std_stamps.length] = rows[i].getCell('item_cost_std_stamp').getValue();
						item_costs[item_costs.length] = rows[i].getCell('item_cost').getValue();
						batch_code_refs[batch_code_refs.length] = rows[i].getCell('batch_code_ref').getValue();
						batch_origins[batch_origins.length] = rows[i].getCell('batch_origin').getValue();

					}
				}

				me.#sleep(200).then(() => {

					fromDossierBOMDialog.hide();

					for (var i = 0; i < warehouses.length; i++) {

						let line = cur_frm.add_child('line_detail');

						line.item_id = items[i]
						line.ioistatus = 0;

						if (me.journal_wms_output_qty_to_prepare_mode != 0) {
							line.qty_to_prepare = qties[i];

						}else{
							line.delivered_qty = qties[i];
						}

						line.stored_qty_mode = stored_qty_modes[i];
						line.dossier_id = dossiers[i];

						line.item_description = item_descriptions[i];
						line.unit_id = units[i];


						line.weight = weights[i];
						line.tare = tares[i];
						line.volume = volumes[i];
						line.decimals_allowed = decimals_alloweds[i];
						line.analytic1_id = analytic1_ids[i];
						line.analytic2_id = analytic2_ids[i];
						line.analytic3_id = analytic3_ids[i];
						line.analytic4_id = analytic4_ids[i];
						line.item_cost_std_stamp = item_cost_std_stamps[i];
						line.item_cost = item_costs[i];

						line.warehouse_id = warehouses[i];
						line.warehouse_location_id = warehouse_locations[i];
						line.batch_sn_id = batchsns[i];
						line.batch_sn_code_ref = batch_code_refs[i];
						line.batch_sn_origin = batch_origins[i];

						me.compute_line(line.doctype, line.name);
					}

					cur_frm.refresh_field('line_detail');


				});

			},
			secondary_action_label: __("Cancel"),
			secondary_action: function(){

				fromDossierBOMDialog.hide();
			}

		});

		this.remove_html_element('from_dossier_bom_content_panel_filters_title');
		this.remove_html_element('from_dossier_bom_content_panel_filters');

		this.remove_html_element('from_dossier_bom_content_filters_dossier_label');
		this.remove_html_element('from_dossier_bom_content_filters_dossier_id');
		this.remove_html_element('from_dossier_bom_content_filters_dossier_button');

		this.remove_html_element('from_dossier_bom_content_filters_warehouse_label');
		this.remove_html_element('from_dossier_bom_content_filters_warehouse_id');
		this.remove_html_element('from_dossier_bom_content_filters_warehouse_button');


		this.remove_html_element('from_dossier_bom_content_filters_warehouse_location_label');
		this.remove_html_element('from_dossier_bom_content_filters_warehouse_location_id');
		this.remove_html_element('from_dossier_bom_content_filters_warehouse_location_button');


		this.remove_html_element('from_dossier_bom_content_filters_stored_qty_mode_label');
		this.remove_html_element('from_dossier_bom_content_filters_stored_qty_mode');




		this.remove_html_element('from_dossier_bom_content_filters_search_label');
		this.remove_html_element('from_dossier_bom_content_filters_search');



		this.remove_html_element('from_dossier_bom_content_filters_button_search');
		this.remove_html_element('from_dossier_bom_content_filters_button_clear');


		this.remove_html_element('from_dossier_bom_content_filters');

		this.remove_html_element('from_dossier_bom_content_panel_result_title');
		this.remove_html_element('from_dossier_bom_content_panel_result');


		this.remove_html_element('from_dossier_bom_content_panel_result_content');


		this.remove_html_element('from_dossier_bom_action_grid_button');
		this.remove_html_element('from_dossier_bom_action_grid_button_all');
		this.remove_html_element('from_dossier_bom_action_grid_button_none');



		let html = '';

		html += '<div id="from_dossier_bom_content_panel_filters" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="from_dossier_bom_content_panel_filters_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Filters") + '</b></label>';
		html += '</div>'


		html += '<div style="overflow: auto; overflow-x: auto; height:8px;">';
		html += '</div>'

		let dossier_value = '';

		if ((this.frm.doc.dossier_id) && (this.frm.doc.dossier_id.trim() != '')) {
			dossier_value = this.frm.doc.dossier_id;
		}

		html += '<div id="from_dossier_bom_content_filters" style="overflow-x: auto;height:120px;">';

		// Dossier
		html += '	<div style="position: relative; top: 0px; left: 2px; width:220px;">';
		html += '		<label id="from_dossier_bom_content_filters_dossier_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Dossier") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_dossier_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="' + dossier_value + '">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_dossier_bom_content_filters_dossier_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Warehouse
		html += '	<div style="position: relative; top: 0px; left: 272px; width:220px;">';
		html += '		<label id="from_dossier_bom_content_filters_warehouse_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_warehouse_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_dossier_bom_content_filters_warehouse_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Location
		html += '	<div style="position: relative; top: 0px; left: 542px; width:220px;">';
		html += '		<label id="from_dossier_bom_content_filters_warehouse_location_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Location") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 220px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_warehouse_location_id" type="text" class="input-with-feedback form-control bold" style="text-transform: uppercase;" value="">';
		html += '		</div>';

		html += '		<div  style="position: absolute; top: 25px; left: 230px; width: 30px; height: 25px;"> ';
		html +='			<button id="from_dossier_bom_content_filters_warehouse_location_button" style="position:absolute; height: 30px; width: 30px; z-index:10; border:none" onclick="">' + __("...") + '</button>';
		html += '		</div>';
		html += '	</div>';


		// Stored qty mode
		html += '	<div style="position: relative; top: 0px; left: 814px; width:170px;">';
		html += '		<label id="from_dossier_bom_content_filters_stored_qty_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Stored qty mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 280px; height: 30px;"> ';
		html += '		<select id="from_dossier_bom_content_filters_stored_qty_mode" class="input-with-feedback form-control bold" style="width:170px"> ';
		html += '			<option value="-1">' + __("All") + '</option> ';
		html += '			<option value="0">' + __("Good") + '</option> ';
		html += '			<option value="1">' + __("To check") + '</option> ';

		if (this.journal_whs_output_allow_bad_qty == 1) {
			html += '			<option value="2">' + __("Bad") + '</option> ';
		}

		html += '		</select> ';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: relative; top: 0px; left: 994px; width:380px;">';
		html += '		<label id="from_dossier_bom_content_filters_search_label" style="position: absolute; top: 0px; left: 2px;z-index:5;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 380px; height: 25px;"> ';
		html += '			<input id="from_dossier_bom_content_filters_search" type="text" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';


		// Search button
		html += '	<div style="position: relative; top: 70px; left: 1132px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="from_dossier_bom_content_filters_button_search" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Search") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear button
		html += '	<div style="position: relative; top: 70px; left: 1262px; width:110px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="from_dossier_bom_content_filters_button_clear" title="' + __("Clear") +'" class="btn btn-default ellipsis" style="height: 30px; width: 110px;" onclick="">' + __("Clear") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';

		html += '<div id="from_dossier_bom_content_panel_result" data-custom-section-head="true" style="height:30px; border-radius:6px; padding: 4px;" data-custom-section-head="true">';
		html += '	<label id="from_dossier_bom_content_panel_result_title" style="position: relative; top: 2px; left: 6px; height:25px;"><b>' + __("Result") + '</b></label>';
		html += '</div>'

		html += '<div id="from_dossier_bom_content_panel_result_content" class="table table-bordered" data-custom-grid="true" style="height:400px; border-radius:6px; padding: 4px;">';
		html += '</div>';



		html += '<div id="from_dossier_bom_action_grid_button" style="overflow-x: auto;height:50px;">';

		// All
		html += '	<div style="position: relative; top: 2px; left: 2px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='			<button id="from_dossier_bom_action_grid_button_all" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("All") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// None
		html += '	<div style="position: relative; top: 2px; left: 110px; width:100px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 30px;">';
		html +='			<button id="from_dossier_bom_action_grid_button_none" title="' + __("Clear") +'" class="btn btn-default ellipsis" style="height: 30px; width: 100px;" onclick="">' + __("None") + '</button>';
		html += '		</div>';
		html += '	</div>';


		html += '</div>';



		fromDossierBOMDialog.fields_dict.from_dossier_bom_content.$wrapper.html(html);
		fromDossierBOMDialog.$wrapper.find('.modal-dialog').css("max-width", "1420px").css("width", "1420px");
		fromDossierBOMDialog.show();

		this.#sleep(200).then(() => {

			var checkEditor = (cell, onRendered, success, cancel, editorParams) => {

				var input = document.createElement("input");
				input.type = 'CHECKBOX';
				input.style.boxSizing = "border-box";

				if ((cell.getValue() == null) || (cell.getValue() == '0')) {
					input.checked = false;
				}else{
					input.checked = true;
				}

				onRendered(function(){
					input.focus();
					input.style.css = "100%";
				});


				function successFunc(){

					let value = 0;


					if (input.checked) {
						value = 1;
					}


					let row = cell.getRow();

					let s = '';

					if ((!value) || ((value) && (value == 0))) {
						s = '{"' + cell.getColumn().getField() + '": 0}';
					}else{
						s = '{"' + cell.getColumn().getField() + '":' + value + '}';
					}

					row.update(JSON.parse(s));


					if ((!value) || ((value) && (value == 0))) {
						success(0);
					}else{
						success(value);
					}
				}


				input.addEventListener("click", successFunc);

				return input;
			};

			let column_editor = checkEditor;

			let cell_format = (cell, formatterParams) => {

				if (cell.getValue() != null) {

					if (cell.getValue() == 1) {
						return "<i class='fa fa-check'></i>"
					}else if (cell.getValue() == 0) {
						return "<i class='fa fa-times'></i>";
					}
				}else{
					return "<i class='fa fa-times'></i>";
				}

			}

			let column_formatter = cell_format;


			let qty_format = (cell, formatterParams) => {

				cell.getElement().style.backgroundColor = '#ebeae8';
				cell.getElement().style.color = '#000000';
				cell.getElement().innerText = cell.getValue();

				return cell.getValue();

			}

			let qty_formatter = qty_format;


			me.ioiTable = new ioi.Tabulator('#from_dossier_bom_content_panel_result_content', {
				maxHeight: 600,
				rowHeight: null,
				selectableRows: 1,
				showProfiles: false,
				movableColumns: true,
				resizableColumns: true,
				autoRedraw: true,

				columns: [

					{title: __(''), field: 'checkbox', width: 50, hozAlign: "center", editor:column_editor, formatter: column_formatter},
					{title: __('Warehouse'), field: 'warehouse_id', width: 150, visible: false},
					{title: __('Location'), field: 'location_id', width: 150},
					{title: __('Item'), field: 'item_id', width: 250},
					{title: __('Description'), field: 'description', width: 250},
					{title: __('Batch/SN'), field: 'batch_sn_id', width: 200},
					{title: __('Stored qty mode id'), field: 'stored_qty_mode', width: 150, visible: false},
					{title: __('Stored qty mode'), field: 'stored_qty_mode_desc', width: 150},
					{title: __('Stock qty'), field: 'q_stock', width: 100, hozAlign: "right", formatter: qty_formatter},
					{title: __('Qty to out'), field: 'qty_to_out', width: 100, hozAlign: "right", editor:true},
					{title: __('Unit'), field: 'unit_id', width: 100},
					{title: __('Dossier'), field: 'dossier_id', width: 250},
					{title: __('Weight'), field: 'weight', width: 150, visible: false},
					{title: __('Tare'), field: 'tare', width: 150, visible: false},
					{title: __('Volume'), field: 'volume', width: 150, visible: false},
					{title: __('Decimals allowed'), field: 'decimals_allowed', width: 150, visible: false},
					{title: __('Analytic 1'), field: 'analytic1_id', width: 150, visible: false},
					{title: __('Analytic 2'), field: 'analytic2_id', width: 150, visible: false},
					{title: __('Analytic 3'), field: 'analytic3_id', width: 150, visible: false},
					{title: __('Analytic 4'), field: 'analytic4_id', width: 150, visible: false},
					{title: __('Std cost'), field: 'item_cost_std_stamp', width: 150, visible: false},
					{title: __('Item cost'), field: 'item_cost', width: 150, visible: false},
					{title: __('Batch code ref'), field: 'batch_code_ref', width: 150, visible: false},
					{title: __('Batch origin'), field: 'batch_origin', width: 150, visible: false}
				]
			})


			let fct_keydown = function(event) {
				if (event.keyCode == 13) {
					me.action_dedicated_location_search();
					return false;
				}
			}

			// Dossier
			document.getElementById('from_dossier_bom_content_filters_dossier_id').onkeydown = fct_keydown;

			let fct_dossier_button = function() {

				let title = __("Select a dossier");
				let form_width_pixel = '960px';
				let table = "ioi Dossier";
				let fields = 'name, item_id, description';
				let fields_len = '250, 250, 380';
				let fields_desc = 'Identification, Item, Description';
				let where = 'ioistatus = 1 or ioistatus = 2 ';
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_dossier_bom_content_filters_dossier_id').value = return_value;
					document.getElementById('from_dossier_bom_content_filters_dossier_id').focus();
					me.action_dedicated_location_search();
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
			}

			document.getElementById('from_dossier_bom_content_filters_dossier_button').onclick = fct_dossier_button;



			let fct_change = function() {
				me.action_dedicated_location_search();
			}


			// Warehouse
			document.getElementById('from_dossier_bom_content_filters_warehouse_id').onkeydown = fct_keydown;

			let fct_warehouse_button = function() {

				let title = __("Select a warehouse");
				let form_width_pixel = '710px';
				let table = "ioi Warehouse";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = "enabled = 1 and site_id = '" + me.frm.doc.site_i.replaceAll("'", "''") + "' ";
				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_dossier_bom_content_filters_warehouse_id').value = return_value;
					document.getElementById('from_dossier_bom_content_filters_warehouse_id').focus();
					me.action_dedicated_location_search();
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

			}

			document.getElementById('from_dossier_bom_content_filters_warehouse_button').onclick = fct_warehouse_button;


			// Location
			document.getElementById('from_dossier_bom_content_filters_warehouse_location_id').onkeydown = fct_keydown;

			let fct_location_button = function() {

				let title = __("Select a location");
				let form_width_pixel = '710px';
				let table = "ioi Warehouse Location";
				let fields = 'name, description';
				let fields_len = '250, 380';
				let fields_desc = __('Identification') + ',' + __('Description');
				let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";

				if (document.getElementById('from_dossier_bom_content_filters_warehouse_id').value.trim() != '') {
					where += "and warehouse_id = '" + document.getElementById('from_dossier_bom_content_filters_warehouse_id').value.toUpperCase().replaceAll("'", "''") + "' "
				}

				let order_by = 'name asc';

				let fct_callback = function (return_value) {
					document.getElementById('from_dossier_bom_content_filters_warehouse_id').value = return_value.substring(0, return_value.indexOf('•')-1).trim().toUpperCase();
					document.getElementById('from_dossier_bom_content_filters_warehouse_location_id').value = return_value.substring(return_value.indexOf('•')+1, return_value.length).trim().toUpperCase();
					document.getElementById('from_dossier_bom_content_filters_warehouse_location_id').focus();
					me.action_dedicated_location_search();
				}

				me.search_select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

			}

			document.getElementById('from_dossier_bom_content_filters_warehouse_location_button').onclick = fct_location_button;


			// Stored qty mode
			document.getElementById('from_dossier_bom_content_filters_stored_qty_mode').onchange = fct_change;


			// Search
			document.getElementById('from_dossier_bom_content_filters_search').onkeydown = fct_keydown;





			let fct_search = function() {
				me.action_dedicated_location_search();
			}

			document.getElementById('from_dossier_bom_content_filters_button_search').onclick = fct_search;

			let fct_clear = function() {
				me.action_dedicated_location_clear();
			}

			document.getElementById('from_dossier_bom_content_filters_button_clear').onclick = fct_clear;



			let fct_all = function() {
				me.action_dedicated_location_salect_all();
			}

			document.getElementById('from_dossier_bom_action_grid_button_all').onclick = fct_all;



			let fct_none = function() {
				me.action_dedicated_location_select_none();
			}

			document.getElementById('from_dossier_bom_action_grid_button_none').onclick = fct_none;

			this.#sleep(200).then(() => {
				me.action_dedicated_location_search();
			});

		});


	}

	action_dedicated_location_salect_all()
	{
		let me = this;

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({checkbox: '1'});
			}
		}
	}

	action_dedicated_location_select_none()
	{
		let me = this;

		let rows = me.ioiTable.getRows();

		if (rows) {

			for (var i = 0; i < rows.length; i++) {

				rows[i].update({checkbox: '0'});
			}
		}
	}

	action_dedicated_location_search()
	{
		let me = this;

		let data = []
		let error_found = false;

		let method = this.path_stock_output + '.ioi_stock_output_get_dedicated_location_list';

		frappe.call({  	method: method,
						args: {
								"document_date": me.frm.doc.document_date,
								"document_language": 'en',
								"dossier_id": document.getElementById('from_dossier_bom_content_filters_dossier_id').value,
								"warehouse_id": document.getElementById('from_dossier_bom_content_filters_warehouse_id').value,
								"warehouse_location_id": document.getElementById('from_dossier_bom_content_filters_warehouse_location_id').value,
								"stored_qty_mode": document.getElementById('from_dossier_bom_content_filters_stored_qty_mode').value,
								"search": document.getElementById('from_dossier_bom_content_filters_search').value,
								"allow_bad_qty": me.journal_whs_output_allow_bad_qty
						},

						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		if (error_found) {
			return false;
		}

		me.ioiTable.setData(data);

	}

	action_dedicated_location_clear()
	{
		document.getElementById('from_dossier_bom_content_filters_dossier_id').value = '';
		document.getElementById('from_dossier_bom_content_filters_warehouse_id').value = '';
		document.getElementById('from_dossier_bom_content_filters_warehouse_location_id').value = '';
		document.getElementById('from_dossier_bom_content_filters_stored_qty_mode').selectedIndex = 0;
		document.getElementById('from_dossier_bom_content_filters_search').value = '';

		this.ioiTable.clearData();
	}




	remove_html_element(id)
	{
		if (document.getElementById(id)) {
			document.getElementById(id).remove();
		}
	}

}

silicon_ioi.doctype.ioiStockOutputDocumentDocType = ioiStockOutputDocumentDocType;
