// *******************************************************************************************************************************************
// Parent Class of Stock Qualification Document
// *******************************************************************************************************************************************

import { ioiDocumentStatus } from "./ioi_document_status";

frappe.provide('silicon_ioi.doctype');

export class ioiQualifDocumentDocType extends silicon_ioi.doctype.ioiDocType
{

	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_module_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status.ioi_module_status';
	path_module_user_status = 'silicon_ioi.ioi_configuration.doctype.ioi_module_user_status.ioi_module_user_status';
	path_module_status_accessible_fields = 'silicon_ioi.ioi_configuration.doctype.ioi_module_status_accessible_fields.ioi_module_status_accessible_fields';
	path_document_change_status = 'silicon_ioi.common.document_change_status';
    path_currency = 'silicon_ioi.ioi_configuration.doctype.ioi_currency.ioi_currency';
    // path_sales_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_sales_settings.ioi_sales_settings';
    path_warehouse_journal = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_journal.ioi_warehouse_journal';
    path_stock_document = 'silicon_ioi.common.stock_document';
	path_stock_qualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';

	path_purchases_receipt = 'silicon_ioi.ioi_purchases.doctype.ioi_purchases_receipt.ioi_purchases_receipt';
	path_stock_entry = 'silicon_ioi.ioi_wms.doctype.ioi_stock_entry.ioi_stock_entry';

	path_staff = 'silicon_ioi.ioi_enterprise.doctype.ioi_staff.ioi_staff';
	path_period = 'silicon_ioi.ioi_enterprise.doctype.ioi_period.ioi_period';

    path_warehouse = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse.ioi_warehouse';
	path_warehouse_location = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_location.ioi_warehouse_location';
	path_user_change_status_action = 'silicon_ioi.ioi_system.doctype.ioi_user_change_status_action.ioi_user_change_status_action';
	path_item = 'silicon_ioi.ioi_items.doctype.ioi_item.ioi_item';
	path_search_definition = 'silicon_ioi.ioi_configuration.doctype.ioi_module_search_definition.ioi_module_search_definition'

	is_dark_mode = 0;

	user_language = '';
	language_1 = '';
	language_2 = '';
	language_3 = '';
	language_4 = '';

	system_currency = '';
	system_currency_rate = 1;
	system_currency_rate_inv = 1;
	system_currency_digit_rounding = 8;


	// General
	journal_division_id = '';
	journal_site_id = '';
	journal_warehouse_id = '';

	// Qualification
	journal_wms_qualification_prefix = '';
	journal_wms_qualification_last_number = 0;
	journal_wms_qualification_nb_digits = 0;

	journal_wms_qualification_sublevel_separator = '.';
	journal_wms_qualification_sublevel_nb_digits = 0;
	journal_wms_qualification_period_break_position = 1;
	journal_wms_qualification_period_break_numbering = 0;

	journal_wms_qualification_to_warehouse_id = '';
	journal_wms_qualification_allow_receipt_linked = 0;
	journal_wms_qualification_allow_entry_linked = 0;

	journal_wms_qualification_operator_mandatory = 0;

	journal_wms_qualification_from_allow_good_qty = 0;
	journal_wms_qualification_from_allow_tocheck_qty = 0;
	journal_wms_qualification_from_allow_bad_qty = 0;
	journal_wms_qualification_from_allow_locked_batchsn = 0;
	journal_wms_qualification_from_allow_unlocked_batchsn = 0;

	journal_wms_qualification_to_allow_good_qty = 0;
	journal_wms_qualification_to_allow_tocheck_qty = 0;
	journal_wms_qualification_to_allow_bad_qty = 0;
	journal_wms_qualification_to_allow_locked_batchsn = 0;
	journal_wms_qualification_to_allow_unlocked_batchsn = 0;

	journal_wms_qualification_role_limit = 0;
	journal_wms_qualification_disabled = 0;
	journal_wms_qualification_disabled = 0;

	DocumentStatus = Object;
    ioistatus = [];
	ioiuserstatus = [];

    document_type = [];
    prefixes = [];

	static warehouse_location = [];
	static batch_id = [];


	// ***************************************************************************************************************************************
	// Constructor
	// ***************************************************************************************************************************************
	constructor(frm)
	{
		super(frm);

		let me = this;
		this.is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		this.DocumentStatus = new ioiDocumentStatus(frm);
		this.DocumentStatus.fill_status();
		this.ioistatus = this.DocumentStatus.ioistatus;

		setTimeout(silicon_ioi.doctype.ioiQualifDocumentDocType.set_static_grid_layout(), 500)
	}


	// ***************************************************************************************************************************************
	// Can access to the module
	// ***************************************************************************************************************************************
	can_access_to_document(doctype, document_type, prefix_id)
	{
		let can_access = false;

		let me = this;

		let method = this.path_stock_document + '.ioi_stock_document_can_access_to_document';

		frappe.call({  	method: method,
						args: {	"doctype" : doctype,
								"document_type": document_type,
								"prefix_id": prefix_id},
						async: false,
						callback:function(r)	{

							if (r.message == 1) {
								can_access = true;
							}

						}
		});

		return can_access;
	}



	// ***************************************************************************************************************************************
	// Load General Settings
	// ***************************************************************************************************************************************
	load_general_settings()
	{
		let me = this;

		let method = this.path_general_settings + '.ioi_general_settings_get_data';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
													me.system_currency = r.message.currency;

													if (me.system_currency.trim() != '')
													{
														method = me.path_currency + '.ioi_currency_get_data';

														frappe.call({  	method: method,
																		args: {"currency_id": me.system_currency},
																		async: false,
																		callback:function(r)	{
                                                                                                    me.language_1 = r.message.language_1;
                                                                                                    me.language_2 = r.message.language_2;
                                                                                                    me.language_3 = r.message.language_3;
                                                                                                    me.language_4 = r.message.language_4;
																									me.system_currency_rate = r.message.sales_rate;
																									me.system_currency_rate_inv = r.message.sales_rate_inv;
																									me.system_currency_digit_rounding = r.message.decimals;
																								}
														});
													}
						}
		});
	}


	// ***************************************************************************************************************************************
	// Load Warehouse Journal data
	// ***************************************************************************************************************************************
	load_warehouse_journal_data()
	{
		if ((this.frm.doc.journal_id) && (this.frm.doc.journal_id.trim() != ''))
		{
			let me = this;

			let method = this.path_warehouse_journal + '.ioi_warehouse_journal_get_data';

			frappe.call({  	method: method,
							args: { "journal_id": this.frm.doc.journal_id},
							async: false,
							callback:function(r)	{

								me.user_language = r.message.user_language;


								me.journal_division_id = r.message.division_id;
								me.journal_site_id = r.message.site_id;


								me.journal_warehouse_id = r.message.warehouse_id;

								me.journal_wms_qualification_prefix = r.message.wms_qualification_prefix;
								me.journal_wms_qualification_last_number = r.message.wms_qualification_last_number;
								me.journal_wms_qualification_nb_digits = r.message.wms_qualification_nb_digits;

								me.journal_wms_qualification_sublevel_separator = r.message.wms_qualification_sublevel_separator;
								me.journal_wms_qualification_sublevel_nb_digits = r.message.wms_qualification_sublevel_nb_digits;
								me.journal_wms_qualification_period_break_position = r.message.wms_qualification_period_break_position;
								me.journal_wms_qualification_period_break_numbering = r.message.wms_qualification_period_break_numbering;

								me.journal_wms_qualification_to_warehouse_id = r.message.wms_qualification_to_warehouse_id;
								me.journal_wms_qualification_allow_receipt_linked = r.message.wms_qualification_allow_receipt_linked;
								me.journal_wms_qualification_allow_entry_linked = r.message.wms_qualification_allow_entry_linked;

								me.journal_wms_qualification_operator_mandatory = r.message.wms_qualification_operator_mandatory;

								me.journal_wms_qualification_from_allow_good_qty = r.message.wms_qualification_from_allow_good_qty;
								me.journal_wms_qualification_from_allow_tocheck_qty = r.message.wms_qualification_from_allow_tocheck_qty;
								me.journal_wms_qualification_from_allow_bad_qty = r.message.wms_qualification_from_allow_bad_qty;
								me.journal_wms_qualification_from_allow_locked_batchsn = r.message.wms_qualification_from_allow_locked_batchsn;
								me.journal_wms_qualification_from_allow_unlocked_batchsn = r.message.wms_qualification_from_allow_unlocked_batchsn;

								me.journal_wms_qualification_to_allow_good_qty = r.message.wms_qualification_to_allow_good_qty;
								me.journal_wms_qualification_to_allow_tocheck_qty = r.message.wms_qualification_to_allow_tocheck_qty;
								me.journal_wms_qualification_to_allow_bad_qty = r.message.wms_qualification_to_allow_bad_qty;
								me.journal_wms_qualification_to_allow_locked_batchsn = r.message.wms_qualification_to_allow_locked_batchsn;
								me.journal_wms_qualification_to_allow_unlocked_batchsn = r.message.wms_qualification_to_allow_unlocked_batchsn;

								me.journal_wms_qualification_role_limit = r.message.wms_qualification_role_limit;
								me.journal_wms_qualification_disabled = r.message.wms_qualification_disabled;
								me.journal_wms_qualification_disabled = r.message.wms_qualification_disabled;


							}
			});
		}
	}

	// ***************************************************************************************************************************************
	// Load User Status
	// ***************************************************************************************************************************************
	fill_ioiuserstatus()
	{
		this.ioiuserstatus = [];

		let me = this;

		let method = this.path_module_user_status + '.ioi_module_user_status_get_list';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length != 0)
													{
														me.ioiuserstatus = [];

														for (var i = 0; i < r.message.length; i++)
														{	//                    code            description       bgcolor          fontcolor
															me.ioiuserstatus[i] = [r.message[i][2],r.message[i][5], r.message[i][3], r.message[i][4]];

														}
													}

													if (me.ioiuserstatus.length == 0)
													{	me.ioiuserstatus[0] = [0, 'Undefined', '#C3C2C4', '#000000'];
													}else
													{
														let found_zero = false;

														for (var i = 0; i < me.ioiuserstatus.length; i++)
														{
															if (me.ioiuserstatus[i][0] == 0)
															{
																found_zero = true;
																break;
															}
														}

														if (!found_zero)
														{	me.ioiuserstatus[me.ioiuserstatus.length] = [0, 'Undefined', '#C3C2C4', '#000000'];
														}
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Refresh ioiuserstatus (User Status)
	// ***************************************************************************************************************************************
	refresh_ioiuserstatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		let cur_userstatus = 0;

		if (this.frm.doc.ioiuserstatus)
		{	cur_userstatus = this.frm.doc.ioiuserstatus;
		}

		for (var i = 0; i < this.ioiuserstatus.length; i++)
		{

			if (cur_userstatus == this.ioiuserstatus[i][0])
			{
				description = this.ioiuserstatus[i][1];
				bgcolor = this.ioiuserstatus[i][2];
				color = this.ioiuserstatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("User status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';

		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioiuserstatus'].$wrapper.append(html);

	}


	// ***************************************************************************************************************************************
	// Change user Status
	// ***************************************************************************************************************************************
	change_user_status()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_change_user_status(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_change_user_status();
		}
	}

	do_change_user_status()
	{

		let me = this;

		var z = new frappe.ui.Dialog({
			'title': __("User status"),
			'fields': [
				{'fieldname': 'html_select_user_status', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let go = true;

				if (document.getElementById('user_status_nb_record').value == 0)
				{
					go = false;
				}

				if ((go) && (document.getElementById('user_status_nb_record').value != 0))
				{	go = false
					for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
					{

						if (document.getElementById('user_status_checked_id_' + i.toString()))
						{
							if (document.getElementById('user_status_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_user_status').value = document.getElementById('user_status_id_' + i.toString()).value;
								go = true;
								break;
							}
						}
					}
				}

				if (go)
				{
					z.hide();

					method = me.path_module_user_status + '.ioi_module_user_status_change_status';

					let can_change = false;

					let cur_user_status = 0;

					if (me.frm.doc.ioiuserstatus)
					{
						cur_user_status = me.frm.doc.ioiuserstatus;
					}

					frappe.call({  	method: method,
									args: {"doctype": me.frm.doctype, "name" : me.frm.doc.name, "from_status" : cur_user_status, "to_status" : document.getElementById('selected_user_status').value, "silent_mode" : true },
									async: false,
										callback:function(r)	{
																		can_change = true;
																}
					});

					if (can_change)
					{
						window.location.reload();
					}
				}else{
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
				}
			},
			secondary_action: function(){
				z.hide();
			}

		});

		if (document.getElementById('user_status_nb_record'))
		{
			for (var i = 0; i < document.getElementById('user_status_nb_record').value; i++)
			{
				if (document.getElementById('user_status_checked_id_' + i.toString()))
				{
					document.getElementById('user_status_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('user_status_id_' + i.toString()))
				{
					document.getElementById('user_status_id_' + i.toString()).remove();
				}

			}

			document.getElementById('user_status_nb_record').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:300px; width: 100%;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=100% data-custom-grid="true">';

		html += '<tr style="height:30px">';

		html += '<td width=5% align="center" style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=95% style="vertical-align: middle;">&nbsp;<b>' + __("User status") + '</b></td>';
		html += '</tr>';
		html += '</table>';



		let method = me.path_module_user_status + '.ioi_module_user_status_get_list';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (r.message.length > 0)
													{
														html += '<table border=1 style="border: 1px solid #E8EAEB" width=100%>';

														let cur_userstatus = 0;

														if (me.frm.doc.ioiuserstatus)
														{
															cur_userstatus = me.frm.doc.ioiuserstatus;
														}

														let cpt = 0


														for (var i = 0; i < r.message.length; i++)
														{
															if (cur_userstatus != r.message[i][2])
															{
																html += '<tr style="height:30px">';

																html += '<td width=5% align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="user_status_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'user_status_nb_record\').value; i++) ';
																html += '					{   if (document.getElementById(\'user_status_checked_id_\' + i.toString())) ';
																html += '						{ '
																html += '							if (document.getElementById(\'user_status_checked_id_\' + i.toString()).id != this.id) ';
																html += '							{ ';
																html += '								document.getElementById(\'user_status_checked_id_\' + i.toString()).checked = false; ';
																html += '							} ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																html += '>';
																html += '<input type="hidden" id="user_status_id_' + cpt.toString() + '" value="' +  r.message[i][2] + '">';
																html += '</td>';

																html += '<td bgcolor="' + r.message[i][3] + '" width=95% style="vertical-align: middle;">&nbsp;<font color="' + r.message[i][4] + '">' + r.message[i][5] + ' ( ' + r.message[i][2] + ' )' + '</font></td>'

																html += '</tr>';
															}

															cpt++;
														}
														html += '<input type="hidden" id="user_status_nb_record" value="' + cpt.toString() + '">';
														html += '</table>';
													}else
													{
														html += '<input type="hidden" id="user_status_nb_record" value="0">';
													}
												}
		});

		html += '<input type="hidden" id="selected_user_status" value="">';
		html += '</div>';


		z.fields_dict.html_select_user_status.$wrapper.html(html);

		z.show();
	}


	// ***************************************************************************************************************************************
	// Fill Period from document date
	// ***************************************************************************************************************************************
	fill_period()
	{
		if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.toString().trim() == '')))
		{
			this.frm.doc.period_id = '';
			this.frm.refresh_field('period_id');
		}else
		{
			let me = this;
			let method = this.path_stock_document + '.ioi_stock_document_get_period_from_date';

			let division = this.journal_division_id;

			if ((!division) || (division.trim() == '')) {
				division = silicon_ioi.ioiAccUtils.get_main_division_id();
			}
			this.frm.doc.division_id = division;

			frappe.call({  	method: method,
							args: {"division_id" : division, "document_date" : this.frm.doc.document_date},
							async: false,
							callback:function(r)	{
															if (r.message.length == 0)
															{
																me.frm.doc.period_id = '';
															}else
															{
																me.frm.doc.period_id = r.message[0].name;
															}
													}
			});

			this.frm.refresh_field('period_id');
		}


	}


	// ***************************************************************************************************************************************
	// Fill Document type
	// ***************************************************************************************************************************************
	fill_document_type()
	{
		this.document_type = [];

		this.document_type[0] = ['STOCK_QUALIFICATION', __('Stock Qualification')];
		this.frm.set_df_property('document_type', 'options', [	{ label: this.document_type[0][1], value: this.document_type[0][0]}]);

	}

	// ***************************************************************************************************************************************
	// Assign query to Link Fields
	// ***************************************************************************************************************************************
    assign_query_to_link_fields()
    {
		this.set_query('period_id', '', this.path_stock_document + '.ioi_stock_document_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');


		this.set_query('author_id', '', this.path_stock_document + '.ioi_sales_document_get_user');
		this.set_query('responsible_id', '', this.path_stock_document + '.ioi_sales_document_get_user');
		this.set_query('operator_id', '', this.path_staff + '.ioi_staff_get_enabled');




		this.set_query('from_warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');
		this.set_query('to_warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

		this.set_query('from_warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');
		this.set_query('to_warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

		this.set_query('from_warehouse_location_id', 'line_detail', this.path_warehouse_location + '.ioi_warehouse_location_get_enabled', '');
		this.set_query('to_warehouse_location_id', 'line_detail', this.path_warehouse_location + '.ioi_warehouse_location_get_enabled', '');



		this.set_query('purchases_receipt_id', '', this.path_purchases_receipt + '.ioi_purchases_receipt_get_enabled', '{"ioistatus": ["in", "1,2,3,4"]}');
		this.set_query('stock_entry_id', '', this.path_stock_entry + '.ioi_stock_entry_get_enabled', '{"ioistatus":  ["in", "1,3"]}');

		this.set_query('purchases_receipt_id', 'line_detail', this.path_purchases_receipt + '.ioi_purchases_receipt_get_enabled', '{"ioistatus": ["in", "1,2,3,4"]}');
		this.set_query('stock_entry_id', 'line_detail', this.path_stock_entry + '.ioi_stock_entry_get_enabled',  '{"ioistatus":  ["in", "1,3"]}');

		this.build_header_buttons('PR', 'html_purchases_receipt_buttons');
		this.build_header_buttons('SE', 'html_stock_entry_buttons');


		this.set_query('item_id', 'line_detail', this.path_search_definition + '.set_search_definition_filters');


		this.frm.fields_dict['line_detail'].grid.update_docfield_property("from_stored_qty_mode", "options", [	{ label: __("Good"), value: 0},
																												{ label: __("To check"), value: 1},
																												{ label: __("Bad"), value: 2}
		]);


		frappe.meta.get_docfield('ioi Stock Qualification Detail', 'from_stored_qty_mode').options = [
			{ label: __("Good"), value: 0},
			{ label: __("To check"), value: 1},
			{ label: __("Bad"), value: 2}
		];

		this.frm.fields_dict['line_detail'].grid.update_docfield_property("to_stored_qty_mode", "options", [	{ label: __("Good"), value: 0},
																												{ label: __("To check"), value: 1},
																												{ label: __("Bad"), value: 2}
		]);


		frappe.meta.get_docfield('ioi Stock Qualification Detail', 'to_stored_qty_mode').options = [
			{ label: __("Good"), value: 0},
			{ label: __("To check"), value: 1},
			{ label: __("Bad"), value: 2}
		];

		this.frm.fields_dict['line_detail'].grid.update_docfield_property("to_batch_sn_locking_mode", "options", [	{ label: __("Unlocked"), value: 0},
																													{ label: __("Locked"), value: 1},
		]);

		frappe.meta.get_docfield('ioi Stock Qualification Detail', 'to_batch_sn_locking_mode').options = [
			{ label: __("Unlocked"), value: 0},
			{ label: __("Locked"), value: 1},
		];

		let me = this;



		let fct_search_responsible = function() {

			let title = __("Select a responsible");
			let form_width_pixel = '710px';
			let table = "User";
			let fields = 'name, full_name';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Full name');
			let where = '';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('responsible_id', return_value);
				me.frm.refresh_field('responsible_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['responsible_id'].open_advanced_search = fct_search_responsible;



		let fct_search_period = function() {

			let title = __("Select a period");
			let form_width_pixel = '600px';
			let table = "ioi Period";
			let fields = 'name';
			let fields_len = '500';
			let fields_desc = __('Period');
			let where = "division = '" + me.frm.doc.division_id.replaceAll("'", "''") + "' and enabled = 1 ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('period_id', return_value);
				me.frm.refresh_field('period_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['period_id'].open_advanced_search = fct_search_period;



		let fct_search_from_warehouse = function() {

			let title = __("Select a warehouse");
			let form_width_pixel = '710px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('from_warehouse_id', return_value);
				me.frm.refresh_field('from_warehouse_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['from_warehouse_id'].open_advanced_search = fct_search_from_warehouse;

		let fct_search_to_warehouse = function() {

			let title = __("Select a warehouse");
			let form_width_pixel = '710px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '250, 380';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {
				me.frm.set_value('to_warehouse_id', return_value);
				me.frm.refresh_field('to_warehouse_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['to_warehouse_id'].open_advanced_search = fct_search_to_warehouse;


		let fct_search_purchase_receipt = function() {

			let title = __("Select a purchase receipt");
			let form_width_pixel = '1100px';
			let table = "ioi Purchases Receipt";
			let fields = 'name, document_date, delivery_supplier_id, external_ref, total_htva, currency_id';
			let fields_len = '250, 120, 200, 250, 120, 80';
			let fields_desc = __('Identification') + ',' + __('Doc. date') + ',' + __('Supplier') + ',' + __('External ref.') + ',' + __('Total wo VAt') + ',' + __('Currency');
			let where = 'ioistatus > 0 ';
			let order_by = 'name asc';


			let fct_callback = function (return_value) {
				me.frm.set_value('purchases_receipt_id', return_value);
				me.frm.refresh_field('purchases_receipt_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['purchases_receipt_id'].open_advanced_search = fct_search_purchase_receipt;

		let fct_search_stock_entry = function() {

			let title = __("Select a stock entry");
			let form_width_pixel = '1200px';
			let table = "ioi Stock Entry";
			let fields = 'name, document_date, external_ref, production_id, dossier_id';
			let fields_len = '250, 120, 250, 250, 250';
			let fields_desc = __('Identification') + ',' + __('Doc. date') + ',' + __('External ref.') + ',' + __('Production') + ',' + __('Dossier');
			let where = 'ioistatus > 0 ';
			let order_by = 'name asc';


			let fct_callback = function (return_value) {
				me.frm.set_value('stock_entry_id', return_value);
				me.frm.refresh_field('stock_entry_id');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)

		}

		this.frm.fields_dict['stock_entry_id'].open_advanced_search = fct_search_stock_entry;


		let fct_search_detail_purchase_receipt = function () {

			let title = __("Select a purchase receipt");
			let form_width_pixel = '1100px';
			let table = "ioi Purchases Receipt";
			let fields = 'name, document_date, delivery_supplier_id, external_ref, total_htva, currency_id';
			let fields_len = '250, 120, 200, 250, 120, 80';
			let fields_desc = __('Identification') + ',' + __('Doc. date') + ',' + __('Supplier') + ',' + __('External ref.') + ',' + __('Total wo VAt') + ',' + __('Currency')
			let where = 'ioistatus > 0 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.purchases_receipt_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("purchases_receipt_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
				me.format_detail_form(me.frm, cdt, cdn);

			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("purchases_receipt_id").open_advanced_search = fct_search_detail_purchase_receipt;



		let fct_search_detail_stock_entry = function () {

			let title = __("Select a stock entry");
			let form_width_pixel = '1200px';
			let table = "ioi Stock Entry";
			let fields = 'name, document_date, external_ref, production_id, dossier_id';
			let fields_len = '250, 120, 250, 250, 250';
			let fields_desc = __('Identification') + ',' + __('Doc. date') + ',' + __('External ref.') + ',' + __('Production') + ',' + __('Dossier');
			let where = 'ioistatus > 0 ';
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.stock_entry_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("stock_entry_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
				me.format_detail_form(me.frm, cdt, cdn);

			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("stock_entry_id").open_advanced_search = fct_search_detail_stock_entry;



		let fct_search_detail_from_warehouse = function () {

			let title = __("Select a warehouse");
			let form_width_pixel = '580px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.from_warehouse_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("from_warehouse_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("from_warehouse_id").open_advanced_search = fct_search_detail_from_warehouse;


		let fct_search_detail_to_warehouse = function () {

			let title = __("Select a warehouse");
			let form_width_pixel = '580px';
			let table = "ioi Warehouse";
			let fields = 'name, description';
			let fields_len = '200, 300';
			let fields_desc = __('Identification') + ',' + __('Description');
			let where = "enabled = 1 and site_id = '" + me.frm.doc.site_id.replaceAll("'", "''") + "' ";
			let order_by = 'name asc';

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let line = locals[cdt][cdn];
				line.to_warehouse_id = return_value;

				let script_manager = me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].frm.script_manager;
				script_manager.trigger("to_warehouse_id", me.frm.fields_dict['line_detail'].grid.doctype, me.frm.fields_dict['line_detail'].grid.grid_rows[line.idx - 1].doc.name);
				me.frm.refresh_field('line_detail');
			}

			silicon_ioi.ioiCommon.select_form(title, form_width_pixel, table, fields, fields_len, fields_desc, where, order_by, fct_callback)
		};

		this.frm.get_field("line_detail").grid.get_field("to_warehouse_id").open_advanced_search = fct_search_detail_to_warehouse;



	}

	// ***************************************************************************************************************************************
	// Set query
	// ***************************************************************************************************************************************
	set_query(fieldname, childfieldname, method, filter = '')
	{
		if (childfieldname.trim() == '')
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method,
								filters : JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, () => {
					return  {   query: method
							}
				});

			}
		}else
		{
			if (filter.trim() != '')
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method,
								filters: JSON.parse(filter)
							}
				});
			}else
			{
				this.frm.set_query(fieldname, childfieldname, () => {
					return  {   query: method
							}
				});

			}
		}
	}

	build_header_buttons(tp, fieldname)
	{
		let html = '';
		this.frm.fields_dict[fieldname].$wrapper.empty();

		let button_generate_caption = ''

		if (tp.toUpperCase() == 'PR') {
			button_generate_caption = __("Get Puch. Receipt lines");

		}else if (tp.toUpperCase() == 'SE') {
			button_generate_caption = __("Get Stock Entry lines")
		}


		if (document.getElementById(fieldname + '_button_generate')) {
			document.getElementById(fieldname + '_button_generate').remove();
		}

		if (document.getElementById(fieldname + '_button_remove')) {
			document.getElementById(fieldname + '_button_remove').remove();
		}

		html += '<div style="overflow: auto; overflow-x: auto; height:45px;">';

		// Generate button
		html += '	<div style="position: relative; top: 2px; left: 2px; width:200px;">';
		html += '		<div style="position: absolute; top:0px; left: 0px; height: 30px">';
		html +='			<button id="' + fieldname + '_button_generate" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + button_generate_caption + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Remove button
		html += '	<div style="position: relative; top: 2px; left: 212px; width:200px;">';
		html += '		<div style="position: absolute; top:0px; left: 0px; height: 30px">';
		html +='			<button id="' + fieldname + '_button_remove" class="btn btn-default ellipsis" style="height: 30px; width: 200px;" onclick="">' + __("Remove from detail")  + '</button>';
		html += '		</div>';
		html += '	</div>';

		html += '</div>';



		this.frm.fields_dict[fieldname].$wrapper.append(html);

		let me = this;

		this.#sleep(250).then(() => {

			let fct_generate_click = function() {

				if (tp.toUpperCase() == 'PR') {
					me.get_purchases_receipt_lines();
				}else{
					me.get_stock_entry_lines();

				}

			}
			document.getElementById(fieldname + '_button_generate').onclick = fct_generate_click;


			let fct_remove_click = function() {

				if (tp.toUpperCase() == 'PR') {
					me.remove_purchases_receipt_in_detail();
				}else{
					me.remove_stock_entry_in_detail();
				}

			}

			document.getElementById(fieldname + '_button_remove').onclick = fct_remove_click;

		});
	}



	get_purchases_receipt_lines()
	{
		let me = this;

		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {

			if ((!this.frm.doc.purchases_receipt_id) || ((this.frm.doc.purchases_receipt_id) && (this.frm.doc.purchases_receipt_id.trim() == ''))) {

				frappe.msgprint({title: __("Message"), message: __('Purchases Receipt has to be filled'), indicator: "red"});
				return false;
			}

			let callback = function(return_values) {

				me.generate_receipt_entry_lines('PR', return_values);

			}

			this.select_receipt_entry_detail_form('PR', this.frm.doc.purchases_receipt_id, null, true, callback);

		}else{
			frappe.msgprint({title: __("Message"), message: __('Stock qualification has to be in "Preparation"'), indicator: "red"});
		}
	}

	remove_purchases_receipt_in_detail()
	{
		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {

			if (!this.frm.doc.line_detail) {
				frappe.msgprint({title: __("Message"), message: __('No line to remove'), indicator: "red"});
				return false;
			}

			if ((!this.frm.doc.purchases_receipt_id) || ((this.frm.doc.purchases_receipt_id) && (this.frm.doc.purchases_receipt_id.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __('Purchases Receipt has to be filled'), indicator: "red"});
				return false;
			}

			let top = 10;

			let msg = '';

			let s = __('Remove qualification lines related to this Purchases Receipt ?');

			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';

			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';

			let me = this;

			frappe.confirm(	msg,
							() => 	{	me.#sleep(200).then(() => {

											me.do_remove_purchases_receipt_in_detail();
										});
									},
							() => 	{}
			);

		}else{
			frappe.msgprint({title: __("Message"), message: __('Document has to be in "Preparation"'), indicator: "red"});
		}
	}

	do_remove_purchases_receipt_in_detail()
	{

		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {
			for (var i = this.frm.doc.line_detail.length-1; i >= 0; i--) {

				if (this.frm.doc.line_detail[i].purchases_receipt_id == this.frm.doc.purchases_receipt_id) {
					this.frm.get_field('line_detail').grid.grid_rows[i].remove();
				}
			}
		}

	}

	get_stock_entry_lines()
	{
		let me = this;

		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {

			if ((!this.frm.doc.stock_entry_id) || ((this.frm.doc.stock_entry_id) && (this.frm.doc.stock_entry_id.trim() == ''))) {

				frappe.msgprint({title: __("Message"), message: __('Stock entry has to be filled'), indicator: "red"});
				return false;
			}

			let callback = function(return_values) {

				me.generate_receipt_entry_lines('SE', return_values);

			}

			this.select_receipt_entry_detail_form('SE', this.frm.doc.stock_entry_id, null, true, callback);

		}else{
			frappe.msgprint({title: __("Message"), message: __('Stock qualification has to be in "Preparation"'), indicator: "red"});
		}
	}

	remove_stock_entry_in_detail()
	{
		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {


			if (!this.frm.doc.line_detail) {
				frappe.msgprint({title: __("Message"), message: __('No line to remove'), indicator: "red"});
				return false;
			}

			if ((!this.frm.doc.stock_entry_id) || ((this.frm.doc.stock_entry_id) && (this.frm.doc.stock_entry_id.trim() == ''))) {
				frappe.msgprint({title: __("Message"), message: __('Stock Entry has to be filled'), indicator: "red"});
				return false;
			}

			let top = 10;

			let msg = '';

			let s = __('Remove qualification lines related to this Stock Entry ?');

			msg += '<label style="position: absolute; top: ' + (top+10).toString() + 'px; left: 80px;"> ' + s + '</label>';

			top += 20;
			msg = '<div style="overflow: auto; overflow-x: auto; height:' + top.toString() + 'px;" align="center">' + msg + '</div>';

			let me = this;

			frappe.confirm(	msg,
							() => 	{	me.#sleep(200).then(() => {

											me.do_remove_stock_entry_in_detail();
										});
									},
							() => 	{}
			);

		}else{
			frappe.msgprint({title: __("Message"), message: __('Document has to be in "Preparation"'), indicator: "red"});
		}
	}

	do_remove_stock_entry_in_detail()
	{
		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {
			for (var i = this.frm.doc.line_detail.length-1; i >= 0; i--) {

				if (this.frm.doc.line_detail[i].stock_entry_id == this.frm.doc.stock_entry_id) {
					this.frm.get_field('line_detail').grid.grid_rows[i].remove();
				}
			}
		}

	}

	generate_receipt_entry_lines(tp, return_values)
	{
		if (return_values.length > 0) {


			for (var i = 0; i < return_values.length; i++) {

				let method = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';
				let parameters = {}

				if (tp.toUpperCase() == 'PR') {
					method += '.ioi_stock_qualification_get_purchases_receipt_line_data';

					parameters = { 	"purchases_receipt_id" : this.frm.doc.purchases_receipt_id,
									"purchases_receipt_detail_id" : return_values[i]
								};
				}else{
					method += '.ioi_stock_qualification_get_stock_entry_line_data';

					parameters = { 	"stock_entry_id" : this.frm.doc.stock_entry_id,
									"stock_entry_detail_id" : return_values[i]
								};
				}

				let data = [];

				frappe.call({  	method: method,
								args: parameters,
								async: false,
								callback:function(r)	{
									data = r.message;
								}
				});


				let line = this.frm.add_child('line_detail');
				this.line_detail_add(this.frm, line.doctype, line.name);

				line.item_id = data[0].item_id;
				line.unit_id = data[0].unit_id;
				line.from_stored_qty_mode = data[0].stored_qty_mode;

				if (line.from_stored_qty_mode == 0) {
					line.to_stored_qty_mode = 1;
				}else if (line.from_stored_qty_mode == 1) {
					line.to_stored_qty_mode = 0;
				}else{
					line.to_stored_qty_mode = 1;
				}

				line.from_qty = data[0].delivered_qty;
				line.to_qty = data[0].delivered_qty;

				if (tp.toUpperCase() == 'PR') {
					line.purchases_receipt_id = data[0].parent;
					line.purchases_receipt_detail_id = data[0].name;
					line.stock_entry_id = '';
					line.stock_entry_detail_id = '';
				}else{
					line.stock_entry_id = data[0].parent;
					line.stock_entry_detail_id = data[0].name;
					line.purchases_receipt_id = '';
					line.purchases_receipt_detail_id = '';
				}

				this.populate_line(this.frm, line.doctype, line.name);

				line.from_warehouse_id = data[0].warehouse_id;
				line.from_warehouse_location_id = data[0].warehouse_location_id;

				line.to_warehouse_id = data[0].warehouse_id;
				line.to_warehouse_location_id = data[0].warehouse_location_id;

				line.batch_sn_id = data[0].batch_sn_id;

				if ((line.batch_sn_id) && (line.batch_sn_id.trim() != '')) {

					let pathstockqualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';

					let method = pathstockqualification + '.ioi_stock_qualification_get_batchsn_cost';

					frappe.call({  	method: method,
									args: {	"batch_sn_id" : line.batch_sn_id
									},
									async: false,
									callback:function(r)	{

										line.unit_stock_base_qualif_cost = r.message.cost;

									}
					});


					this.frm.refresh_field('line_detail');
					this.update_costing(this.frm, line.doctype, line.name);
				}
			}

			this.frm.refresh_field('line_detail');
		}
	}



	build_detail_buttons(tp, fieldname, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form) {

			let html = '';

			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();


			if (document.getElementById(fieldname + '_button_select')) {
				document.getElementById(fieldname + '_button_select').remove();
			}

			if (document.getElementById(fieldname + '_button_clear')) {
				document.getElementById(fieldname + '_button_clear').remove();
			}

			html += '<div style="overflow: auto; overflow-x: auto; height:45px;">';

			// Select button
			html += '	<div style="position: relative; top: 2px; left: 2px; width:120px;">';
			html += '		<div style="position: absolute; top:0px; left: 0px; height: 30px">';
			html +='			<button id="' + fieldname + '_button_select" class="btn btn-default ellipsis" style="height: 30px; width: 120px;" onclick="">' + __("Select") + '</button>';
			html += '		</div>';
			html += '	</div>';

			// Remove button
			html += '	<div style="position: relative; top: 2px; left: 132px; width:120px;">';
			html += '		<div style="position: absolute; top:0px; left: 0px; height: 30px">';
			html +='			<button id="' + fieldname + '_button_clear" class="btn btn-default ellipsis" style="height: 30px; width: 120px;" onclick="">' + __("Clear")  + '</button>';
			html += '		</div>';
			html += '	</div>';

			html += '</div>';

			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

			let me = this;

			this.#sleep(250).then(() => {

				let fct_select_click = function() {

					if (tp.toUpperCase() == 'PR') {
						me.select_purchases_receipt_line(cdt, cdn);
					}else{
						me.select_stock_entry_line(cdt, cdn);

					}

				}
				document.getElementById(fieldname + '_button_select').onclick = fct_select_click;


				let fct_clear_click = function() {

					if (tp.toUpperCase() == 'PR') {
						me.clear_purchases_receipt_in_detail(cdt, cdn);
					}else{
						me.clear_stock_entry_in_detail(cdt, cdn);
					}

				}

				document.getElementById(fieldname + '_button_clear').onclick = fct_clear_click;

			});
		}
	}

	select_purchases_receipt_line(cdt, cdn)
	{

		let line = locals[cdt][cdn];

		let me = this;

		if ((!line.ioistatus) || (line.ioistatus == 0)) {

			if ((!line.purchases_receipt_id) || ((line.purchases_receipt_id) && (line.purchases_receipt_id.trim() == ''))) {

				frappe.msgprint({title: __("Message"), message: __('Purchases receipt has to be filled'), indicator: "red"});
				return false;
			}

			let callback = function(return_values) {

				me.update_line('PR', cdt, cdn, return_values);
			}

			this.select_receipt_entry_detail_form('PR', line.purchases_receipt_id, line.purchases_receipt_detail_id, false, callback);
		}



	}

	clear_purchases_receipt_in_detail(cdt, cdn)
	{
		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {

			let line = locals[cdt][cdn];

			line.purchases_receipt_detail_id = '';
			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_purchases_receipt_detail'].$wrapper.empty();
			this.frm.refresh_field('line_detail');
			this.format_detail_form(this.frm, cdt, cdn);
		}

	}

	select_stock_entry_line(cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let me = this;

		if ((!line.ioistatus) || (line.ioistatus == 0)) {

			if ((!line.stock_entry_id) || ((line.stock_entry_id) && (line.stock_entry_id.trim() == ''))) {

				frappe.msgprint({title: __("Message"), message: __('Stock entry has to be filled'), indicator: "red"});
				return false;
			}

			let callback = function(return_values) {

				me.update_line('SE', cdt, cdn, return_values);

			}

			this.select_receipt_entry_detail_form('SE', line.stock_entry_id, line.stock_entry_detail_id, false, callback);

		}
	}

	update_line(tp, cdt, cdn, return_values)
	{
		let line = locals[cdt][cdn];

		if (return_values) {
			if (return_values.length > 0) {

				let pathstockqualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';

				let method = pathstockqualification;

				if (tp.toUpperCase() == 'PR') {
					method += '.ioi_stock_qualification_get_purchases_receipt_line_data';
				}else{
					method += '.ioi_stock_qualification_get_stock_entry_line_data';
				}

				let parameters = {}

				if (tp.toUpperCase() == 'PR') {
					parameters = { 	"purchases_receipt_id" : line.purchases_receipt_id,
									"purchases_receipt_detail_id" : return_values[0]
								};
				}else{
					parameters = { 	"stock_entry_id" : line.stock_entry_id,
									"stock_entry_detail_id" : return_values[0]
								};
				}

				let me = this;


				frappe.call({  	method: method,
								args: parameters,
								async: false,
								callback:function(r)	{

									line.item_id = r.message[0].item_id;
									line.unit_id = r.message[0].unit_id;
									line.from_stored_qty_mode = r.message[0].stored_qty_mode;

									if (line.from_stored_qty_mode == 0) {
										line.to_stored_qty_mode = 1;
									}else if (line.from_stored_qty_mode == 1) {
										line.to_stored_qty_mode = 0;
									}else{
										line.to_stored_qty_mode = 1;
									}

									line.from_qty = r.message[0].delivered_qty;
									line.to_qty = r.message[0].delivered_qty;

									if (tp.toUpperCase() == 'PR') {
										line.purchases_receipt_detail_id = r.message[0].name;
									}else{
										line.stock_entry_detail_id = r.message[0].name;
									}

									me.populate_line(me.frm, cdt, cdn);

									line.from_warehouse_id = r.message[0].warehouse_id;
									line.from_warehouse_location_id = r.message[0].warehouse_location_id;

									line.to_warehouse_id = r.message[0].warehouse_id;
									line.to_warehouse_location_id = r.message[0].warehouse_location_id;

									line.batch_sn_id = r.message[0].batch_sn_id;

									if ((line.batch_sn_id) && (line.batch_sn_id.trim() != '')) {

										let pathstockqualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';

										let method = pathstockqualification + '.ioi_stock_qualification_get_batchsn_cost';

										frappe.call({  	method: method,
														args: {	"batch_sn_id" : line.batch_sn_id
														},
														async: false,
														callback:function(r)	{

															line.unit_stock_base_qualif_cost = r.message.cost;

														}
										});


										me.frm.refresh_field('line_detail');
										me.update_costing(me.frm, cdt, cdn);
									}

									me.frm.refresh_field('line_detail');
									me.format_detail_form(me.frm, cdt, cdn);

								}
							});

			}
		}
	}

	clear_stock_entry_in_detail(cdt, cdn)
	{
		if ((!this.frm.doc.ioistatus) || (this.frm.doc.ioistatus == 0)) {

			let line = locals[cdt][cdn];

			line.stock_entry_detail_id = '';
			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_stock_entry_detail'].$wrapper.empty();
			this.frm.refresh_field('line_detail');
			this.format_detail_form(this.frm, cdt, cdn);
		}
	}

	select_receipt_entry_detail_form(tp, document_id, document_detail_id, multiselect = false, callback = null)
	{

		let me = this;


		let title = '';

		if (tp.toUpperCase() == 'PR') {
			title = __("Purchases Receipt") + ' : ' + document_id;
		}else{
			title = __("Stock Entry") + ' : ' + document_id;
		}
		var z = new frappe.ui.Dialog({
			'title': title,
			'fields': [
				{'fieldname': 'html_select_document_lines', 'fieldtype': 'HTML'}

			],
			primary_action_label: 'Ok',
			secondary_action_label: __('Cancel'),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				if (!document.getElementById('html_select_document_lines_table_lines')) {

					frappe.msgprint({title: __("Message"), message: __('No selected data'), indicator: "red"});
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					return false;
				}

				let one_selected = false;

				for (var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {
					if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
						if (document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked) {
							one_selected = true;
							break;
						}
					}
				}

				if (!one_selected) {
					frappe.msgprint({title: __("Message"), message: __('No selected data'), indicator: "red"});
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					return false;
				}

				let names = [];

				for (var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {
					if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
						if (document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked) {
							names[names.length] = document.getElementById('html_select_document_lines_name_' + i.toString()).value;
						}
					}
				}


				z.hide();


				me.#sleep(250).then(() => {
					if (callback) {
						callback(names);
					}
				});


			},
			secondary_action: function(){
				z.hide();
			}

		});


		if (document.getElementById('html_select_document_lines_filter_warehouse_id')) {
			document.getElementById('html_select_document_lines_filter_warehouse_id').remove();
		}


		if (document.getElementById('html_select_document_lines_filter_warehouse_location_id')) {
			document.getElementById('html_select_document_lines_filter_warehouse_location_id').remove();
		}

		if (document.getElementById('html_select_document_lines_filter_stored_qty_mode')) {
			document.getElementById('html_select_document_lines_filter_stored_qty_mode').remove();
		}

		if (document.getElementById('html_select_document_lines_filter_search')) {
			document.getElementById('html_select_document_lines_filter_search').remove();
		}



		if (document.getElementById('html_select_document_lines_checked_all')) {
			document.getElementById('html_select_document_lines_checked_all').remove();
		}

		if (document.getElementById('html_select_document_lines_table_header')) {
			document.getElementById('html_select_document_lines_table_header').remove();
		}

		let html = '';

		html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';

		html += '	<div style="position: relative; left: 0px; width:200px;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Warehouse") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 200px; height: 25px;"> ';
		html += '			<select id="html_select_document_lines_filter_warehouse_id" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1" selected>' + __("All") + '</option> ';

		let method = this.path_warehouse + '.ioi_warehouse_from_current_site'
		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							for (var i = 0; i < r.message.length; i++) {
								html += '		<option value="' + r.message[i].name + '">' + r.message[i].name + '</option> ';
							}
						}
		});

		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; left: 210px; width:200px;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Location") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 200px; height: 25px;"> ';
		html += '			<select id="html_select_document_lines_filter_warehouse_location_id" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1" selected>' + __("All") + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; left: 420px; width:180px;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Stored qty mode") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 180px; height: 25px;"> ';
		html += '			<select id="html_select_document_lines_filter_stored_qty_mode" class="input-with-feedback form-control bold"> ';
		html += '				<option value="-1">' + __("All") + '</option> ';
		html += '				<option value="0">' + __("Good") + '</option> ';
		html += '				<option value="1" selected>' + __("To check") + '</option> ';
		html += '				<option value="2">' + __("Bad") + '</option> ';
		html += '			</select> ';
		html += '		</div>';
		html += '	</div>';

		html += '	<div style="position: relative; left: 610px; width:300px;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 25px;"> ';
		html += '			<input id="html_select_document_lines_filter_search" class="input-with-feedback form-control bold" value=""> ';
		html += '		</div>';
		html += '	</div>';
		html += '</div>'

		html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

		html += '<table id="html_select_document_lines_table_header" border=1 style="border: 1px solid #E8EAEB" width=1835px data-custom-grid="true">';

		html += '<tr style="height:30px">';
		html += '<td width=30px align="center" style="vertical-align: middle;">';



		if (multiselect) {

			html += '<input type="checkbox" id="html_select_document_lines_checked_all" style="postion:absolute; top: 2px; left: 2px;">';
		}

		html += '</td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;<b>' + __("Line no") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';
		html += '<td width=175px style="vertical-align: middle;">&nbsp;<b>' + __("Stored qty mode") + '</b></td>';
		html += '<td width=120px style="vertical-align: middle;">&nbsp;<b>' + __("Qty") + '</b></td>';
		html += '<td width=80px style="vertical-align: middle;">&nbsp;<b>' + __("Unit") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Batch SN") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Warehouse") + '</b></td>';
		html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + __("Location") + '</b></td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;<b>' + __("Item description") + '</b></td>';
		html += '</tr>';

		html += '</table>';




		html += '</div>';


		z.fields_dict.html_select_document_lines.$wrapper.html(html);
		z.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "50%");
		z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

		z.show();

		this.#sleep(250).then(() => {

			let fct_change_whs = function() {

				for(var i = document.getElementById('html_select_document_lines_filter_warehouse_location_id').options.length-1; i >= 0; i--) {
					document.getElementById('html_select_document_lines_filter_warehouse_location_id').options[i].remove();
				}

				let option = new Option(__("All"), "-1");
				document.getElementById('html_select_document_lines_filter_warehouse_location_id').add(option);

				if (this.value != '-1') {

					let method = me.path_warehouse_location + '.ioi_warehouse_location_get_locations';
					frappe.call({  	method: method,
									args: {"warehouse_id": this.value},
									async: false,
									callback:function(r)	{

										for (var i = 0; i < r.message.data.length; i++) {

											let option = new Option(r.message.data[i].name, r.message.data[i].name);
											document.getElementById('html_select_document_lines_filter_warehouse_location_id').add(option);
										}
									}
					});

				}

				me.select_receipt_entry_detail_refresh(tp, document_id, document_detail_id, multiselect);
			}

			document.getElementById('html_select_document_lines_filter_warehouse_id').onchange = fct_change_whs;


			let fct_change = function() {
				me.select_receipt_entry_detail_refresh(tp, document_id, document_detail_id, multiselect);
			}

			document.getElementById('html_select_document_lines_filter_warehouse_location_id').onchange = fct_change;
			document.getElementById('html_select_document_lines_filter_stored_qty_mode').onchange = fct_change;

			let fct_keyup = function(event) {

				if (event.keyCode == 13) {
					me.select_receipt_entry_detail_refresh(tp, document_id, document_detail_id, multiselect);
				}
			}

			document.getElementById('html_select_document_lines_filter_search').onkeyup = fct_keyup;

			if (multiselect) {

				let fct_click = function() {

					if (document.getElementById('html_select_document_lines_table_lines')) {

						for (var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {
							if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
								document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked = this.checked;
							}
						}
					}
				}

				document.getElementById('html_select_document_lines_checked_all').onclick = fct_click;
			}


			me.select_receipt_entry_detail_refresh(tp, document_id, document_detail_id, multiselect);
		});



	}

	select_receipt_entry_detail_refresh(tp, document_id, document_detail_id, multiselect)
	{
		if (document.getElementById('html_select_document_lines_table_lines')) {

			for (var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {

				if (document.getElementById('html_select_document_lines_parent_' + i.toString())) {
					document.getElementById('html_select_document_lines_parent_' + i.toString()).remove();
				}

				if (document.getElementById('html_select_document_lines_name_' + i.toString())) {
					document.getElementById('html_select_document_lines_name_' + i.toString()).remove();
				}

				if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
					document.getElementById('html_select_document_lines_checked_id_' + i.toString()).remove();
				}

			}

			document.getElementById('html_select_document_lines_table_lines').remove();

		}

		let pathstockqualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';

		let method = pathstockqualification;

		if (tp.toUpperCase() == 'PR') {
			method += '.ioi_stock_qualification_get_purchases_receipt_line_data';
		}else{
			method += '.ioi_stock_qualification_get_stock_entry_line_data';
		}

		let parameters = {}

		if (tp.toUpperCase() == 'PR') {
			parameters = { 	"purchases_receipt_id" : document_id,
							"warehouse_id": document.getElementById('html_select_document_lines_filter_warehouse_id').value,
							"warehouse_location_id": document.getElementById('html_select_document_lines_filter_warehouse_location_id').value,
							"stored_qty_mode": document.getElementById('html_select_document_lines_filter_stored_qty_mode').value,
							"search_term" : document.getElementById('html_select_document_lines_filter_search').value
						};
		}else{
			parameters = { 	"stock_entry_id" : document_id,
							"warehouse_id": document.getElementById('html_select_document_lines_filter_warehouse_id').value,
							"warehouse_location_id": document.getElementById('html_select_document_lines_filter_warehouse_location_id').value,
							"stored_qty_mode": document.getElementById('html_select_document_lines_filter_stored_qty_mode').value,
							"search_term" : document.getElementById('html_select_document_lines_filter_search').value
						 };
		}

		let me = this;


		frappe.call({  	method: method,
						args: parameters,
						async: false,
						callback:function(r)	{

													let html = '';

													if (r.message.length > 0)
													{

														html += '<table id="html_select_document_lines_table_lines" border=1 style="border: 1px solid #E8EAEB" width=1835px>';

														for (var i = 0; i < r.message.length; i++)
														{
															html += '<input type="hidden" id="html_select_document_lines_parent_' + i.toString() + '" value="' + r.message[i].name + '">';
															html += '<input type="hidden" id="html_select_document_lines_name_' + i.toString() + '" value="' + r.message[i].name + '">';

															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="html_select_document_lines_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;">';
															html += '</td>';
															html += '<td width=80px style="vertical-align: middle;" align="right">' + r.message[i].idx + '&nbsp;</td>';
															html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].item_id + '</td>';

															html += '<td width=175px style="vertical-align: middle;">&nbsp;' + r.message[i].lib_stored_qty_mode + '</td>';

															if ((r.message[i].delivered_qty) && (r.message[i].delivered_qty != 0)) {
																html += '<td width=120px style="vertical-align: middle;" align="right">' + parseFloat(r.message[i].delivered_qty).toFixed(2) + '&nbsp;</td>';
															}else{
																html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].unit_id) && (r.message[i].unit_id.trim() != '')) {
																html += '<td width=80px style="vertical-align: middle;">&nbsp;' + r.message[i].unit_id + '</td>';
															}else{
																html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].batch_sn_id) && (r.message[i].batch_sn_id.trim() != '')) {
																html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].batch_sn_id + '</td>';
															}else{
																html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].warehouse_id) && (r.message[i].warehouse_id.trim() != '')) {
																html += '<td width=250px style="vertical-align: middle;">&nbsp;' + r.message[i].warehouse_id + '</td>';
															}else{
																html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].warehouse_location_id) && (r.message[i].warehouse_location_id.trim() != '')) {
																html += '<td width=250px style="vertical-align: middle;">&nbsp;<b>' + r.message[i].warehouse_location_id + '</b></td>';
															}else{
																html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
															}

															if ((r.message[i].item_description) && (r.message[i].item_description.trim() != '')) {
																html += '<td width=350px style="vertical-align: middle;">&nbsp;' + r.message[i].item_description + '</td>';
															}else{
																html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
															}
															html += '</tr>';


														}
														html += '</table>';
													}else
													{

														html += '<table id="html_select_document_lines_table_lines" border=1 style="border: 1px solid #E8EAEB" width=1835px>';
														html += '<tr style="height:30px">';
														html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=120px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=250px style="vertical-align: middle;">&nbsp;</td>';
														html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
														html += '</tr>';
														html += '</table>';
													}

													document.getElementById('html_select_document_lines_table_header').insertAdjacentHTML('afterend', html);

													me.#sleep(200).then(() => {

														if (document.getElementById('html_select_document_lines_table_lines')) {

															if (!multiselect) {

																let is_checked = false;

																for(var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {

																	if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {

																		if (document.getElementById('html_select_document_lines_name_')) {

																			if (document.getElementById('html_select_document_lines_name_').value == document_detail_id) {
																				document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked = true;
																				is_checked = true;
																				break;
																			}

																		}
																	}
																}

																if (!is_checked) {

																	for(var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {

																		if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
																			document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked = true;
																			break;
																		}
																	}
																}

															}else{

																for(var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {

																	if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
																		document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked = true;
																	}
																}
															}

														}

														if (!multiselect) {

															let fct_click = function() {

																for(var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {

																	if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
																		document.getElementById('html_select_document_lines_checked_id_' + i.toString()).checked = false;
																	}
																}

																this.checked = true;

															}

															if (document.getElementById('html_select_document_lines_table_lines')) {
																for(var i = 0; i < document.getElementById('html_select_document_lines_table_lines').rows.length; i++) {
																	if (document.getElementById('html_select_document_lines_checked_id_' + i.toString())) {
																		document.getElementById('html_select_document_lines_checked_id_' + i.toString()).onclick = fct_click;
																	}
																}
															}

														}

													});


												}
		});
	}

	build_html_purchases_receipt_detail(cdt, cdn)
	{
		let me = this;

		let method = me.path_stock_qualification + '.ioi_stock_qualification_get_purchases_receipt_line_data';

		let html = '';

		let line = locals[cdt][cdn];

		if (this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form) {

			if ((line.purchases_receipt_id) && (line.purchases_receipt_id.trim() != '') && (line.purchases_receipt_detail_id) && (line.purchases_receipt_detail_id.trim() != '')) {

				frappe.call({  	method: method,
								args: {	"purchases_receipt_id": line.purchases_receipt_id, "purchases_receipt_detail_id": line.purchases_receipt_detail_id},
								async: false,
								callback:function(r)	{

									if (r.message.length > 0) {

										let content = r.message[0].parent + ' | ' + r.message[0].idx;



										let bgcolor = r.message[0].bgcolor;
										let color = '#000000';

										html += '<table width=100%>';
										html += '<tr>';
										html += '<td>' + __("Purchases Receipt Line") + '</td>';
										html += '</tr>';

										html += '<tr>';
										html += '<td>';
										html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
										html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ content +'</b></font>';
										html += '</div>';
										html += '</td>';
										html += '</tr>';
										html += '</table>';

									}else{

										html += '<table width=100%>';
										html += '<tr>';
										html += '<td>' + __("Purchases Receipt Line") + '</td>';
										html += '</tr>';

										html += '<tr>';
										html += '<td>&nbsp;</td>';
										html += '</tr>';
										html += '</table>';


									}

								}
				});


			}else{
				html += '<table width=100%>';
				html += '<tr>';
				html += '<td>' + __("Purchases Receipt Line") + '</td>';
				html += '</tr>';

				html += '<tr>';
				html += '<td>&nbsp;</td>';
				html += '</tr>';
				html += '</table>';

			}

			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_purchases_receipt_detail'].$wrapper.empty();
			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_purchases_receipt_detail'].$wrapper.append(html);
		}


	}

	build_html_stock_entry_detail(cdt, cdn)
	{
		let me = this;

		let method = me.path_stock_qualification + '.ioi_stock_qualification_get_stock_entry_line_data';

		let html = '';

		let line = locals[cdt][cdn];

		if (this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form) {

			if ((line.stock_entry_id) && (line.stock_entry_id.trim() != '') && (line.stock_entry_detail_id) && (line.stock_entry_detail_id.trim() != '')) {

				frappe.call({  	method: method,
								args: {	"stock_entry_id": line.stock_entry_id, "stock_entry_detail_id": line.stock_entry_detail_id},
								async: false,
								callback:function(r)	{

									if (r.message.length > 0) {

										let content = r.message[0].parent + ' | ' + r.message[0].idx;



										let bgcolor = r.message[0].bgcolor;
										let color = '#000000';

										html += '<table width=100%>';
										html += '<tr>';
										html += '<td>' + __("Stock Entry Line") + '</td>';
										html += '</tr>';

										html += '<tr>';
										html += '<td>';
										html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
										html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ content +'</b></font>';
										html += '</div>';
										html += '</td>';
										html += '</tr>';
										html += '</table>';

									}else{

										html += '<table width=100%>';
										html += '<tr>';
										html += '<td>' + __("Stock Entry Line") + '</td>';
										html += '</tr>';

										html += '<tr>';
										html += '<td>&nbsp;</td>';
										html += '</tr>';
										html += '</table>';


									}

								}
				});


			}else{
				html += '<table width=100%>';
				html += '<tr>';
				html += '<td>' + __("Stock Entry Line") + '</td>';
				html += '</tr>';

				html += '<tr>';
				html += '<td>&nbsp;</td>';
				html += '</tr>';
				html += '</table>';

			}

			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_stock_entry_detail'].$wrapper.empty();
			this.frm.get_field('line_detail').grid.grid_rows[line.idx-1].grid_form.fields_dict['html_stock_entry_detail'].$wrapper.append(html);
		}


	}



	// ***************************************************************************************************************************************
	// Fill Prefixes
	// ***************************************************************************************************************************************
	fill_prefixes()
	{
		let me = this;

		let amethod = '';
		let tp = '';

		if (this.frm.is_new()) {
			if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {
				this.frm.doc.prefix_id = '';
				this.frm.doc.id_prefix = '';
				this.frm.doc.id_number = 0;
				this.frm.doc.identification = '?';
			}
		}


		this.frm.refresh_field('journal_id');
		this.frm.refresh_field('prefix_id');
		this.frm.refresh_field('id_prefix');
		this.frm.refresh_field('id_number');
		this.frm.refresh_field('identification');
		this.load_warehouse_journal_data();

		amethod = this.path_warehouse_journal + '.ioi_warehouse_journal_get_qualifications_prefixes';
		tp = 'STOCK_QUALIFICATION';

		let prefix_enabled = 0

		if (this.frm.is_new())
		{	prefix_enabled = 1
		}

		frappe.call({  	method: amethod,
					   	args: {"enabled":1, "prefix_enabled" : prefix_enabled, "tp":tp, "current_prefix": this.current_prefix},
						async: false,
						callback:function(r){
												let options = '[';

                                                me.prefixes = [];

												for (var i = 0; i < r.message.length; i++)
												{

													me.prefixes[i] = [r.message[i][0], r.message[i][1], r.message[i][2], r.message[i][3], r.message[i][4], r.message[i][5], r.message[i][6], r.message[i][7], r.message[i][8], r.message[i][9]];
													options += '{"label": "' + me.prefixes[i][1] + '", "value": "' + me.prefixes[i][1] + '"}';

													if (i != r.message.length-1)
													{	options += ',';
													}
												}


												options += ']';

												me.frm.set_df_property('prefix_id', 'options', JSON.parse(options));
												me.frm.refresh_field("prefix_id");

                                                if (me.frm.is_new())
                                                {		if (me.prefixes.length > 0)
														{
															let mtd = 'silicon_ioi.ioi_system.doctype.ioi_user_module_params.ioi_user_module_params.ioi_user_module_params_get_prefix';

															let selected_pref = ''

															frappe.call({
																method: mtd,
																args: {"doctype":  me.frm.doctype},
																async: false,
																callback:function(r){
																	selected_pref = r.message;

																	if (!selected_pref) {
																		selected_pref = '';
																	}
																}
															});


															let found = false;

															for (var w = 0; w < me.prefixes.length; w++) {

																if (me.prefixes[w][1].toUpperCase() == selected_pref.toUpperCase()) {
																	found = true;
																	break;
																}
															}

															if (!found) {
																me.frm.set_value('prefix_id', me.prefixes[0][1]);
															}else{
																me.frm.set_value('prefix_id', selected_pref);
															}

														}
                                                }

											}
		});

	}

	// ***************************************************************************************************************************************
	// Refresh Prefixes
	// ***************************************************************************************************************************************
	refresh_prefixes()
	{
		this.fill_prefixes();
	}

	// ***************************************************************************************************************************************
	// Refresh ioistatus (Status)
	// ***************************************************************************************************************************************
	refresh_ioistatus()
	{
		let html = '';
		this.frm.fields_dict['html_ioistatus'].$wrapper.empty();


		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (this.frm.doc.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}


		html = '<table width=100%>';
		html += '<tr>';
		html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100%>';
		html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
		html += '</div></td>';
		html += '</tr>';

		html += '<tr>';
		html += '<td width=100% height=7px>&nbsp;</td>';
		html += '</tr>';

		html += '</table>';

		this.frm.fields_dict['html_ioistatus'].$wrapper.append(html);
	}

	// ***************************************************************************************************************************************
	// Detail : Refresh ioistatus (Status)
	// ***************************************************************************************************************************************
	#refresh_detail_ioistatus(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let bgcolor = '';
		let color = '';
		let description = '';

		for (var i = 0; i < this.ioistatus.length; i++)
		{
			if (item.ioistatus == this.ioistatus[i][0])
			{
				description = this.ioistatus[i][1];
				bgcolor = this.ioistatus[i][2];
				color = this.ioistatus[i][3];
				break;
			}
		}

		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form)
		{
			let html = '';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.empty();

			html = '<table width=100%>';
			html += '<tr>';
			html += '<td width=100% height=20px><font style="font-size:12px;">'+ __("Status") +'</font></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100%>';
			html += '<div style="height:32px; background-color:' + bgcolor + '; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
			html += '<b><font style="font-size:14px;color:' + color +'">&nbsp;'+ __(description) +'</b></font>';
			html += '</div></td>';
			html += '</tr>';

			html += '<tr>';
			html += '<td width=100% height=7px>&nbsp;</td>';
			html += '</tr>';

			html += '</table>';

			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].grid_form.fields_dict['html_detail_ioistatus'].$wrapper.append(html);
		}

		if (this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1])
		{
			this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
		}
	}


	// ***************************************************************************************************************************************
	// New document
	// ***************************************************************************************************************************************
    new_document()
    {
		this.init_values_when_new_or_duplicate();

        if ((!this.frm.doc.document_type) || ((this.frm.doc.document_type) && (this.frm.doc.document_type.trim() == '')))
        {
			this.frm.set_value('document_type', 'STOCK_QUALIFICATION');

        }

        if ((this.frm.doc.prefix_id) && (this.frm.doc.prefix_id.trim() != ''))
        {	this.frm.set_df_property('document_type', 'read_only', 1);
            this.frm.set_df_property('prefix_id', 'read_only', 1);
        }else
        {	this.frm.set_df_property('document_type', 'read_only', 0);
            this.frm.set_df_property('prefix_id', 'read_only', 0);
        }

        if ((!this.frm.doc.identification) || ((this.frm.doc.identification) && (this.frm.doc.identification.trim() == '')))
        {	this.frm.set_value('identification', '?');
        }


        this.frm.set_df_property('identification', 'read_only', 1);

        this.frm.set_value('ioistatus', 0);
        this.frm.set_value('lib_ioistatus', __('In preparation'));


    }

	// ***************************************************************************************************************************************
	// Init value when new document or duplicate
	// ***************************************************************************************************************************************

	init_values_when_new_or_duplicate()
	{

		if ((this.frm.doc.level_tp != 1) && (this.frm.doc.level_tp != 2)) {

			let me = this;

			let amethod = this.path_stock_document + '.ioi_stock_document_get_fields_for_initialization'
			frappe.call({  	method: amethod,
							args: {"doctype": this.frm.doctype, "doctype_detail" : this.frm.doctype + ' Detail'},
							async: false,
							callback:function(r){

								if (r.message.length > 0) {

									for (var i = 0; i < r.message.length; i++) {

										if (me.frm.doctype == r.message[i].parent) {
											me.frm.set_value(r.message[i].fieldname, r.message[i].default_value);
											me.frm.refresh_field(r.message[i].fieldname);
										}
									}

									if (me.frm.doc.line_detail)
									{
										for (var j = 0; j < me.frm.doc.line_detail.length; j++) {

											for (var i = 0; i < r.message.length; i++) {

												if (me.frm.doctype + ' Detail' == r.message[i].parent) {

													me.frm.doc.line_detail[j][r.message[i].fieldname] = r.message[i].default_value;
												}
											}
										}

										me.frm.refresh_field('line_detail');
									}
								}
							}
			});
		}

	}




	// ***************************************************************************************************************************************
	// Create subdocument (same level)
	// ***************************************************************************************************************************************
	create_subdocument_same_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_same_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_same_level();
		}
	}

	do_create_subdocument_same_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = this.path_stock_qualification + '.ioi_stock_qualification_get_next_subdoc_samelevel';

		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});

		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.parent_id;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.parent_id;
		}else
		{
			new_root = me.frm.doc.root_id;
		}

		frappe.call({  	method: this.path_stock_document + '.ioi_stock_document_get_document',
						args: {"doctype": this.frm.doctype, "name": me.frm.doc.parent_id},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;

								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 1;

								for (var i = 0; i < keys.length; i++)
								{
									if ((keys[i].toUpperCase() != 'NAME')                  && (keys[i].toUpperCase() != 'OWNER')                    && (keys[i].toUpperCase() != 'CREATION')               &&
										(keys[i].toUpperCase() != 'MODIFIED')              && (keys[i].toUpperCase() != 'MODIFIED_BY')              && (keys[i].toUpperCase() != 'DOCSTATUS')              &&
										(keys[i].toUpperCase() != 'IDX')                   && (keys[i].toUpperCase() != 'DOCUMENT_TYPE')            && (keys[i].toUpperCase() != 'IOISTATUS')              &&
										(keys[i].toUpperCase() != 'LIB_IOISTATUS')         && (keys[i].toUpperCase() != 'DOCTYPE')                  && (keys[i].toUpperCase() != 'JOURNAL_ID')             &&
										(keys[i].toUpperCase() != 'PREFIX_ID')             && (keys[i].toUpperCase() != 'IDENTIFICATION')           && (keys[i].toUpperCase() != 'PARENT_ID')              &&
										(keys[i].toUpperCase() != 'ROOT_ID')               && (keys[i].toUpperCase() != 'LEVEL_TP')                 && (keys[i].toUpperCase() != 'LINE_DETAIL')            &&
										(keys[i].toUpperCase() != 'LOG_DETAIL')	           && (keys[i].toUpperCase() != 'ID_PREFIX')                && (keys[i].toUpperCase() != 'ID_NUMBER'))

									{
										doc[keys[i]] = values[i];
									}
								}

							});
						}
		});

	}


	// ***************************************************************************************************************************************
	// Create subdocument (sub level)
	// ***************************************************************************************************************************************
	create_subdocument_sub_level()
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_create_subdocument_sub_level(); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_create_subdocument_sub_level();
		}
	}
	do_create_subdocument_sub_level()
	{
		let sublevel_separator = '';
		let sublevel_nb_digit = 0;

		for (var i = 0; i < this.prefixes.length; i++)
		{
			if (this.prefixes[i][1] == this.frm.doc.prefix_id)
			{
				sublevel_separator = this.prefixes[i][4];
				sublevel_nb_digit = this.prefixes[i][5];
				break;
			}
		}

		let me = this;

		let new_identification = '';

        let amethod = this.path_stock_qualification + '.ioi_stock_qualification_get_next_subdoc_sublevel';



		frappe.call({  	method: amethod,
						args: {"prefix_id": this.frm.doc.prefix_id, "identification" : this.frm.doc.identification, "sublevel_separator" : sublevel_separator, "sublevel_nb_digits": sublevel_nb_digit},
						async: false,
		   				callback: function(r)	{
													new_identification = r.message;
												}
		});

		let current_document_type = me.frm.doc.document_type;
		let current_prefix_id = me.frm.doc.prefix_id;
		let current_journal_id = me.frm.doc.journal_id;
		let current_identification = me.frm.doc.name;

		let new_root = ''

		if ((!me.frm.doc.root_id) || ((me.frm.doc.root_id) && (me.frm.doc.root_id.trim() == '')))
		{
			new_root = me.frm.doc.name;
		}else
		{
			new_root = me.frm.doc.root_id;
		}

		frappe.call({  	method: this.path_stock_document + '.ioi_stock_document_get_document',
						args: {"doctype": this.frm.doctype, "name": this.frm.doc.name},
						async: false,
						callback: function(r){

							let keys = Object.keys(r.message);
							let values = Object.values(r.message);

							frappe.new_doc(me.frm.doctype, {}, doc => {

								doc["document_type"] = current_document_type;
								doc["journal_id"] = current_journal_id;
								doc["prefix_id"] = current_prefix_id;
								doc["id_prefix"] = current_prefix_id;
								doc["identification"] = new_identification;

								doc["parent_id"] = current_identification;
								doc["root_id"] = new_root;
								doc["level_tp"] = 2;

								for (var i = 0; i < keys.length; i++)
								{
									if ((keys[i].toUpperCase() != 'NAME')                  && (keys[i].toUpperCase() != 'OWNER')                    && (keys[i].toUpperCase() != 'CREATION')               &&
										(keys[i].toUpperCase() != 'MODIFIED')              && (keys[i].toUpperCase() != 'MODIFIED_BY')              && (keys[i].toUpperCase() != 'DOCSTATUS')              &&
										(keys[i].toUpperCase() != 'IDX')                   && (keys[i].toUpperCase() != 'DOCUMENT_TYPE')            && (keys[i].toUpperCase() != 'IOISTATUS')              &&
										(keys[i].toUpperCase() != 'LIB_IOISTATUS')         && (keys[i].toUpperCase() != 'DOCTYPE')                  && (keys[i].toUpperCase() != 'JOURNAL_ID')             &&
										(keys[i].toUpperCase() != 'PREFIX_ID')             && (keys[i].toUpperCase() != 'IDENTIFICATION')           && (keys[i].toUpperCase() != 'PARENT_ID')              &&
										(keys[i].toUpperCase() != 'ROOT_ID')               && (keys[i].toUpperCase() != 'LEVEL_TP')                 && (keys[i].toUpperCase() != 'LINE_DETAIL')            &&
										(keys[i].toUpperCase() != 'LOG_DETAIL')	           && (keys[i].toUpperCase() != 'ID_PREFIX')                && (keys[i].toUpperCase() != 'ID_NUMBER'))
									{
										doc[keys[i]] = values[i];
									}
								}
							});
						}
		});
	}

	// ***************************************************************************************************************************************
	// Get next number
	// ***************************************************************************************************************************************
	get_next_number()
	{
		if (this.frm.is_new())
		{
			if (!this.frm.doc.level_tp)
			{	this.frm.doc.level_tp = 0;
			}

			if ((this.frm.doc.level_tp == 0) || (this.frm.doc.level_tp == 3))
			{
				this.frm.set_intro('', '');

				for (var i = 0; i < this.prefixes.length; i++)
				{
					if (this.prefixes[i][1] == this.frm.doc.prefix_id)
					{

						this.frm.set_value('identification', this.prefixes[i][2]+1);

						let id_journal = '';
						let id_prefix = '';
						let id_last_number = 0;
						let id_number_digits = 0;
						let id_formatted = '';

						id_journal = this.prefixes[i][0];

						this.frm.doc.journal_id = id_journal;

						this.frm.refresh_field('journal_id');
						this.frm.refresh_field('prefix_id');
						this.frm.refresh_field('id_prefix');
						this.frm.refresh_field('id_number');
						this.frm.refresh_field('identification');

						let me = this;

						this.load_warehouse_journal_data();

						id_prefix = this.prefixes[i][1];

						this.frm.doc.division_id = this.prefixes[i][6];
						this.frm.doc.site_id = this.prefixes[i][7];


						this.frm.refresh_field('division_id');
						this.frm.refresh_field('site_id');

						this.fill_period();
						this.set_query('period_id', '', this.path_stock_document + '.ioi_stock_document_get_enabled_period', '{"division":"' + this.frm.doc.division_id + '"}');


						this.frm.set_value('from_warehouse_id', this.journal_warehouse_id);
						this.frm.refresh_field('from_warehouse_id');
						this.set_query('from_warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');


						if ((this.journal_wms_qualification_to_warehouse_id) && (this.journal_wms_qualification_to_warehouse_id.trim() != '')) {
							this.frm.set_value('to_warehouse_id', this.journal_wms_qualification_to_warehouse_id);
						}else{
							this.frm.set_value('to_warehouse_id', this.journal_warehouse_id);
						}

						this.frm.refresh_field('to_warehouse_id');
						this.set_query('to_warehouse_id', '', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');




						let mode_break = false;

						let journal_period_break_numbering = this.journal_wms_qualification_period_break_numbering;
						let journal_period_break_position = this.journal_wms_qualification_period_break_position;



						if (journal_period_break_numbering == 1)
						{
							mode_break = true;
						}

						if (!mode_break)
						{
							id_last_number = this.prefixes[i][2]+1;

							id_number_digits = this.prefixes[i][3];

							if (id_number_digits != 0)
							{
								let n = id_number_digits - id_last_number.toString().trim().length;

								if (n >= 0)
								{
									for (var j = 0; j < n; j++)
									{	id_formatted += '0';
									}
								}
							}

							id_formatted += id_last_number.toString().trim();

							this.frm.doc.level_tp = '0';
						}else
						{
							let amethod = '';
							let tp = ''
							let period_year = ''
							let period_month = ''



							if ((!this.frm.doc.period_id) || ((this.frm.doc.period_id) && (this.frm.doc.period_id.toString().trim() == '')))
							{
								if ((!this.frm.doc.document_date) || ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() == '')))
								{
									frappe.msgprint({title: __("Message"), message: __('Document date is mandatory'), indicator: "red"});
									raise;
								}
								let dt = new Date(this.frm.doc.document_date)
								period_year = dt.getFullYear();
								period_month = dt.getMonth()+1;

								if (Number(period_month) < 10)
								{	period_month = '0' + period_month
								}

							}else
							{
								let me = this;

								let method = this.path_stock_document + '.ioi_stock_document_get_period';

								frappe.call({  	method: method,
												args: {"period_id" : this.frm.doc.period_id.toString()},
												async: false,
												callback:function(r)	{
																			if (r.message.length == 0)
																			{
																				frappe.msgprint({title: __("Message"), message: __('No Period found'), indicator: "red"});
																				raise;
																			}

																			period_year = Number(r.message[0].year);

																			if (Number(r.message[0].month) < 10)
																			{
																				period_month = '0' + r.message[0].month;
																			}else
																			{
																				period_month = r.message[0].month;
																			}
																		}
								});

							}

							amethod = this.path_warehouse_journal + '.ioi_warehouse_journal_get_qualifications_period_break_last_number';



							tp = this.frm.doc.document_type.toUpperCase();

							let me = this;

							frappe.call({  	method: amethod,
											args: {"tp":tp, "current_prefix": this.frm.doc.prefix_id, "period_year" : period_year, "period_month": period_month},
											async: false,
											callback:function(r){
																	id_last_number = r.message[0][0];
																	id_number_digits = me.prefixes[i][3];
																	id_formatted = r.message[0][0];
																}
							});

							this.frm.doc.level_tp = '3';
						}

						this.frm.set_value('journal_id', id_journal);

						this.frm.set_value('id_prefix', id_prefix);
						this.frm.set_value('id_number', id_last_number);
						this.frm.set_value('identification', id_formatted);
						this.frm.set_df_property('identification', 'read_only', 1);
					}
				}
			}

		}
	}

	// ***************************************************************************************************************************************
	// Format qty
	// ***************************************************************************************************************************************
	format_qty(frm, cdt, cdn, fieldname)
	{
		let item = locals[cdt][cdn]

		if ((!item.decimals_allowed) || (item.decimals_allowed == 0))
		{
			if ((item.item_id) && (item.item_id.trim() != ''))
			{
				item[fieldname] = Math.round(item[fieldname]);
				frm.refresh_field('line_detail');
			}
		}
	}



	// ***************************************************************************************************************************************
	// Set field's labels with (!) when error found
	// ***************************************************************************************************************************************
	set_label_when_error()
	{
		this.#disable_log_grid_actions();

		this.frm.refresh_field('log_detail');

		if (this.frm.doc.log_detail)
		{
			for (var i = 0; i < this.frm.doc.log_detail.length; i++)
			{
				if ((this.frm.doc.log_detail[i].fieldname.toUpperCase() != 'ITEM_SALES_CONDITION_ID') &&
					(this.frm.doc.log_detail[i].fieldname.toUpperCase() != 'ITEM_ITEM_ID'))
				{
					this.frm.fields_dict[this.frm.doc.log_detail[i].fieldname].label_area.innerHTML = __(this.frm.fields_dict[this.frm.doc.log_detail[i].fieldname]._label)+ '&nbsp;<font color = "#FF5338"><b>(!)</b></font>';
				}
			}

			if (this.frm.doc.log_detail.length > 0)
			{
				for (var i = 0; i < this.frm.layout.tabs.length; i++)
				{
					if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'STOCK_QUALIFICATION_TAB_WARNINGS')
					{
						document.getElementById('ioi-stock-qualification-stock_qualification_tab_warnings-tab').innerHTML = '<font color = "#FF5959"><b>' + __(this.frm.layout.tabs[i].label) + ' (!)</b><font>'; // #FF5338
						document.getElementById('ioi-stock-qualification-stock_qualification_tab_warnings-tab').hidden = false;

					}
				}
			}else
			{
				for (var i = 0; i < this.frm.layout.tabs.length; i++)
				{
					if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'STOCK_QUALIFICATION_TAB_WARNINGS')
					{
						document.getElementById('ioi-stock-qualification-stock_qualification_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
						document.getElementById('ioi-stock-qualification-stock_qualification_tab_warnings-tab').hidden = true;

					}

				}

			}

		}else
		{
			for (var i = 0; i < this.frm.layout.tabs.length; i++)
			{
				if (this.frm.layout.tabs[i].df.fieldname.toUpperCase() == 'STOCK_QUALIFICATION_TAB_WARNINGS')
				{
					document.getElementById('ioi-stock-qualification-stock_qualification_tab_warnings-tab').innerHTML = __(this.frm.layout.tabs[i].label);
					document.getElementById('ioi-stock-qualification-stock_qualification_tab_warnings-tab').hidden = true;

				}
		}
		}


		this.set_grid_layout();
	}

	// ***************************************************************************************************************************************
	// Log grid : disable all actions
	// ***************************************************************************************************************************************
	#disable_log_grid_actions()
	{
		for (var i = 0; i < this.frm.fields_dict['log_detail'].grid.grid_buttons.length; i++)
		{
			this.frm.fields_dict['log_detail'].grid.grid_buttons[i].hidden = true;
		}

		for (var i = 0; i < this.frm.fields_dict['log_detail'].grid.grid_rows.length; i++)
		{
			// this.frm.fields_dict['log_detail'].grid.grid_rows[i].row_check[0].hidden = true;
			this.frm.fields_dict['log_detail'].grid.grid_rows[i].open_form_button[0].hidden = true;
		}

		// this.frm.fields_dict['log_detail'].grid.header_row.row[0].children[0].hidden = true
		// this.frm.fields_dict['log_detail'].grid.header_row.row_check[0].hidden = true;
	}

	// ***************************************************************************************************************************************
	// Set Grid layout
	// ***************************************************************************************************************************************
	set_grid_layout()
	{
		silicon_ioi.doctype.ioiQualifDocumentDocType.set_static_grid_layout(null);
	}

	// ***************************************************************************************************************************************
	// Set static Grid layout : used outside de class without instanciate an object
	// ***************************************************************************************************************************************
	static set_static_grid_layout(dm = null)
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {
			if (dm == null)
			{
				dm = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;
			}

			if (cur_frm.doc.line_detail)
			{

				for(var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					let bgcolor = '';

					for (var k = 0; k < document.getElementById("ioistatus_id").length; k++)
					{
						if (cur_frm.doc.line_detail[i].ioistatus == document.getElementById("ioistatus_id").options[k].value)
						{
							bgcolor = document.getElementById("ioistatus_color").options[k].value;
							break;
						}
					}


					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.backgroundColor = bgcolor;
						cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[1].style.color = "black";

						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (dm == 0) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
							}else{
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
							}
						}

						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if (dm == 0) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FFFFFF';
							}else{
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#1C2126';
							}

						}


						if ((cur_frm.doc.line_detail[i].item_id) && (cur_frm.doc.line_detail[i].item_id.trim() != '')) {

							let from_qty = 0;

							if ((cur_frm.doc.line_detail[i].from_qty != null) && (cur_frm.doc.line_detail[i].from_qty != 0)) {
								from_qty = cur_frm.doc.line_detail[i].from_qty;
							}


							let to_qty = 0;

							if ((cur_frm.doc.line_detail[i].to_qty != null) && (cur_frm.doc.line_detail[i].to_qty != 0)) {
								to_qty = cur_frm.doc.line_detail[i].to_qty;
							}

							if (from_qty != to_qty) {

								for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

									if ((cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FROM_QTY') ||
										(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TO_QTY')) {

										cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#fcdf9f';
										cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
									}
								}


							}else{

								if ((from_qty == 0) && (to_qty == 0)) {


									for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

										if ((cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FROM_QTY') ||
											(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TO_QTY')) {

											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#fab6a0';
											cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
										}
									}

								}

							}

							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++) {

								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FROM_STORED_QTY_MODE') {

									let s = '';

									if ((!cur_frm.doc.line_detail[i].from_stored_qty_mode) || ((cur_frm.doc.line_detail[i].from_stored_qty_mode) && (cur_frm.doc.line_detail[i].from_stored_qty_mode == '0'))) {
										s = __('Good');
									}else if (cur_frm.doc.line_detail[i].from_stored_qty_mode == '1') {
										s = __('To check');
									}else if (cur_frm.doc.line_detail[i].from_stored_qty_mode == '2') {
										s = __('Bad');
									}

									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].innerText = s;
								}

								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TO_STORED_QTY_MODE') {

									let s = '';

									if ((!cur_frm.doc.line_detail[i].to_stored_qty_mode) || ((cur_frm.doc.line_detail[i].to_stored_qty_mode) && (cur_frm.doc.line_detail[i].to_stored_qty_mode == '0'))) {
										s = __('Good');
									}else if (cur_frm.doc.line_detail[i].to_stored_qty_mode == '1') {
										s = __('To check');
									}else if (cur_frm.doc.line_detail[i].to_stored_qty_mode == '2') {
										s = __('Bad');
									}

									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].innerText = s;
								}


								if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TO_BATCH_SN_LOCKING_MODE') {

									let s = '';

									if ((!cur_frm.doc.line_detail[i].to_batch_sn_locking_mode) || ((cur_frm.doc.line_detail[i].to_batch_sn_locking_mode) && (cur_frm.doc.line_detail[i].to_batch_sn_locking_mode == '0'))) {
										s = __('Unlocked');
									}else if (cur_frm.doc.line_detail[i].to_batch_sn_locking_mode == '1') {
										s = __('Locked');
									}

									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].innerText = s;
								}
							}



						}





						for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
						{
							if ((cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'FROM_QTY') ||
								(cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'TO_QTY'))
							{
								let fn = cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname;

								let gridelement = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].children[1].children[0];

								let gridcell = null;

								if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0]) {


									if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0]) {

										if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0]) {
											gridcell = cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[fn][0].children[0].children[0].children[0];
										}
									}
								}

								silicon_ioi.doctype.ioiQualifDocumentDocType.grid_format_float_qty(gridelement, gridcell);

							}else if (cur_frm.fields_dict['line_detail'].grid.header_row.columns_list[m].df.fieldname.toUpperCase() == 'ITEM_ID') {

								let set_in_orange = false;

								if ((cur_frm.doc.purchases_receipt_id) && (cur_frm.doc.purchases_receipt_id.trim() != '')) {

									if ((!cur_frm.doc.line_detail[i].purchases_receipt_id) || ((cur_frm.doc.line_detail[i].purchases_receipt_id) && (cur_frm.doc.line_detail[i].purchases_receipt_id.trim() == ''))) {

										if ((!cur_frm.doc.line_detail[i].stock_entry_id) || ((cur_frm.doc.line_detail[i].stock_entry_id) && (cur_frm.doc.line_detail[i].stock_entry_id.trim() == ''))) {

											set_in_orange = true
										}
									}
								}


								if ((cur_frm.doc.stock_entry_id) && (cur_frm.doc.stock_entry_id.trim() != '')) {

									if ((!cur_frm.doc.line_detail[i].stock_entry_id) || ((cur_frm.doc.line_detail[i].stock_entry_id) && (cur_frm.doc.line_detail[i].stock_entry_id.trim() == ''))) {

										if ((!cur_frm.doc.line_detail[i].purchases_receipt_id) || ((cur_frm.doc.line_detail[i].purchases_receipt_id) && (cur_frm.doc.line_detail[i].purchases_receipt_id.trim() == ''))) {

											set_in_orange = true
										}
									}
								}

								if (set_in_orange) {
									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#ffe2ab';
									cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
								}
							}

						}




						if ((cur_frm.doc.line_detail[i].is_warning) && (cur_frm.doc.line_detail[i].is_warning != 0))
						{
							for (var m = 0; m < cur_frm.fields_dict['line_detail'].grid.header_row.columns_list.length; m++)
							{
								if (cur_frm.doc.line_detail[i].is_warning == 1)
								{	cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FCA874';
								}else
								{	cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.backgroundColor = '#FEADAD';
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[m+2].style.color = "black";
							}
						}

					}
				}
			}
			let fct_grid = function () { silicon_ioi.doctype.ioiQualifDocumentDocType.set_static_grid_layout(dm); };
			setTimeout(fct_grid, 500);
		}
	}


	// ***************************************************************************************************************************************
	// Sleep
	// ***************************************************************************************************************************************
	#sleep(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Sleep static
	// ***************************************************************************************************************************************
	static sleep_static(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	// ***************************************************************************************************************************************
	// Select document date
	// ***************************************************************************************************************************************
	select_document_date()
	{
		if ((this.frm.doc.document_date) && (this.frm.doc.document_date.trim() != ''))
		{
			this.frm.set_intro('', 'red');

			if (this.frm.doc.log_detail)
			{
				let i = this.frm.get_field("log_detail").grid.grid_rows.length-1;

				while (i >= 0)
				{
					if (this.frm.doc.log_detail[i].fieldname.toUpperCase() == 'DOCUMENT_DATE')
					{
						this.frm.get_field("log_detail").grid.grid_rows[i].remove();
						break;
					}
					i--;
				}
			}

			this.#sleep(1000).then(() => { this.set_label_when_error(); });
		}
	}

	// ***************************************************************************************************************************************
	// Fill warehouse in all lines
	// ***************************************************************************************************************************************
	fill_whs_all_lines(fieldname, value)
	{
		if (this.frm.doc.line_detail)
		{
			if (this.frm.doc.line_detail.length > 0)
			{
				if (this.frm.doc.ioistatus != 0)
				{
					frappe.msgprint({title: __("Message"), message: __('Document has to be "in preparation"'), indicator: "red"});
					raise;
				}

				if ((!value) || ((value) && (value.trim() == '')))
				{

					if (fieldname.toUpperCase() == 'FROM_WAREHOUSE_ID') {
						frappe.msgprint({title: __("Message"), message: __('Fill the warehouse (from), please"'), indicator: "red"});
					}else {
						frappe.msgprint({title: __("Message"), message: __('Fill the warehouse (to), please"'), indicator: "red"});
					}

					raise;
				}

				let me = this;

				let msg = '';


				if (fieldname.toUpperCase() == 'FROM_WAREHOUSE_ID') {
					msg = 'Update warehouse (from) in all lines ?';
				}else{
					msg = 'Update warehouse (to) in all lines ?';
				}


				frappe.confirm(	msg,
					() => 	{
								// action to perform if Yes is selected

								document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

								let location_active = 0;

								let method = me.path_warehouse + '.ioi_warehouse_get_data';

								frappe.call({  	method: method,
												args: {	"warehouse_id" : value
											},
												async: false,
												callback:function(r)	{

													location_active = 0;

													if (r.message[0].location_active) {
														location_active = r.message[0].location_active;
													}


												}
								});



								for (var i = 0; i < me.frm.doc.line_detail.length; i++)
								{
									if (fieldname.toUpperCase() == 'FROM_WAREHOUSE_ID') {
										if (me.frm.doc.line_detail[i].from_warehouse_id != value)
										{
											me.frm.doc.line_detail[i].from_warehouse_id = value;
											me.frm.doc.line_detail[i].from_warehouse_location_id = '';
											me.frm.doc.line_detail[i].from_location_active = location_active;
										}
									}else {

										if (me.frm.doc.line_detail[i].to_warehouse_id != value)
										{
											me.frm.doc.line_detail[i].to_warehouse_id = value;
											me.frm.doc.line_detail[i].to_warehouse_location_id = '';
											me.frm.doc.line_detail[i].to_location_active = location_active;
										}
									}
								}

								me.frm.refresh_field('line_detail');

								me.frm.dirty();

							},
					() => 	{
								// action to perform if No is selected or if click outsite the modal screen

							}
				);
			}
		}
	}

	// ***************************************************************************************************************************************
	// Format spacer
	// ***************************************************************************************************************************************
	#refresh_spacer(cdt, cdn)
	{
		let item = locals[cdt][cdn];

		let html = '<div id="spacer" style="width:100%; height:150px"></div>';

		let selected_row = this.frm.get_field('address_detail').grid.grid_rows_by_docname[item.name];
		selected_row.get_field('html_spacer').set_value(html);
	}

	// ***************************************************************************************************************************************
	// Format banner detail
	// ***************************************************************************************************************************************
	#build_banner_detail(frm, cdt, cdn, childdoc, fieldname, description, br=true)
	{
		let html = '';
		let item = locals[cdt][cdn];
		let selected_row = this.frm.get_field(childdoc).grid.grid_rows_by_docname[item.name];

		html += '<div style="height:30px; background-color: #D0E7FB; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;">';
		html += '<b><font style="font-size:14px;color: #1F272E">&nbsp;'+ __(description) +'</b></font>';
		html += '</div>';

		if (br)
		{
			html += '<div style="height:8px;"></div>';
		}

		selected_row.get_field(fieldname).set_value(html);
	}



	// ***************************************************************************************************************************************
	// Error Log : cancel add row
	// ***************************************************************************************************************************************
	error_log_cancel_add(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];
		this.frm.get_field("log_detail").grid.grid_rows_by_docname[item.name].remove();
		this.frm.refresh_field('log_detail');
	}

	// ***************************************************************************************************************************************
	// Populate line
	// ***************************************************************************************************************************************
	populate_line(frm, cdt, cdn)
	{
		this.frm.refresh_field('line_detail');
		let item = locals[cdt][cdn];
		let can_populate_line = false;

		this.frm.set_intro('', 'red');


		let item_id = ''
		let barcode_ref = ''

		if ((item.item_id) && (item.item_id.trim() != ''))
		{	can_populate_line = true;
			item_id = item.item_id;

		}else{
			item.item_mode = 0
		}


		if (can_populate_line)
		{
            let dc_type = '';
            let qty = 0;

            dc_type = 'STOCK_QUALIFICATION';


			if (can_populate_line)
			{
				let me = this;

				frappe.call({  	method: this.path_stock_document + '.ioi_stock_document_populate_line',
								args: 	{	"doctype": this.frm.doctype,
											"document_type": dc_type,
											"document_date": this.frm.doc.document_date,
											"item_id": item_id
								},
								async: false,
								callback: function(r)	{

															item.item_id = '';
															item.item_description = '';
															item.item_mode = 0
															item.unit_id = '';
															item.decimals_allowed = 1;

															if (r.message.error == 1)
															{	me.#set_trace(me, 'log_detail', r.message.error_from_fieldname, r.message.error_fieldname, r.message.error_message, 'N');
															}else
															{
																item.item_id = r.message.item_id;
																item.item_description = r.message.item_description;
																item.unit_id = r.message.unit_id;
																item.item_mode = r.message.mode;

																item.unit_stock_base_qualif_cost = r.message.item_cost;


																if ((r.message.from_warehouse_id) && (r.message.from_warehouse_id.trim() != '')) {
																	item.from_warehouse_id = r.message.from_warehouse_id;
																	item.from_warehouse_location_id = r.message.from_warehouse_location_id;
																}


																if ((r.message.to_warehouse_id) && (r.message.to_warehouse_id.trim() != '')) {
																	item.to_warehouse_id = r.message.to_warehouse_id;
																	item.to_warehouse_location_id = r.message.to_warehouse_location_id;
																}

																me.update_costing(frm, cdt, cdn);
															}

															me.frm.refresh_field('line_detail');


														}
				});
			}
		}else
		{
			this.frm.refresh_field('line_detail');
			item.item_description = '';
			item.unit_id = '';
		}

		const grid_row = this.frm.fields_dict["line_detail"].grid.grid_rows_by_docname[item.name];
		const item_description = grid_row.docfields.filter(f => f.fieldname == "item_description")[0];
		const item_unit = grid_row.docfields.filter(f => f.fieldname == "unit_id")[0];

		item_unit.read_only = true;

		if ((item.item_id) && (item.item_id != ''))
		{
			item_description.read_only = true;
		}else
		{
			item_description.read_only = false;
		}
		this.frm.refresh_field('line_detail');
		this.set_grid_layout();

		this.#sleep(1000).then(() => { this.set_label_when_error(); });

	}

	// ***************************************************************************************************************************************
	// Update Costing
	// ***************************************************************************************************************************************

	update_costing(frm, cdt, cdn)
	{
		let line = locals[cdt][cdn];

		let coeff_from = 1;

		if ((line.from_stored_qty_mode) == 2) {
			coeff_from = 0;
		}

		let coeff_to = 1;

		if ((line.to_stored_qty_mode) == 2) {
			coeff_to = 0;
		}

		let from_qty = 0;

		if (line.from_qty) {
			from_qty = line.from_qty;
		}


		let to_qty = 0;

		if (line.to_qty) {
			to_qty = line.to_qty;
		}

		let unit_stock_base_qualif_cost = 0;

		if (line.unit_stock_base_qualif_cost) {
			unit_stock_base_qualif_cost = line.unit_stock_base_qualif_cost
		}

		line.unit_stock_qualif_cost = ((to_qty * coeff_to) - (from_qty * coeff_from)) * unit_stock_base_qualif_cost;

		line.value_line_sys_currency = line.unit_stock_qualif_cost * from_qty;

		this.frm.refresh_field('line_detail');

	}


	// ***************************************************************************************************************************************
	// Set a trace to the log
	// ***************************************************************************************************************************************
	#set_trace(obj, log_detail, from_fieldname, fieldname, error_message, db_save)
	{
		let error_row = obj.frm.add_child(log_detail);
		error_row.from_fieldname = from_fieldname;
		error_row.fieldname = fieldname;
		error_row.error_message = error_message;
		error_row.db_save = db_save;
		obj.frm.refresh_field(log_detail);

		obj.frm.set_intro(error_message, 'red');
		frappe.show_alert({ message:error_message, indicator:'red'}, 5);
	}

	// ***************************************************************************************************************************************
	// Format Child Detail form
	// ***************************************************************************************************************************************
	format_detail_form(frm, cdt, cdn)
	{
		this.frm.refresh_field('line_detail');

		let item = locals[cdt][cdn];

		if ((item.is_warning) && (item.is_warning != 0))
		{
			let msg_bgcolor = '';
			let msg_fontcolor = ''

			if (item.is_warning == 1)
			{	msg_bgcolor = '#FFF5F0';
				msg_fontcolor = '#F57231';
			}else
			{	msg_bgcolor = '#FEADAD';
				msg_fontcolor = 'black';
			}

			if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();

					let html = '';

					html = '<br><div align="center" style="overflow:auto; vertical-align: middle; height:28px;border-radius:6px;padding:4px;background-color:' + msg_bgcolor + ';">';
					html += '<font color="' + msg_fontcolor +'">' + item.warninfo + '</font>';
					html += '</div>';

					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.append(html);
				}
			}
		}else
		{
			if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'])
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['html_warning'].$wrapper.empty();
				}
			}
		}




		this.#refresh_detail_ioistatus(frm, cdt, cdn);

		this.form_format_all_qties(item.idx);


		let me = this;

		if ((!me.frm.doc.site_id) || ((me.frm.doc.site_id) && (me.frm.doc.site_id.trim()) == ''))
		{
			me.set_query('from_warehouse_id', 'line_detail', me.path_warehouse + '.ioi_warehouse_get_enabled', '');
			me.set_query('to_warehouse_id', 'line_detail', me.path_warehouse + '.ioi_warehouse_get_enabled', '');

		}else{
			me.set_query('from_warehouse_id', 'line_detail', me.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + me.frm.doc.site_id + '"}');
			me.set_query('to_warehouse_id', 'line_detail', me.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + me.frm.doc.site_id + '"}');
		}

		if ((!item.from_warehouse_id) || ((item.from_warehouse_id) && (item.from_warehouse_id.trim() == '')))
		{
			me.set_query('from_warehouse_location_id', 'line_detail', me.path_warehouse_location + '.ioi_warehouse_location_get_enabled', '');
		}else
		{
			me.set_query('from_warehouse_location_id', 'line_detail', me.path_warehouse_location + '.ioi_warehouse_location_get_enabled', '{"warehouse_id":"' + item.from_warehouse_id + '"}');
		}

		if ((!item.to_warehouse_id) || ((item.to_warehouse_id) && (item.to_warehouse_id.trim() == '')))
		{
			me.set_query('to_warehouse_location_id', 'line_detail', me.path_warehouse_location + '.ioi_warehouse_location_get_enabled', '');
		}else
		{
			me.set_query('to_warehouse_location_id', 'line_detail', me.path_warehouse_location + '.ioi_warehouse_location_get_enabled', '{"warehouse_id":"' + item.to_warehouse_id + '"}');
		}

		this.color_qties(cdt, cdn);


		if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
		{

			this.#build_html_location_buttons('FROM', 'html_from_location_buttons', cdt, cdn);
			this.#build_html_location_buttons('TO', 'html_to_location_buttons', cdt, cdn);


			this.#build_html_batch_buttons('html_batch_buttons', cdt, cdn);


			let set_in_orange = false;

			if ((this.frm.doc.purchases_receipt_id) && (this.frm.doc.purchases_receipt_id.trim() != '')) {

				if ((!item.purchases_receipt_id) || ((item.purchases_receipt_id) && (item.purchases_receipt_id.trim() == ''))) {

					if ((!item.stock_entry_id) || ((item.stock_entry_id) && (item.stock_entry_id.trim() == ''))) {

						set_in_orange = true
					}
				}
			}


			if ((this.frm.doc.stock_entry_id) && (this.frm.doc.stock_entry_id.trim() != '')) {

				if ((!item.stock_entry_id) || ((item.stock_entry_id) && (item.stock_entry_id.trim() == ''))) {

					if ((!item.purchases_receipt_id) || ((item.purchases_receipt_id) && (item.purchases_receipt_id.trim() == ''))) {

						set_in_orange = true
					}
				}
			}

			if (set_in_orange) {

				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['item_id'].$input_wrapper[0].children[0].children[0]) {

					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['item_id'].$input_wrapper[0].children[0].children[0].children[0].children[0].style.backgroundColor = '#ffe2ab';
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['item_id'].$input_wrapper[0].children[0].children[0].children[0].children[0].style.color = 'black';

				}else{

					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['item_id'].$input_wrapper[0].children[1].style.backgroundColor = '#ffe2ab';
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict['item_id'].$input_wrapper[0].children[1].style.color = 'black';
				}
			}





			for (var i = 0; i < this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
			{
				if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
				{
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.attr('data-custom-section-head', 'true');
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('border-radius', '6px');
					this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.css('padding', '4px');

					if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].is_collapsed())
					//if (this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head[0].className == 'section-head collapsed')
					{	this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}
					//this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
				}

			}
		}



		if (cur_frm.doc.ioistatus != 0) {

			let from_stored_qty_mode = cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('from_stored_qty_mode').value;

			if ((!from_stored_qty_mode) || ((from_stored_qty_mode) && (from_stored_qty_mode == 0))) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('from_stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Good');
			}else if (from_stored_qty_mode == 1) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('from_stored_qty_mode').$input_wrapper[0].children[1].innerText = __('To check');
			}else{
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('from_stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Bad');
			}

			let to_stored_qty_mode = cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_stored_qty_mode').value;

			if ((!to_stored_qty_mode) || ((to_stored_qty_mode) && (to_stored_qty_mode == 0))) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Good');
			}else if (to_stored_qty_mode == 1) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_stored_qty_mode').$input_wrapper[0].children[1].innerText = __('To check');
			}else{
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_stored_qty_mode').$input_wrapper[0].children[1].innerText = __('Bad');
			}


			let to_batch_sn_locking_mode = cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_batch_sn_locking_mode').value;

			if ((!to_batch_sn_locking_mode) || ((to_batch_sn_locking_mode) && (to_batch_sn_locking_mode == 0))) {
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_batch_sn_locking_mode').$input_wrapper[0].children[1].innerText = __('Unlocked');
			}else{
				this.frm.fields_dict['line_detail'].grid.grid_rows[item.idx-1].get_field('to_batch_sn_locking_mode').$input_wrapper[0].children[1].innerText = __('Locked');
			}



		}

		this.build_detail_buttons('PR', 'html_purchases_receipt_detail_buttons', cdt, cdn);
		this.build_detail_buttons('SE', 'html_stock_entry_detail_buttons', cdt, cdn);
		this.build_html_purchases_receipt_detail(cdt, cdn);
		this.build_html_stock_entry_detail(cdt, cdn);
	}

	color_qties(cdt, cdn)
	{

		let item = locals[cdt][cdn];

		if (this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form) {

			let from_qty = 0;

			if ((item.from_qty != null) && (item.from_qty != 0)) {
				from_qty = item.from_qty;
			}


			let to_qty = 0;

			if ((item.to_qty != null) && (item.to_qty != 0)) {
				to_qty = item.to_qty;
			}

			if (from_qty != to_qty) {

				if (this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].has_input) {
					this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].$input_wrapper[0].children[0].children[0].style.backgroundColor = '#fcdf9f';
					this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["to_qty"].$input_wrapper[0].children[0].children[0].style.backgroundColor = '#fcdf9f';
				}else{
					this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].$input_wrapper[0].children[1].style.backgroundColor = '#fcdf9f';
					this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["to_qty"].$input_wrapper[0].children[1].style.backgroundColor = '#fcdf9f';
				}
			}else{

				if ((from_qty == 0) && (to_qty == 0)) {

					if (this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].has_input) {
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].$input_wrapper[0].children[0].children[0].style.backgroundColor = '#fab6a0';
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["to_qty"].$input_wrapper[0].children[0].children[0].style.backgroundColor = '#fab6a0';
					}else{
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].$input_wrapper[0].children[1].style.backgroundColor = '#fab6a0';
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["to_qty"].$input_wrapper[0].children[1].style.backgroundColor = '#fab6a0';
					}
				}else{
					if (this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].has_input) {
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].$input_wrapper[0].children[0].children[0].style.backgroundColor = '';
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["to_qty"].$input_wrapper[0].children[0].children[0].style.backgroundColor = '';
					}else{
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["from_qty"].$input_wrapper[0].children[1].style.backgroundColor = '';
						this.frm.fields_dict["line_detail"].grid.grid_rows[item.idx-1].grid_form.fields_dict["to_qty"].$input_wrapper[0].children[1].style.backgroundColor = '';
					}
				}
			}
		}
	}

	static grid_format_float_qty(element, gridcell)
	{

		let original_value = element.innerText.trim()

		if (gridcell != null) {

			let fct_blur = function() {
				silicon_ioi.doctype.ioiQualifDocumentDocType.grid_format_float_qty(element, gridcell);
			}

			gridcell.onblur = fct_blur;
		}


		if ((original_value != '') && (original_value != 0)) {

			original_value = original_value.replaceAll(' ', '');

			let idx_coma = -1;
			let idx_point = -1;

			if (original_value.indexOf(',') != -1) {
				idx_coma = original_value.indexOf(',');
			}

			if (original_value.indexOf('.') != -1) {
				idx_point = original_value.indexOf('.');
			}

			if ((idx_coma != -1) || (idx_point != -1)) {

				let is_point = false;

				if (idx_coma < idx_point) {

					if (idx_coma != -1) {
						original_value = original_value.replaceAll(',', '');
						is_point = true;
					}

				}else if (idx_coma > idx_point)
				{
					if (idx_point != -1) {
						original_value = original_value.replaceAll('.', '');
					}
				}

				let can_continue = false;

				if (is_point)
				{
					if (original_value.indexOf('.') != -1) {
						can_continue = true;
					}

				}else{
					if (original_value.indexOf(',') != -1) {
						can_continue = true;
					}

				}

				if (can_continue) {

					can_continue = true;

					let is_number = true;

					if (isNaN(original_value)) {

						if (original_value.indexOf('.') != -1) {
							original_value = original_value.replaceAll('.', ',');
						}else if (original_value.indexOf(',') != -1) {
							original_value = original_value.replaceAll(',', '.');
						}else{
							can_continue = false;
						}

						is_number = false;
					}

					if (can_continue) {

						let modified_value = parseFloat(original_value);

						if (!is_number) {

							if (modified_value.toString().indexOf('.') != -1) {
								modified_value = modified_value.toString().replaceAll('.', ',');
							}else if (modified_value.toString().indexOf(',') != -1) {
								modified_value = modified_value.toString().replaceAll(',', '.');
							}
						}

						element.innerText = modified_value;
					}
				}
			}
		}

	}


	form_format_qty_float(fieldname, grid_idx)
	{
		if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form) {

			let original_value = '';

			if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
				original_value = cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value.trim();

				let me = this;
				let fct_blur = function() {
					me.form_format_qty_float(fieldname, grid_idx);
				}
				cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.onblur = fct_blur;

			}else{
				original_value = cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText.trim();
			}


			if ((original_value != '') && (original_value != 0)) {

				original_value = original_value.replaceAll(' ', '');

				let idx_coma = -1;
				let idx_point = -1;

				if (original_value.indexOf(',') != -1) {
					idx_coma = original_value.indexOf(',');
				}

				if (original_value.indexOf('.') != -1) {
					idx_point = original_value.indexOf('.');
				}

				if ((idx_coma != -1) || (idx_point != -1)) {

					let is_point = false;

					if (idx_coma < idx_point) {

						if (idx_coma != -1) {
							original_value = original_value.replaceAll(',', '');
							is_point = true;
						}

					}else if (idx_coma > idx_point)
					{
						if (idx_point != -1) {
							original_value = original_value.replaceAll('.', '');
						}
					}

					let can_continue = false;

					if (is_point)
					{
						if (original_value.indexOf('.') != -1) {
							can_continue = true;
						}

					}else{
						if (original_value.indexOf(',') != -1) {
							can_continue = true;
						}

					}

					if (can_continue) {

						can_continue = true;

						let is_number = true;

						if (isNaN(original_value)) {

							if (original_value.indexOf('.') != -1) {
								original_value = original_value.replaceAll('.', ',');
							}else if (original_value.indexOf(',') != -1) {
								original_value = original_value.replaceAll(',', '.');
							}else{
								can_continue = false;
							}

							is_number = false;
						}

						if (can_continue) {

							let modified_value = parseFloat(original_value);

							if (!is_number) {

								if (modified_value.toString().indexOf('.') != -1) {
									modified_value = modified_value.toString().replaceAll('.', ',');
								}else if (modified_value.toString().indexOf(',') != -1) {
									modified_value = modified_value.toString().replaceAll(',', '.');
								}
							}


							if (cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input) {
								cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].input.value = modified_value;
							}else{
								cur_frm.get_field('line_detail').grid.grid_rows[grid_idx-1].grid_form.fields_dict[fieldname].$input_wrapper[0].children[1].innerText = modified_value;
							}

						}
					}
				}
			}
		}
	}

	form_format_all_qties(grid_idx)
	{
		this.form_format_qty_float('from_qty', grid_idx);
		this.form_format_qty_float('to_qty', grid_idx);

	}



	// ***************************************************************************************************************************************
	// Line detail : add new line
	// ***************************************************************************************************************************************
	line_detail_add(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		item.guid = this.#createGUID();
		item.ioistatus = this.frm.doc.ioistatus;


		item.site_id = this.frm.doc.site_id;


		item.from_warehouse_id = this.frm.doc.from_warehouse_id;

		item.from_location_active = 0;

		if ((item.from_warehouse_id) && (item.from_warehouse_id.trim() != '')) {

			let me = this;

			let method = this.path_warehouse + '.ioi_warehouse_get_data';

			frappe.call({  	method: method,
							args: {	"warehouse_id" : item.from_warehouse_id
						},
							async: false,
							callback:function(r)	{

								item.location_active = 0;

								if (r.message[0].location_active) {
									item.from_location_active = r.message[0].location_active;
								}


							}
			});
		}


		item.to_warehouse_id = this.frm.doc.to_warehouse_id;

		item.to_location_active = 0;

		if ((item.to_warehouse_id) && (item.to_warehouse_id.trim() != '')) {

			let me = this;

			let method = this.path_warehouse + '.ioi_warehouse_get_data';

			frappe.call({  	method: method,
							args: {	"warehouse_id" : item.to_warehouse_id
						},
							async: false,
							callback:function(r)	{

								item.location_active = 0;

								if (r.message[0].location_active) {
									item.to_location_active = r.message[0].location_active;
								}


							}
			});
		}


		this.set_query('from_warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');
		this.set_query('to_warehouse_id', 'line_detail', this.path_warehouse + '.ioi_warehouse_get_enabled', '{"site_id":"' + this.frm.doc.site_id + '"}');

		item.from_qty = 1;
		item.to_qty = 1;
		item.from_stored_qty_mode = 0;
		item.to_stored_qty_mode = 0;
		item.to_batch_sn_locking_mode = 0;

		item.purchases_receipt_id = this.frm.doc.purchases_receipt_id;
		item.stock_entry_id = this.frm.doc.stock_entry_id;



		item.document_date = this.frm.doc.document_date;
		item.period_id = this.frm.doc.period_id;


		if (document.getElementById('hidden_can_edit_document').value == '0')
		{	item.can_remove = 2;
			this.frm.get_field("line_detail").grid.grid_rows[item.idx-1].remove();
		}


		this.frm.refresh_field('line_detail');
		this.set_grid_layout();


		silicon_ioi.doctype.ioiQualifDocumentDocType.sleep_static(100).then(() => {
			if (cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form)
			{
				for (var i = 0; i < cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections.length; i++)
				{
					if (cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head)
					{
						cur_frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.layout.sections[i].head.click();
					}

				}
			}
		});

	}

	// ***************************************************************************************************************************************
	// Line detail : remove line
	// ***************************************************************************************************************************************
	line_detail_before_remove(frm, cdt, cdn)
	{
		let item = locals[cdt][cdn];

		if (document.getElementById('hidden_can_edit_document').value == '0')
		{
			let item = locals[cdt][cdn];

			if (item.can_remove != 2)
			{	raise;
			}
		}


		this.frm.refresh_field('line_detail');
		this.set_grid_layout();
	}

	// ***************************************************************************************************************************************
	// Create GUID
	// ***************************************************************************************************************************************
	#createGUID()
	{
		function random()
		{
		  	return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}

		return random() + random() + '-' + random() + '-' + random() + '-' + random() + '-' + random() + random() + random();
	}

	// ***************************************************************************************************************************************
	// Disable frm except fields
	// ***************************************************************************************************************************************
	disable_frm_except_fields()
	{
		if ((document.getElementById('hidden_can_edit_document').value == '0') || (this.frm.doc.frozen_data == 1))
		{
			this.frm.fields.forEach((field) => {
				this.frm.set_df_property(field.df.fieldname, "read_only", "1");
			  });

			this.frm.disable_save();

			let me = this;

			let method = this.path_module_status_accessible_fields + '.ioi_module_status_accessible_fields_get_status_fields';

			frappe.call({  	method: method,
							args: {"doctype":this.frm.doctype, "ioistatus":this.frm.doc.ioistatus},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{
															let field_child = [];

															for(var i = 0; i < r.message.length; i++)
															{
																// 0 : doctype_id, 1 : field_id, 2 : fieldname, 3 :fieldtype

																if (r.message[i][0] == me.frm.doctype)
																{
																	me.frm.set_df_property(r.message[i][1], 'read_only', 0);
																}else
																{	if (r.message[i][2] != null)
																	{
																		me.frm.set_df_property(r.message[i][2], 'read_only', 0);

																		if (r.message[i][3].toUpperCase() == 'TABLE')
																		{
																			me.frm.fields_dict[r.message[i][2]].grid.docfields.forEach((field) => {
																				if (field_child.indexOf(r.message[i][2] + "|" + field.fieldname) == -1)
																				{
																					me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(field.fieldname, "read_only", 1);
																				}
																			});

																			if (field_child.indexOf(r.message[i][2] + "|" + r.message[i][1]) == -1)
																			{
																				field_child.push(r.message[i][2] + "|" + r.message[i][1]);
																			}

																			me.frm.fields_dict[r.message[i][2]].grid.update_docfield_property(r.message[i][1], "read_only", 0);
																		}
																	}
																}
															}

															me.frm.enable_save();
														}
													}
			});

		}

	}

	// ***************************************************************************************************************************************
	// Display select label when readonly
	// ***************************************************************************************************************************************
	display_select_label()
	{
		for (var i = 0; i < this.frm.fields.length; i++)
		{
			if (this.frm.fields[i].df)
			{
				if (this.frm.fields[i].df.read_only)
				{
					if (this.frm.fields[i].df.read_only == 1)
					{
						let display = true;

						if (this.frm.fields[i].df.hidden)
						{
							if (this.frm.fields[i].df.hidden == 1)
							{
								display = false;
							}
						}

						if (display)
						{
							if (this.frm.fields[i].df.fieldname)
							{
								if (this.frm.fields_dict[this.frm.fields[i].df.fieldname])
								{
									if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df)
									{
										if (this.frm.fields_dict[this.frm.fields[i].df.fieldname].df.fieldtype.toUpperCase() == 'SELECT')
										{
											if (this.frm.fields[i].last_options)
											{
												let t = JSON.parse(this.frm.fields[i].last_options);

												for (var j = 0; j < t.length; j++)
												{
													if (t[j].value == this.frm.fields[i].value)
													{
														this.frm.fields_dict[this.frm.fields[i].df.fieldname].$wrapper[0].children[0].children[1].children[1].innerText = __(t[j].label);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// ***************************************************************************************************************************************
	// Select Item : advanced search
	// ***************************************************************************************************************************************
	select_item(frm)
	{
		let me = this;

		if (document.getElementById('hidden_can_edit_document').value == 1)
		{
			let fields = 'a.name, a.description, a.unit_id, b.q_stock, b.q_reserved, a.manufacturer_ref';
			let fields_len = '300, 500, 125, 150, 150, 300';
			let fields_desc = 'Identification, Description, Unit, Stock qty, Reserved qty, Manufacturer Catalog';
			let where = 'ioistatus = 2';
			let order_by = 'a.name asc';
			let parameter_with_stock = false;
			let site_id = this.frm.doc.site_id;

			let fct_callback = function (return_value) {

				let cdt = me.frm.selected_doc.doctype;
				let cdn = me.frm.selected_doc.name;

				let item = locals[cdt][cdn];
				item.item_id = return_value;

				me.frm.refresh_field('line_detail');
				me.populate_line(frm, cdt, cdn);
				me.format_detail_form(frm, cdt, cdn);
			};


			silicon_ioi.ioiCommon.select_item(fields, fields_len, fields_desc, where, order_by, parameter_with_stock, site_id, fct_callback);

		}
	}





	// ***************************************************************************************************************************************
	// Build Location Buttons (select and clear)
	// **************************************************************************************************************************************

	#build_html_location_buttons(tp, fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (tp == 'FROM')
		{
			if (document.getElementById('bt_select_from_location')) {
				document.getElementById('bt_select_from_location').remove();
			}

			if (document.getElementById('bt_clear_from_location')) {
				document.getElementById('bt_clear_from_location').remove();
			}

			if (document.getElementById('line_from_cdt')) {
				document.getElementById('line_from_cdt').remove();
			}

			if (document.getElementById('line_from_cdn')) {
				document.getElementById('line_from_cdn').remove();
			}

			let html = '';
			html += '<div>';
			html +=	'	<button id="bt_select_from_location" data-label="Select a location" class="btn btn-default ellipsis" onclick="">' + __("Select a location") + '</button> ';
			html +=	'	<button id="bt_clear_from_location"  data-label="Clear location" class="btn btn-default ellipsis" onclick="">' + __("Clear location") + '</button> ';
			html += '   <input type="hidden" id="line_from_cdt" value="' + cdt + '"> ';
			html += '   <input type="hidden" id="line_from_cdn" value="' + cdn + '"> ';
			html += '</div>'
			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);



			let fct_select = function () {	me.select_location(tp, true); };
			let fct_clear = function () {	me.clear_location(tp, true); };

			document.getElementById('bt_select_from_location').onclick = fct_select;
			document.getElementById('bt_clear_from_location').onclick = fct_clear;

		}else{
			if (document.getElementById('bt_select_to_location')) {
				document.getElementById('bt_select_to_location').remove();
			}

			if (document.getElementById('bt_clear_to_location')) {
				document.getElementById('bt_clear_to_location').remove();
			}

			if (document.getElementById('line_to_cdt'))	{
				document.getElementById('line_to_cdt').remove();
			}

			if (document.getElementById('line_to_cdn')) {
				document.getElementById('line_to_cdn').remove();
			}

			let html = '';
			html += '<div>';
			html +=	'	<button id="bt_select_to_location" data-label="Select a location" class="btn btn-default ellipsis" onclick="">' + __("Select a location") + '</button> ';
			html +=	'	<button id="bt_clear_to_location"  data-label="Clear location" class="btn btn-default ellipsis" onclick="">' + __("Clear location") + '</button> ';
			html += '   <input type="hidden" id="line_to_cdt" value="' + cdt + '"> ';
			html += '   <input type="hidden" id="line_to_cdn" value="' + cdn + '"> ';
			html += '</div>'
			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
			this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

			let fct_select = function () {	me.select_location(tp, true); };
			let fct_clear = function () {	me.clear_location(tp, true); };


			document.getElementById('bt_select_to_location').onclick = fct_select;
			document.getElementById('bt_clear_to_location').onclick = fct_clear;

		}


	}

	// ***************************************************************************************************************************************
	// Clear location
	// **************************************************************************************************************************************
	clear_location(tp, form = false, idx = 0)
	{
		let item = null;

		if (tp == 'FROM')
		{
			if (form) {
				item = locals[document.getElementById('line_from_cdt').value][document.getElementById('line_from_cdn').value];
			}else{
				item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
			}
		}else{
			if (form) {
				item = locals[document.getElementById('line_to_cdt').value][document.getElementById('line_to_cdn').value];
			}else{
				item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
			}
		}
		let can_clear_location = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_location = false;
		}

		if (can_clear_location)
		{
			if (tp == 'FROM')
			{
				item.from_warehouse_location_id = '';


				this.frm.refresh_field('line_detail');
				this.frm.dirty();

				if (form) {
					this.format_detail_form(this.frm, document.getElementById('line_from_cdt').value, document.getElementById('line_from_cdn').value);
				}else{
					this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
				}
			}else{
				item.to_warehouse_location_id = '';
				this.frm.refresh_field('line_detail');
				this.frm.dirty();

				if (form) {
					this.format_detail_form(this.frm, document.getElementById('line_to_cdt').value, document.getElementById('line_to_cdn').value);
				}else{
					this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
				}

			}
		}
	}

	// ***************************************************************************************************************************************
	// Select a location
	// **************************************************************************************************************************************
	select_location(tpe, form = false, idx = 0)
	{

		let item = null;

		if (tpe == 'FROM')
		{
			if (form) {
				item = locals[document.getElementById('line_from_cdt').value][document.getElementById('line_from_cdn').value];
			}else{
				item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
			}
		}else{
			if (form) {
				item = locals[document.getElementById('line_to_cdt').value][document.getElementById('line_to_cdn').value];
			}else{
				item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
			}
		}


		let can_show_location_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_show_location_frm = false;
		}

		if (tpe == 'FROM') {


			if ((can_show_location_frm) && ((!item.from_warehouse_id) || ((item.from_warehouse_id) && (item.from_warehouse_id.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Warehouse (from) has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_location_frm = false;
			}

		}else{
			if ((can_show_location_frm) && ((!item.to_warehouse_id) || ((item.to_warehouse_id) && (item.to_warehouse_id.trim() == ''))))
			{
				frappe.msgprint({title: __("Message"), message: __("Warehouse (to) has to be filled"), indicator: "red"});
				this.frm.refresh_field('line_detail');
				can_show_location_frm = false;
			}

		}

		if (can_show_location_frm)
		{
			let me = this;

			let ccdt = null;
			let ccdn = null;
			let title = '';


			if (tpe == 'FROM') {
				if (form) {
					ccdt = document.getElementById('line_from_cdt').value;
					ccdn = document.getElementById('line_from_cdn').value;
				}else{
					ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
					ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
				}

				title = __("Select a location in " + item.from_warehouse_id)
			}else{
				if (form) {
					ccdt = document.getElementById('line_to_cdt').value;
					ccdn = document.getElementById('line_to_cdn').value;
				}else{
					ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
					ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
				}

				title = __("Select a location in " + item.to_warehouse_id)
			}

			let tp = '';
			let warehouse_id = '';
			let warehouse_location_id = '';


			if (tpe == 'FROM') {
				tp = 'OUT';

				if ((item.from_qty) && (item.from_qty < 0)) {
					tp = 'IN';
				}

				warehouse_id = item.from_warehouse_id;
				warehouse_location_id = item.from_warehouse_location_id;

			}else{
				tp = 'IN';

				if ((item.from_qty) && (item.from_qty < 0)) {
					tp = 'OUT';
				}

				warehouse_id = item.to_warehouse_id;
				warehouse_location_id = item.to_warehouse_location_id;

			}

			let batch_sn_id = null;
			let dossier_id = item.dossier_id;
			let dossier_focus = 0;
			let item_id = item.item_id;

			let stored_qty_mode = 0;

			batch_sn_id = item.batch_sn_id;
			stored_qty_mode = item.from_stored_qty_mode;


			let callback = function(warehouse_location_id, batch_sn_id, batch_sn_code_ref, batch_sn_origin, batch_sn_cost, dossier_id) {

				if (tpe == 'FROM') {
					item.from_warehouse_location_id = warehouse_location_id;
				}else{
					item.to_warehouse_location_id = warehouse_location_id;
				}


				if ((dossier_id != null) && (dossier_id != 'null') && (dossier_id != '')) {
					item.dossier_id = dossier_id;
				}

				if ((batch_sn_id != null) && (batch_sn_id != 'null')) {
					item.batch_sn_id = batch_sn_id;
				}else{
					item.batch_sn_id = '';
				}

				if ((!batch_sn_id) || ((batch_sn_id) && (batch_sn_id.trim() == ''))) {
					item.batch_sn_code_ref = '';
					item.batch_sn_origin = '';
				}else{
					if ((batch_sn_code_ref != null) && (batch_sn_code_ref != 'null')) {
						item.batch_sn_code_ref = batch_sn_code_ref;
					}else{
						item.batch_sn_code_ref = '';
					}

					if ((batch_sn_origin != null) &&(batch_sn_origin != 'null')) {
						item.batch_sn_origin = batch_sn_origin;
					}else{
						item.batch_sn_origin = '';
					}

				}

				me.frm.refresh_field('line_detail');
				me.frm.dirty();
				me.format_detail_form(me.frm, ccdt, ccdn);
			}

			silicon_ioi.ioiCommon.select_location(tp, warehouse_id, warehouse_location_id, dossier_id, dossier_focus, item_id, batch_sn_id, stored_qty_mode, callback);

		}else
		{
			if (tpe == 'FROM')
			{
				if (form) {
					this.format_detail_form(this.frm, document.getElementById('line_from_cdt').value, document.getElementById('line_from_cdn').value);
				}else{
					this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
				}
			}else{
				if (form) {
					this.format_detail_form(this.frm, document.getElementById('line_to_cdt').value, document.getElementById('line_to_cdn').value);
				}else{
					this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
				}
			}
		}
	}


	// ***************************************************************************************************************************************
	// Build Batch Buttons (select, clear and FIFO proposal)
	// **************************************************************************************************************************************
	#build_html_batch_buttons(fieldname, cdt, cdn)
	{
		let me = this;
		let item = locals[cdt][cdn];

		if (document.getElementById('bt_select_batch'))
		{
			document.getElementById('bt_select_batch').remove();
		}

		if (document.getElementById('bt_clear_batch'))
		{
			document.getElementById('bt_clear_batch').remove();
		}

		if (document.getElementById('batch_line_cdt'))
		{
			document.getElementById('batch_line_cdt').remove();
		}

		if (document.getElementById('batch_line_cdn'))
		{
			document.getElementById('batch_line_cdn').remove();
		}


		let html = '';
		html += '<div>';
		html +=	'	<button id="bt_select_batch" data-label="Select a batch/SN" class="btn btn-default ellipsis" onclick="">' + __("Select a batch / SN") + '</button> ';
		html +=	'	<button id="bt_clear_batch"  data-label="Clear batch/SN" class="btn btn-default ellipsis" onclick="">' + __("Clear batch / SN") + '</button> ';
		html += '   <input type="hidden" id="batch_line_cdt" value="' + cdt + '"> ';
		html += '   <input type="hidden" id="batch_line_cdn" value="' + cdn + '"> ';
		html += '</div>'
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.empty();
		this.frm.get_field('line_detail').grid.grid_rows[item.idx-1].grid_form.fields_dict[fieldname].$wrapper.append(html);

		let fct_select = function () {	me.select_batch(true); };
		let fct_clear = function () {	me.clear_batch(true); };

		document.getElementById('bt_select_batch').onclick = fct_select;
		document.getElementById('bt_clear_batch').onclick = fct_clear;
	}

	// ***************************************************************************************************************************************
	// Splt line qty
	/// **************************************************************************************************************************************
	split_line_qty(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}

		let can_split_line = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_split_line = false;
		}

		let me = this;

		if (can_split_line)
		{
			let original_from_qty = 0;

			if ((item.from_qty) && (item.from_qty != '')) {
				original_from_qty = parseFloat(item.from_qty);
			}

			let original_to_qty = 0;

			if ((item.to_qty) && (item.to_qty != '')) {
				original_to_qty = parseFloat(item.to_qty);
			}

			let from_stored_qty_mode = 0

			if ((item.from_stored_qty_mode) && (item.from_stored_qty_mode != '')) {
				from_stored_qty_mode = item.from_stored_qty_mode;
			}

			let to_stored_qty_mode = 0

			if ((item.to_stored_qty_mode) && (item.to_stored_qty_mode != '')) {
				to_stored_qty_mode = item.to_stored_qty_mode;
			}

			var splitLineQtyForm = new frappe.ui.Dialog({
				'title': __("Split line qty"),
				'fields': [
					{'fieldname': 'html_qualification_split_line_qty', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

					let new_qty = 0;

					if ((document.getElementById('html_qualification_split_line_qty_value').value) && (document.getElementById('html_qualification_split_line_qty_value').value != '')) {
						new_qty = parseFloat(document.getElementById('html_qualification_split_line_qty_value').value);
					}

					if (parseFloat(new_qty) >= parseFloat(original_from_qty)) {

						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("The qty has to be lower than") + ' ' + original_from_qty.toString(), indicator: "red"});
						return false;
					}



					if (parseFloat(original_from_qty) - parseFloat(new_qty) > parseFloat(original_to_qty)) {
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
						frappe.msgprint({title: __("Message"), message: __("The qty") + ' (' + original_from_qty.toString() + ' - ' + new_qty.toString() + ' ) ' + __('has to be lower than or equal to') + ' ' + original_to_qty.toString(), indicator: "red"});
						return false;
					}

					let new_item = cur_frm.fields_dict['line_detail'].grid.add_new_row();

					item.from_qty = parseFloat(original_from_qty) - parseFloat(new_qty) ;

					new_item = cur_frm.fields_dict['line_detail'].grid.duplicate_row(new_item, item);


					me.#sleep(150).then(() => {

						cur_frm.fields_dict['line_detail'].grid.refresh();

						let script_manager = cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].frm.script_manager;


						cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc["from_qty"] = new_qty;
						script_manager.trigger("from_qty", cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc.name);

						cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc["to_qty"] = new_qty;
						script_manager.trigger("to_qty", cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc.name);

						cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc["from_stored_qty_mode"] = document.getElementById('html_qualification_split_line_qty_from_stored_qty_mode').value;
						script_manager.trigger("from_stored_qty_mode", cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc.name);

						cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc["to_stored_qty_mode"] = document.getElementById('html_qualification_split_line_qty_to_stored_qty_mode').value;
						script_manager.trigger("to_stored_qty_mode", cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[new_item.idx - 1].doc.name);

						cur_frm.refresh_field('line_detail');

						me.#sleep(150).then(() => {

							if (cur_frm.get_field('line_detail').grid.grid_rows)
							{
								for (var i = 0; i < cur_frm.get_field('line_detail').grid.grid_rows.length; i++) {

									cur_frm.get_field('line_detail').grid.grid_rows[i].row_check[0].children[0].checked = false;

								}
							}
						});

						cur_frm.fields_dict['line_detail'].grid.refresh();
						cur_frm.refresh_field('line_detail');


					});



					splitLineQtyForm.hide();


				},
				secondary_action: function(){
					splitLineQtyForm.hide();
				}

			});




			if (document.getElementById('html_qualification_split_line_qty_value_label')) {
				document.getElementById('html_qualification_split_line_qty_value_label').remove();
			}

			if (document.getElementById('html_qualification_split_line_qty_value')) {
				document.getElementById('html_qualification_split_line_qty_value').remove();
			}

			if (document.getElementById('html_qualification_split_line_qty_from_stored_qty_mode_label')) {
				document.getElementById('html_qualification_split_line_qty_from_stored_qty_mode_label').remove();
			}

			if (document.getElementById('html_qualification_split_line_qty_from_stored_qty_mode')) {
				document.getElementById('html_qualification_split_line_qty_from_stored_qty_mode').remove();
			}

			if (document.getElementById('html_qualification_split_line_qty_to_stored_qty_mode_label')) {
				document.getElementById('html_qualification_split_line_qty_to_stored_qty_mode_label').remove();
			}

			if (document.getElementById('html_qualification_split_line_qty_to_stored_qty_mode')) {
				document.getElementById('html_qualification_split_line_qty_to_stored_qty_mode').remove();
			}


			let html = '';


			html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';

			// From Qty
			html += '	<div style="position: relative; left: 0px; width:120px;">';
			html += '		<label id="html_qualification_split_line_qty_value_label" style="position: absolute; top: 0px; left: 2px;">' + __("Qty") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 25px;"> ';
			html += '			<input id="html_qualification_split_line_qty_value" type="number" type="any" style="text-align:right" class="input-with-feedback form-control bold" value="' + original_from_qty.toString() + '">';
			html += '		</div>';
			html += '	</div>';

			// From Stored qty mode
			html += '	<div style="position: relative; left: 130px; width:180px;">';
			html += '		<label id="html_qualification_split_line_qty_from_stored_qty_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("From stored qty mode") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 180px; height: 25px;"> ';
			html += '			<select id="html_qualification_split_line_qty_from_stored_qty_mode" class="input-with-feedback form-control bold"> ';

			if (from_stored_qty_mode == 0) {
				html += '				<option value="0" selected>' + __("Good") + '</option> ';
			}else{
				html += '				<option value="0">' + __("Good") + '</option> ';
			}

			if (from_stored_qty_mode == 1) {
				html += '				<option value="1" selected>' + __("To check") + '</option> ';
			}else{
				html += '				<option value="1">' + __("To check") + '</option> ';
			}

			if (from_stored_qty_mode == 2) {
				html += '				<option value="2" selected>' + __("Bad") + '</option> ';
			}else{
				html += '				<option value="2">' + __("Bad") + '</option> ';
			}


			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';


			// To Stored qty mode
			html += '	<div style="position: relative; left: 320px; width:180px;">';
			html += '		<label id="html_qualification_split_line_qty_to_stored_qty_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("To stored qty mode") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 180px; height: 25px;"> ';
			html += '			<select id="html_qualification_split_line_qty_to_stored_qty_mode" class="input-with-feedback form-control bold"> ';

			if (to_stored_qty_mode == 0) {
				html += '				<option value="0" selected>' + __("Good") + '</option> ';
			}else{
				html += '				<option value="0">' + __("Good") + '</option> ';
			}

			if (to_stored_qty_mode == 1) {
				html += '				<option value="1" selected>' + __("To check") + '</option> ';
			}else{
				html += '				<option value="1">' + __("To check") + '</option> ';
			}

			if (to_stored_qty_mode == 2) {
				html += '				<option value="2" selected>' + __("Bad") + '</option> ';
			}else{
				html += '				<option value="2">' + __("Bad") + '</option> ';
			}

			html += '			</select> ';
			html += '		</div>';
			html += '	</div>';


			html += '</div>'



			splitLineQtyForm.fields_dict.html_qualification_split_line_qty.$wrapper.html(html);
			splitLineQtyForm.$wrapper.find('.modal-dialog').css("max-width", "548px").css("width", "548px");

			splitLineQtyForm.show();

		}

	}

	// ***************************************************************************************************************************************
	// Clear batch
	// **************************************************************************************************************************************
	clear_batch(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}

		let can_clear_batch = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_clear_batch = false;
		}

		if (can_clear_batch)
		{	cur_frm.dirty();
			item.batch_sn_id = '';
			item.batch_sn_code_ref = '';
			item.batch_sn_origin = '';

			this.frm.refresh_field('line_detail');

			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}
		}

	}

	// ***************************************************************************************************************************************
	// Select a batch
	// **************************************************************************************************************************************
	select_batch(form = false, idx = 0)
	{
		let item = null;

		if (form) {
			item = locals[document.getElementById('batch_line_cdt').value][document.getElementById('batch_line_cdn').value];
		}else{
			item = locals[cur_frm.fields_dict['line_detail'].grid.doctype][cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name];
		}


		let can_show_batch_frm = true;

		if (item.ioistatus != 0)
		{
			frappe.msgprint({title: __("Message"), message: __("Line status has to be in preparation"), indicator: "red"});
			can_show_batch_frm = false;
		}



		if ((can_show_batch_frm) && ((!item.from_warehouse_id) || ((item.from_warehouse_id) && (item.from_warehouse_id.trim() == ''))))
		{
			frappe.msgprint({title: __("Message"), message: __("Warehouse (from) has to be filled"), indicator: "red"});
			this.frm.refresh_field('line_detail');
			can_show_batch_frm = false;
		}


		if (can_show_batch_frm)
		{
			let me = this;
			let ccdt = null;
			let ccdn = null;

			if (form) {
				ccdt = document.getElementById('batch_line_cdt').value;
				ccdn = document.getElementById('batch_line_cdn').value;

			}else{
				ccdt = cur_frm.fields_dict['line_detail'].grid.doctype;
				ccdn = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name;
			}

			var z = new frappe.ui.Dialog({
				'title': __("Select a batch / SN in " + item.from_warehouse_id),
				'fields': [
					{'fieldname': 'html_select_batch', 'fieldtype': 'HTML'}

				],
				primary_action_label: 'Ok',
				secondary_action_label: __('Cancel'),
				primary_action: function(){

					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

					let go = true;

					if (document.getElementById('batch_nb_record').value == 0)
					{
						go = false;
					}

					if ((go) && (document.getElementById('batch_nb_record').value != 0))
					{	go = false
						for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
						{
							if (document.getElementById('batch_checked_id_' + i.toString()).checked)
							{
								document.getElementById('selected_batch_id').value = document.getElementById('batch_name_id_' + i.toString()).value;
								document.getElementById('selected_batch_location_id').value = document.getElementById('batch_location_id_' + i.toString()).value;
								document.getElementById('selected_batch_item_id').value = document.getElementById('batch_item_id_' + i.toString()).value;

								go = true;
								break;
							}
						}
					}

					if (go)
					{
						z.hide();
						cur_frm.dirty();
						item.batch_sn_id = document.getElementById('selected_batch_id').value;
						item.from_warehouse_location_id = document.getElementById('selected_batch_location_id').value;

						item.batch_sn_code_ref = '';
						item.batch_sn_origin = '';

						let qty = 0;

						if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id)
						{
							for (var i = 0; i < silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id.length; i++)
							{
								if (item.batch_sn_id == silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0])
								{
									if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3] != null)
									{
										item.batch_sn_code_ref = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3];
									}

									if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4] != null)
									{
										item.batch_sn_origin = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4];
									}

									if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1] != null)
									{
										qty = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1];
									}

								}
							}
						}

						if ((!item.item_id) || ((item.item_id) && (item.item_id.trim() == '')))
						{
							item.item_id = document.getElementById('selected_batch_item_id').value;


							let script_manager = cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx - 1].frm.script_manager;
							script_manager.trigger("item_id", cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[item.idx - 1].doc.name);
						}


						item.from_qty = qty;



						let pathstockqualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';

						let method = pathstockqualification + '.ioi_stock_qualification_get_batchsn_cost';

						frappe.call({  	method: method,
										args: {	"batch_sn_id" : item.batch_sn_id
										},
										async: false,
										callback:function(r)	{

											item.unit_stock_base_qualif_cost = r.message.cost;

										}
						});


						me.frm.refresh_field('line_detail');
						me.update_costing(me.frm, ccdt, ccdn);
						me.format_detail_form(me.frm, ccdt, ccdn);
					}else{
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
					}
				},
				secondary_action: function(){
					z.hide();
				}

			});

			if (document.getElementById('batch_nb_record'))
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batch_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_item_id_' + i.toString()))
					{
						document.getElementById('batch_item_id_' + i.toString()).remove();
					}

				}

				document.getElementById('batch_nb_record').remove();
			}

			if (document.getElementById("table_batch"))
			{
				document.getElementById("table_batch").remove();
			}

			if (document.getElementById('batch_filter'))
			{
				document.getElementById('batch_filter').remove();
			}

			if (document.getElementById('selected_batch_id'))
			{
				document.getElementById('selected_batch_id').remove();
			}

			if (document.getElementById('selected_batch_location_id'))
			{
				document.getElementById('selected_batch_location_id').remove();
			}

			if (document.getElementById('selected_batch_item_id'))
			{
				document.getElementById('selected_batch_item_id').remove();
			}


			let html = '';

			html += '<div style="overflow: auto; overflow-x: auto; height:70px; width: 100%;">';
			html += '	<div style="position: relative; left: 0px; width:600px;">';
			html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
			html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 598px; height: 25px;"> ';
			html += '			<input type="text" id="batch_filter" class="input-with-feedback form-control bold" value="">';
			html += '		</div>';
			html += '	</div>';
			html += '</div>'

			html += '<div style="overflow: auto; overflow-x: auto; height:600px; width: 100%;">';

			html += '<table border=1 style="border: 1px solid #E8EAEB" width=1560px data-custom-grid="true">';

			html += '<tr style="height:30px">';

			html += '<td width=30px align="center" style="vertical-align: middle;">&nbsp;</td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Batch") + '</b></td>';
			html += '<td width=100px style="vertical-align: middle;" align="center"><b>' + __("Qty") + '</b></td>';
			html += '<td width=80px style="vertical-align: middle;">&nbsp;<b>' + __("Locked") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Code ref") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Origin") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Location") + '</b></td>';
			html += '<td width=270px style="vertical-align: middle;">&nbsp;<b>' + __("Item") + '</b></td>';

			html += '</tr>';
			html += '</table>';


			let pathstockqualification = 'silicon_ioi.ioi_wms.doctype.ioi_stock_qualification.ioi_stock_qualification';
			let method = pathstockqualification + '.ioi_stock_qualification_get_enabled_batch_for_warehouse';

			let location_id = '';


			let warehouse_id = item.from_warehouse_id;

			if ((item.from_warehouse_location_id) && (item.from_warehouse_location_id.trim() != ''))
			{
				location_id = item.from_warehouse_location_id;
			}


			let item_id = '';

			if ((item.item_id) && (item.item_id.trim() != ''))
			{
				item_id = item.item_id;
			}


			let item_stored_qty_mode = item.from_stored_qty_mode;


			frappe.call({  	method: method,
							args: { "site_id": item.site_id, "warehouse_id": warehouse_id, "location_id": location_id, "item_id": item_id, "stored_qty_mode": item_stored_qty_mode},
							async: false,
							callback:function(r)	{

														silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id = [];

														if (r.message.length > 0)
														{
															html += '<input type="hidden" id="batch_nb_record" value="' + r.message.length + '">';
															html += '<table id="table_batch" border=1 style="border: 1px solid #E8EAEB" width=1560px>';

															for (var i = 0; i < r.message.length; i++)
															{
																silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i] = [r.message[i].name, r.message[i].qt, r.message[i].location_id, r.message[i].code_ref, r.message[i].origin, r.message[i].item_id, r.message[i].batch_sn_locked];


																html += '<tr id="batch_row_' + i.toString() + '" style="height:30px">';
																html += '<td width=30px align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="batch_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
																html += '       onclick=" ';
																html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
																html += '					{';
																html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
																html += '						{ ';
																html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
																html += '						} ';
																html += '					} ';
																html += '" ';

																if (item.batch_sn_id == silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0])
																{
																	html += ' checked ';
																}
																html += '>';
																html += '<input type="hidden" id="batch_name_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0] + '">';
																html += '<input type="hidden" id="batch_qt_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1] + '">';
																html += '<input type="hidden" id="batch_location_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2] + '">';
																html += '<input type="hidden" id="batch_item_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5] + '">';
																html += '</td>';

																// Batch_sn_id
																let v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0];

																html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';

																// Qty
																html += '<td width=100px style="vertical-align: middle;" align="right">' + silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1] + '&nbsp;</td>'

																// Locked

																if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][6] == 0) {
																	html += '<td width=80px style="vertical-align: middle;">&nbsp;</td>';
																}else{
																	html += '<td width=80px style="vertical-align: middle;" align="center">V</td>';
																}

																// Code Ref
																if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3] != null)
																{
																	v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3];
																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>';
																}else
																{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																}


																// Origin
																if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4] != null)
																{
																	v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4];
																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																}else
																{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																}

																// Location
																if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2] != null)
																{
																	v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2];
																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																}else
																{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																}


																// Item
																if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5] != null)
																{
																	v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5];
																	html += '<td width=270px style="vertical-align: middle;">&nbsp;' + v + '</td>'
																}else
																{ 	html += '<td width=270px style="vertical-align: middle;">&nbsp;</td>';
																}




																html += '</tr>';

															}
															html += '</table>';
														}else
														{	html += '<input type="hidden" id="batch_nb_record" value="0">';
														}
													}
			});

			html += '<input type="hidden" id="selected_batch_id" value="">';
			html += '<input type="hidden" id="selected_batch_location_id" value="">';
			html += '<input type="hidden" id="selected_batch_item_id" value="">';

			html += '</div>';


			z.fields_dict.html_select_batch.$wrapper.html(html);
			z.$wrapper.find('.modal-dialog').css("max-width", "50%").css("width", "50%");
			z.$wrapper.find('.modal-dialog').css("max-height", "50%").css("height", "50%");

			z.show();

			me.#sleep(250).then(() => {
				document.getElementById('batch_filter').onkeyup = this.#batch_filter_keyup;

			});
		}else
		{
			if (form) {
				this.format_detail_form(this.frm, document.getElementById('batch_line_cdt').value, document.getElementById('batch_line_cdn').value);
			}else{
				this.format_detail_form(this.frm, cur_frm.fields_dict['line_detail'].grid.doctype, cur_frm.fields_dict['line_detail'].grid.grid_rows[idx - 1].doc.name);
			}

		}

	}

	#batch_filter_keyup(event)
	{
		silicon_ioi.doctype.ioiQualifDocumentDocType.refresh_batches()
	}

	static refresh_batches()
	{
		if (document.getElementById('batch_nb_record'))
		{
			if (document.getElementById('batch_nb_record').value > 0)
			{
				for (var i = 0; i < document.getElementById('batch_nb_record').value; i++)
				{
					if (document.getElementById('batch_row_' + i.toString()))
					{
						document.getElementById('batch_row_' + i.toString()).remove();
					}

					if (document.getElementById('batch_checked_id_' + i.toString()))
					{
						document.getElementById('batch_checked_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_name_id_' + i.toString()))
					{
						document.getElementById('batch_name_id_' + i.toString()).remove();
					}

					if (document.getElementById('batch_qt_' + i.toString()))
					{
						document.getElementById('batc((can_fifo) &&h_qt_' + i.toString()).remove();
					}

					if (document.getElementById('batch_location_id_' + i.toString()))
					{
						document.getElementById('batch_location_id_' + i.toString()).remove();
					}


					if (document.getElementById('batch_item_id_' + i.toString()))
					{
						document.getElementById('batch_item_id_' + i.toString()).remove();
					}

				}
			}
		}

		let table = document.getElementById("table_batch");

		let cpt = 0;
		let create_row = false;


		for (var i = 0; i < silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id.length; i++)
		{
			create_row = false;
			if (document.getElementById('batch_filter').value.toString().trim() == '')
			{
				create_row = true;
			}else
			{
				let search = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				if ((silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0].toString().toUpperCase().search(search) != -1) ||
					((silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1] != null) && (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2] != null) && (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5] != null) && (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3] != null) && (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3].toString().toUpperCase().search(search) != -1)) ||
					((silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4] != null) && (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4].toString().toUpperCase().search(search) != -1)))
				{	create_row = true;
				}
			}

			if (create_row)
			{
				let row = table.insertRow(cpt);
				row.id = 'batch_row_' + cpt.toString();
				row.style = 'height:30px';

				let cell1 = row.insertCell(0);
				cell1.width = '30px;'
				cell1.align = 'center';
				cell1.style = 'vertical-align: middle';

				let html = '';
				html += '<input type="checkbox" id="batch_checked_id_' + cpt.toString() +'" style="postion:absolute; top: 2px; left: 2px;"';
				html += '       onclick=" ';
				html += '					for (var i = 0; i < document.getElementById(\'batch_nb_record\').value; i++) ';
				html += '					{';
				html += '						if (document.getElementById(\'batch_checked_id_\' + i.toString()).id != this.id) ';
				html += '						{ ';
				html += '							document.getElementById(\'batch_checked_id_\' + i.toString()).checked = false; ';
				html += '						} ';
				html += '					} ';
				html += '">';
				html += '<input type="hidden" id="batch_name_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0] + '">';
				html += '<input type="hidden" id="batch_qt_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1] + '">';
				html += '<input type="hidden" id="batch_location_id_' + cpt.toString() + '" value="' + silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2] + '">';
				html += '<input type="hidden" id="batch_item_id_' + i.toString() + '" value="' +  silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5] + '">';

				cell1.innerHTML = html;

				let cell2 = row.insertCell(1);
				cell2.width = '270px;'
				cell2.style = 'vertical-align: middle';

				let v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][0];


				let search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				let formatted_data = '';

				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}


				html = '&nbsp;' + formatted_data;
				cell2.innerHTML = html;

				let cell3 = row.insertCell(2);
				cell3.width = '270px;'
				cell3.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3] != null)
				{
					v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][3];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;



				cell3.innerHTML = html;

				let cell4 = row.insertCell(3);
				cell4.width = '270px;'
				cell4.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4] != null)
				{
					v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][4];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell4.innerHTML = html;



				let cell5 = row.insertCell(4);
				cell5.width = '270px;'
				cell5.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2] != null)
				{
					v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][2];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell5.innerHTML = html;




				let cell6 = row.insertCell(5);
				cell6.width = '270px;'
				cell6.style = 'vertical-align: middle';

				v = '';
				if (silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5] != null)
				{
					v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][5];
				}

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell6.innerHTML = html;






				let cell7 = row.insertCell(6);
				cell7.width = '300px;'
				cell7.style = 'vertical-align: middle';

				search_term = document.getElementById('batch_filter').value.toString().trim().toUpperCase();

				formatted_data = '';
				v = silicon_ioi.doctype.ioiQualifDocumentDocType.batch_id[i][1].toString();


				if (search_term != '') {

					search_term = search_term.toUpperCase();

					let data = v.toString();

					if (data != '') {
						data = data.trim().toUpperCase();

						if (data.indexOf(search_term) != -1) {
							let from_idx = data.indexOf(search_term);
							let len_search = search_term.length;

							let begin_data = v.substring(0, from_idx);
							let intermediate_data = v.substring(from_idx,  from_idx + len_search);
							let end_data = v.substring(from_idx+len_search, data.length);

							formatted_data = begin_data;

							formatted_data += '<font style="background-color:#D0E7FB"><b>' + intermediate_data + '</b></font>' + end_data;


						}else {
							formatted_data = v;
						}
					}else{
						formatted_data = v;
					}
				}else {
					formatted_data = v;
				}



				html = '&nbsp;' + formatted_data;


				cell7.innerHTML = html;

				cpt++;


			}
		}
		document.getElementById('batch_nb_record').value = cpt.toString();

	}


	// ***************************************************************************************************************************************
	// Action after change status
	// **************************************************************************************************************************************
	action_after_change_status()
	{
		if (this.frm.doc.ioistatus == 1)
		{
			//this.module_execute_action('ASK_TO_CREATE_LINKED_DOCUMENT');
		}

	}

	// ***************************************************************************************************************************************
	// Module execute action
	// **************************************************************************************************************************************
	module_execute_action(action)
	{
		let me = this;

		let method = this.path_user_change_status_action + '.ioi_user_change_status_action_exists';

		frappe.call({  	method: method,
						args: {"doctype" : this.frm.doctype, "name" : this.frm.doc.name, "to_status" : this.frm.doc.ioistatus, "action" : action},
						async: false,
						callback:function(r)	{
													if (r.message == 1)
													{
														let msg = '';



														frappe.confirm(	msg,
																		() => 	{
																					// action to perform if Yes is selected

																					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

																					method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																					frappe.call({  	method: method,
																									args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																									async: false,
																									callback:function(r)	{ 	}
																					});

																				},
																		() => 	{
																					// action to perform if No is selected or if click outsite the modal screen
																					method = me.path_user_change_status_action + '.ioi_user_change_status_action_delete';

																					frappe.call({  	method: method,
																									args: {"doctype" : me.frm.doctype, "name" : me.frm.doc.name, "to_status" : me.frm.doc.ioistatus, "action" : action},
																									async: false,
																									callback:function(r)	{ 	}
																					});

																				}
														);






													}
						}
		});

	}

	static assign_event_on_grid_first_column()
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {

			if (cur_frm.doc.line_detail)
			{
				for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
				{
					if (cur_frm.fields_dict['line_detail'].grid)
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0])
							{
								let idx = i;

								let fct_enter = function() {
									silicon_ioi.doctype.ioiQualifDocumentDocType.display_custom_info(idx);
								}

								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].wrapper[0].children[0].children[0].onmouseenter = fct_enter;
							}
						}
					}

				}
			}


			silicon_ioi.doctype.ioiQualifDocumentDocType.sleep_static(500).then(() => {

				silicon_ioi.doctype.ioiQualifDocumentDocType.assign_event_on_grid_first_column();
			});
		}
	}

	static display_custom_info(idx)
	{
		if (cur_frm.doctype.toUpperCase() == 'IOI STOCK QUALIFICATION') {

			if (cur_frm.fields_dict['line_detail'].grid)
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
				{
					let name = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['name'];

					let method = 'silicon_ioi.common.common.ioi_common_get_tooltip';

					frappe.call({  	method: method,
									args: {"tooltip_id": 1, "doctype": cur_frm.doctype, "parent": cur_frm.doc.name, "name": name},
									async: false,
									callback:function(r)	{
																let html = r.message.content;
																let time_display = r.message.time_display;

																if (html.trim() != '') {
																	silicon_ioi.ioiCommon.show_grid_info_popup(html, time_display);
																}
									}
					});
				}
			}
		}
	}

	static assign_event_on_grid_qty()
	{
		let qty_field = 'from_qty';
		let qty_field_to = 'to_qty';


		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if ((cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field]) || (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field_to]) )
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiQualifDocumentDocType.display_stock_info(idx);
							}

							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field]) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field][0].onmouseenter = fct_enter;
							}

							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field_to]) {
								cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[qty_field_to][0].onmouseenter = fct_enter;
							}
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiQualifDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiQualifDocumentDocType.assign_event_on_grid_qty();
		});


	}


	static display_stock_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'])
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'].trim() != '')
					{
						let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id']

						let from_warehouse_id = '';
						let from_warehouse_location_id = '';

						let to_warehouse_id = '';
						let to_warehouse_location_id = '';


						// From warehouse
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_warehouse_id'])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_warehouse_id'].trim() != '')
							{
								from_warehouse_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_warehouse_id'];
							}else
							{
								if (cur_frm.doc.from_warehouse_id)
								{
									if (cur_frm.doc.from_warehouse_id.trim() != '')
									{
										from_warehouse_id = cur_frm.doc.from_warehouse_id;
									}
								}
							}

						}
						// From warehouse - location
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_warehouse_location_id'])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_warehouse_location_id'].trim() != '')
							{
								from_warehouse_location_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_warehouse_location_id'];
							}
						}


						// To warehouse
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['to_warehouse_id'])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['to_warehouse_id'].trim() != '')
							{
								to_warehouse_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['to_warehouse_id'];
							}
						}

						// To warehouse - location
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['to_warehouse_location_id'])
						{
							if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['to_warehouse_location_id'].trim() != '')
							{
								to_warehouse_location_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['to_warehouse_location_id'];
							}
						}


						let line_storedqtymode = 0;

						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_stored_qty_mode'])
						{
							line_storedqtymode = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['from_stored_qty_mode'];
						}

						let me = this;

						let method = 'silicon_ioi.ioi_wms.doctype.ioi_warehouse_stock.ioi_warehouse_stock.ioi_warehouse_stock_get_site_fromwhs_fromloc_towhs_toloc_stock_for_an_item';


						frappe.call({  	method: method,
										args: {	"item_id": item_id,
												"from_warehouse_id": from_warehouse_id,
												"from_warehouse_location_id": from_warehouse_location_id,
												"to_warehouse_id": to_warehouse_id,
												"to_warehouse_location_id" : to_warehouse_location_id
										},
										async: false,
										callback:function(r)	{
																	let site_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.site_stock_qty != 0) {
																			site_qt = r.message.site_stock_qty
																		}
																	}else if (line_storedqtymode == 1) {
																		if (r.message.site_tocheck_qty != 0) {
																			site_qt = r.message.site_tocheck_qty
																		}
																	}else if (line_storedqtymode == 2) {
																		if (r.message.site_bad_qty != 0) {
																			site_qt = r.message.site_bad_qty
																		}
																	}

																	let from_whs_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.from_whs_stock_qty != 0) {
																			from_whs_qt = r.message.from_whs_stock_qty
																		}
																	}else if (line_storedqtymode == 1) {
																		if (r.message.from_whs_tocheck_qty != 0) {
																			from_whs_qt = r.message.from_whs_tocheck_qty
																		}
																	}else if (line_storedqtymode == 2) {
																		if (r.message.from_whs_bad_qty != 0) {
																			from_whs_qt = r.message.from_whs_bad_qty
																		}
																	}


																	let from_loc_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.from_loc_stock_qty != 0) {
																			from_loc_qt = r.message.from_loc_stock_qty
																		}
																	}else if (line_storedqtymode == 1) {
																		if (r.message.from_loc_tocheck_qty != 0) {
																			from_loc_qt = r.message.from_loc_tocheck_qty
																		}
																	}else if (line_storedqtymode == 2) {
																		if (r.message.from_loc_bad_qty != 0) {
																			from_loc_qt = r.message.from_loc_bad_qty
																		}
																	}


																	let to_whs_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.to_whs_stock_qty != 0) {
																			to_whs_qt = r.message.to_whs_stock_qty
																		}
																	}else if (line_storedqtymode == 1) {
																		if (r.message.to_whs_tocheck_qty != 0) {
																			to_whs_qt = r.message.to_whs_tocheck_qty
																		}
																	}else if (line_storedqtymode == 2) {
																		if (r.message.to_whs_bad_qty != 0) {
																			to_whs_qt = r.message.to_whs_bad_qty
																		}
																	}


																	let to_loc_qt = 0;

																	if (line_storedqtymode == 0) {
																		if (r.message.to_loc_stock_qty != 0) {
																			to_loc_qt = r.message.to_loc_stock_qty
																		}
																	}else if (line_storedqtymode == 1) {
																		if (r.message.to_loc_tocheck_qty != 0) {
																			to_loc_qt = r.message.to_loc_tocheck_qty
																		}
																	}else if (line_storedqtymode == 2) {
																		if (r.message.to_loc_bad_qty != 0) {
																			to_loc_qt = r.message.to_loc_bad_qty
																		}
																	}


																	let lib_qt = '';

																	if (line_storedqtymode == 0) {
																		lib_qt = 'Stock qty';
																	}else if (line_storedqtymode == 1) {
																		lib_qt = 'Tocheck qty';
																	}else if (line_storedqtymode == 2) {
																		lib_qt = 'Bad qty';
																	}


																	let html = '';

																	if (r.message.is_obsolete == 1) {
																		html += '<div style="position: relative; top: 0px; left: 0px; width: 100%">';
																		html += '	<label style="position: absolute; top: 0px; left: 0px;"><font color="red">' + __("This item is obsolete") + '</font></label>';
																		html += '</div><br>';


																	}



																	html += '<div style="overflow: auto; overflow-x: auto; height:100px; width: 100%;">';

																	html += '<table border=0 width=570px>';

																	// Site

																	html += '<tr height=20px>';

																	html += '<td colspan=2>&nbsp;<u><b>' + __("Current stock") + '</b></u></td>';
																	html += '<td width=125px align="right"><u><b>' + lib_qt + '</b></u>&nbsp;</td>';

																	html += '<td width=80px>&nbsp;</td>';
																	html += '<td width=80px>&nbsp;</td>';
																	html += '<td width=125px align="right"><u><b>' + lib_qt + '</b></u>&nbsp;</td>';

																	html += '</tr>';

																	html += '<tr height=20px>';

																	html += '<td width=80px>&nbsp;<b>' + __("Site") + '</b></td>';
																	html += '<td width=80px>&nbsp;' + r.message.site_id + '</td>';
																	html += '<td width=125px align="right">' + site_qt + '&nbsp;' + r.message.site_unit + '&nbsp;</td>';


																	html += '<td width=80px>&nbsp;</td>';
																	html += '<td width=80px>&nbsp;</td>';
																	html += '<td width=125px>&nbsp;</td>';



																	html += '</tr>';


																	// Whs
																	html += '<tr height=20px>';

																	html += '<td width=80px>&nbsp;<b>' + __("From whs") + '</b></td>';
																	html += '<td width=80px>&nbsp;' + r.message.from_whs_id + '</td>';
																	html += '<td width=125px align="right">' + from_whs_qt + '&nbsp;' + r.message.from_whs_unit + '&nbsp;</td>';

																	html += '<td width=80px>&nbsp;<b>' + __("To whs") + '</b></td>';
																	html += '<td width=80px>&nbsp;' + r.message.to_whs_id + '</td>';
																	html += '<td width=125px align="right">' + to_whs_qt + '&nbsp;' + r.message.to_whs_unit + '&nbsp;</td>';
																	html += '</tr>';

																	// Locations

																	html += '<tr height=20px>';

																	html += '<td width=80px>&nbsp;<b>' + __("From loc") + '</b></td>';

																	let s = r.message.from_loc_id;

																	if ((s != null) && (s != ''))
																	{
																		s = s.substring(s.indexOf('•')+1, s.length)
																		s = s.trim();
																	}

																	html += '<td width=80px>&nbsp;' + s + '</td>';
																	html += '<td width=125px align="right">' + from_loc_qt + '&nbsp;' + r.message.from_loc_unit + '&nbsp;</td>';

																	html += '<td width=80px>&nbsp;<b>' + __("To loc") + '</b></td>';

																	s = r.message.to_loc_id;

																	if ((s != null) && (s != ''))
																	{
																		s = s.substring(s.indexOf('•')+1, s.length)
																		s = s.trim();
																	}

																	html += '<td width=80px>&nbsp;' + s + '</td>';
																	html += '<td width=125px align="right">' + to_loc_qt + '&nbsp;' + r.message.to_loc_unit + '&nbsp;</td>';

																	html += '</tr>';


																	html += '</table>';

																	html += '</div>';

																	silicon_ioi.ioiCommon.show_grid_info_popup(html)
										}
						});

					}
				}
			}
		}
	}

	static assign_event_on_grid_description()
	{
		let description_field = 'item_description';

		if (cur_frm.doc.line_detail)
		{
			for (var i = 0; i < cur_frm.doc.line_detail.length; i++)
			{
				if (cur_frm.fields_dict['line_detail'].grid)
				{
					if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i])
					{
						if (cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[description_field])
						{
							let idx = i;

							let fct_enter = function() {
								silicon_ioi.doctype.ioiQualifDocumentDocType.display_description_info(idx);
							}

							cur_frm.fields_dict['line_detail'].grid.grid_rows[i].columns[description_field][0].onmouseenter = fct_enter;
						}
					}
				}

			}
		}


		silicon_ioi.doctype.ioiQualifDocumentDocType.sleep_static(500).then(() => {

			silicon_ioi.doctype.ioiQualifDocumentDocType.assign_event_on_grid_description();
		});
	}

	static display_description_info(idx)
	{
		if (cur_frm.fields_dict['line_detail'].grid)
		{
			if (cur_frm.fields_dict['line_detail'].grid.grid_rows[idx])
			{
				let item_id = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
				let manufacturer_ref = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];

				let what = ''
				let identification = ''

				if ((item_id) && (item_id.trim() != '')) {
					what = 'ITEM_ID';
					identification = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['item_id'];
				}else if ((manufacturer_ref) && (manufacturer_ref.trim() != '')) {
					what = 'MANUFACTURER_REF';
					identification = cur_frm.fields_dict['line_detail'].grid.grid_rows[idx].doc['manufacturer_ref'];
				}

				if (what != '') {

					let method = 'silicon_ioi.common.sales_document.ioi_sales_document_tooltip_item_description';

					let data = {};

					frappe.call({  	method: method,
									args: {"what": what, "identification": identification},
									async: false,
									callback:function(r)	{
										data = r.message
									}
								});

					let html = '';

					if (data.customized == 0) {

						if (data.content.trim() != '') {
							html = '<table width=700px>';
							html += '<tr height=20px>';
							if (what == 'ITEM_ID')  {
								html += '<td width=250px><b><u>' + __("Item") + '</u></b></td>';
							}else{
								html += '<td width=250px><b><u>' + __("Manufacturer ref") + '</u></b></td>';
							}
							html += '<td width=450px><b><u>' + __("Description") + '</u></b></td>';
							html += '</tr>';
							html += '<tr height=20px>';
							html += '<td width=250px>' + identification + '</td>';
							html += '<td width=450px%>' + data.content + '</td>';
							html += '</tr>';
							html += '</table>';
						}
					}else{
						if (data.content.trim() != '') {
							html = data.content;
						}
					}

					if (html.trim() != '') {
						silicon_ioi.ioiCommon.show_grid_info_popup(html);
					}

				}

			}
		}
	}
}

silicon_ioi.doctype.ioiQualifDocumentDocType = ioiQualifDocumentDocType;
