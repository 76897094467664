// *******************************************************************************************************************************************
// Parent Class of all DocType classes
// *******************************************************************************************************************************************

frappe.provide('silicon_ioi.doctype');

export class ioiDocType
{
	path_common = 'silicon_ioi.common.common';
	path_general_settings = 'silicon_ioi.ioi_configuration.doctype.ioi_general_settings.ioi_general_settings';
	path_user_public_section = 'silicon_ioi.ioi_configuration.doctype.ioi_user_public_section_configuration.ioi_user_public_section_configuration';
	path_module = 'silicon_ioi.ioi_configuration.doctype.ioi_module.ioi_module';
	path_user_module_config = 'silicon_ioi.ioi_configuration.doctype.ioi_user_module_config.ioi_user_module_config';
	path_user_screen_context = 'silicon_ioi.ioi_configuration.doctype.ioi_user_screen_context.ioi_user_screen_context';
	path_user = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user';
	path_site = 'silicon_ioi.ioi_enterprise.doctype.ioi_site.ioi_site';
	path_event_todo_file = 'silicon_ioi.ioi_crm.event_todo_file';
	imgextpath = '/assets/silicon_ioi/images/extensions/';
	url_event = '/app/event/';
	url_todo = '/app/todo/';
	img_param_path = '/assets/silicon_ioi/images/buttons/parameters.svg';
	img_section_down = '/assets/silicon_ioi/images/buttons/arrow_down.png';
	img_section_up = '/assets/silicon_ioi/images/buttons/arrow_up.png';

	section_original_label = [];
	section_customized_label = [];

	current_site = '';
	usual_site = '';

	constructor(frm)
	{
		this.frm = frm;

		if (this.frm.doctype.toUpperCase() != 'IOI MODULE')
		{
			if (this.frm.fields_dict.html_module_logs)
			{
				this.#module_load_logs_list();
			}

			if (this.frm.fields_dict.html_module_events)
			{
				this.events_build_page();
			}

			if (this.frm.fields_dict.html_module_todo)
			{
				this.todo_build_page();
			}

			if (this.frm.fields_dict.html_module_files)
			{
				this.files_build_page();
				document.getElementById('file_bt_search').click();
			}
		}

		this.clear_ioi_side_view()

		let me = this;

		$(document).ready(function () {
			window.setTimeout(() => me.after_refresh(), 50);

			frappe.realtime.on(`doc_to_sign_status_changed_${me.frm.doc.doctype}_${me.frm.doc.name}`, (message) => {
				me.refresh_last_doc_to_sign_status();
			});

			frappe.realtime.on(`doc_gw_outbox_status_changed_${me.frm.doc.doctype}_${me.frm.doc.name}`, (message) => {
				me.refresh_last_doc_gw_outbox_status();
			});
		});
	}

	after_refresh()
	{	this.load_function_menu();

		let method = this.path_module + '.ioi_module_can_select_site_scan_active';
		let can_select_site = 0
		let scan_active = 0

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{ 	can_select_site = r.message.can_select_site;
													scan_active = r.message.scan_active;
												}
		});

		if (can_select_site == 1) {

			this.check_user_site();

			if (!this.is_multi_site) {
				let site_desc = __('select a site')
				if (this.current_site.trim() != '') site_desc = 'Site : ' + this.current_site;

				this.frm.remove_custom_button(site_desc);

			} else {
				this.add_change_site_to_mainmenu();
			}
		}

		this.init_side_view()
		this.jump_to_db_companies();

		if (scan_active == 0) {
			if (document.getElementById('sb_main_scan_section')) {

				if (document.getElementById('sb_main_scan_check')) {
					document.getElementById('sb_main_scan_check').checked = false;
				}

				document.getElementById('sb_main_scan_section').hidden = true;
			}
		}else {
			if (document.getElementById('sb_main_scan_section')) {
				document.getElementById('sb_main_scan_section').hidden = false;
			}
		}

		this.load_user_screen_context();
		this.assign_click_on_tab();
		this.assign_click_on_section();
		this.check_db_alerts();
		this.show_labels_print_btn();
		this.add_dropdown_prefix();
		this.refresh_last_doc_to_sign_status();
		this.refresh_last_doc_gw_outbox_status();
	}

	add_dropdown_prefix() {
		silicon_ioi.ioiCommon.add_dropdown_prefix('F', cur_frm.doctype);
	}

	async refresh_last_doc_to_sign_status(){
		if (this.frm.meta.fields.filter((f) => f.fieldname == "last_doc_to_sign_html").length > 0){
			let output = {};
			frappe.call({
				method:"silicon_ioi.ioi_core.doctype.ioi_doc_to_sign.ioi_doc_to_sign.get_last_doc_to_sign_info",
				args:{
					dt: this.frm.doc.doctype,
					dn: this.frm.doc.name
				},
				async: false,
				callback: (r) => {
					if (r.message.success){
						output = r.message
					}
				}
			});


			this.frm.fields_dict.last_doc_to_sign_html.$wrapper.html("");
			if (output.success){
				let btn_show_signed_file = "";

				if (output.signed_file_name){
					btn_show_signed_file = `
					<span  title="${__("Get signed doc")}" data-toggle="tooltip" >
						<svg class="icon  icon-sm" style="margin-top: -3px; cursor: pointer;" aria-hidden="true" onclick="window.open('/api/method/silicon_ioi.utils.lib.ioiFile.download_file?name=${output.signed_file_name}');">
							<use class="" href="#icon-file"></use>
						</svg>
					</span>
					`
				}

				let doc_to_sign_info_html = `
				<div>
					<div class="clearfix">
						<label class="control-label" style="padding-right: 0px;">${__("Last signed doc information")}</label>
					</div>
					<div class="control-value like-disabled-input">
						<b><a href="/app/ioi-doc-to-sign/${output.doc_name}" target="_blank" style="color: var(--disabled-text-color)">${__("More info")}</a></b>${btn_show_signed_file}</br>
						<b>${__("Status")}: </b>
						<span class="indicator-pill no-indicator-dot whitespace-nowrap ${output.status_color}"><span>${output.status_label}</span></span></br>
						<b>${__("Updated on")}: </b> <label>${cstr(output.updated_on)}</label></br>

					</div>
				</div>
				`;
				this.frm.fields_dict.last_doc_to_sign_html.$wrapper.html(doc_to_sign_info_html);
			}
		}
	}

	async refresh_last_doc_gw_outbox_status(){
		if (this.frm.meta.fields.filter((f) => f.fieldname == "last_doc_gateway_outbox_html").length > 0){
			let output = {};
			frappe.call({
				method:"silicon_ioi.ioi_gateway.doctype.ioi_doc_gateway_outbox.ioi_doc_gateway_outbox.get_last_doc_gw_outbox_info",
				args:{
					dt: this.frm.doc.doctype,
					dn: this.frm.doc.name
				},
				async: false,
				callback: (r) => {
					if (r.message.success){
						output = r.message
					}
				}
			});

			this.frm.fields_dict.last_doc_gateway_outbox_html.$wrapper.html("");
			$("#last_doc_gateway_outbox_html").remove();
			if (output.success){
				let doc_gw_outbox_info_html = `
				<div id="last_doc_gateway_outbox_html" title="${__("Click to refresh")}">
					<div class="clearfix">
						<label class="control-label" style="padding-right: 0px;">${__("PEPPOL")}</label>
					</div>
					<div class="control-value like-disabled-input">
						<b><a href="/app/ioi-doc-gateway-outbox/${output.doc_name}" target="_blank" style="color: var(--disabled-text-color)">${__("More info")}</a></b></br>
						<b>${__("Status")}: </b>
						<span class="indicator-pill no-indicator-dot whitespace-nowrap ${output.status_color}"><span>${output.status_label}</span></span></br>
						<b>${__("Updated on")}: </b> <label>${cstr(output.updated_on)}</label></br>
					</div>
				</div>
				`;
				this.frm.fields_dict.last_doc_gateway_outbox_html.$wrapper.html(doc_gw_outbox_info_html);
			}
		}
	}



	async show_labels_print_btn(){
		let show_btn = await silicon_ioi.ioiCommon.is_accept_manual_labelling(cur_frm.doctype);

		if (show_btn){
			let me = this;
			silicon_ioi.ioiCommon.add_custom_action_in_page(me.frm.page, 'fa-solid fa-qrcode fa-lg',() => {
				silicon_ioi.ioiLabelling.init_print_labels(me.frm, me.frm.doctype, me.frm.docname);
			},"s",__("Print labels"))
		}

	}

	check_db_alerts() {
		if (frappe.boot.user.user_type.toLowerCase() == 'system user' && frappe.boot.db_alerts.find((e) => e.TABLE_NAME == 'tab'+this.frm.doctype)){
			let me = this;
			let db_advice_id = `db_advice_id_${frappe.scrub(this.frm.doctype)}`;
			if (document.getElementById(db_advice_id)){
				document.getElementById(db_advice_id).remove();
			}
			let href = `<a id="${db_advice_id}">${__('here')}</a>`;
			let msg = `${__('The system has identified potential optimization opportunities for the doctype')}.<br>${__('Please refer to the recommendations provided')} ${href}`;
			this.frm.dashboard.add_comment(msg, "yellow", true);
			setTimeout(() => {
				let tabledata = [];

				for(var i = 0; i < frappe.boot.db_alerts.length; i++){
					if (frappe.boot.db_alerts[i].TABLE_NAME == 'tab'+me.frm.doctype){
						tabledata.push({
							doctype_name: frappe.boot.db_alerts[i].TABLE_NAME,
							field_name : '',
							field_type: '',
							size: frappe.boot.db_alerts[i].EST_MAX_ROW_SIZE
						})

						for(var j = 0; j < frappe.boot.db_alerts[i].FIELDS.length; j++){
							tabledata.push({
								doctype_name: '',
								field_name : frappe.boot.db_alerts[i].FIELDS[j].COLUMN_NAME,
								field_type: frappe.boot.db_alerts[i].FIELDS[j].DATA_TYPE,
								size: frappe.boot.db_alerts[i].FIELDS[j].COL_SIZE
							})
						}
					}
				}

				$(`#${db_advice_id}`).on('click', () =>{
					let dlg =  new frappe.ui.Dialog({
						title: "List of doctypes and fields: Optimization detected",
						fields: [{'fieldname': 'html_tab', 'fieldtype': 'HTML'}],
						primary_action: () => {
							dlg.hide();
						},
						primary_action_label: "OK",
						on_page_show: () => {

							if (document.getElementById('db_advice_tabulator_id')) {
								document.getElementById('db_advice_tabulator_id').remove();
							}

							let html_tabulator_div = '<div id="db_advice_tabulator_id" class="table table-bordered" data-custom-grid="true"></div>';
							$(html_tabulator_div).appendTo(dlg.fields_dict['html_tab'].$wrapper);
							let tabulator = new ioi.Tabulator("#db_advice_tabulator_id",
							{
								maxHeight: 800,
								rowHeight: 30,
								data: tabledata,
								layout: "fitColumns",
								selectableRows: false,
								movableColumns: false,
								resizableColumns: true,
								showProfiles: false,
								autoRedraw: true,
								columns: [
									{title: __('Doctype'), field: 'doctype_name', width: 170, headerSort: false},
									{title: __('Fieldname'), field: 'field_name', width: 190, headerSort: false},
									{title: __('Type'), field: 'field_type', width: 80, headerSort: false},
									{title: __('Size'), field: 'size', width: 80, headerSort: false}
								]
							});
						}
					});

					dlg.show();
				}
				);
			},500)

		}
	}

	// ***************************************************************************************************************************************
	// Add Side View buttons
	// **************************************************************************************************************************************
	init_side_view() {

		this.add_menu_side_bar()

		frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: this.frm.doctype, side_view_origin: 'FORM' }).then(r => {
			const res = r.message

			if (res && res.side_view_type) {
				if (res.side_view_type === "TODO") this.show_todo_side_view(res);
				if (res.side_view_type === "CALENDAR") this.show_calendar_side_view(res);
				if (res.side_view_type === "PDF") this.show_pdf_side_view(res);
				if (res.side_view_type === "RELATED") this.show_related_data_side_view(res)
				if (res.side_view_type === "HIGHLIGHT") this.show_highlight_side_view(res)
				if (res.side_view_type === "EMAILS") this.show_emails_sent_side_view(res)
			}
		})

		$(document).on("form-unload.clear_file_preview", (e2, frm) => {
			this.clear_ioi_side_view()
			$(document).off(".clear_file_preview");
		})
	}

	add_menu_side_bar() {
		let me = this;

		const buttons = [
			{ label: __("PDF Side View"), type: "PDF", action: me.show_pdf_side_view },
			{ label: __("Todo Side View"), type: "TODO", action: me.show_todo_side_view, condition: this.frm.fields_dict.html_module_todo },
			{ label: __("Calendar Side View"), type: "CALENDAR", action: me.show_calendar_side_view, condition: this.frm.fields_dict.html_module_events },
			{ label: __("Related Side View"), type: "RELATED", action: me.show_related_data_side_view },
			{ label: __("Highlight Info Side View"), type: "HIGHLIGHT", action: me.show_highlight_side_view },
			{ label: __("Emails Sent Side View"), type: "EMAILS", action: me.show_emails_sent_side_view }
		];

		const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layout-sidebar-inset-reverse" viewBox="0 0 16 16">
							<path d="M2 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z"/>
							<path d="M13 4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1z"/>
						</svg>`;

		buttons.forEach(({ label, type, action, condition }) => {
			if (condition ? condition : true) {
				this.frm.add_custom_button(label, () => {
					frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: this.frm.doctype, side_view_origin: 'FORM' }).then(res => {
						frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 1, doctype: this.frm.doctype, type: type, is_new: res.message ? 0 : 1, side_view_origin: 'FORM' }).then(r => action.call(me, r.message));
					});
				}, icon);
			}
		})
	}

	clear_ioi_side_view() {
		let side_view = $(`[data-page-route="${this.frm.doctype}"]`).find("#ioi_side_view")
		let side_view_resizer = $(`[data-page-route="${this.frm.doctype}"]`).find("#ioi_side_view_resizer")

		if (side_view) side_view.remove()
		if (side_view_resizer) side_view_resizer.remove()
	}

	check_side_view_res(saved_width) {
		if (saved_width > (window.innerWidth/2.25)) {
			saved_width = 0
			frappe.call('silicon_ioi.utils.lib.system.save_pdf_viewer_width', { doctype: this.frm.doctype, width: 0 });
		}

		return saved_width
	}

	show_todo_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'Form') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {
				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)
				const side_view_height = 950

				const html = `<div id="ioi_side_view" class="p-4 ml-3 rounded" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};"></div>`;
				const resizer_html = `<div id="ioi_side_view_resizer" class="resizer rounded" style="height: ${side_view_height - 50}px;"></div>`;
				const main_layout = $(`[data-page-route="${this.frm.doctype}"]`).find(".row.layout-main");

				this.clear_ioi_side_view()
				main_layout.append(resizer_html).append(html)

				this.todo_sv_build_page()
				this.create_resizer(this.frm.doctype);

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_side_view()
			}
		} else {
			this.clear_ioi_side_view()
		}
	}


	// ***************************************************************************************************************************************
	// Todo : Build side view
	// ***************************************************************************************************************************************
	async todo_sv_build_page() {
		let me = this

		this.event_categories = await frappe.call('silicon_ioi.utils.lib.system.get_categories', { doctype: this.frm.doctype }).then(r => [''].concat(r.message))

		this.allocated_users = await frappe.call('silicon_ioi.utils.lib.system.get_allocated_users').then(r => [''].concat(r.message))

		const search_input = `	<div class="m-1" style="width: 150px;">
									<label style="font-size: var(--text-md);">${__('Search')}</label>
									<div class="control-input" >
										<input id="todo_sv_search" type="text" class="input-with-feedback form-control bold">
									</div>
								</div>`


		const from_button = `<div class="m-1">
								<label style="font-size: var(--text-md);">${__('From')}</label>
								<div class="control-input">
									<input id="todo_sv_dt_from" type="date" class="input-with-feedback form-control bold">
								</div>
							</div>`

		const to_button = `	<div class="m-1">
								<label style="font-size: var(--text-md);">${__('To')}</label>
								<div class="control-input">
									<input id="todo_sv_dt_to" type="date" class="input-with-feedback form-control bold" pattern="\d{4}-\d{2}-\d{2}">
								</div>
							</div>`

		const status_button = `	<div class="m-1">
									<label style="font-size: var(--text-md);">${__('Status')}</label>
									<div class="control-input">
										<select id="todo_sv_status" class="input-with-feedback form-control bold">
											<option value="" selected></option>
											<option value="${__('Open')}">${__('Open')}</option>
											<option value="${__('Close')}">${__('Close')}</option>
											<option value="${__('Cancelled')}">${__('Cancelled')}</option>
										</select>
									</div>
								</div>`

		const only_open = `<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" id="todo_sv_only_open" checked>
								<label class="form-check-label" style="font-size: var(--text-md);" for="todo_sv_only_open">${__('Only open')}</label>
							</div>`

		const only_mine = `<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" id="todo_sv_only_mine" checked>
								<label class="form-check-label" style="font-size: var(--text-md);" for="todo_sv_only_mine">${__('Only mine')}</label>
							</div>`

		const documents = () => {
			let html = ''

			if ((this.frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER')) {
				html += `<div class="m-1">
					<label id="todo_sv_document_label" style="font-size: var(--text-md);">${__("Documents")}</label>
					<div class="control-input d-flex flex-row flex-wrap p-2 rounded" style="min-height: 33.5px; background-color: var(--control-bg);">`;

				if (this.frm.doctype.toUpperCase() == 'IOI CUSTOMER') {
					const sales = [__('Sales Quote'), __("Sales Order"), __("Sales Delivery"), __("Sales Invoice")]

					sales.map((item, i) => html += `<div class="form-check form-check-inline">
														<input type="hidden" id="todo_sv_nb_document" value="4">
														<input class="form-check-input" value="ioi ${item}" type="checkbox" id="todo_sv_document_${i}" checked>
														<label class="form-check-label" style="font-size: var(--text-md);" for="todo_sv_document_${i}">${item}</label>
													</div>`)

				} else if (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER') {
					const purchases = [__('Purchases Price Request'), __("Purchases Order"), __("Purchases Receipt"), __("Purchases Invoice")]

					purchases.map((item, i) => html += `<div class="form-check form-check-inline">
														<input type="hidden" id="todo_sv_nb_document" value="4">
														<input class="form-check-input" value="ioi ${item}" type="checkbox" id="todo_sv_document_${i}" checked>
														<label class="form-check-label" style="font-size: var(--text-md);" for="todo_sv_document_${i}">${item}</label>
													</div>`)
				}
				html += `</div>
					</div>`;
			}
			return html
		}

		const ioi_category_button = `<div class="m-1">
									<label id="todo_sv_ioi_category_label" style="font-size: var(--text-md);">${__("ioi Category")}</label>
									<div class="control-input" style="">
										<select id="todo_sv_ioi_category" class="input-with-feedback form-control bold">
											${ this.event_categories.map(el => `<option value="${el}">${el}</option>`) }
										</select>
									</div>
								</div>`

		const topn_button = `<div class="m-1">
								<label id="todo_sv_topn_label" style="font-size: var(--text-md);">${__("No records")}</label>
								<div class="control-input" style="">
									<input id="todo_sv_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
								</div>
							</div>`

		const search_button = `<div class="m-1">
									<button id="todo_sv_bt_search" title="${__("Refresh")}" data-label="${__("Search")}" class="btn btn-default btn-xs ellipsis" style="" onclick="">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
											<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
										</svg>
									</button>
								</div>`

		const clear_button = `<div class="m-1">
								<button id="todo_sv_bt_clear" title="${__("Clear")}" data-label="${__("Search")}" class="btn btn-default btn-xs ellipsis" onclick="">${__("...")}</button>
							</div>`

		const new_button = `<div id="todo_sv_bt_new" class="m-1">
								<button title="${__("Add")}" data-label="Add todo" class="btn btn-default btn-xs ellipsis" onclick="
									if (cur_frm.is_new()) {
										frappe.msgprint({title: __('Message'), message: __('Save or cancel data before'), indicator: 'red'});
										raise;
									}

									// filter ioi Category with Reference Doctype
									frappe.new_doc('ToDo', { reference_type: '${me.frm.doctype}', reference_name: '${me.frm.doc.name}'}, doc => {
										doc.fields_dict.ioi_category_id.get_query = function() {
											return {
												filters: {
													related_doctype: '${me.frm.doctype}'
												}
											};
										};	
									});
								">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
										<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
									</svg>
								</button>
							</div>`

		const duplicate_button = `<div id="todo_sv_bt_duplicate" class="m-1">
									<button title="${__("Duplicate ToDo")}" data-label="Duplicate todo" class="btn btn-default btn-xs ellipsis">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
										</svg>
									</button>
								</div>`

		const delete_button = `	<div id="todo_sv_bt_delete" class="m-1">
									<button title="${__("Delete")}" data-label="Delete" class="btn btn-default btn-xs ellipsis">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
											<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
											<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
										</svg>
									</button>
								</div>`;

		const hide_button = `<button id="close_ioi_side_view" class="btn btn-xs position-absolute shadow-none" style="top: -21px; right: -21px;">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
									<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
								</svg>
							</button>`

		const control_panel = `
			<div class="d-flex flex-column w-100 position-relative" style="max-height: 100%;">
				${hide_button}
				<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
					${search_input}
					${from_button}
					${to_button}
					${status_button}
					${ioi_category_button}
					${topn_button}
					${documents()}
				</div>
				<div class="d-flex flex-row flex-wrap-reverse m-1 justify-content-between">
					<div class="d-flex my-1">
						<div class="d-flex flex-row mr-3">
							${search_button}
							${clear_button}
						</div>
						<div id="todo_sv_actions" class="d-flex flex-row">
							${new_button}
							${duplicate_button}
							${delete_button}
						</div>
					</div>
					<div class="d-flex flex-row m-1">
						${only_open}
						${only_mine}
					</div>
				</div>
				<input id="todo_sv_orderby" type="hidden" value="date">
				<input id="todo_sv_order" type="hidden" value="desc">
				<div id="todo_sv_grid" class="table table-bordered" style="max-height: 100%; font-size: var(--text-md);" data-custom-grid="true"></div>
			</div>`

		$("#ioi_side_view").append(control_panel);

		this.#todo_sv_grid([])

		let fct_search = function () { me.#todo_search(me.todo_sv_table); };
		let fct_clear = function () { me.#todo_clear(me.todo_sv_table); };

		document.getElementById('todo_sv_search').onkeydown = this.#todo_sv_search_key_down;

		document.getElementById('todo_sv_topn').onkeydown = this.#todo_sv_search_key_down;
		document.getElementById('todo_sv_topn').onchange = this.#todo_sv_search_on_change;

		document.getElementById('todo_sv_dt_from').onkeydown = this.#todo_sv_search_key_down;
		document.getElementById('todo_sv_dt_from').onchange = this.#todo_sv_search_on_change;

		document.getElementById('todo_sv_dt_to').onkeydown = this.#todo_sv_search_key_down;
		document.getElementById('todo_sv_dt_to').onchange = this.#todo_sv_search_on_change;

		document.getElementById('todo_sv_status').onchange = this.#todo_sv_search_on_change;
		document.getElementById('todo_sv_ioi_category').onchange = this.#todo_sv_search_on_change;

		document.getElementById("todo_sv_only_open").onclick = this.#todo_sv_search_on_change;
		document.getElementById("todo_sv_only_mine").onclick = this.#todo_sv_search_on_change;

		if (document.getElementById("todo_sv_nb_document")) {
			for (var i = 0; i < document.getElementById("todo_sv_nb_document").value; i++) {
				if (document.getElementById("todo_sv_document_" + i.toString())) {
					document.getElementById("todo_sv_document_" + i.toString()).onclick = this.#todo_sv_search_on_change;
				}
			}
		}

		document.getElementById('todo_sv_bt_search').onclick = fct_search;
		document.getElementById('todo_sv_bt_clear').onclick = fct_clear;

		$("#close_ioi_side_view")[0].onclick = () => {
			this.clear_ioi_side_view()
			frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.frm.doctype, type: "", is_new: 0, side_view_origin: 'FORM' })
		}

		$("#todo_sv_bt_duplicate")[0].onclick = () => this.todo_duplicate(this.todo_sv_table)

		let fct_delete_click = function () { me.#todo_delete(me.todo_sv_table); };

		document.getElementById('todo_sv_bt_delete').onclick = fct_delete_click;
		document.getElementById('todo_sv_bt_search').click();
	}


	// ***************************************************************************************************************************************
	// To do Grid
	// ***************************************************************************************************************************************
	#todo_sv_grid(data) {
		let me = this

		let format_due_date = (cell) => {
			let date = cell.getValue()
			let status = cell.getData().status

			if (date) {
				let today = frappe.datetime.get_today()
				let container = frappe.ui.form.make_control({
					parent: cell.getElement(),
					df: {
						fieldname: 'date',
						fieldtype: 'Date'
					},
					render_input: true
				})

				container.input.value = cell.getValue()
				container.input.classList.add('m-1', 'p-0', "text-center")

				if (today > date && (status && status == __('Open'))) {
					container.input.classList.add('bg-danger')
				} else if (today == date && (status && status == __('Open'))) {
					container.input.classList.add('bg-warning')
				} else if (today < date && (status && status == __('Open'))) {
					container.input.classList.add('bg-success')
				}

				return container.input
			}
		}

		let format_status = (cell) => {
			if (cell.getValue()) {
				return `<div class="d-flex flex-row justify-content-center w-100">
							<div style="width: 15px; height: 15px; border-radius: 50%; background:${cell.getValue().toUpperCase() == __("OPEN") ? "#E7A4A4" : "#9ABBA7"}"></div>
						</div>`
			}
		}

		let format_action = (cell) => {
			const row = cell.getRow()
			const index = row.getPosition() - 1

			const build_buttons = () => {
				if (cell.getData().status.toLowerCase() === "open") {
					return `<div class="d-flex flex-column w-100 h-100 justify-content-around align-items-center m-1">
								<button id="todo_sv_status_bt_close_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z"/>
										<path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0"/>
									</svg>
								</button>
								<button id="todo_sv_status_bt_cancel_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
									</svg>
								</button>
							</div>`
				} else {
					return `<div class="d-flex flex-column align-items-center w-100 m-1">
								<button id="todo_sv_status_bt_reopen_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-open" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1"/>
										<path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117M11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5M4 1.934V15h6V1.077z"/>
									</svg>
								</button>
							</div>`
				}
			}

			const build_empty = () => `<div class="w-100"></div>`

			if (frappe.session.user.toUpperCase() == 'ADMINISTRATOR') {
				return build_buttons()
			} else {
				const allocated_to = row.getData().allocated_to

				if (allocated_to && allocated_to !== "") {
					if ((frappe.session.user.toUpperCase() == allocated_to.toUpperCase()) || (frappe.session.user_email.toUpperCase() == allocated_to.toUpperCase())) {
						return build_buttons()
					} else {
						return build_empty()
					}
				} else {
					return build_empty()
				}
			}
		}

		let action_click = (e, cell) => {
			const index = cell.getRow().getPosition() - 1

			if (e.target.id == `todo_sv_status_bt_close_${index}`) {
				this.todo_close(cell.getData().name, this.todo_sv_table)
			} else if (e.target.id == `todo_sv_status_bt_cancel_${index}`) {
				this.todo_cancel(cell.getData().name, this.todo_sv_table)
			} else if (e.target.id == `todo_sv_status_bt_reopen_${index}`) {
				this.todo_reopen(cell.getData().name, this.todo_sv_table)
			}
		}

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				const data = cell.getData()
				const index = cell.getRow().getPosition() - 1

				let s = data.reference_type;
				s = s.replace('ioi ', '');

				const div = document.createElement('div')
				div.innerHTML = cell.getValue()
				div.firstElementChild ? div.firstElementChild.style.pointerEvents = "none" : ''

				let html = `<div class="d-flex flex-row align-items-center w-100 h-100 position-relative p-2 rounded my-2" style="overflow: auto; max-width: 100%; background-color: var(--control-bg);">
								<div id="todo_sv_edit_${index}" style="position: absolute; top: 5px; right: 5px">
									<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-pencil-square" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
										<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
									</svg>
								</div>
								${data.color ? `<div class="mr-2" style="width: 15px; min-width: 15px; height: 15px; border-radius: 50%; background:${data.color}"></div>` : ''}
								<div class="d-flex flex-column">
									<a id="todo_sv_id_${index}">${div.innerHTML}</a>
									${data.reference_type != me.frm.doctype && data.reference_name ? `
										<div>
											<u>${s}</u>&nbsp;:&nbsp;
											<a id="todo_sv_jump_id_${index}" onmouseover="this.style.textDecoration ='underline';" onmouseout="this.style.textDecoration = 'none';">${data.reference_name}</a>
										</div>`
									: ''}
								</div>
							</div>`

				return html
			}
		}

		let link_onclick = (e, cell) => {
			const value = cell.getValue()
			const row = cell.getRow()
			const index = row.getPosition() - 1

			if (value && value !== "") {
				if (cell.getColumn().getField() == "description") {
					if (e.target.id == `todo_sv_jump_id_${index}`) {
						this.open_link(row.getData().reference_type, row.getData().reference_name)
					} else if (e.target.id == `todo_sv_edit_${index}`) {
						edit_todo_description(e, cell, index)
					}
				}
			}
		}

		let edit_todo_description = (e, cell, index) => {
			const desc_link = document.getElementById(`todo_sv_id_${index}`)

			let d = new frappe.ui.Dialog({
				title: __('Update Description'),
				fields: [
					{
						fieldname: 'description',
						fieldtype: 'Text Editor',
						default: desc_link.innerHTML
					},
				],
				size: 'small',
				primary_action_label: 'Submit',
				primary_action(values) {
					desc_link.innerHTML = values.description
					desc_link.firstElementChild ? desc_link.firstElementChild.style.pointerEvents = "none" : ''

					desc_link.classList.add('desc_updated')

					d.hide();
				}
			});

			d.show();
		}

		let format_ioi_category = (cell) => {
			let options = this.event_categories

			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'ioi_category',
					fieldtype: 'Select',
					options: options,
				},
				render_input: true
			})

			container.wrapper.querySelector('select').value = cell.getValue()
			container.wrapper.classList.add('w-100', 'm-1')

			return container.wrapper
		}

		let format_allocated = (cell) => {
			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'allocated_to',
					fieldtype: 'Select',
					options: this.allocated_users.map(allocated_user => allocated_user),
				},
				render_input: true
			})

			if (cell.getValue()) {
				container.wrapper.querySelector('select').value = cell.getValue()
			}

			container.wrapper.classList.add('w-100', 'my-2')
			container.wrapper.style.maxWidth = "100%"

			return container.wrapper
		}

		let save_changes_onclick = (e, cell) => {
			if (e.target.id.includes("sv_btn_save_changes")) {
				let data = cell.getData()

				let updated_allocated = cell.getData().allocated_to && cell.getRow().getElement().querySelector('[data-fieldname="allocated_to"] select')
				let updated_ioi_category = cell.getRow().getElement().querySelector('[data-fieldname="ioi_category"] select')
				let updated_date = cell.getData().date && cell.getRow().getElement().querySelector('[data-fieldname="date"]')
				let updated_description = cell.getRow().getElement().querySelector(`#todo_sv_id_${cell.getRow().getPosition() - 1}`)

				if (updated_date && (data.date != updated_date.value)) {
					this.todo_update_datas(cell.getData().name, "date", updated_date.value, this.todo_sv_table)
				}
				if (data.ioi_category_id != updated_ioi_category.value) {
					this.todo_update_datas(cell.getData().name, "ioi_category_id", updated_ioi_category.value, this.todo_sv_table)
				}
				if (updated_allocated && (data.allocated_to != updated_allocated.value)) {
					this.todo_update_datas(cell.getData().name, "allocated_to", updated_allocated.value, this.todo_sv_table)
				}
				if (updated_description && updated_description.classList.contains('desc_updated')) {
					this.todo_update_datas(cell.getData().name, "description", updated_description.innerHTML, this.todo_sv_table)
				}
			} else if (e.target.id.includes("sv_btn_jump")) {
				let row = cell.getRow()
				this.open_todo(row.getData().name)
			}
		}

		let format_save_changes = () => {
			return `<div class="d-flex flex-column justify-content-around align-items-center w-100 h-100">
						<button id="sv_btn_save_changes" type="button" class="btn btn-secondary btn-xs">
							${__('Save')}
						</button>
						<button id="sv_btn_jump" type="button" class="btn btn-secondary btn-xs">
							${__('Show')}
						</button>
					</div>`
		}

		const check_type_render = (td, func) => {
			if (typeof(func) === "object") {
				return td.appendChild(func)
			} else if (typeof(func) === "string") {
				return td.innerHTML = func
			}
		}

		this.todo_sv_table = new ioi.Tabulator("#todo_sv_grid", {
			maxHeight: "100%",
			minHeight: 150,
			rowHeight: 110,
			wrapLines: false,
			width: "100%",
			data: data,
			layout: "fitDataStretch",
			autoRedraw: true,
			columnDefaults: {
				vertAlign: "middle",
			},
			initialSort: [
				{ column: "date", dir: "desc" },
			],
			rowFormatter: (row) => {
				row.getElement().id = `todo_sv_name_${row.getPosition() - 1}`

				var element = row.getElement()

				let rowTable = document.createElement("table")
				rowTable.style.width = "100%"

				let rowTabletr = document.createElement("tr");
				let rowTabletd

				row.getCells().forEach(cell => {
					const title = cell.getColumn().getDefinition().title

					if (title && title !== "") {
						const field = cell.getColumn().getDefinition().field

						if (field === "description" || field === "allocated_to") {
							rowTabletd = document.createElement("td");

							rowTabletd.classList.add('d-flex', 'align-items-center', 'justify-content-between', "px-3", "border-top")
							rowTabletd.style.minHeight = "75px"
							field === "allocated_to" ? rowTabletd.style.height = "75px" : ''
							rowTabletd.style.backgroundColor = "var(--card-bg)"

							rowTabletr.appendChild(rowTabletd)

							const format = field === "description" ? format_link(cell) : field === "allocated_to" ? format_allocated(cell) : ""

							check_type_render(rowTabletd, format)
							rowTabletd.addEventListener('click', (e) => link_onclick(e, cell))
							rowTabletd.insertAdjacentHTML('afterbegin', `<div class="mr-2 bold">${cell.getColumn().getDefinition().title} :</div>`)
						}
					}
				})

				rowTable.appendChild(rowTabletr);
				element.append(rowTable);
			},
			columns: [
				{ field: "checkbox", formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false, minWidth: 40 },
				{ title: __("Due Date"), field: "date",	minWidth: 120, maxWidth: 120, formatter: format_due_date },
				{ title: __("Status"), field: "status", minWidth: 50, headerSort: false, formatter: format_status },
				{ title: __("Action"), field: "action", minWidth: 50, headerSort: false, formatter: format_action, cellClick: (e, cell) => action_click(e, cell) },
				{ title: __("Description"), field: "description", visible: false },
				{ title: __("ioi Category"), field: "ioi_category_id", minWidth: 70, formatter: format_ioi_category },
				{ title: __("Allocated to"), field: "allocated_to", visible: false },
				{ field: "save_changes", minWidth: 70, formatter: format_save_changes, cellClick: (e, cell) => save_changes_onclick(e, cell), hozAlign: "center", headerSort: false },
			]
		});

		let columns_not_to_order = ['checkbox', 'action', 'header_menu']

		this.todo_sv_table.on("headerClick", (e, column) => {
			let ordered_field = document.getElementById('todo_sv_orderby')
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById('todo_sv_order')
			ordered_dir.value = me.todo_sv_table.getSorters()[0].dir

			let field = column.getField()

			if (!columns_not_to_order.includes(field)) {
				me.#todo_col_click(column.getField(), me.todo_sv_table)
			}
		});
	}

	// ***************************************************************************************************************************************
	// To do : key down
	// ***************************************************************************************************************************************
	#todo_sv_search_key_down(event) {
		if (event.keyCode == 13) {
			document.getElementById('todo_sv_bt_search').click();
		}
	}

	// ***************************************************************************************************************************************
	// To do : change
	// ***************************************************************************************************************************************
	#todo_sv_search_on_change() {
		document.getElementById('todo_sv_bt_search').click();
	}


	// ***************************************************************************************************************************************
	// Calendar Side view
	// ***************************************************************************************************************************************
	show_calendar_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'Form') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {
				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)
				const side_view_height = 950

				const html = `<div id="ioi_side_view" class="p-4 ml-3 rounded" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};"></div>`;
				const resizer_html = `<div id="ioi_side_view_resizer" class="resizer rounded" style="height: ${side_view_height - 50}px;"></div>`;
				const main_layout = $(`[data-page-route="${this.frm.doctype}"]`).find(".row.layout-main");

				this.clear_ioi_side_view()
				main_layout.append(resizer_html).append(html)

				this.calendar_sv_build_page()
				this.create_resizer(this.frm.doctype);

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_side_view()
			}
		} else {
			this.clear_ioi_side_view()
		}
	}

	async calendar_sv_build_page() {
		let me = this

		this.event_categories = await frappe.call('silicon_ioi.utils.lib.system.get_categories', { doctype: this.frm.doctype }).then(r => [''].concat(r.message))

		const search_input = `	<div class="m-1" style="width: 150px;">
									<label style="font-size: var(--text-md);">${__('Search')}</label>
									<div class="control-input" >
										<input id="event_sv_search" type="text" class="input-with-feedback form-control bold">
									</div>
								</div>`


		const from_button = `<div class="m-1">
								<label style="font-size: var(--text-md);">${__('From')}</label>
								<div class="control-input">
									<input id="event_sv_dt_from" type="date" class="input-with-feedback form-control bold">
								</div>
							</div>`

		const to_button = `	<div class="m-1">
								<label style="font-size: var(--text-md);">${__('To')}</label>
								<div class="control-input">
									<input id="event_sv_dt_to" type="date" class="input-with-feedback form-control bold" pattern="\d{4}-\d{2}-\d{2}">
								</div>
							</div>`

		const status_button = `	<div class="m-1">
									<label style="font-size: var(--text-md);">${__('Status')}</label>
									<div class="control-input">
										<select id="event_sv_status" class="input-with-feedback form-control bold">
											<option value="" selected></option>
											<option value="${__('Open')}">${__('Open')}</option>
											<option value="${__('Close')}">${__('Close')}</option>
										</select>
									</div>
								</div>`

		const ioi_category_button = `<div class="m-1">
									<label id="event_sv_ioi_category_label" style="font-size: var(--text-md);">${__("ioi Category")}</label>
									<div class="control-input" style="">
										<select id="event_sv_event_ioi_category" class="input-with-feedback form-control bold">
											${ this.event_categories.map(el => `<option value="${el}">${el}</option>`) }
										</select>
									</div>
								</div>`


		const type_button = `<div class="m-1">
									<label id="event_sv_type_label" style="font-size: var(--text-md);">${__("Type")}</label>
									<div class="control-input" style="">
										<select id="event_sv_event_type" class="input-with-feedback form-control bold">
											<option value="" selected></option>
											<option value="${__("Private")}">${__("Private")}</option>
											<option value="${__("Public")}">${__("Public")}</option>
											<option value="${__("Cancelled")}">${__("Cancelled")}</option>
										</select>
									</div>
								</div>`

		const topn_button = `<div class="m-1">
								<label id="event_sv_topn_label" style="font-size: var(--text-md);">${__("No records")}</label>
								<div class="control-input" style="">
									<input id="event_sv_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">
								</div>
							</div>`

		const search_button = `<div class="m-1">
									<button id="event_sv_bt_search" title="${__("Refresh")}" data-label="${__("Search")}" class="btn btn-default btn-xs ellipsis" style="" onclick="">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
											<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
										</svg>
									</button>
								</div>`

		const clear_button = `<div class="m-1">
								<button id="event_sv_bt_clear" title="${__("Clear")}" data-label="${__("Search")}" class="btn btn-default btn-xs ellipsis" onclick="">${__("...")}</button>
							</div>`

		const new_button = `<div id="event_sv_bt_new" class="m-1">
								<button title="${__("New Event")}" data-label="New event" class="btn btn-default btn-xs ellipsis" onclick="
									if (cur_frm.is_new()) {
										frappe.msgprint({title: __('Message'), message: __('Save or cancel data before'), indicator: 'red'});
										raise;
									}
									frappe.new_doc('Event', {}, doc => {
										let row = frappe.model.add_child(doc, 'event_participants');
										row.reference_doctype = '${me.frm.doctype}';
										row.reference_docname = '${me.frm.doc.name}';
									});
								">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
										<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
									</svg>
								</button>
							</div>`

		const duplicate_button = `<div id="event_sv_bt_duplicate" class="m-1">
									<button title="${__("Duplicate Event")}" data-label="Duplicate event" class="btn btn-default btn-xs ellipsis">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
										</svg>
									</button>
								</div>`

		const delete_button = `	<div id="event_sv_bt_delete" class="m-1">
									<button title="${__("Delete")}" data-label="Delete" class="btn btn-default btn-xs ellipsis">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
											<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
											<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
										</svg>
									</button>
								</div>`;

		const hide_button = `<button id="close_ioi_side_view" class="btn btn-xs position-absolute shadow-none" style="top: -21px; right: -21px;">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
									<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
								</svg>
							</button>`

		const control_panel = `
			<div class="d-flex flex-column w-100 position-relative" style="max-height: 100%;">
				${hide_button}
				<div class="d-flex flex-row flex-wrap align-items-start justify-content-start">
					${search_input}
					${from_button}
					${to_button}
					${status_button}
					${ioi_category_button}
					${type_button}
					${topn_button}
				</div>
				<div class="d-flex flex-row flex-wrap-reverse m-1 justify-content-between">
					<div class="d-flex my-1">
						<div class="d-flex flex-row mr-3">
							${search_button}
							${clear_button}
						</div>
						<div id="event_sv_actions" class="d-flex flex-row">
							${new_button}
							${duplicate_button}
							${delete_button}
						</div>
					</div>
				</div>
				<input id="event_sv_orderby" type="hidden" value="starts_on">
				<input id="event_sv_order" type="hidden" value="desc">
				<div id="event_sv_grid" class="table table-bordered" style="max-height: 100%; font-size: var(--text-md);" data-custom-grid="true"></div>
			</div>`

		$("#ioi_side_view").append(control_panel);

		this.event_sv_grid([])

		$('#event_sv_search')[0].onkeydown = (e) => this.event_sv_search_key_down(e)
		$('#event_sv_topn')[0].onkeydown = (e) => this.event_sv_search_key_down(e)
		$('#event_sv_dt_from')[0].onkeydown = (e) => this.event_sv_search_key_down(e)
		$('#event_sv_dt_to')[0].onkeydown = (e) => this.event_sv_search_key_down(e)

		$('#event_sv_topn')[0].onchange = () => this.event_sv_search_on_change()
		$('#event_sv_dt_from')[0].onchange = () => this.event_sv_search_on_change()
		$('#event_sv_dt_to')[0].onchange = () => this.event_sv_search_on_change()
		$('#event_sv_status')[0].onchange = () => this.event_sv_search_on_change()
		$('#event_sv_event_ioi_category')[0].onchange = () => this.event_sv_search_on_change()
		$('#event_sv_event_type')[0].onchange = () => this.event_sv_search_on_change()

		$('#event_sv_bt_search')[0].onclick = () => this.#events_search(this.event_sv_table)
		$('#event_sv_bt_clear')[0].onclick = () => this.event_sv_table.clearData()
		$("#event_sv_bt_duplicate")[0].onclick = () => this.event_duplicate(this.event_sv_table)
		$('#event_sv_bt_delete')[0].onclick = () => this.#events_delete(this.event_sv_table)
		$("#close_ioi_side_view")[0].onclick = () => {
			this.clear_ioi_side_view()
			frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.frm.doctype, type: "", is_new: 0, side_view_origin: 'FORM' })
		}

		document.getElementById('event_sv_bt_search').click();
	}

	event_sv_search_key_down(e) {
		if (e.keyCode == 13) {
			document.getElementById('event_sv_bt_search').click();
		}
	}

	event_sv_search_on_change() {
		document.getElementById('event_sv_bt_search').click();
	}

	event_duplicate(table) {
		if (cur_frm.is_new()) {
			frappe.msgprint({title: __('Message'), message: __('Save or cancel data before'), indicator: 'red'});
			raise;
		}

		let me = this

		const table_name = table && table.element.id === "event_sv_grid" ? "event_sv" : "event"

		let d = new frappe.ui.Dialog({
			title: __('Duplicate Event'),
			fields: [
				{
					label: __('Event Participants'),
					fieldname: 'event_participants',
					fieldtype: 'Table',
					fields: [
						{
							fieldname: "reference_document_type",
							fieldtype: "Link",
							in_list_view: 1,
							label: __("Reference Document Type"),
							options: "DocType",
							reqd: 1,
							onchange: (e) => {
								if (e) {
									d.fields_dict.event_participants.grid.grid_rows[Number(e.target.closest('.grid-row').getAttribute('data-idx')) - 1].columns.reference_name.field.input.value = null
								}
							},
						},
						{
							fieldname: "reference_name",
							fieldtype: "Dynamic Link",
							in_list_view: 1,
							label: __("Reference Name"),
							options: "reference_document_type",
							reqd: 1,
							get_options: (df) => {
								return df.doc.reference_document_type;
							},
						},
					],
					data:[{ "reference_document_type": me.frm.doctype, "reference_name": me.frm.docname }]
				},
			],
			size: 'large',
			primary_action_label: __('Duplicate'),
			primary_action(values) {

				values.event_participants.forEach(val => {
					if (!val.reference_document_type || val.reference_document_type === "") {
						frappe.throw(__("Reference Document Type can't be empty"))
					} else if (!val.reference_name || val.reference_name === "") {
						frappe.throw(__("Reference Name can't be empty"))
					}
				})

				table.getSelectedRows().map(row => {
					const data = row.getData()

					frappe.db.insert({
						doctype: 'Event',
						subject: data.subject,
						ioi_category_id: data.ioi_category_id,
						event_type: data.event_type,
						color: data.color,
						starts_on: data.starts_on,
						ends_on: data.ends_on,
						status: data.status,
						event_participants: values.event_participants.map(val => ({"reference_doctype": val.reference_document_type, "reference_docname": val.reference_name}))
					}).then(doc => {
						document.getElementById(`${table_name}_bt_search`).click()
					})
				})

				d.hide();
			}
		});

		d.show();
	}

	event_sv_grid(data) {
		let me = this

		let format_date = (cell) => {
			let date = cell.getValue()
			let status = cell.getData().status

			if (date) {
				const today = frappe.datetime.get_today()
				date = date.split(' ')[0]

				let container = frappe.ui.form.make_control({
					parent: cell.getElement(),
					df: {
						fieldname: cell.getField(),
						fieldtype: 'Datetime',
					},
					render_input: true
				})

				container.input.value = cell.getValue()

				container.wrapper.classList.add('m-1', 'p-0', "text-center")

				if (today > date && (status && status == __('Open'))) {
					container.input.classList.add('bg-danger')
				} else if (today == date && (status && status == __('Open'))) {
					container.input.classList.add('bg-warning')
				} else if (today < date && (status && status == __('Open'))) {
					container.input.classList.add('bg-success')
				}

				return container.input
			}
		}

		let edit_subject = (e, cell, index) => {
			const subject_link = document.getElementById(`event_sv_id_${index}`)

			let d = new frappe.ui.Dialog({
				title: __('Update Subject'),
				fields: [
					{
						fieldname: 'subject',
						fieldtype: 'Text',
						default: subject_link.innerHTML
					},
				],
				size: 'small',
				primary_action_label: __('Submit'),
				primary_action(values) {
					subject_link.innerHTML = values.subject
					subject_link.firstElementChild ? subject_link.firstElementChild.style.pointerEvents = "none" : ''

					subject_link.classList.add('subject_updated')

					d.hide();
				}
			});

			d.show();
		}

		let format_event_participants = (cell) => {
			const event_participants = cell.getData().event_participants.split(',')
			const user_contact = []
			const link = []

			event_participants.forEach(item => {
				const parts = item.split(':');
				const key = parts[0];
				const value = parts.slice(1).join(':');

				if (key === "User" || key === "Contact") {
					user_contact.unshift(`${key}: ${value}`)
				} else {
					link.unshift(`${key}: ${value}`)
				}
			});

			return `<div class="d-flex flex-row w-100 align-items-center">
						<div class="d-flex flex-column w-100 mr-3">
							<div class="d-flex flex-row justify-content-start align-items-center">
								<div class="mr-2 bold">${__('User/Contact')} :</div>
								<div id="event_participants_user_contact" class="w-100 p-2 rounded my-2 overflow-auto" style="background-color: var(--control-bg); min-height: 33.5px;">
									${user_contact.map(el => ` ${el}`)}
								</div>
							</div>
							<div class="d-flex flex-row justify-content-start align-items-center">
								<div class="mr-2 bold">${__('Link')} :</div>
								<div id="event_participants_link" class="w-100 p-2 rounded my-2 overflow-auto" style="background-color: var(--control-bg); min-height: 33.5px;">
									${link.map(el => ` ${el}`)}
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-around align-items-center" style="min-height: 100px;">
							<button id="edit_event_user_contact" class="btn btn-xs btn-secondary" style="height: 25px">
								<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16" style="pointer-events:none;">
									<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
								</svg>
							</button>
							<button id="edit_event_link" class="btn btn-xs btn-secondary" style="height: 25px">
								<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16" style="pointer-events:none;">
									<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
								</svg>
							</button>
						</div>
					</div>`
		}

		let edit_event_participants = (e, cell, mode) => {
			const event_participants = cell.getData().event_participants.split(',');
			const event_link = [];
			const event_user_contact = [];

			let query_filters = {}
			let title = ""
			let label = ""

			event_participants.forEach(item => {
				const parts = item.split(':');
				const key = parts[0];
				const value = parts.slice(1).join(':');

				if (key === 'Contact' || key === 'User') {
					event_user_contact.push({ "reference_document_type": key, "reference_name": value })
				} else {
					event_link.push({ "reference_document_type": key, "reference_name": value })
				}
			});

			if (mode === "user_contact") {
				query_filters = { name: ['in', ["Contact", "User"]] }
				title = __('Update Users/Contacts')
				label = __('Event Users/Contacts')

				if (!event_user_contact.length) {
					event_user_contact.push({ "reference_document_type": 'Contact', "reference_name": '' })
				}
			} else if (mode === "link") {
				query_filters = { name: ['not in', ["Contact", "User"]] }
				title = __('Update Links')
				label = __('Event Links')
			}

			let d = new frappe.ui.Dialog({
				title: title,
				fields: [
					{
						label: label,
						fieldname: 'event_participants',
						fieldtype: 'Table',
						fields: [
							{
								fieldname: "reference_document_type",
								fieldtype: "Link",
								in_list_view: 1,
								label: __("Reference Document Type"),
								options: "DocType",
								reqd: 1,
								onchange: (e) => {
									if (e) {
										d.fields_dict.event_participants.grid.grid_rows[Number(e.target.closest('.grid-row').getAttribute('data-idx')) - 1].columns.reference_name.field.input.value = null
									}
								},
								get_query: (e) => {
									return {
										filters: query_filters
									}
								}
							},
							{
								fieldname: "reference_name",
								fieldtype: "Dynamic Link",
								in_list_view: 1,
								label: __("Reference Name"),
								options: "reference_document_type",
								reqd: 1,
								get_options: (df) => {
									return df.doc.reference_document_type;
								},
							},
						],
						data: mode === "user_contact" ? event_user_contact : event_link
					},
				],
				size: 'large',
				primary_action_label: __('Update'),
				primary_action(values) {
					let updated_values = []

					if (values.event_participants && values.event_participants.length) values.event_participants.forEach(el => updated_values.push({
						reference_doctype: el.reference_document_type,
						reference_docname: el.reference_name
					}))

					if (mode === "user_contact") {
						event_link.map(el => updated_values.unshift({
							reference_doctype: el.reference_document_type,
							reference_docname: el.reference_name
						}))
					} else if (mode === "link") {
						event_user_contact.map(el => updated_values.push({
							reference_doctype: el.reference_document_type,
							reference_docname: el.reference_name
						}))
					}

					frappe.call(me.path_event_todo_file + '.event_update_participants', { name: cell.getData().name, rows: updated_values }).then(r => {
						document.getElementById('event_sv_bt_search').click();
						d.hide();
					})
				}
			});

			d.show();
		}

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				const data = cell.getData()
				const index = cell.getRow().getPosition() - 1

				let s = data.reference_doctype;
				s = s.replace('ioi ', '');

				const div = document.createElement('div')
				div.innerHTML = cell.getValue()
				div.firstElementChild ? div.firstElementChild.style.pointerEvents = "none" : ''

				let html = `<div class="d-flex flex-row align-items-center w-100 h-100 position-relative p-2 rounded my-2" style="overflow: auto; max-width: 100%; background-color: var(--control-bg);">
								<div id="event_sv_edit_${index}" style="position: absolute; top: 5px; right: 5px">
									<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-pencil-square" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
										<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
									</svg>
								</div>
								${data.color ? `<div class="mr-2" style="width: 15px; min-width: 15px; height: 15px; border-radius: 50%; background:${data.color}"></div>` : ''}
								<div class="d-flex flex-column">
									<a id="event_sv_id_${index}" onmouseover="this.style.textDecoration = 'underline';" onmouseout="this.style.textDecoration = 'none';">${div.innerHTML}</a>
									${data.reference_doctype != me.frm.doctype && data.reference_docname ? `
										<div>
											<u>${s}</u>&nbsp;:&nbsp;
											<a id="event_sv_jump_id_${index}" onmouseover="this.style.textDecoration ='underline';" onmouseout="this.style.textDecoration = 'none';">${data.reference_docname}</a>
										</div>`
									: ''}
								</div>
							</div>`

				return html
			}
		}

		let link_onclick = (e, cell) => {
			const value = cell.getValue()
			const row = cell.getRow()
			const index = row.getPosition() - 1

			if (value && value !== "") {
				if (cell.getColumn().getField() == "subject") {
					if (e.target.id == `event_sv_id_${index}`) {
						this.open_event(row.getData().name)
					} else if (e.target.id == `event_sv_jump_id_${index}`) {
						this.open_link(row.getData().reference_doctype, row.getData().reference_docname)
					} else if (e.target.id == `event_sv_edit_${index}`) {
						edit_subject(e, cell, index)
					}
				}
			}
		}

		let format_status = (cell) => {
			if (cell.getValue()) {
				return `<div class="d-flex flex-row justify-content-center w-100">
							<div style="width: 15px; height: 15px; border-radius: 50%; background:${cell.getValue().toUpperCase() == __("OPEN") ? "#E7A4A4" : "#9ABBA7"}"></div>
						</div>`
			}
		}

		let format_action = (cell) => {
			const row = cell.getRow()
			const index = row.getPosition() - 1

			const build_buttons = () => {
				if (cell.getData().status.toLowerCase() === "open") {
					return `<div class="d-flex flex-column w-100 align-items-center m-1">
								<button id="event_sv_status_bt_close_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z"/>
										<path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0"/>
									</svg>
								</button>
							</div>`
				} else {
					return `<div class="d-flex flex-column align-items-center w-100 m-1">
								<button id="event_sv_status_bt_reopen_${index}" type="button" class="btn btn-xs btn-secondary p-1" style="height: 29px; width: 29px;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-open" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1"/>
										<path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117M11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5M4 1.934V15h6V1.077z"/>
									</svg>
								</button>
							</div>`
				}
			}

			const build_empty = () => `<div class="w-100"></div>`

			if (frappe.session.user.toUpperCase() == 'ADMINISTRATOR') {
				return build_buttons()
			} else {
				const reference_docname = row.getData().reference_docname

				if (reference_docname && reference_docname !== "") {
					if ((frappe.session.user.toUpperCase() == reference_docname.toUpperCase()) || (frappe.session.user_email.toUpperCase() == reference_docname.toUpperCase())) {
						return build_buttons()
					} else {
						return build_empty()
					}
				} else {
					return build_empty()
				}
			}
		}

		let action_click = (e, cell) => {
			const index = cell.getRow().getPosition() - 1

			const promise = new Promise((resolve) => {
				if (e.target.id == `event_sv_status_bt_close_${index}`) {
					resolve(this.event_close("", cell.getData().name))
				} else if (e.target.id == `event_sv_status_bt_reopen_${index}`) {
					resolve(this.event_reopen("", cell.getData().name))
				}
			})

			promise.then(() => document.getElementById('event_sv_bt_search').click())
		}

		let format_ioi_category = (cell) => {
			let options = this.event_categories

			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'ioi_category',
					fieldtype: 'Select',
					options: options,
				},
				render_input: true
			})

			container.wrapper.querySelector('select').value = cell.getValue()
			container.wrapper.classList.add('w-100', 'my-2')
			container.wrapper.style.maxWidth = "100%"

			return container.wrapper
		}

		let format_type = (cell) => {
			if (cell.getValue()) {
				let options = [__('Private'), __('Public'), __('Cancelled')]

				let container = frappe.ui.form.make_control({
					parent: cell.getElement(),
					df: {
						fieldname: 'type',
						fieldtype: 'Select',
						options: options,
					},
					render_input: true
				})

				container.wrapper.querySelector('select').value = cell.getValue()
				container.wrapper.classList.add('w-100', 'm-1')

				return container.wrapper
			}
		}

		let format_save_changes = () => {
			return `<div class="d-flex justify-content-center align-items-center w-100 h-100">
						<button id="sv_btn_save_changes" type="button" class="btn btn-secondary btn-xs">
							${__('Save')}
						</button>
					</div>`
		}

		let save_changes_onclick = (e, cell) => {
			if (e.target.id.includes("sv_btn_save_changes")) {
				const data = cell.getData()
				const element = cell.getRow().getElement()

				let updated_starts_on = data.starts_on && element.querySelector('[data-fieldname="starts_on"] input')
				let updated_ends_on = data.ends_on && element.querySelector('[data-fieldname="ends_on"] input')
				let updated_ioi_category = element.querySelector('[data-fieldname="ioi_category"] select')
				let updated_type = data.event_type && element.querySelector('[data-fieldname="type"] select')
				let updated_subject = element.querySelector(`#event_sv_id_${cell.getRow().getPosition() - 1}`)

				if (updated_starts_on && (data.starts_on != updated_starts_on.value)) {
					this.event_update_datas(data.name, "starts_on", updated_starts_on.value, this.event_sv_table)
				}
				if (updated_ends_on && (data.ends_on != updated_ends_on.value)) {
					this.event_update_datas(data.name, "ends_on", updated_ends_on.value, this.event_sv_table)
				}
				if (updated_ioi_category && (data.ioi_category_id != updated_ioi_category.value)) {
					this.event_update_datas(data.name, "ioi_category_id", updated_ioi_category.value, this.event_sv_table)
				}
				if (updated_type && (data.type != updated_type.value)) {
					this.event_update_datas(data.name, "event_type", updated_type.value, this.event_sv_table)
				}
				if (updated_subject && updated_subject.classList.contains('subject_updated')) {
					this.event_update_datas(data.name, "subject", updated_subject.innerHTML, this.event_sv_table)
				}
			}
		}

		const check_type_render = (td, func) => {
			if (typeof(func) === "object") {
				return td.appendChild(func)
			} else if (typeof(func) === "string") {
				return td.innerHTML = func
			}
		}

		this.event_sv_table = new ioi.Tabulator("#event_sv_grid", {
			maxHeight: "100%",
			minHeight: 150,
			rowHeight: 50,
			wrapLines: false,
			data: data,
			layout: "fitColumns",
			autoRedraw: true,
			resizable: true,
			columnDefaults: {
				vertAlign: "middle",
			},
			initialSort: [
				{ column: "starts_on", dir: "desc" },
			],
			rowFormatter: (row) => {
				row.getElement().id = `event_sv_name_${row.getPosition() - 1}`

				var element = row.getElement()

				let rowTable = document.createElement("table")
				rowTable.style.width = "100%"

				let rowTabletr = document.createElement("tr");
				let rowTabletd

				row.getCells().forEach(cell => {
					const title = cell.getColumn().getDefinition().title

					if (title && title !== "") {
						const field = cell.getColumn().getDefinition().field

						if (field === "subject" || field === "event_participants") {
							rowTabletd = document.createElement("td");

							rowTabletd.classList.add('d-flex', 'align-items-center', 'justify-content-between', "px-3", "border-top")
							rowTabletd.style.minHeight = "75px"
							rowTabletd.style.backgroundColor = "var(--card-bg)"

							rowTabletr.appendChild(rowTabletd)

							const format = field === "subject" ? format_link(cell) : field === "event_participants" ? format_event_participants(cell) : ''

							check_type_render(rowTabletd, format)

							if (field === "subject") {
								rowTabletd.addEventListener('click', (e) => link_onclick(e, cell))
								rowTabletd.insertAdjacentHTML('afterbegin', `<div class="mr-2 bold">${cell.getColumn().getDefinition().title} :</div>`)
							} else {
								rowTabletd.addEventListener('click', (e) => {
									if (e.target.id === "edit_event_user_contact") edit_event_participants(e, cell, "user_contact")
									if (e.target.id === "edit_event_link") edit_event_participants(e, cell, "link")
								})
							}
						}
					}
				})

				rowTable.appendChild(rowTabletr);
				element.append(rowTable);
			},
			columns: [
				{ field: "checkbox", formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false, minWidth: 40, maxWidth: 40 },
				{ title: __("Starts on"), field: "starts_on", minWidth: 165, width: 165, formatter: format_date },
				{ title: __("Ends on"), field: "ends_on", minWidth: 165,  width: 165, formatter: format_date },
				{ title: __("Subject"), field: "subject", visible: false },
				{ title: __("Event participants"), field: "event_participants", visible: false },
				{ title: __("Status"), field: "status", minWidth: 50, width: 50, headerSort: false, formatter: format_status },
				{ title: __("Action"), field: "action", minWidth: 50, width: 50, headerSort: false, formatter: format_action, cellClick: (e, cell) => action_click(e, cell) },
				{ title: __("ioi Category"), field: "ioi_category_id", minWidth: 80, formatter: format_ioi_category },
				{ title: __("Type"), field: "event_type", minWidth: 80, formatter: format_type },
				{ field: "save_changes", minWidth: 70, width: 70, formatter: format_save_changes, cellClick: (e, cell) => save_changes_onclick(e, cell), hozAlign: "center", headerSort: false },
			]
		});
	}

	show_pdf_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'Form') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				let pdf_attachments = this.frm.get_files().filter(file => file.file_name.toLowerCase().includes('.pdf'));
				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)
				const side_view_height = 950

				const buttons = `
					<div id="ioi_side_view_buttons" class="d-flex flex-row justify-content-between" style="margin-bottom: 8px;">
						<div class="d-flex w-100">
							<div class="frappe-control input-max-width w-75" data-fieldtype="Select">
								<div class="form-group">
									<div class="clearfix">
										<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Document PDF files')}</label>
									</div>
									<div class="control-input-wrapper">
										<div class="control-input flex align-center">
											<select id="ioi_side_view_select" type="text" autocomplete="off" class="input-with-feedback form-control ellipsis">
												${pdf_attachments.length && pdf_attachments.map(file => `<option value="${file.file_url}">${file.file_name}</option>`).join('')}
											</select>
											<div class="select-icon ">
												<svg class="icon  icon-sm" style="">
													<use class="" href="#icon-select"></use>
												</svg>
											</div>
										</div>
									</div>
								</div>
							</div>
							<button id="ioi_side_view_refresh" style="margin-top: 28px;" class="text-muted btn btn-default icon-btn mx-2" title="${__('Refresh')}">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
									<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
								</svg>
							</button>
						</div>
						<span class="page-icon-group" style="margin-top: 25px;">
							<button id="ioi_side_view_previous" class="text-muted btn btn-default icon-btn" title="${__('Previous')}">
								<svg class="icon  icon-sm" style="">
									<use class="" href="#icon-left"></use>
								</svg>
							</button>
							<button id="ioi_side_view_next" class="text-muted btn btn-default icon-btn mx-2" title="${__('Next')}">
								<svg class="icon  icon-sm" style="">
									<use class="" href="#icon-right"></use>
								</svg>
							</button>
							<button id="close_ioi_side_view" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
									<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
								</svg>
							</button>
						</span>
					</div>`

				const file_viewer = `<object id="ioi_side_view_file_prev" style="background:#323639; border-radius: var(--border-radius-md); border: 1px solid var(--border-color);" width="100%" height="${side_view_height - 133}" data="${pdf_attachments.length && pdf_attachments[0].file_url}" type="application/pdf"></object>`

				const container = `
					<div id="ioi_side_view" class="p-4 ml-3 rounded position-relative" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};">
						${buttons}
						${pdf_attachments.length ? file_viewer : `<div class="w-100 d-flex justify-content-center mt-4">${__('No PDF documents to display')}</div>`}
					</div>`;

				const resizer_html = `<div id="ioi_side_view_resizer" class="resizer rounded" style="height: ${side_view_height - 50}px;"></div>`;
				const main_layout = $(`[data-page-route="${this.frm.doctype}"]`).find(".row.layout-main");

				this.clear_ioi_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.frm.doctype);

				// On PDF change in viewer
				const toggle_attachment = (update) => {
					let options = $("#ioi_side_view_select")[0].options

					if (update === "increase" && options[options.selectedIndex + 1]) {
						options.selectedIndex = options.selectedIndex + 1
						document.querySelector('#ioi_side_view object').data = options[options.selectedIndex].value
					} else if (update === "decrease" && options[options.selectedIndex - 1]) {
						options.selectedIndex = options.selectedIndex - 1
						document.querySelector('#ioi_side_view object').data = options[options.selectedIndex].value
					}
				}

				$("#ioi_side_view_select")[0].onchange = (e) => document.querySelector('#ioi_side_view object').data = e.target.value
				$("#ioi_side_view_refresh")[0].onclick = () => frappe.call('silicon_ioi.utils.lib.system.get_doctype_preview', { doctype: this.frm.doctype, side_view_origin: 'FORM' }).then(r => this.show_pdf_side_view(r.message))
				$("#ioi_side_view_previous")[0].onclick = () => toggle_attachment("decrease");
				$("#ioi_side_view_next")[0].onclick = () => toggle_attachment("increase")
				$("#close_ioi_side_view")[0].onclick = () => {
					this.clear_ioi_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.frm.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'FORM' })
				}
			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_side_view()
			}
		} else {
			this.clear_ioi_side_view()
		}
	}

	show_related_data_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'Form') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)
				const side_view_height = 950

				const refresh_button = `
					<button id="related_data_refresh" class="text-muted btn btn-xs btn-default mr-2 my-1" title="${__('Refresh')}">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
							<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
						</svg>
					</button>`

				const add_button = `
					<div id="related_data_add_btn" class="my-1 mr-1">
						<button title="${__("Add Row")}" class="btn btn-secondary btn-xs ellipsis">
							${__("Add Row")}
						</button>
					</div>`

				const remove_button = `
					<div id="related_data_remove_btn" class="m-1 hide">
						<button title="${__("Remove")}" class="btn btn-danger btn-xs ellipsis">
							${__("Remove")}
						</button>
					</div>`

				const container = `
					<div id="ioi_side_view" class="p-4 ml-3 rounded position-relative" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};">
						<button id="close_ioi_side_view" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Related Data')}</label>
						<div id="related_data_grid" class="table table-bordered mt-2 mb-1" style="max-height: 100%; font-size: var(--text-md);" data-custom-grid="true"></div>
						<div class="d-flex flex-row flex-wrap align-items-start justify-content-start mt-3">
							${refresh_button}
							${add_button}
							${remove_button}
						</div>
					</div>`;

				const resizer_html = `<div id="ioi_side_view_resizer" class="resizer rounded" style="height: ${side_view_height - 50}px;"></div>`;
				const main_layout = $(`[data-page-route="${this.frm.doctype}"]`).find(".row.layout-main");

				this.clear_ioi_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.frm.doctype);

				this.related_data_grid()
				this.related_data_search()

				let me = this

				$("#related_data_refresh")[0].onclick = () => this.related_data_search()

				$("#related_data_add_btn")[0].onclick = () => {
					let d = new frappe.ui.Dialog({
						title: __('New Related Data'),
						fields: [
							{
								label: __('Remark'),
								fieldname: 'remark',
								fieldtype: 'Small Text'
							},
							{
								label: __('Doctype'),
								fieldname: 'doc_type',
								fieldtype: 'Link',
								options: 'DocType',
								reqd: 1
							},
							{
								label: __('Document'),
								fieldname: 'document',
								fieldtype: 'Dynamic Link',
								options: 'doc_type',
								reqd: 1
							}
						],
						size: 'small',
						primary_action_label: __('Create'),
						primary_action(values) {
							frappe.call('silicon_ioi.utils.lib.system.create_related_data', { remark: values.remark, master_dt: me.frm.doctype, master_doc: me.frm.docname, linked_dt: values.doc_type, linked_doc: values.document }).then(() => me.related_data_search())
							d.hide();
						}
					});
					d.show();
				}

				$("#related_data_remove_btn")[0].onclick = () => {
					const rows = this.related_data_table.getSelectedData()
					frappe.call('silicon_ioi.utils.lib.system.remove_related_data', { rows: rows }).then(() => me.related_data_search())
				}

				$("#close_ioi_side_view")[0].onclick = () => {
					this.clear_ioi_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.frm.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'FORM' })
				}

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_side_view()
			}
		} else {
			this.clear_ioi_side_view()
		}
	}

	related_data_grid(data) {
		let format_checkbox = (cell) => {
			let value = cell.getValue()

			if (value != null) {
				const data = cell.getData()
				const div = document.createElement('div')

				div.classList.add('w-100', 'h-100', 'd-flex', 'justify-content-center', 'align-items-center')
				div.innerHTML = `<input id="related_deprecated_checkbox" type="checkbox" value="" ${value == 0 ? '' : 'checked'}>`

				div.onclick = (e) => {
					if (e.target.id == 'related_deprecated_checkbox') frappe.call('silicon_ioi.utils.lib.system.related_data_toggle_deprecated', { name: data.parent, checkbox: data.deprecated }).then(() => this.related_data_search())
				}

				return div
			}
		}

		let format_child = (cell) => {
			const value = cell.getValue()

			if (value) {
				const field = cell.getField()

				if (field.includes('doc_type')) {
					return value
				} else if (field.includes('document')) {
					const title_field = cell.getData().title_field
					const div = document.createElement('div')
					const html = `<span style="cursor: pointer; text-decoration: underline;">${title_field ? title_field : value}</span>`
					const dt = cell.getData().doc_type.toLowerCase().replaceAll(' ', '-')

					div.innerHTML = html
					div.onclick = () => window.open(`/app/${dt}/${value}`, "_blank")

					return div
				}
			}
		}

		let format_date = (cell) => {
			return cell.getValue().split(".")[0]
		}

		let format_linked_info = (cell) => {
			const value = cell.getValue()

			if (value) {
				const info_fn = cell.getData().info_from_fieldname.split(';')
				const array = value.split(';')

				const formatted_array = array.map((el, i) => {
					if (el && el !== "") {
						if (info_fn[i] === "title_id" || info_fn[i] === "salutation") {
							info_fn[i] = __("Title")
						} else if (info_fn[i] === "lastname" || info_fn[i] === "last_name") {
							info_fn[i] = __("Name")
						} else if (info_fn[i] === "firstname" || info_fn[i] === "first_name") {
							info_fn[i] = __("First name")
						} else if (info_fn[i] === "full_name") {
							info_fn[i] = __("Name")
						} else if (info_fn[i] === "description") {
							info_fn[i] = __("Description")
						}

						return `${info_fn[i]} : ${el}`
					} else {
						i++
					}
				})

				return `<div class="" style="width: 100%;">${formatted_array.filter(el => el !== undefined).join(', ')}</div>`
			}
		}

		const check_type_render = (td, func) => {
			if (typeof(func) === "object") {
				return td.appendChild(func)
			} else if (typeof(func) === "string") {
				return td.innerHTML = func
			}
		}

		let columns = [
			{ field: "checkbox", formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false, resizable: false, minWidth: 40, maxWidth: 40 },
			{ title: __("Link type"), field: "doc_type", formatter: format_child },
			{ title: __("Linked to"), field: "document", formatter: format_child },
			{ title: __("Linked info"), field: "linked_info", visible: false },
			{ title: __("Info from fieldname"), field: "info_from_fieldname", visible: false },
			{ title: __("Last update on"), field: "modified", formatter: format_date },
			{ title: __("Deprecated"), field: "deprecated", minWidth: 40, formatter: format_checkbox },
			{ title: __("Remark"), field: "remark", visible: false },
			{ title: __("Author"), field: "author", visible: false },
		]

		this.related_data_table = new ioi.Tabulator("#related_data_grid", {
			maxHeight: "100%",
			data: data,
			layout: "fitColumns",
			autoRedraw: true,
			initialSort: [
				{ column: "modified", dir: "desc" },
			],
			columnDefaults: {
				minWidth: 85,
				vertAlign: "middle",
			},
			columns: columns,
			rowFormatter: (row) => {
				var element = row.getElement()

				let rowTable = document.createElement("table")
				rowTable.style.width = "100%"

				let rowTabletr = document.createElement("tr");
				let rowTabletd

				row.getCells().forEach(cell => {
					const title = cell.getColumn().getDefinition().title

					if (title && title !== "") {
						const field = cell.getColumn().getDefinition().field

						if (field === "linked_info" || field === "remark" || field === "author") {
							rowTabletd = document.createElement("td");

							rowTabletd.classList.add('d-flex', 'align-items-center', 'justify-content-between', "px-3", "border-top")
							rowTabletd.style.backgroundColor = "var(--card-bg)"

							rowTabletr.appendChild(rowTabletd)

							const format = field === "linked_info" ? format_linked_info(cell) : `<div class="" style="width: 100%;">${cell.getElement().innerHTML}</div>`

							check_type_render(rowTabletd, format)
							rowTabletd.insertAdjacentHTML('afterbegin', `<div class="mr-2 bold">${cell.getColumn().getDefinition().title} :</div>`)
						}
					}
				})

				rowTable.appendChild(rowTabletr);
				element.append(rowTable);
			},
		});

		this.related_data_table.on("rowSelectionChanged", (data, rows, selected, deselected) => {
			const remove_btn = $('#related_data_remove_btn')

			if (rows.length) {
				remove_btn.addClass('show');
				remove_btn.removeClass('hide');
			} else {
				remove_btn.addClass('hide');
				remove_btn.removeClass('show');
			}
		});
	}

	related_data_search() {
		let me = this

		frappe.call({
			method: "silicon_ioi.utils.lib.system.get_related_data_list",
			args: {
				doctype: this.frm.doctype,
				docname: this.frm.docname,
			},
			async: true,
			callback: function (r) {
				if (r.message.length > 0) {
					if (me.related_data_table.initialized) {
						me.related_data_table.replaceData(r.message)
					} else {
						me.related_data_table.on('tableBuilt', () => me.related_data_table.replaceData(r.message))
					}
				} else {
					me.related_data_table.clearData()
				}
			}
		})
	}

	show_highlight_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'Form') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)
				const side_view_height = 950

				const container = `
					<div id="ioi_side_view" class="p-4 ml-3 rounded position-relative overflow-auto" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};">
						<button id="close_ioi_side_view" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Highlight Info')}</label>
						<div class="mt-3" id="highlight_info_buttons"></div>
						<div class="mt-3" id="highlight_info_content"></div>
					</div>`;

				const resizer_html = `<div id="ioi_side_view_resizer" class="resizer rounded" style="height: ${side_view_height - 50}px;"></div>`;
				const main_layout = $(`[data-page-route="${this.frm.doctype}"]`).find(".row.layout-main");

				this.clear_ioi_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.frm.doctype);

				this.get_highlight_list()

				$("#close_ioi_side_view")[0].onclick = () => {
					this.clear_ioi_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.frm.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'FORM' })
				}

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_side_view()
			}
		} else {
			this.clear_ioi_side_view()
		}
	}

	get_highlight_list(view) {
		let me = this
		$('#highlight_info_content')[0].innerHTML = ''

		frappe.call({
			method: "silicon_ioi.utils.lib.system.get_highlight_list",
			args: {
				doctype: this.frm.doctype,
				view: view || 0
			},
			async: true,
			callback: async function (r) {

				if (r.message.length > 0) {
					let result = r.message

					if (result.find(el => el.view_selector !== 0) && !$('#ioi_side_view_select')[0]) {

						const unique_view_selectors = new Set();
						const select = `
							<div class="d-flex w-100">
								<div class="frappe-control input-max-width w-25" data-fieldtype="Select">
									<div class="form-group">
										<div class="clearfix">
											<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('View Selector')}</label>
										</div>
										<div class="control-input-wrapper">
											<div class="control-input flex align-center">
												<select id="ioi_side_view_select" type="text" autocomplete="off" class="input-with-feedback form-control ellipsis">
													${result
														.filter(el => {
															if (el.view_selector !== 0 && !unique_view_selectors.has(el.view_selector)) {
																unique_view_selectors.add(el.view_selector);
																return true;
															}
															return false;
														})
														.sort((a, b) => a.view_selector - b.view_selector)
														.map(el => `<option value="${el.view_selector}">${el.view_selector}</option>`)
														.join('')
													}
												</select>
												<div class="select-icon ">
													<svg class="icon  icon-sm" style="">
														<use class="" href="#icon-select"></use>
													</svg>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>`;

						$('#highlight_info_buttons').append(select);

						$("#ioi_side_view_select")[0].onchange = () => me.get_highlight_list($("#ioi_side_view_select").val())

						result = result.filter(el => el.view_selector == $("#ioi_side_view_select").val())
					}

					for (const item of result) await me.build_highlight_content(item);

				} else {
					const div = document.createElement('div')
					div.innerHTML = `
						<div class="w-100 d-flex justify-content-center mt-4" style="text-align: center;">
							${__('Nothing to show')} <br/>
							${__('Define Highlight info in ioi Module')}
						</div>`

					$('#highlight_info_content').append(div)
				}
			}
		})
	}

	async build_highlight_content(item) {
		const div = document.createElement('div')
		div.classList.add('card', 'mb-4', 'border-0')
		div.style.height = `${item.card_height}px`

		const limit_text = isNaN(item.limited_to) ? __('No records limit') : __('Limited to {0} records', [item.limited_to])

		div.innerHTML = `
			<div class="card-header border-0" style="font-family: var(--font-stack); font-size: 0.84rem;">
				${item.title}
			</div>
			<div class="card-body ${item.sql_for_query_grid ? 'p-3' : ''} rounded-bottom" style="font-size: var(--text-sm); background-color: var(--card-bg); overflow: auto; border: 1px solid var(--border-color);">
				${
					item.display_mode === "HTML" ?
						item.fields_to_display ?
							await this.highlight_content_filter_html(item.fields_to_display)
						: ''
					: item.display_mode === "Query Grid" ?
						item.sql_for_query_grid ?
							`
							<div id="highlight_query_grid_${item.display_order}" class="table table-bordered my-0" data-custom-grid="true"></div>
							<p class="help-box small text-muted">${limit_text}</p>
							`
						: ''
					: ''
				}
			</div>
		`

		$('#highlight_info_content').append(div)

		if (item.sql_for_query_grid) this.build_query_grid(item)
	}

	get_params(el) {
		let param = el.match(/\[(.*?)\]/)

		if (param) {
			param = param[0]
			el = el.replace(param, '')
		} else {
			param = null
			el = el
		}

		return [param, el]
	}

	async highlight_content_filter_html(text) {
		// Search {}
		const regex = /\{([^}]+)\}/g
		const matches = text.match(regex);

		if (matches) {
			let modified_text = text;

			const simple_fields = []
			const linked_fields = []
			const composite_fields = []

			const set_params_and_format = (el) => {
				let new_value = el.value
				let field_to_replace = `{${el.field}}`

				if (el.param && el.param !== "") {
					if (el.param === "[LINK]") {
						let url = ''

						if (el.field.includes('•')) {
							url = el.doctype && el.name ? `/app/${this.frm.doctype.toLowerCase().replaceAll(' ', '-')}/${this.frm.docname}?id=${el.field_name}` : ''
						} else {

							let section = el.field.split('.')[1]
							url = el.doctype && el.name ? `/app/${el.doctype.toLowerCase().replaceAll(' ', '-')}/${el.name}${!!section ? `#${section}` : ""}` : ''
						}

						new_value = `<span class="text-underline"><a href="${url}" target="_blank">${el.value}</a></span>`
						field_to_replace = `{${el.field}[LINK]}`
					}
				}

				return modified_text = modified_text.replace(field_to_replace, new_value);
			}

			// Prepare to format values
			matches.forEach(el => {
				// Remove {}
				el = el.replace(/\{|\}/g, '')

				if (el.includes('.') && !el.includes('•')) {

					const [param, new_el] = this.get_params(el)
					const [link, field] = new_el.split('.')

					const doctype =	this.frm.fields_dict[link].df.fieldtype == "Link" ? this.frm.fields_dict[link].df.options :
									this.frm.fields_dict[link].df.fieldtype == "Dynamic Link" ? this.frm.fields_dict[link].df._options :
									undefined

					const link_data = this.frm.doc[link]
					linked_fields.push({ link_data, doctype, field, link, param })

				} else if (el.includes('•')) {

					let [param, new_el] = this.get_params(el)
					let doctype = new_el.split(', ')[1].trim()

					new_el = new_el.split(', ')[0]
					let [comp, field] = new_el.split('.')

					// curr_field is current doc field value
					let curr_field = comp.split('•')[0].trim()
					const curr_field_name = curr_field

					curr_field = this.frm.doc[curr_field]

					comp = comp.split('•')[1].trim()

					composite_fields.push({ comp, field, curr_field, curr_field_name, doctype, param })

				} else {

					const [param, field] = this.get_params(el)

					const doctype =	this.frm.fields_dict[field].df.fieldtype == "Dynamic Link" ? this.frm.fields_dict[field].df._options :
									this.frm.fields_dict[field].df.options

					const value = this.frm.doc[field] || __('undefined')
					const name = value

					simple_fields.push({ field, doctype, value, param, name })
				}
			})

			// Format text for simple fields
			simple_fields.forEach(el => set_params_and_format(el));

			// Format text for linked and composites fields
			if (linked_fields.length || composite_fields.length) {
				const r = await frappe.call("silicon_ioi.utils.lib.system.get_highlight_content_fields", {
					linked_fields: linked_fields,
					composite_fields: composite_fields,
				})

				const result = r.message

				if (result) {
					if (result.linked_fields) result.linked_fields.forEach(el => set_params_and_format(el))
					if (result.composite_fields) result.composite_fields.forEach(el => set_params_and_format(el))
				}
			}

			// Format text for left undefined values
			const last_matches = modified_text.match(regex);
			if (last_matches) last_matches.map(el => modified_text = modified_text.replace(el, __('undefined')))

			return modified_text;
		}

		return text;
	}

	is_read_only_query(query) {
		if (!!query) {
			const forbidden_keywords = ["insert ", "update ", "delete ", "drop ", "create ", "alter "];
			const normalized_query = query.toLowerCase().replace(/\s+/g, ' ').trim();

			if (!normalized_query.startsWith("select")) {
				return false;
			}

			for (const keyword of forbidden_keywords) {
				if (normalized_query.includes(keyword)) {
					return false;
				}
			}

			return true;
		}
	}

	build_query_grid(item) {
		const query = item.sql_for_query_grid
		const regex = /\{([^}]+)\}/g
		const matches = query.match(regex);
		let error = false

		if (matches) {
			const fields = []
			let modified_query = query;

			const set_params_and_format = (el) => {
				let value = el.new_el
				let field_to_replace = `{${el.new_el}}`

				if (el.param && el.param !== "") {
					if (el.param === "[COLLAPSED]") {
						field_to_replace = `{${value}[COLLAPSED]}`
					}
				}

				if (!this.frm.doc[value] || this.frm.doc[value] === "") {
					error = true
					return $(`#highlight_query_grid_${item.display_order}`).parent().html(`<div class="d-flex justify-content-center align-items-center h-100 text-danger text-center">${__('Error : Field {0} is undefined', [value])}</div>`)
				}

				return modified_query = modified_query.replace(field_to_replace, `'${this.frm.doc[value]}'`);
			}

			matches.forEach(el => {
				// Remove {}
				el = el.replace(/\{|\}/g, '')

				const [param, new_el] = this.get_params(el)
				fields.push({new_el, param})
			})

			fields.forEach(el => set_params_and_format(el))

			if (error) {
				return;
			}

			if (this.is_read_only_query(modified_query)) {

				frappe.call("silicon_ioi.utils.lib.system.get_highlight_content_query", {
					query: modified_query,
					limit: item.limited_to
				}).then(r => {
					if (r.message.length) {
						const select = modified_query.match(/SELECT(.*?)FROM/i)
						let columns = []

						const saved_column_sizes = item.query_column_sizes && item.query_column_sizes
							.split(';')
							.filter(el => el)
							.map(el => {
								const array = el.split(':')
								return { [array[0]]: array[1] }
						})

						if (select && select.length > 1) {
							const select_array = select[1].split(',')

							if (item.collapsible_only_for_query_grid) columns.push({ formatter:"responsiveCollapse", headerSort:false })

							select_array.forEach(el => {
								const is_size_saved = saved_column_sizes && saved_column_sizes.find(col => Object.keys(col)[0] == el.trim())
								const saved_value = is_size_saved ? Object.values(is_size_saved)[0] : ""

								const field = el.trim()
								const title = field.charAt(0).toUpperCase() + field.slice(1)

								columns.push({ title: __(title.replaceAll('_', ' ')), field: field, width: saved_value && saved_value != 0 ? `${saved_value}%` : "" })
							})
						}

						let table = new ioi.Tabulator(`#highlight_query_grid_${item.display_order}`, {
							maxHeight: `${item.card_height - 100}px`,
							data: r.message,
							layout: saved_column_sizes ? "fitData" : "fitColumns",
							columns: columns,
							responsiveLayout: item.collapsible_only_for_query_grid ? "collapse" : false,
							columnDefaults: {
								minWidth: item.collapsible_only_for_query_grid ? 100 : '100%',
							},
						});
					}
				})
			} else {

				return $(`#highlight_query_grid_${item.display_order}`).parent().html(`<div class="d-flex justify-content-center align-items-center h-100 text-danger text-center">${__('Error : Only read-only queries are allowed, they must start with SELECT')}</div>`)
			}
		}
	}

	show_emails_sent_side_view(curr_doctype_infos) {
		if (frappe.get_route()[0] == 'Form') {
			if (curr_doctype_infos && curr_doctype_infos.enabled == 1) {

				const saved_width = this.check_side_view_res(curr_doctype_infos.preview_width)
				const side_view_height = 950

				const refresh_button = `
					<button id="emails_sent_refresh" class="text-muted btn btn-xs btn-default mr-2 my-1" title="${__('Refresh')}">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
							<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
						</svg>
					</button>`

				const container = `
					<div id="ioi_side_view" class="p-4 ml-3 rounded position-relative" style="width: ${saved_width && saved_width != 0 ? `${saved_width}px` : '30%'};">
						<button id="close_ioi_side_view" class="btn btn-xs position-absolute shadow-none" style="top: 0px; right: 0px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
							</svg>
						</button>
						<label class="control-label" style="padding-right: 0px; font-size: var(--text-md);">${__('Emails Sent')}</label>
						<div id="emails_sent_grid" class="table table-bordered mt-2 mb-1" style="max-height: 100%; font-size: var(--text-md);" data-custom-grid="true"></div>
						<div class="d-flex flex-row flex-wrap align-items-start justify-content-start mt-3">
							${refresh_button}
						</div>
					</div>`;

				const resizer_html = `<div id="ioi_side_view_resizer" class="resizer rounded" style="height: ${side_view_height - 50}px;"></div>`;
				const main_layout = $(`[data-page-route="${this.frm.doctype}"]`).find(".row.layout-main");

				this.clear_ioi_side_view()

				main_layout.append(resizer_html).append(container)
				this.create_resizer(this.frm.doctype);

				this.emails_sent_grid()
				this.emails_sent_search()

				$("#emails_sent_refresh")[0].onclick = () => this.emails_sent_search()

				$("#close_ioi_side_view")[0].onclick = () => {
					this.clear_ioi_side_view()
					frappe.call('silicon_ioi.utils.lib.system.toggle_doctype_preview', { enabled: 0, doctype: this.frm.doctype, type: curr_doctype_infos.side_view_type, is_new: 0, side_view_origin: 'FORM' })
				}

			} else if (curr_doctype_infos && curr_doctype_infos.enabled == 0) {
				this.clear_ioi_side_view()
			}
		} else {
			this.clear_ioi_side_view()
		}
	}

	emails_sent_grid(data) {

		let format_date = (cell) => {
			return cell.getValue().split(".")[0]
		}

		let columns = [
			{ title: __("Status"), field: "status" },
			{ title: __("Last mod (Date Time)"), field: "modified", formatter: format_date },
			{ title: __("Communication"), field: "communication" },
			{ title: __("Error"), field: "error" },
			{ title: __("Email account"), field: "email_account" },
		]

		this.emails_sent_table = new ioi.Tabulator("#emails_sent_grid", {
			maxHeight: "100%",
			data: data,
			layout: "fitColumns",
			autoRedraw: true,
			selectableRows: 1,
			initialSort: [
				{ column: "modified", dir: "desc" },
			],
			columnDefaults: {
				minWidth: 85,
				vertAlign: "middle",
			},
			columns: columns,
		})

		this.emails_sent_table.on("rowSelectionChanged", function(data, rows, selected, deselected){
			if (data.length) {
				$('#ioi_side_view').append(`<div id="email_info_content" class="border p-3 mt-5"></div>`)

				frappe.call('silicon_ioi.utils.lib.system.get_emails_sent_recipients_list', {
					name: data[0].name,
				}).then(r => {
					const container = $('#email_info_content')

					const show_email_queue = frappe.ui.form.make_control({
						parent: container,
						df: {
							label: __('Open Email Queue'),
							fieldname: 'email_queue',
							fieldtype: 'Button',
							btn_size: 'xs',
						},
						render_input: true,
					})

					show_email_queue.input_area.classList.add('d-flex', 'justify-content-end')
					show_email_queue.input.onclick = () => open(`/app/email-queue/${data[0].name}`, "_blank")

					const recipients_table = frappe.ui.form.make_control({
						parent: container,
						df: {
							label: __('Recipients'),
							fieldname: 'recipients',
							fieldtype: 'Table',
							fields: [
								{
									fieldname: "recipient",
									fieldtype: "Data",
									in_list_view: 1,
									label: __("Recipient"),
								},
								{
									fieldname: "status",
									fieldtype: "Data",
									in_list_view: 1,
									label: __("Status"),
								},
							],
							data: r.message.map(el => ({'recipient': el.recipient, 'status': el.status}))
						},
						render_input: true,
					})

					recipients_table.wrapper.querySelector('.grid-footer').remove()
					recipients_table.grid.cannot_add_rows = true;
					recipients_table.refresh()

					const show_as_cc = frappe.ui.form.make_control({
						parent: container,
						df: {
							label: __('Show as cc'),
							fieldname: 'show_as_cc',
							fieldtype: 'Small Text',
						},
						render_input: true,
					})

					show_as_cc.value = data[0].show_as_cc
					show_as_cc.refresh()

					const message = frappe.ui.form.make_control({
						parent: container,
						df: {
							label: __('Message'),
							fieldname: 'message',
							fieldtype: 'Code',
						},
						render_input: true,
					})

					message.value = data[0].message
					message.refresh()
				})
			} else {
				$('#ioi_side_view #email_info_content').remove()
			}
		});
	}

	emails_sent_search() {
		let me = this

		frappe.call({
			method: "silicon_ioi.utils.lib.system.get_emails_sent_list",
			args: {
				doctype: this.frm.doctype,
				docname: this.frm.docname,
			},
			async: true,
			callback: function (r) {
				if (r.message.length > 0) {
					if (me.emails_sent_table.initialized) {
						me.emails_sent_table.replaceData(r.message)
					} else {
						me.emails_sent_table.on('tableBuilt', () => me.emails_sent_table.replaceData(r.message))
					}
				} else {
					me.emails_sent_table.clearData()
				}
			}
		})
	}

	create_resizer(doctype) {
		const file_preview = $("#ioi_side_view")[0];
		const resizer = $("#ioi_side_view_resizer")[0];

		if (file_preview) {
			let x = 0;
			let file_preview_width = 0;
			let original_width = 0;
			let timeoutId = null;

			const cursor_style_display = (toggle) => {
				const prevSibling = resizer.previousElementSibling;
				const nextSibling = resizer.nextElementSibling;

				if (toggle === "add") {
					document.body.style.cursor = "ew-resize";
					prevSibling.style.userSelect = 'none';
					prevSibling.style.pointerEvents = 'none';
					nextSibling.style.userSelect = 'none';
					nextSibling.style.pointerEvents = 'none';
				} else {
					document.body.style.removeProperty('cursor');
					prevSibling.style.removeProperty('user-select');
					prevSibling.style.removeProperty('pointer-events');
					nextSibling.style.removeProperty('user-select');
					nextSibling.style.removeProperty('pointer-events');
				}
			}

			const mouse_down_handler = function(e) {
				x = e.clientX;
				const rect = file_preview.getBoundingClientRect();
				file_preview_width = rect.width;
				original_width = file_preview_width;

				cursor_style_display("add")

				document.addEventListener('mousemove', mouse_move_handler);
				document.addEventListener('mouseup', mouse_up_handler);

				clearTimeout(timeoutId);
			};

			const mouse_move_handler = function(e) {
				const dx = e.clientX - x;
				const new_width = file_preview_width - dx;

				if (new_width > 0 && new_width < (window.innerWidth/2.25) ) {
					file_preview.style.width = new_width + 'px';
				}

				clearTimeout(timeoutId);
			};

			const mouse_up_handler = function() {
				cursor_style_display("remove")

				document.removeEventListener('mousemove', mouse_move_handler);
				document.removeEventListener('mouseup', mouse_up_handler);

				clearTimeout(timeoutId);

				timeoutId = setTimeout(() => {
					if (Math.round(parseFloat(file_preview.style.width)) !== original_width) {
						frappe.call('silicon_ioi.utils.lib.system.save_pdf_viewer_width', { doctype: doctype, width: Math.round(parseFloat(file_preview.style.width)) });
					}
				}, 2000);
			};

			resizer.addEventListener('mousedown', mouse_down_handler);
		}
	}

	// ***************************************************************************************************************************************
	// Add button to jump to other company (ioi) website
	// **************************************************************************************************************************************
	jump_to_db_companies() {

		frappe.call({
			method: "silicon_ioi.utils.lib.system.jump_to_db_companies_data",
			args: {route: frappe.get_route()},
			callback: (c) => {
				let cmp_list = c.message;
				if (cmp_list.length > 0) {
					let icon = `<div id="jump_content_btn_id" style="position: relative; top:7px; left: 0px; height: 30px; width:auto">
									<i class="fa fa-globe"></i>
									<span>${__("Jump")}</span>
								</div>`;

					if ($(".custom-btn-group").find('#jump_content_btn_id')){
						$(".custom-btn-group").remove();
					}
					let jump_grp = this.frm.page.add_custom_button_group(icon);
					cmp_list.forEach((company) => {
						this.frm.page.add_custom_menu_item(jump_grp, company?.company_name, () => {
							if (company.jump_on_new_tab){
								window.open(company.jump_url, "_blank");
							}else{
								window.location.href = company.jump_url;
							}
						});
					});

				}
			}
		});
	}

	// ***************************************************************************************************************************************
	// Add Click on tab (if exists)
	// **************************************************************************************************************************************
	assign_click_on_tab() {
		let me = this;

		for (var i = 0; i < this.frm.layout.tabs.length; i++) {

			this.frm.layout.tabs[i].tab_link.on('mouseup', (e) => {
				me.save_current_tab(e.target.id);

				if (document.getElementById('bt_map_refresh')) {
					document.getElementById('bt_map_refresh').click();
				}
			})
		}
	}

	// ***************************************************************************************************************************************
	// Add Click on section (if exists)
	// **************************************************************************************************************************************
	assign_click_on_section()
	{
		let me = this;
		this.section_original_label = [];
		this.section_customized_label = [];
		this.section_collapse = [];

		if (!this.frm.is_new())
		{
			let dc = this.frm.doctype;
			let name = this.frm.doc.name;

			if (dc == 'ioi Purchases Proposals') {
				dc = 'ioi Purchases Proposal Internal';
				name = ''
				if (document.getElementById('ioi_purchases_proposals_form_name')) {
					name = document.getElementById('ioi_purchases_proposals_form_name').value;
				}
			}

			let method = this.path_user_public_section + '.get_formatted_section'

			frappe.call({  	method: method,
							args: {"doctype": dc, "name": name},
							async: false,
							callback:function(r) {

								if (r.message)
								{
									for(var i = 0; i < r.message.length; i++)
									{
										me.section_customized_label[i] = [r.message[i].section, r.message[i].content];

										let collapse = r.message[i].collapse_public != undefined ? r.message[i].collapse_public : r.message[i].collapse_personal
										me.section_collapse[i] = { 'section': r.message[i].section, 'default_collapse': collapse, 'keep_open': r.message[i].open, 'collapse_conditions': r.message[i].collapse_conditions };
									}
								}
							}
			});
		}

		for (var i = 0; i < this.frm.layout.sections.length; i++)
		{

			if (document.getElementById('img_' + i.toString() + '_' + this.frm.layout.sections[i].df.fieldname))
			{
				document.getElementById('img_' + i.toString() + '_' + this.frm.layout.sections[i].df.fieldname).remove();
			}

			if (this.frm.layout.sections[i].head)
			{
				if (this.frm.layout.sections[i].collapse_link)
				{
					let fct_section_click = function () { me.save_current_section(this.parentElement); };

					this.frm.layout.sections[i].head[0].onmouseup = fct_section_click;

				}
			}

			if ((this.frm.layout.sections[i].df) && (this.frm.layout.sections[i].df.fieldname) && (this.frm.layout.sections[i].head))
			{
				this.section_original_label[i] = __(this.frm.layout.sections[i].df.label);

			}else
			{
				this.section_original_label[i] = '';

			}

			if (this.frm.layout.sections[i].df)
			{
				if (this.frm.layout.sections[i].df.fieldname)
				{
					if (this.frm.layout.sections[i].head)
					{
						if (this.frm.layout.sections[i].head[0].className == 'section-head collapsible collapsed')
						{
							let caption = '';

							for(var k = 0; k < this.section_customized_label.length; k++)
							{
								if (this.section_customized_label[k][0] == this.frm.layout.sections[i].df.fieldname)
								{
									caption = this.section_customized_label[k][1];
									break;
								}
							}

							let s = this.frm.layout.sections[i].head[0].innerHTML;


							let first = '';

							if (caption.trim() != '') {
								first = caption;
							}else
							{	first = __(this.frm.layout.sections[i].df.label);

							}

							let last = '';

							if (s.indexOf('</span>') != -1) {
								last = s.substring(s.indexOf('</span>') + 7, s.length);
							}else if (s.indexOf('↓') != -1) {
								last = s.substring(s.indexOf('↓') + 1, s.length);
							}else if (s.indexOf('↑') != -1) {
								last = s.substring(s.indexOf('↑') + 1, s.length);
							}



							let html = '';

							html += '&nbsp;&nbsp;&darr;';


							last = html + last;

							this.frm.layout.sections[i].head[0].innerHTML = first + last;

						}else
						{

							let s = this.frm.layout.sections[i].head[0].innerHTML;


							let caption = '';

							for(var k = 0; k < this.section_customized_label.length; k++)
							{
								if (this.section_customized_label[k][0] == this.frm.layout.sections[i].df.fieldname)
								{
									caption = this.section_customized_label[k][1];
									break;
								}
							}

							let first = '';


							if (this.frm.layout.sections[i].collapse_link)
							{
								first = __(this.frm.layout.sections[i].df.label);
							}else
							{
								if (caption.trim() != '') {
									first = caption;
								}else
								{
									first = __(this.frm.layout.sections[i].df.label);
								}
							}


							let last = '';

							if (this.frm.layout.sections[i].collapse_link)
							{

								if (s.indexOf('</span>') != -1) {
									last = s.substring(s.indexOf('</span>') + 7, s.length);
								}else if (s.indexOf('↓') != -1) {
									last = s.substring(s.indexOf('↓') + 1, s.length);
								}else if (s.indexOf('↑') != -1) {
									last = s.substring(s.indexOf('↑') + 1, s.length);

								}



								let html = '';

								html += '&nbsp;&nbsp;&uarr;';



								last = html + last;
							}



							this.frm.layout.sections[i].head[0].innerHTML = first + last;

						}

						if (this.frm.layout.sections[i].head[0].className.includes('collapsible')) {

							let section_params = this.section_collapse.find(el => el.section == this.frm.layout.sections[i].df.fieldname)

							if (section_params) {
								let fields_dict = this.frm.fields_dict[this.frm.layout.sections[i].df.fieldname]
								let head = fields_dict && fields_dict.head[0]

								if (
									(section_params.default_collapse == 1 && section_params.keep_open == 1) ||
									(section_params.default_collapse == 0 && section_params.keep_open == 1)) {

									if (fields_dict && fields_dict.is_collapsed()) fields_dict.collapse();
									head.innerHTML = head.innerHTML.replace('↓', '↑')

								} else if (section_params.default_collapse == 1 && section_params.keep_open == 0) {

									const match_in_conditions = this.check_collapse_conditions(section_params.collapse_conditions)

									// If one or more conditions and no condition match --> open panel, if one or more conditions and match --> close panel
									// If no condition --> close panel

									if (section_params.collapse_conditions && section_params.collapse_conditions.length) {
										if (match_in_conditions) {

											if (fields_dict && !fields_dict.is_collapsed()) fields_dict.collapse();
											head.innerHTML = head.innerHTML.replace('↑', '↓')

										} else {

											if (fields_dict && fields_dict.is_collapsed()) fields_dict.collapse();
											head.innerHTML = head.innerHTML.replace('↓', '↑')

										}
									} else {

										if (fields_dict && !fields_dict.is_collapsed()) fields_dict.collapse();
										head.innerHTML = head.innerHTML.replace('↑', '↓')
									}
								}
							}
						}
					}
				}
			}


			if (this.frm.layout.sections[i].df)
			{
				if (this.frm.layout.sections[i].df.fieldname)
				{
					if (this.frm.layout.sections[i].head)
					{
						let html = '<img id="img_' + i.toString() + '_' + this.frm.layout.sections[i].df.fieldname + '" ';
						html += 'src="' + this.img_param_path + '" width="20px" height="20px" align="right" style="horizontal-align:left;">';
						html += '</img>&nbsp;';

						this.frm.layout.sections[i].head[0].insertAdjacentHTML('beforeend', html);

						let fct_click = function () {

							let fn = this.id;
							fn = fn.substring(fn.indexOf('img_')+4, fn.length);

							let idx = fn.substring(0, fn.indexOf('_'));
							fn = fn.substring(fn.indexOf('_')+1, fn.length);

							me.open_section_param(this, fn);
							return false;
						};

						document.getElementById('img_' + i.toString() + '_' + this.frm.layout.sections[i].df.fieldname).onmousedown = fct_click;
					}
				}
			}

		}
	}

	check_collapse_conditions(conditions) {

		return conditions && conditions.some(condition => {
			let str_value = condition.value?.toString().toLowerCase() || "";
			let str_doc_value = this.frm.doc[condition.field]?.toString().toLowerCase() || "";

			let int_value = parseFloat(condition.value);
			let int_doc_value = parseFloat(this.frm.doc[condition.field]);

			switch (condition.type) {
				case "=":
					return str_value === str_doc_value;
				case "!=":
					return str_value !== str_doc_value;
				case ">":
					return !isNaN(int_value) && !isNaN(int_doc_value) && int_doc_value > int_value;
				case "<":
					return !isNaN(int_value) && !isNaN(int_doc_value) && int_doc_value < int_value;
				case ">=":
					return !isNaN(int_value) && !isNaN(int_doc_value) && int_doc_value >= int_value;
				case "<=":
					return !isNaN(int_value) && !isNaN(int_doc_value) && int_doc_value <= int_value;
				case "like":
					return str_doc_value.includes(str_value);
				default:
					return false;
			}
		});
	}

	open_section_param(obj, section_fieldname)
	{
		let o = obj;
		let sect = section_fieldname

		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_open_section_param(o, sect); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_open_section_param(o, sect);
		}
	}

	do_open_section_param(obj, section_fieldname)
	{
		let me = this;

		var d = new frappe.ui.Dialog({
			'title': __("Section configuration"),
			'fields': [
				{'fieldname': 'html_section_parameter', 'fieldtype': 'HTML'}

			],
			primary_action_label: __('Ok'),
			secondary_action_label: __("Cancel"),
			primary_action: function(){

				document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

				let is_public = document.getElementById('html_section_parameter_ispublic').checked ? 1 : 0;
				let default_collapse = document.getElementById('html_section_parameter_defaultcollapse').checked ? 1 : 0;
				let keep_open = document.getElementById('html_section_parameter_keepopen').checked ? 1 : 0;

				let dc = me.frm.doctype;

				if (dc == 'ioi Purchases Proposals') {
					dc = 'ioi Purchases Proposal Internal';
				}

				let method = me.path_user_public_section + '.save_section_configuration'
				frappe.call({  	method: method,
								args: {	"doctype": dc,
										"name": me.frm.doc.name,
										"section": section_fieldname,
										"content": document.getElementById('html_section_parameter_content').value,
										"is_public": is_public,
										"default_collapse": default_collapse,
										"keep_open": keep_open
									},
								async: false,
								callback:function(r)	{
															d.hide();
															window.location.reload();
														}
				});

			},
			secondary_action: function() {
				d.hide();
			}

		});


		if (document.getElementById('html_section_parameter_content'))
		{
			document.getElementById('html_section_parameter_content').remove();
		}

		if (document.getElementById('html_section_parameter_fields'))
		{
			document.getElementById('html_section_parameter_fields').remove();
		}

		if (document.getElementById('html_section_parameter_checkboxes'))
		{
			document.getElementById('html_section_parameter_checkboxes').remove();
		}

		if (document.getElementById('html_section_parameter_search_field'))
		{
			document.getElementById('html_section_parameter_search_field').remove();
		}

		if (document.getElementById('html_section_parameter_all_fields'))
		{
			document.getElementById('html_section_parameter_all_fields').remove();
		}



		if (document.getElementById('html_section_parameter_grid'))
		{
			document.getElementById('html_section_parameter_grid').remove();
		}

		if (document.getElementById('html_section_parameter_grid_header'))
		{
			document.getElementById('html_section_parameter_grid_header').remove();
		}

		if (document.getElementById('html_section_parameter_grid_detail'))
		{
			document.getElementById('html_section_parameter_grid_detail').remove();
		}

		let current_content = '';

		for (var i = 0; i < this.frm.layout.sections.length; i++)
		{
			if (this.frm.layout.sections[i].df)
			{
				if (this.frm.layout.sections[i].df.fieldname)
				{
					if (this.frm.layout.sections[i].df.fieldname.toLowerCase() == section_fieldname.toLowerCase())
					{
						current_content = __(this.frm.layout.sections[i].df.label);
						break;
					}
				}
			}
		}

		let content = current_content;
		let is_standard = 0;
		let is_public = 0;
		let default_collapse = 0;
		let keep_open = 0;

		let dc = me.frm.doctype;

		if (dc == 'ioi Purchases Proposals') {
			dc = 'ioi Purchases Proposal Internal';
		}


		let method = this.path_user_public_section + '.get_section_configuration'
		frappe.call({  	method: method,
						args: {	"doctype": dc,
								"section": section_fieldname
						},
						async: false,
						callback:function(r)	{

							if (r.message.content.trim() != '') {
								content = r.message.content;
							}

							is_standard = r.message.std;
							is_public = r.message.is_public;
							default_collapse = r.message.default_collapse;
							keep_open = r.message.keep_open;

						}
		});


		let html = '';

		html += '<div style="overflow: auto; height:500px; width: 100%;">';

		html += '<div style="position:absolute; top: 0px; left: 0px; height:30px; width: 100%; color:black; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;" data-custom-section-head="true">';
		html += '	<label style="position: absolute; top: 6px; left: 4px;"><b>' + __("Title") + '</b></label>';
		html += '</div>';

		html += '	<div style="position: absolute; top: 40px; left: 0px; width:100%;">';
		html += '		<div class="control-input" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 25px;"> ';
		html += '			<input id="html_section_parameter_content" type="text" autocomplete="off" class="input-with-feedback form-control bold" value="">';
		html += '		</div>';
		html += '	</div>';

		html += '	<div id="html_section_parameter_checkboxes" class="d-flex flex-column" style="position: absolute; top: 80px; left: 0px; width:100%;">'

		html += '	<div class="d-flex flex-row">'

		html += '		<div class="form-check mr-3">';
		html += `			<input class="form-check-input" type="checkbox" value="" id="html_section_parameter_ispublic" ${is_public == 1 ? 'checked' : ''}>`
		html += `			<label class="form-check-label" for="html_section_parameter_ispublic">${__('Public')}</label>`
		html += '		</div>';

		html += '		<div class="form-check mr-3">';
		html += `			<input class="form-check-input" type="checkbox" value="" id="html_section_parameter_isstandard" disabled ${is_standard == 1 ? 'checked' : ''}>`
		html += `			<label class="form-check-label" for="html_section_parameter_isstandard">${__('Standard')}</label>`
		html += '		</div>';

		html += '		<div class="form-check mr-3">';
		html += `			<input class="form-check-input" type="checkbox" value="" id="html_section_parameter_defaultcollapse" ${default_collapse == 1 ? 'checked' : ''}>`
		html += `			<label class="form-check-label" for="html_section_parameter_defaultcollapse">${__('Collapse by default')}</label>`
		html += '		</div>';

		html += '		<div class="form-check mr-3">';
		html += `			<input class="form-check-input" type="checkbox" value="" id="html_section_parameter_keepopen" ${keep_open == 1 ? 'checked' : ''}>`
		html += `			<label class="form-check-label" for="html_section_parameter_keepopen">${__('Keep open for me')}</label>`
		html += '		</div>';

		html += '	</div>'

		html += '	<div id="collapsed_conditions_button" class="mt-2"></div>'

		html += '	</div>'

		html += '	<div style="position: absolute; top: 150px; left: 0px; width:100%;">';
		html += '		<label style="position: absolute; top: 0px; left: 2px;">' + __("Free texts have to be in \" \" and fields in { } ") + '</label>';
		html += '	</div>';

		html += '	<div style="position:absolute; top: 180px; left: 0px; height:30px; width: 100%; color:black; border-radius: 6px; padding: 4px; display: auto; vertical-align: middle;" data-custom-section-head="true">';
		html += '		<label style="position: absolute; top: 6px; left: 4px;"><b>' + __("Fields (drag & drop in title area)") + '</b></label>';
		html += '	</div>';

		html += '	<div style="position: absolute; top: 220px; left: 0px; width:100%;">';
		html += '		<table id="html_section_parameter_grid_header" border=1 style="border: 1px solid #E8EAEB" width=806px>';

		html += '	<tr style="height:40px" data-custom-grid-bottom="true">';

		html += '		<td width=400px style="vertical-align: middle;">';
		html += '			<div class="control-input" style="position: absolute; top: 5px; left: 5px; width: 390px; height: 30px;"> ';
		html += '			<input id="html_section_parameter_search_field" type="text" autocomplete="off" class="input-with-feedback form-control bold" placeholder="'+__("Type here to search for a field")+'" style="height:30px; ">';
		html += '			</div>';
		html += '		</td>';
		html += '		<td width=406px style="vertical-align: middle;">';
		html += '			<div class="control-input" style="position: relative; top: 5px; left: 5px; width: 390px; height: 30px;"> ';
		html += '			<input type="checkbox" id="html_section_parameter_all_fields" style="postion:absolute; top: 2px; left: 2px;">&nbsp;' + __("All fields");;
		html += '			</div>';
		html += '		</td>';


		html += '		</tr>';
		html += '		</table>';
		html += '	</div>';

		html += '	<div id="html_section_parameter_grid" style="position: absolute; top: 260px; left: 0px; width:806px; height:220px; overflow: auto;">';
		html += '	</div>';


		html += '</div>';

		d.fields_dict.html_section_parameter.$wrapper.html(html);

		this.build_collapsed_conditions_grid(d.$wrapper, section_fieldname);

		if (window.innerWidth <= 500) {
			d.$wrapper.find('.modal-dialog').css("max-width", '100%').css("width", '100%');
		}else {
			d.$wrapper.find('.modal-dialog').css("max-width", '850px').css("width", '850px');
		}


		let fct_search_keydown = function(event) {

			if (event.keyCode == 13) {
				me.section_parameter_search(section_fieldname);
			}
		};

		let fct_search_click = function() {

			me.section_parameter_search(section_fieldname);
		};


		this.sleep_ms(200).then(() => {
			document.getElementById('html_section_parameter_content').value = content;
			document.getElementById('html_section_parameter_search_field').onkeydown = fct_search_keydown;
			document.getElementById('html_section_parameter_all_fields').onclick = fct_search_click;
			me.section_parameter_search(section_fieldname);
		});

		d.show();

	}

	async build_collapsed_conditions_grid(wrapper, section_fieldname) {
		let me = this

		const button = frappe.ui.form.make_control({
			parent: wrapper.find('#collapsed_conditions_button'),
			df: {
				label: __('Set conditions to default collapse'),
				fieldname: 'collapse_conditions',
				fieldtype: 'Button',
				btn_size: 'xs'
			},
			render_input: true
		})

		const dc = this.frm.doctype == 'ioi Purchases Proposals' ? 'ioi Purchases Proposal Internal' : this.frm.doctype;

		// Get section fields
		const field_options = await frappe.call({
			method: this.path_common + '.section_parameters_get_field',
			args: {
				"doctype": dc,
				"search_term": '',
				"section": section_fieldname,
				"all_fields": 0
			},
		}).then(r => r.message)

		button.$input.on('mousedown', async () => {

			const public_value = wrapper.find('#html_section_parameter_ispublic')[0].checked
			const parent = public_value == 1 ? `${dc} • ${section_fieldname}` : `${dc} • ${section_fieldname} • ${frappe.session.user}`

			const child_table_data = await frappe.call({
				method: this.path_user_public_section + '.get_section_collapse_conditions',
				args: {
					"parent": parent,
				},
			}).then(r => r.message)

			let d = new frappe.ui.Dialog({
				title: __('Set conditions to default collapse'),
				fields: [
					{
						label: __('Collapse the panel only if any condition matches'),
						fieldname: "collapse_conditions",
						fieldtype: "Table",
						fields: [
							{
								fieldname: "field",
								fieldtype: "Autocomplete",
								in_list_view: 1,
								label: __("Field"),
								options: field_options.map(el => el.fieldname),
								reqd: 1,
							},
							{
								fieldname: "type",
								fieldtype: "Select",
								in_list_view: 1,
								label: __("Type"),
								reqd: 1,
								options: [
									'=',
									'<',
									'<=',
									'>',
									'>=',
									'!=',
									'like'
								]
							},
							{
								fieldname: "value",
								fieldtype: "Data",
								in_list_view: 1,
								label: __("Value"),
							}
						],
						data: child_table_data
					}
				],
				size: "large",
				primary_action_label: __("Save"),
				primary_action(values) {

					const content = values.collapse_conditions ? values.collapse_conditions.map(cond => ({ 'field': cond.field, 'type': cond.type, 'value': cond.value, 'is_public': public_value ? 1 : 0 })) : []
					frappe.call({ method: me.path_user_public_section + '.update_section_collapse_conditions', args: { "doctype": dc, "section": section_fieldname, 'content': content, 'is_public': public_value ? 1 : 0 }})

					d.hide();
				},
				secondary_action_label: __("Cancel"),
				secondary_action() {
					d.hide();
				},
			});

			d.show();
		})
	}

	sleep_ms(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	static static_sleep_ms(ms)
	{
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	section_parameter_search(section_fieldname)
	{
		if (document.getElementById('html_section_parameter_grid_detail'))
		{
			document.getElementById('html_section_parameter_grid_detail').remove();
		}

		let html = ' <table id="html_section_parameter_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=806px>';

		let all_fields = 0;

		if (document.getElementById('html_section_parameter_all_fields').checked)
		{
			all_fields = 1;
		}

		let dc = this.frm.doctype;

		if (dc == 'ioi Purchases Proposals') {
			dc = 'ioi Purchases Proposal Internal';
		}


		let method = this.path_common + '.section_parameters_get_field'
		frappe.call({  	method: method,
						args: {	"doctype": dc,
								"search_term": document.getElementById('html_section_parameter_search_field').value,
								"section": section_fieldname,
								"all_fields": all_fields},
						async: false,
						callback:function(r)	{

													for (var i = 0; i < r.message.length; i++)
													{
														html += '<tr style="height:30px">';
														html += '<td width=400px style="vertical-align: middle;" ">';
														html += '<input type="text"  value="{' + r.message[i].fieldname + '}" ';
														html += 'style="border-top-style: hidden; border-right-style: hidden; border-left-style: hidden; border-bottom-style: none; width:100%" readonly '

														html += 'onclick="this.select();">';

														html += '</td>';
														if (r.message[i].label != null)
														{	html += '<td width=406px style="vertical-align: middle;" onclick="return false;" onmousedown = "return false;">&nbsp;' + __(r.message[i].label) + '</td>';
														}else{
															html += '<td width=406px style="vertical-align: middle;" onclick="return false;" onmousedown = "return false;">&nbsp;</td>';
														}
														html += '</tr>';
													}
												}
		});


		html += '		</table>';

		document.getElementById('html_section_parameter_grid').innerHTML = html;

	}


	// ***************************************************************************************************************************************
	// Add Change site to main menu
	// **************************************************************************************************************************************
	add_change_site_to_mainmenu()
	{
		let me = this;
		me.frm.page.add_menu_item(__('Change site'), () => me.show_select_site(true, true))
		me.frm.page.add_menu_item(__('This is my usual site'), () => me.update_usual_site(true));

	}

	// ***************************************************************************************************************************************
	// Format Section Breaks
	// ***************************************************************************************************************************************
	format_section_break(expand)
	{

		let method = this.path_module + '.ioi_module_can_select_site_scan_active';
		let can_select_site = 0;
		let scan_active = 0;
		let scan_detail_only = 0;

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{ 	can_select_site = r.message.can_select_site;
													scan_active = r.message.scan_active;
													scan_detail_only = r.message.scan_detail_only;
												}
		});

		if (can_select_site == 1)
		{
			this.get_user_site();

			let site_desc = __('select a site')
			if (this.current_site.trim() != '')
			{
				site_desc = 'Site : ' + this.current_site;
			}

			let me = this;

			this.frm.remove_custom_button(site_desc);
			this.frm.add_custom_button(site_desc, function() { me.show_select_site(true, true); });

			for(var i = 0; i < this.frm.page.custom_actions[0].childElementCount; i++) {

				if (this.frm.page.custom_actions[0].children[i].innerText == site_desc) {

					if ((this.usual_site) && (this.usual_site.trim() != '')) {

						if (this.usual_site != this.current_site) {
							this.frm.page.custom_actions[0].children[i].style.backgroundColor = 'orange';
						}

					}
				}

			}
		}

		if (scan_detail_only == 1)
		{
			method = this.path_user_module_config + '.ioi_user_module_config_get_config';

			let is_detail_focused = 0;

			frappe.call({  	method: method,
							args: {"doctype": this.frm.doctype},
							async: false,
							callback:function(r)	{
														if (r.message.length > 0)
														{
															if (r.message[0].detail_focused != null) {
																is_detail_focused = r.message[0].detail_focused
															}
														}
													}
			});

			if (document.getElementById('sb_main_detail_focused_separtor')) {
				document.getElementById('sb_main_detail_focused_separtor').remove();
			}

			if (document.getElementById('sb_main_detail_focused')) {
				document.getElementById('sb_main_detail_focused').remove();
			}


			let checkbox_detail_focused = document.createElement("INPUT");
			checkbox_detail_focused.setAttribute("type", "checkbox");
			checkbox_detail_focused.setAttribute("class", "form-control")
			checkbox_detail_focused.setAttribute("id", "sb_main_detail_focused");
			checkbox_detail_focused.setAttribute("style", "position: relative; top: 8px;");


			if (is_detail_focused == 0)	{
				checkbox_detail_focused.setAttribute("title", "Detail focused");
				checkbox_detail_focused.checked = false;
			}else{
				checkbox_detail_focused.setAttribute("title", "Show all screen");
				checkbox_detail_focused.checked = true;
			}

			checkbox_detail_focused.onclick = function () {

				if (this.checked) {
					this.title = __("Show all screen")
				}else {
					this.title = __("Detail focused")
				}

				silicon_ioi.doctype.ioiDocType.show_detail_only(this.checked);
			};

			document.getElementsByClassName('menu-btn-group')[0].insertAdjacentElement('afterend', checkbox_detail_focused);


			let div_detail_focused_separtor = document.createElement("DIV");
			div_detail_focused_separtor.setAttribute("id", "sb_main_detail_focused_separtor");
			div_detail_focused_separtor.setAttribute("style", "width: 8px;");
			document.getElementsByClassName('menu-btn-group')[0].insertAdjacentElement('afterend', div_detail_focused_separtor);


			if (is_detail_focused) {
				silicon_ioi.doctype.ioiDocType.do_show_detail_only(true);
			}


		}


		if (scan_active == 0) {
			if (document.getElementById('sb_main_scan_section')) {

				if (document.getElementById('sb_main_scan_check')) {
					document.getElementById('sb_main_scan_check').checked = false;
				}

				document.getElementById('sb_main_scan_section').hidden = true;
			}
		}else {
			if (document.getElementById('sb_main_scan_section')) {
				document.getElementById('sb_main_scan_section').hidden = false;
			}
		}

		for (var i = 0; i < this.frm.layout.sections.length; i++)
		{
			if (this.frm.layout.sections[i].head)
			{
				this.frm.layout.sections[i].head.attr("data-custom-section-head", "true")
				this.frm.layout.sections[i].head.css('border-radius', '6px');
				this.frm.layout.sections[i].head.css('padding', '4px');

				if (expand)
				{

					if (this.frm.fields_dict[this.frm.layout.sections[i].df.fieldname])
					{
						if (this.frm.fields_dict[this.frm.layout.sections[i].df.fieldname].is_collapsed())
						{
							this.frm.layout.sections[i].head.click();
						}
					}
				}
			}
		}
		this.load_user_screen_context()
	}

	// ***************************************************************************************************************************************
	// Display back
	// ***************************************************************************************************************************************
	set_back(url, imgpath)
	{
		// let html = '';
		// html += '<a href="' + url + '" style="cursor: pointer;" title="">';
		// html += '<img src="' + this.path_buttons + 'back.png" width="25px" height="25px" style="horizontal-align:left"></img>';
		// html += '</a>';
		// html += '&nbsp;<img src="' + imgpath +
		if (! this.frm.toolbar.page.$title_area[0].children[0].children[0].children[0].innerHTML.includes('<img src=')) {
			let html = '<img src="' + imgpath + '" width="56px" height="56px" style="horizontal-align:left;"></img>&nbsp;&nbsp;';
			this.frm.toolbar.page.$title_area[0].children[0].children[0].children[0].style.display="flex";
			this.frm.toolbar.page.$title_area[0].children[0].children[0].children[0].style.alignItems ="center";
			this.frm.toolbar.page.$title_area[0].children[0].children[0].children[0].innerHTML =
				html + this.frm.toolbar.page.$title_area[0].children[0].children[0].children[0].innerHTML;
		}

		this.assign_click_on_section();
	}

	// ***************************************************************************************************************************************
	// Display description in module header
	// ***************************************************************************************************************************************
	display_description_in_module_header()
	{
		// Be carefull if you change something here, there is sometimes the "change status" frame displayed just bellow the description.
		// If the content of the description is over the "change status" frame, these one doesn't work anymore.

		if ((this.frm.doctype.toUpperCase() == 'IOI CUSTOMER') 		|| (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER') 	|| (this.frm.doctype.toUpperCase() == 'IOI STAFF') ||
			(this.frm.doctype.toUpperCase() == 'IOI SALES AGENT') 	|| (this.frm.doctype.toUpperCase() == 'IOI ITEM') 		|| (this.frm.doctype.toUpperCase() == 'IOI WORKCENTER') || (this.frm.doctype.toUpperCase() == 'IOI OPPORTUNITY')) {

			if (window.innerWidth >= 600) {

				let s = this.frm.doctype.toLowerCase();
				s = s.replaceAll(' ', '_')

				let description = '';

				if(!this.frm.is_new()){
					if ((this.frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER')) {
						description = this.frm.doc.full_name;
					}else if ((this.frm.doctype.toUpperCase() == 'IOI STAFF') || (this.frm.doctype.toUpperCase() == 'IOI SALES AGENT')) {
						description = this.frm.doc.firstname + ' ' + this.frm.doc.lastname;
					}else if ((this.frm.doctype.toUpperCase() == 'IOI ITEM') || (this.frm.doctype.toUpperCase() == 'IOI WORKCENTER')) {
						description = this.frm.doc.description;
					} else if (this.frm.doctype.toUpperCase() == 'IOI OPPORTUNITY') {
						description = this.frm.doc.customer_id + ' : ' + this.frm.doc.customer_name;
					}
				}

				let html = '';

				html = '<div id="' + s + '_sb_form_description_div" style="height:20px;">';
				html += '	<label id="' + s + '_sb_form_description"  style="font-size: 14px"><b>' + description + '</b></label>';
				html += '</div>';

				if (document.getElementById( s + '_sb_form_description')) {
					document.getElementById( s + '_sb_form_description').remove();
				}

				if (document.getElementById( s + '_sb_form_description_div')) {
					document.getElementById( s + '_sb_form_description_div').remove();
				}

				$(`[data-page-route="${this.frm.doctype}"]`).find(".flex.fill-width.title-area")[0].children[0].insertAdjacentHTML('beforeend', html);
			}
		}

	}





	// ***************************************************************************************************************************************
	// Module clear logs
	// ***************************************************************************************************************************************
	module_clear_logs()
	{
		document.getElementById('ioi_modulelog_from').value = '';
		document.getElementById('ioi_modulelog_to').value = '';
		document.getElementById('ioi_modulelog_docdesc').value = '';
		document.getElementById("ioi_modulelog_type").selectedIndex = 0;
		document.getElementById("ioi_modulelog_mode").selectedIndex = 0;
		document.getElementById("ioi_modulelog_recop").selectedIndex = 0;
		document.getElementById('ioi_modulelog_user').value = '';
		document.getElementById('ioi_modulelog_topn').value = '100';

		document.getElementById('ioi_modulelog_orderby').value = 'creation_datetime';
		document.getElementById('ioi_modulelog_order').value = 'desc';

		if (document.getElementById('ioi_module_log_nb_record'))
		{
			for (var i = 0; i < document.getElementById('ioi_module_log_nb_record').value; i++)
			{
				if (document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_1'))
				{
					document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_1').remove();
				}

				if (document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_2'))
				{
					document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_2').remove();
				}

				if (document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_3'))
				{
					document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_3').remove();
				}

			}

			document.getElementById('ioi_module_log_nb_record').remove();
		}



		let html = '';
		html += '<table id="ioi_modulelog_detail_row_0_1" border=1 style="border: 1px solid #E8EAEB" width=930px>';
		html += '<tr style="height:30px">';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=630px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '<table id="ioi_modulelog_detail_row_0_2" border=1 style="border: 1px solid #E8EAEB" width=930px>';
		html += '<tr style="height:30px">';
		html += '<td width=450px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=180px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '<table id="ioi_modulelog_detail_row_0_3" border=1 style="border: 1px solid #E8EAEB" width=930px>';
		html += '<tr style="height:30px">';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=330px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '<input type="hidden" id="ioi_module_log_nb_record" value="1">';

		document.getElementById('ioi_modulelog_content').insertAdjacentHTML('beforeend', html);

	}

	// ***************************************************************************************************************************************
	// Module refresh_log
	// ***************************************************************************************************************************************
	module_refresh_logs()
	{
		if (!cur_frm.is_new())
		{
			let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

			let me = this;
			let html = '';

			let method = 'silicon_ioi.ioi_configuration.doctype.ioi_module_log.ioi_module_log.ioi_module_log_get_list_frame';

			frappe.call({  	method: method,
							args: {	"doctype": cur_frm.doctype,
									"doc_id": cur_frm.doc.name,
									"dt_from" : document.getElementById('ioi_modulelog_from').value,
									"dt_to" : document.getElementById('ioi_modulelog_to').value,
									"docdesc" : document.getElementById('ioi_modulelog_docdesc').value,
									"type" : document.getElementById('ioi_modulelog_type').value,
									"mode" : document.getElementById('ioi_modulelog_mode').value,
									"recop" : document.getElementById('ioi_modulelog_recop').value,
									"user" : document.getElementById('ioi_modulelog_user').value,
									"topn" : document.getElementById('ioi_modulelog_topn').value,
									"order_field": document.getElementById('ioi_modulelog_orderby').value,
									"order_dir": document.getElementById('ioi_modulelog_order').value
								},
							async: true,
							callback:function(r)	{
														if (document.getElementById('ioi_module_log_nb_record'))
														{
															for (var i = 0; i < document.getElementById('ioi_module_log_nb_record').value; i++)
															{
																if (document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_1'))
																{
																	document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_1').remove();
																}

																if (document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_2'))
																{
																	document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_2').remove();
																}

																if (document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_3'))
																{
																	document.getElementById('ioi_modulelog_detail_row_' + i.toString() + '_3').remove();
																}

															}

															document.getElementById('ioi_module_log_nb_record').remove();
														}


														if (r.message.length > 0)
														{
															let cpt = 0;

															for (var i = 0; i < r.message.length; i++)
															{
																if (r.message[i].record_operation != 'DELETE') {
																	let dt = '';
																	let user = '';
																	let doc_id = '';
																	let desc = '';
																	let type_log = '';
																	let mode = '';
																	let rec_op = '';
																	let from_st = '';
																	let to_st = '';
																	let child_doc = '';
																	let child_doc_id = '';

																	if (r.message[i].creation_datetime)
																	{
																		dt = r.message[i].creation_datetime;
																		dt = dt.toString();
																		dt = dt.substring(0, 16);
																	}

																	if (r.message[i].user_id)
																	{	user = r.message[i].user_id;
																	}

																	if (r.message[i].doc_id)
																	{	doc_id = r.message[i].doc_id;
																	}

																	if (r.message[i].description)
																	{	desc = r.message[i].description;
																		desc = desc.substring(0, 65);
																	}

																	if (r.message[i].type_log)
																	{	type_log = r.message[i].type_log;
																	}

																	if (r.message[i].mode)
																	{	mode = r.message[i].mode;
																	}

																	if (r.message[i].record_operation)
																	{	rec_op = r.message[i].record_operation;
																	}

																	if (r.message[i].current_ioistatus)
																	{	from_st = r.message[i].current_ioistatus;
																	}

																	if (r.message[i].to_ioistatus)
																	{	to_st = r.message[i].to_ioistatus;
																	}

																	if (r.message[i].child_doctype)
																	{	child_doc = r.message[i].child_doctype;
																	}

																	if (r.message[i].child_id)
																	{	child_doc_id = r.message[i].child_id;
																	}


																	if ((dt != '') || (user != '')  || (doc_id != '') || (desc != '') || (type_log != '') || (mode != '') || (rec_op != '') ||
																		(from_st != '') || (to_st != '') || (child_doc != '') || (child_doc_id != ''))
																	{
																		html += '<table id="ioi_modulelog_detail_row_' + i.toString() + '_1" border=1 style="border: 1px solid #E8EAEB" width=930px>';

																		if (cpt % 2 != 0)
																		{
																			if (is_dark_mode == 0) {
																				html += '<tr bgcolor="#F5FAFE" style="height:30px">';
																			}else{
																				html += '<tr bgcolor="#1C2126" style="height:30px">';
																			}
																		}else
																		{
																			html += '<tr style="height:30px">';
																		}

																		html += '<td width=150px style="vertical-align: middle;">&nbsp;<a href="/app/ioi-module-log/' + r.message[i].name + '"><u>' + dt + '</u></a></td>';
																		html += '<td width=150px style="vertical-align: middle;">&nbsp;<a href="/app/ioi-module-log/' + r.message[i].name + '"><u>' + user + '</u></a></td>';
																		html += '<td width=630px style="vertical-align: middle;">&nbsp;<a href="/app/ioi-module-log/' + r.message[i].name + '"><u>' + doc_id + '</u></a></td>';
																		html += '</tr>';
																		html += '</table>';

																		html += '<table id="ioi_modulelog_detail_row_' + i.toString() + '_2" border=1 style="border: 1px solid #E8EAEB" width=930px>';
																		if (cpt % 2 != 0)
																		{
																			if (is_dark_mode == 0) {
																				html += '<tr bgcolor="#F5FAFE" style="height:30px">';
																			}else{
																				html += '<tr bgcolor="#1C2126" style="height:30px">';
																			}

																		}else
																		{
																			html += '<tr style="height:30px">';
																		}

																		html += '<td width=450px style="vertical-align: middle;">&nbsp;' + desc + '</td>';
																		html += '<td width=150px style="vertical-align: middle;">&nbsp;' + type_log + '</td>';
																		html += '<td width=150px style="vertical-align: middle;">&nbsp;' + mode + '</td>';
																		html += '<td width=180px style="vertical-align: middle;">&nbsp;' + rec_op + '</td>';
																		html += '</tr>';
																		html += '</table>';

																		html += '<table id="ioi_modulelog_detail_row_' + i.toString() + '_3" border=1 style="border: 1px solid #E8EAEB" width=930px>';

																		if (cpt % 2 != 0)
																		{
																			if (is_dark_mode == 0) {
																				html += '<tr bgcolor="#F5FAFE" style="height:30px">';
																			}else{
																				html += '<tr bgcolor="#1C2126" style="height:30px">';
																			}
																		}else
																		{
																			html += '<tr style="height:30px">';
																		}

																		html += '<td width=150px style="vertical-align: middle;">&nbsp;' + from_st + '</td>';
																		html += '<td width=150px style="vertical-align: middle;">&nbsp;' + to_st + '</td>';
																		html += '<td width=300px style="vertical-align: middle;">&nbsp;' + child_doc + '</td>';
																		html += '<td width=330px style="vertical-align: middle;">&nbsp;' + child_doc_id + '</td>';
																		html += '</tr>';
																		html += '</table>';

																		cpt++;
																	}
																}

															}
															html += '<input type="hidden" id="ioi_module_log_nb_record" value="' + cpt.toString() + '">';


														}else
														{
															html += '<table id="ioi_modulelog_detail_row_0_1" border=1 style="border: 1px solid #E8EAEB" width=930px>';
															html += '<tr style="height:30px">';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=630px style="vertical-align: middle;">&nbsp;</td>';
															html += '</tr>';
															html += '</table>';

															html += '<table id="ioi_modulelog_detail_row_0_2" border=1 style="border: 1px solid #E8EAEB" width=930px>';
															html += '<tr style="height:30px">';
															html += '<td width=450px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=180px style="vertical-align: middle;">&nbsp;</td>';
															html += '</tr>';
															html += '</table>';

															html += '<table id="ioi_modulelog_detail_row_0_3" border=1 style="border: 1px solid #E8EAEB" width=930px>';
															html += '<tr style="height:30px">';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
															html += '<td width=330px style="vertical-align: middle;">&nbsp;</td>';
															html += '</tr>';
															html += '</table>';

															html += '<input type="hidden" id="ioi_module_log_nb_record" value="1">';

														}

														document.getElementById('ioi_modulelog_content').insertAdjacentHTML('beforeend', html);
													}
			});
		}else
		{
			this.module_clear_logs();
		}

	}

	// ***************************************************************************************************************************************
	// Module load log frame
	// ***************************************************************************************************************************************
	#module_load_logs_list()
	{
		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		if (document.getElementById("ioi_modulelog_from"))
		{	document.getElementById("ioi_modulelog_from").remove();
		}

		if (document.getElementById("ioi_modulelog_to"))
		{	document.getElementById("ioi_modulelog_to").remove();
		}

		if (document.getElementById("ioi_modulelog_docdesc"))
		{	document.getElementById("ioi_modulelog_docdesc").remove();
		}

		if (document.getElementById("ioi_modulelog_type"))
		{	document.getElementById("ioi_modulelog_type").remove();
		}


		if (document.getElementById("ioi_modulelog_mode"))
		{	document.getElementById("ioi_modulelog_mode").remove();
		}

		if (document.getElementById("ioi_modulelog_recop"))
		{	document.getElementById("ioi_modulelog_recop").remove();
		}

		if (document.getElementById("ioi_modulelog_user"))
		{	document.getElementById("ioi_modulelog_user").remove();
		}

		if (document.getElementById("topn"))
		{	document.getElementById("topn").remove();
		}

		if (document.getElementById("ioi_modulelog_search"))
		{	document.getElementById("ioi_modulelog_search").remove();
		}

		if (document.getElementById("ioi_modulelog_clear"))
		{	document.getElementById("ioi_modulelog_clear").remove();
		}


		if (document.getElementById("ioi_modulelog_orderby"))
		{	document.getElementById("ioi_modulelog_orderby").remove();
		}

		if (document.getElementById("ioi_modulelog_order"))
		{	document.getElementById("ioi_modulelog_order").remove();
		}

		if (document.getElementById("ioi_modulelog_content"))
		{	document.getElementById("ioi_modulelog_content").remove();
		}





		let html = '';

		html = '<div style="overflow: auto; overflow-x: auto; height:190px;">';

		// From
		html += '<div style="position: relative; top: 0px; left: 0px; width:150px;">';
		html += '	<label id="ioi_modulelog_from_label" style="position: absolute; top: 0px; left: 2px;">' + __("From") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 30px;"> ';
		html += '		<input id="ioi_modulelog_from" type="date" class="input-with-feedback form-control bold">';
		html += '	</div>';
		html += '</div>';

		// To
		html += '<div style="position: relative; top: 0px; left: 160px; width:150px;">';
		html += '	<label id="ioi_modulelog_to_label" style="position: absolute; top: 0px; left: 2px;">' + __("To") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 150px; height: 30px;"> ';
		html += '		<input id="ioi_modulelog_to" type="date" class="input-with-feedback form-control bold" pattern="\y{4}-\m{2}-\d{2}">';
		html += '	</div>';
		html += '</div>';

		// Doc id or Desc
		html += '<div style="position: relative; top: 0px; left: 320px; width:150px;">';
		html += '	<label id="ioi_modulelog_docdesc_label" style="position: absolute; top: 0px; left: 2px;">' + __("Doc Id or description") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 30px;"> ';
		html += '		<input id="ioi_modulelog_docdesc" type="text" class="input-with-feedback form-control bold">';
		html += '	</div>';
		html += '</div>';


		// Type
		html += '<div style="position: relative; top: 0px; left: 630px; width:150px;">';
		html += '	<label id="ioi_modulelog_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
		html += '		<select id="ioi_modulelog_type" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
  		html += '			<option value="APPLICATION">APPLICATION</option> ';
		html += '			<option value="DATA">DATA</option> ';
		html += '			<option value="SYSTEM">SYSTEM</option> ';
		html += '		</select> '
		html += '	</div>';
		html += '</div>';


		// Mode
		html += '<div style="position: relative; top: 60px; left: 0px; width:150px;">';
		html += '	<label id="ioi_modulelog_mode_label" style="position: absolute; top: 0px; left: 2px;">' + __("Mode") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 310px; height: 30px;"> ';
		html += '		<select id="ioi_modulelog_mode" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
  		html += '			<option value="CRITICAL ERROR">CRITICAL ERROR</option> ';
		html += '			<option value="ERROR">ERROR</option> ';
		html += '			<option value="INFORMATION">INFORMATION</option> ';
		html += '			<option value="WARNING HIGH">WARNING HIGH</option> ';
		html += '			<option value="WARNING LOW">WARNING LOW</option> ';
		html += '		</select> '
		html += '	</div>';
		html += '</div>';


		// Record Operation
		html += '<div style="position: relative; top: 60px; left: 320px; width:150px;">';
		html += '	<label id="ioi_modulelog_recop_label" style="position: absolute; top: 0px; left: 2px;">' + __("Record operation") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 30px;"> ';
		html += '		<select id="ioi_modulelog_recop" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
  		html += '			<option value="DELETE">DELETE</option> ';
		html += '			<option value="INSERT">INSERT</option> ';
		html += '			<option value="UPDATE">UPDATE</option> ';
		html += '		</select> '
		html += '	</div>';
		html += '</div>';

		// User
		html += '<div style="position: relative; top: 60px; left: 630px; width:150px;">';
		html += '	<label id="ioi_modulelog_user_label" style="position: absolute; top: 0px; left: 2px;">' + __("User") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 300px; height: 30px;"> ';
		html += '		<input id="ioi_modulelog_user" type="text" class="input-with-feedback form-control bold">';
		html += '	</div>';
		html += '</div>';

		// Topn
		html += '<div style="position: relative; top: 125px; left: 0px; width:150px;">';
		html += '	<label id="ioi_modulelog_from_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 150px; height: 30px;"> ';
		html += '		<input id="ioi_modulelog_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '	</div>';
		html += '</div>';



		// Search
		html += '<div style="position: relative; top: 145px; left: 630px; width:70px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px">';
		html +='		<button id="ioi_modulelog_search" data-label="Search" class="btn btn-default ellipsis" style="height: 35px; width: 100px;" onclick="">' + __("Search") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Clear
		html += '<div style="position: relative; top: 145px; left: 740px; width:50px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 30px; width: 200px;">';
		html +='		<button id="ioi_modulelog_clear" data-label="Search" class="btn btn-default ellipsis" style="height: 35px; width: 100px;" onclick="">' + __("Clear") + '</button>';
		html += '	</div>';
		html += '</div>';



		html += '<input id="ioi_modulelog_orderby" type="hidden" value="creation_datetime">';
		html += '<input id="ioi_modulelog_order" type="hidden" value="desc">';


		html += '</div>';

		// Grid Header
		html += '<div id="ioi_modulelog_content" style="overflow: auto; overflow-x: auto; height:400px;">';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=930px data-custom-grid="true">';

		html += `<tr style="height:30px">`;

		html += '<td id="ioi_modulelog_col_creation_datetime" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Date") + '<label id="ioi_modulelog_col_label_creation_datetime" style="width:30px; height:8px" align="right">&uarr;</label></b></td>';
		html += '<td id="ioi_modulelog_col_user_id" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("User") + '<label id="ioi_modulelog_col_label_user_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_doc_id" width=630px style="vertical-align: middle;"><b>&nbsp;' + __("Document id") + '<label id="ioi_modulelog_col_label_doc_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '</tr>';
		html += '</table>';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=930px data-custom-grid="true">';

		html += `<tr style="height:30px">`;

		html += '<td id="ioi_modulelog_col_description" width=450px style="vertical-align: middle;"><b>&nbsp;' + __("Description") + '<label id="ioi_modulelog_col_label_description" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_type_log" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Type") + '<label id="ioi_modulelog_col_label_type_log" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_mode" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Mode") + '<label id="ioi_modulelog_col_label_mode" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_record_operation" width=180px style="vertical-align: middle;"><b>&nbsp;' + __("Rec. operation") + '<label id="ioi_modulelog_col_label_record_operation" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '</tr>';
		html += '</table>';

		html += '<table border=1 style="border: 1px solid #E8EAEB" width=930px data-custom-grid="true">';

		html += `<tr style="height:30px">`;

		html += '<td id="ioi_modulelog_col_current_ioistatus" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Current status") + '<label id="ioi_modulelog_col_label_current_ioistatus" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_to_ioistatus" width=150px style="vertical-align: middle;"><b>&nbsp;' + __("To Status") + '<label id="ioi_modulelog_col_label_to_ioistatus" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_child_doctype" width=300px style="vertical-align: middle;"><b>&nbsp;' + __("Child doctype") + '<label id="ioi_modulelog_col_label_child_doctype" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="ioi_modulelog_col_child_id" width=330px style="vertical-align: middle;"><b>&nbsp;' + __("Child id") + '<label id="ioi_modulelog_col_label_child_id" style="width:30px; height:8px" align="right"></label></b></td>';
		html += '</tr>';
		html += '</table>';

		// Result

		html += '<table id="ioi_modulelog_detail_row_0_1" border=1 style="border: 1px solid #E8EAEB" width=930px>';
		html += '<tr style="height:30px">';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=630px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '<table id="ioi_modulelog_detail_row_0_2" border=1 style="border: 1px solid #E8EAEB" width=930px>';
		html += '<tr style="height:30px">';
		html += '<td width=450px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=180px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		html += '<table id="ioi_modulelog_detail_row_0_3" border=1 style="border: 1px solid #E8EAEB" width=930px>';
		html += '<tr style="height:30px">';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=330px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';
		html += '<input type="hidden" id="ioi_module_log_nb_record" value="1">';

		html += '</div>';

		this.frm.fields_dict['html_module_logs'].$wrapper.empty();
		this.frm.fields_dict['html_module_logs'].$wrapper.append(html);


		document.getElementById('ioi_modulelog_from').onkeydown = this.#module_search_key_down;
		document.getElementById('ioi_modulelog_to').onkeydown = this.#module_search_key_down;
		document.getElementById('ioi_modulelog_docdesc').onkeydown = this.#module_search_key_down;
		document.getElementById("ioi_modulelog_type").onchange = this.#module_search_on_change;
		document.getElementById("ioi_modulelog_mode").onchange = this.#module_search_on_change;
		document.getElementById("ioi_modulelog_recop").onchange = this.#module_search_on_change;
		document.getElementById('ioi_modulelog_user').onkeydown = this.#module_search_key_down;
		document.getElementById('ioi_modulelog_topn').onkeydown = this.#module_search_key_down;

		document.getElementById('ioi_modulelog_search').onclick = this.module_refresh_logs;
		document.getElementById('ioi_modulelog_clear').onclick = this.module_clear_logs;

		document.getElementById('ioi_modulelog_col_creation_datetime').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_user_id').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_doc_id').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_description').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_type_log').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_mode').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_record_operation').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_current_ioistatus').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_to_ioistatus').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_child_doctype').onclick = this.#module_log_col_click;
		document.getElementById('ioi_modulelog_col_child_id').onclick = this.#module_log_col_click;


		document.getElementById('ioi_modulelog_col_creation_datetime').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_user_id').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_doc_id').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_description').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_type_log').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_mode').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_record_operation').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_current_ioistatus').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_to_ioistatus').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_child_doctype').onmouseover = this.#module_log_col_mouse_over;
		document.getElementById('ioi_modulelog_col_child_id').onmouseover = this.#module_log_col_mouse_over;


		document.getElementById('ioi_modulelog_col_creation_datetime').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_user_id').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_doc_id').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_description').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_type_log').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_mode').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_record_operation').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_current_ioistatus').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_to_ioistatus').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_child_doctype').onmouseleave = this.#module_log_col_mouse_leave;
		document.getElementById('ioi_modulelog_col_child_id').onmouseleave = this.#module_log_col_mouse_leave;

	}

	// ***************************************************************************************************************************************
	// Module Log : parameters <input> on key down
	// ***************************************************************************************************************************************
	#module_search_key_down(event)
	{
		if (event.keyCode == 13)
		{
			document.getElementById('ioi_modulelog_search').click();
		}
	}

	// ***************************************************************************************************************************************
	// Module Log : parameters <select> on chnage
	// ***************************************************************************************************************************************
	#module_search_on_change()
	{
		document.getElementById('ioi_modulelog_search').click();
	}

	// ***************************************************************************************************************************************
	// Module Log : column header mouse click
	// ***************************************************************************************************************************************
	#module_log_col_click()
	{
		let s = this.id;

		s = s.substring(18, this.id.length);

		document.getElementById('ioi_modulelog_col_label_creation_datetime').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_user_id').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_doc_id').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_description').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_type_log').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_mode').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_record_operation').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_current_ioistatus').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_to_ioistatus').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_child_doctype').innerHTML = '';
		document.getElementById('ioi_modulelog_col_label_child_id').innerHTML = '';

		if (document.getElementById('ioi_modulelog_orderby').value == s)
		{
			if (document.getElementById('ioi_modulelog_order').value == 'desc')
			{	document.getElementById('ioi_modulelog_order').value = 'asc';
				document.getElementById('ioi_modulelog_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('ioi_modulelog_order').value = 'desc';
				document.getElementById('ioi_modulelog_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('ioi_modulelog_orderby').value = s;
			document.getElementById('ioi_modulelog_order').value = 'desc';
			document.getElementById('ioi_modulelog_col_label_' + s).innerHTML = '&uarr;';
		}

		document.getElementById('ioi_modulelog_search').click();
	}

	// ***************************************************************************************************************************************
	// Module Log : column header mouse over
	// ***************************************************************************************************************************************
	#module_log_col_mouse_over()
	{
		this.style.cursor = 'pointer';
	}


	// ***************************************************************************************************************************************
	// Module Log : column header mouse leave
	// ***************************************************************************************************************************************
	#module_log_col_mouse_leave()
	{
		this.style.cursor = 'none';
	}

	// ***************************************************************************************************************************************
	// Save user screen context
	// ***************************************************************************************************************************************
	save_user_screen_context()
	{
		let me = this;
		let method = this.path_user_screen_context + '.ioi_user_screen_context_clear_screen_context';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (me.frm.layout.tabs.length > 0)
													{
														method = me.path_user_screen_context + '.ioi_user_screen_context_insert';

														frappe.call({  	method: method,
																		args: {"doctype": me.frm.doctype, "fieldname": me.frm.get_active_tab().df.fieldname, "fieldtype": me.frm.get_active_tab().df.fieldtype, "active":1 },
																		async: false,
																		callback:function(r) {}
														});


													}

													let sections_list = '';

													if (me.frm.layout.sections.length > 0)
													{
														for (var i = 0; i < me.frm.layout.sections.length; i++)
														{
															if (me.frm.layout.sections[i].df.fieldname)
															{
																if (me.frm.fields_dict[me.frm.layout.sections[i].df.fieldname])
																{	me.frm.fields_dict[me.frm.layout.sections[i].df.fieldname].refresh();
																	if (me.frm.fields_dict[me.frm.layout.sections[i].df.fieldname].is_collapsed())
																	{
																		sections_list += me.frm.layout.sections[i].df.fieldname + ',';
																	}
																}
															}
														}
													}

													if (sections_list.trim() != '')
													{
														method = me.path_user_screen_context + '.ioi_user_screen_context_insert_sections';

														frappe.call({  	method: method,
																		args: {"doctype": me.frm.doctype, "sections": sections_list, "active":1 },
																		async: false,
																		callback:function(r) {}
														});

													}
												}
		});

	}


	// ***************************************************************************************************************************************
	// Save current tab
	// ***************************************************************************************************************************************
	save_current_tab(tab)
	{
		let dc = this.frm.doctype.toLowerCase();
		dc = dc.replaceAll(' ', '-')

		let fn = tab;

		let n = fn.indexOf(dc);
		n += dc.length+1;
		fn = fn.substring(n, fn.length);
		fn = fn.substring(0, fn.length-4);


		let me = this;
		let method = this.path_user_screen_context + '.ioi_user_screen_context_remove_tabs';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													if (me.frm.layout.tabs.length > 0)
													{
														method = me.path_user_screen_context + '.ioi_user_screen_context_insert';

														frappe.call({  	method: method,
																		args: {"doctype": me.frm.doctype, "fieldname": fn, "fieldtype": me.frm.get_active_tab().df.fieldtype, "active":1 },
																		async: false,
																		callback:function(r) {}
														});
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Save current tab
	// ***************************************************************************************************************************************
	save_current_doc_tab(dc, tab)
	{
		let dct = dc.toLowerCase();
		dct = dct.replaceAll(' ', '-')

		let fn = tab;

		let n = fn.indexOf(dct);
		n += dct.length+1;
		fn = fn.substring(n, fn.length);
		fn = fn.substring(0, fn.length-4);


		let me = this;
		let method = this.path_user_screen_context + '.ioi_user_screen_context_remove_tabs';

		frappe.call({  	method: method,
						args: {"doctype": dc},
						async: false,
						callback:function(r)	{
													if (me.frm.layout.tabs.length > 0)
													{
														method = me.path_user_screen_context + '.ioi_user_screen_context_insert';

														frappe.call({  	method: method,
																		args: {"doctype": dc, "fieldname": fn, "fieldtype": 'Tab Break', "active":1 },
																		async: false,
																		callback:function(r) {}
														});
													}
												}
		});
	}

	// ***************************************************************************************************************************************
	// Static Save current tab
	// ***************************************************************************************************************************************
	static static_save_current_doc_tab(dc, tab)
	{
		let dct = dc.toLowerCase();
		dct = dct.replaceAll(' ', '-')

		let fn = tab;

		let n = fn.indexOf(dct);
		n += dct.length+1;
		fn = fn.substring(n, fn.length);
		fn = fn.substring(0, fn.length-4);


		let path_user_screen_context = 'silicon_ioi.ioi_configuration.doctype.ioi_user_screen_context.ioi_user_screen_context';
		let method = path_user_screen_context + '.ioi_user_screen_context_remove_tabs';

		frappe.call({  	method: method,
						args: {"doctype": dc},
						async: false,
						callback:function(r)	{
													if (cur_frm.layout.tabs.length > 0)
													{
														method = path_user_screen_context + '.ioi_user_screen_context_insert';

														frappe.call({  	method: method,
																		args: {"doctype": dc, "fieldname": fn, "fieldtype": 'Tab Break', "active":1 },
																		async: false,
																		callback:function(r) {}
														});
													}
												}
		});
	}


	// ***************************************************************************************************************************************
	// Save current section
	// ***************************************************************************************************************************************
	save_current_section(section)
	{
		let fn = section.querySelector('.form-column .frappe-control').getAttribute('data-fieldname')

		let section_name = '';

		let found_section = false;

		for (var i = 0; i < this.frm.layout.sections.length; i++)
		{
			for (var field in this.frm.layout.sections[i].fields_dict)
			{
				if (field == fn)
				{
					if (this.frm.layout.sections[i].df)
					{
						section_name = this.frm.layout.sections[i].df.fieldname;
						found_section = true;
						break;
					}
				}
			}

			if (found_section)
			{
				break;
			}
		}

		if (section_name.trim() != '')
		{
			// delete section from Context
			let me = this;
			let method = this.path_user_screen_context + '.ioi_user_screen_context_remove_section';

			frappe.call({  	method: method,
							args: {"doctype": this.frm.doctype, "section": section_name},
							async: false,
							callback:function(r)	{
													}
			});



			if (section.children[0].className == 'section-head collapsible')
			{
				// save section	in Context
				method = me.path_user_screen_context + '.ioi_user_screen_context_insert';

				frappe.call({  	method: method,
								args: {"doctype": me.frm.doctype, "fieldname": section_name, "fieldtype": 'Section Break', "active":1 },
								async: false,
								callback:function(r) {}
				});
			}

			if (this.frm.layout.sections[i].df)
			{
				if (this.frm.layout.sections[i].df.fieldname)
				{
					if (this.frm.layout.sections[i].head)
					{


						if (this.frm.layout.sections[i].head[0].className == 'section-head collapsible')
						{
							let caption = '';

							for(var k = 0; k < this.section_customized_label.length; k++)
							{
								if (this.section_customized_label[k][0] == this.frm.layout.sections[i].df.fieldname)
								{
									caption = this.section_customized_label[k][1];
									break;
								}
							}

							let s = this.frm.layout.sections[i].head[0].innerHTML;


							let first = '';

							if (caption.trim() != '') {
								first = caption;
							}else {
								first = __(this.frm.layout.sections[i].df.label);
							}

							let last = '';

							let was_up = false;

							if (s.indexOf('↑') != -1) {
								was_up = true;
							}


							if (s.indexOf('</span>') != -1) {
								last = s.substring(s.indexOf('</span>') + 7, s.length);
							}else if (s.indexOf('↓') != -1) {
								last = s.substring(s.indexOf('↓') + 1, s.length);
							}else if (s.indexOf('↑') != -1) {
								last = s.substring(s.indexOf('↑') + 1, s.length);
							}

							let html = '';


							if (was_up)
							{
								html += '&nbsp;&nbsp;&darr;';
							}else
							{
								html += '&nbsp;&nbsp;&uarr;';

							}


							last = html + last;



							if (document.getElementById('img_' + i.toString + '_' + this.frm.layout.sections[i].df.fieldname))
							{
								document.getElementById('img_' + i.toString + '_' + this.frm.layout.sections[i].df.fieldname).remove();
							}


							this.frm.layout.sections[i].head[0].innerHTML = first + last;

							this.sleep_ms(200).then(() => {

								let fct_click = function () {

									let fn = this.id;
									fn = fn.substring(fn.indexOf('img_')+4, fn.length);

									let idx = fn.substring(0, fn.indexOf('_'));
									fn = fn.substring(fn.indexOf('_')+1, fn.length);

									me.open_section_param(this, fn);
									return false;
								};

								document.getElementById('img_' + i.toString() + '_' + this.frm.layout.sections[i].df.fieldname).onmousedown = fct_click;


							});


						}else
						{

							let caption = '';

							for(var k = 0; k < this.section_customized_label.length; k++)
							{
								if (this.section_customized_label[k][0] == this.frm.layout.sections[i].df.fieldname)
								{
									caption = this.section_customized_label[k][1];
									break;
								}
							}

							let s = this.frm.layout.sections[i].head[0].innerHTML;


							let first = '';

							if (caption.trim() != '') {
								first = __(this.frm.layout.sections[i].df.label); // caption;
							}else {
								first = __(this.frm.layout.sections[i].df.label);
							}

							let last = '';

							let was_up = false;


							if (s.indexOf('↑') != -1) {
								was_up = true;
							}


							if (s.indexOf('</span>') != -1) {
								last = s.substring(s.indexOf('</span>') + 7, s.length);
							}else if (s.indexOf('↓') != -1) {
								last = s.substring(s.indexOf('↓') + 1, s.length);
							}else if (s.indexOf('↑') != -1) {
								last = s.substring(s.indexOf('↑') + 1, s.length);

							}


							let html = '';

							if (was_up)
							{
								html += '&nbsp;&nbsp;&darr;';
							}else
							{
								html += '&nbsp;&nbsp;&uarr;';

							}

							last = html + last;


							if (document.getElementById('img_' + i.toString + '_' + this.frm.layout.sections[i].df.fieldname))
							{
								document.getElementById('img_' + i.toString + '_' + this.frm.layout.sections[i].df.fieldname).remove();
							}

							this.frm.layout.sections[i].head[0].innerHTML = first + last;

							this.sleep_ms(200).then(() => {

								let fct_click = function () {

									let fn = this.id;
									fn = fn.substring(fn.indexOf('img_')+4, fn.length);

									let idx = fn.substring(0, fn.indexOf('_'));
									fn = fn.substring(fn.indexOf('_')+1, fn.length);

									me.open_section_param(this, fn);
									return false;
								};

								document.getElementById('img_' + i.toString() + '_' + this.frm.layout.sections[i].df.fieldname).onmousedown = fct_click;

							});

						}
					}
				}
			}

		}
	}


	// ***************************************************************************************************************************************
	// Load user screen context
	// ***************************************************************************************************************************************
	load_user_screen_context()
	{
		let me = this;
		let method = this.path_user_screen_context + '.ioi_user_screen_context_get_context';

		frappe.call({  	method: method,
						args: {"doctype": this.frm.doctype},
						async: false,
						callback:function(r)	{
													silicon_ioi.doctype.ioiDocType.static_sleep_ms(500).then(() => {

														if (r.message.length > 0)
														{
															for (var i = 0; i < r.message.length; i++)
															{
																if (r.message[i].fieldtype.toUpperCase() == 'TAB BREAK')
																{
																	if (!me.frm.is_new())
																	{
																		if (r.message[i].active == 1)
																		{
																			let dc = me.frm.doctype.toLowerCase();
																			dc = dc.replaceAll(' ', '-');
																			dc += '-' + r.message[i].fieldname + '-tab';

																			if (document.getElementById(dc))
																			{
																				if ((me.frm.doctype.toUpperCase() == 'IOI SALES INVOICE') || (me.frm.doctype.toUpperCase() == 'IOI PURCHASES INVOICE'))
																				{
																					if (dc == 'ioi-sales-invoice-ioi_sales_invoice_tab_invoice_summary-tab') {

																						if (document.getElementById(dc).hidden == false)
																						{
																							document.getElementById(dc).click();
																						}

																					}else{
																						document.getElementById(dc).click();
																					}

																				}else{
																					document.getElementById(dc).click();
																				}

																			}
																		}
																	}
																}
																// Comment code below to avoid double collapse and prevent unwanted behavior

																// else if (r.message[i].fieldtype.toUpperCase() == 'SECTION BREAK')
																// {
																// 	if (r.message[i].active == 1)
																// 	{
																// 		if (me.frm.fields_dict[r.message[i].fieldname]) {
																// 			if (!me.frm.fields_dict[r.message[i].fieldname].is_collapsed())
																// 			{
																// 				me.frm.fields_dict[r.message[i].fieldname].collapse();
																// 			}
																// 		}
																// 	}
																// }
															}
														}
													});
												}
		});
	}

	// ***************************************************************************************************************************************
	// Check if site is assigned to the user
	// ***************************************************************************************************************************************
	check_user_site()
	{
		let me = this;
		let method = this.path_user + '.ioi_user_has_site';

		frappe.call({  	method: method,
						args: {
							check_if_multi_site: true
						},
						async: false,
						callback:function(r)	{ 	if (r.message.has_site == 0)
													{
														me.show_select_site(true);
													}else
													{
														me.current_site = r.message.site_id;
														me.usual_site = r.message.usual_site_id;
														me.is_multi_site = r.message.multi_site
													}
												}
		});

	}

	// ***************************************************************************************************************************************
	// Get user site
	// ***************************************************************************************************************************************
	get_user_site()
	{
		let me = this;
		let method = this.path_user + '.ioi_user_has_site';

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{ 	if (r.message.has_site != 0)
													{
														me.current_site = r.message.site_id;
														me.usual_site = r.message.usual_site_id;

													}
												}
		});
	}


	update_usual_site(reload = true)
	{
		let method = this.path_user + '.ioi_user_update_usual_site';

		frappe.call({
			method: method,
			args: {},
			async: false,
			callback:function(r) {

				if (reload) {
					window.location.reload();
				}

			}
		});
	}


	// ***************************************************************************************************************************************
	// Show site list
	// ***************************************************************************************************************************************
	show_select_site(reload=false, goto_list = false)
	{
		let r = reload;
		let g = goto_list;

		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_show_select_site(r, g); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_show_select_site(r, g);
		}
	}

	do_show_select_site(reload=false, goto_list = false)
	{
		let html = '';

		let me = this;
		let method = this.path_user + '.ioi_user_get_site'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{ 	me.current_site = r.message;
												}
		});


		let division_tab = []
		method = this.path_user + '.ioi_user_get_division_with_sites'

		frappe.call({  	method: method,
						args: {},
						async: false,
						callback:function(r)	{
							division_tab = r.message;
						}
		});

		let current_division = ''

		if ((me.current_site) && (me.current_site.trim() != '')) {

			method = this.path_user + '.ioi_user_get_division_for_site'

			frappe.call({  	method: method,
							args: {"site_id": me.current_site},
							async: false,
							callback:function(r)	{
								current_division = r.message;
							}
			});

		}else {
			if (division_tab.length != 0) {
				current_division = division_tab[0].name;
			}
		}

		if (document.getElementById('ioi_site_list_label')) {
			document.getElementById('ioi_site_list_label').remove();
		}
		if (document.getElementById('ioi_site_list')) {
			document.getElementById('ioi_site_list').remove();
		}

		html += '<div style="position: relative; top: -20px; left: 0px; width:300px; height: 25px;">';
		html += '	<label id="ioi_site_list_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a site") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
		html += '		<select id="ioi_site_list" class="input-with-feedback form-control bold"> ';


		if (current_division != '') {

			method = this.path_user + '.ioi_user_get_sites_for_division'

			frappe.call({  	method: method,
							args: {"division_id": current_division},
							async: false,
							callback:function(r)	{

								for (var i = 0; i < r.message.length; i++)
								{
									if (me.current_site == r.message[i].name)
									{	html += '		<option value="' + r.message[i].name + '" selected>' + r.message[i].description + '</option> ';
									}else
									{	html += '		<option value="' + r.message[i].name + '">' + r.message[i].description + '</option> ';
									}
								}

							}
			});

		}


		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		if (division_tab.length > 1) {

			html += '<div style="position: relative; top: -45px; left: 310px; width:200px; height: 32px;">';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width:200px; height: 32px;"> ';
			html += '		<input id="ioi_select_division" type="button" class="btn btn-default ellipsis" value="' + __("Change division")  + '" style="width:100%; height:100%">';
			html += '	</div>';
			html += '</div>';

			if (document.getElementById('ioi_select_division')) {
				document.getElementById('ioi_select_division').remove();
			}


		}


		frappe.confirm(html,
			() => 	{
						// action to perform if Yes is selected
						document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

						if ((!document.getElementById('ioi_site_list')) || (document.getElementById('ioi_site_list').value.trim() == ''))
						{	document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = false;
							frappe.msgprint({title: __("Message"), message: __('Site is mandatory'), indicator: "red"});
							raise;
						}

						me.current_site = document.getElementById('ioi_site_list').value;

						let method_user = this.path_user + '.ioi_user_save_site'
						frappe.call({  	method: method_user,
										args: {"site_id": document.getElementById('ioi_site_list').value},
										async: false,
										callback:function(r)	{ 	if (reload)
																	{
																		const is_history_doctype = me.retrieve_record_on_reload()

																		if (goto_list) {
																			if (!is_history_doctype) {
																				let dc = me.frm.doctype.toLowerCase();
																				dc = dc.replaceAll(' ', '-')
																				window.location.href = '/app/' + dc + '/';
																			}
																		} else {
																			if (!is_history_doctype) {
																				window.location.reload();
																			}
																		}
																	}
																}
						});

					},
			() => 	{
						// action to perform if No is selected or if click outsite the modal screen
						if (reload)
						{
							window.location.reload();
						}
					}
		);

		this.sleep_ms(300).then(() => {

			let fct_click = function() {

				var changeDivisionDialog = new frappe.ui.Dialog({
					title: __("Change division"),
					static: true,
					fields: [	{'fieldname': 'html_change_division', 'fieldtype': 'HTML'}
							],
					primary_action_label: __("Ok"),
					primary_action: function(){

						let value = document.getElementById('html_change_division_id').value;

						changeDivisionDialog.hide();

						if (value != current_division) {

							let method = 'silicon_ioi.ioi_system.doctype.ioi_user.ioi_user.ioi_user_get_sites_for_division';

							frappe.call({  	method: method,
											args: {"division_id": value},
											async: false,
											callback:function(r)	{

												while (document.getElementById('ioi_site_list').options.length > 0) {
													document.getElementById('ioi_site_list').remove(0);
												}

												for (var i = 0; i < r.message.length; i++) {

													var option = document.createElement("option");
													option.value = r.message[i].name;
													option.text = r.message[i].description;
													document.getElementById('ioi_site_list').add(option);
												}
											}
							});

							current_division = value;
						}
					},
					secondary_action_label: __("Cancel"),
					secondary_action: function(){

						changeDivisionDialog.hide();
					}

				});

				if (document.getElementById('html_change_division_label')) {
					document.getElementById('html_change_division_label').remove();
				}

				if (document.getElementById('html_change_division_id')) {
					document.getElementById('html_change_division_id').remove();
				}

				let html = ""
				html += '<div style="height: 70px;">';
				html += '	<div style="position: relative; top: 0px; left: 0px; width:300px; height: 25px;">';
				html += '		<label id="html_change_division_label" style="position: absolute; top: 0px; left: 2px;">' + __("Select a division") + '</label>';
				html += '		<div class="control-input" style="position: absolute; top: 25px; left:2px; width:300px; height: 25px;"> ';
				html += '			<select id="html_change_division_id" class="input-with-feedback form-control bold"> ';

				for (var i = 0; i < division_tab.length; i++)
				{
					if (current_division == division_tab[i].name)
					{	html += '		<option value="' + division_tab[i].name + '" selected>' + division_tab[i].description + '</option> ';
					}else
					{	html += '		<option value="' + division_tab[i].name + '">' + division_tab[i].description + '</option> ';
					}
				}

				html += '			</select> ';
				html += '		</div>';
				html += '	</div>';
				html += '</div>';

				changeDivisionDialog.fields_dict.html_change_division.$wrapper.html(html);
				changeDivisionDialog.$wrapper.find('.modal-dialog').css("max-width", "340px").css("width", "340px");
				changeDivisionDialog.show();
			}

			document.getElementById('ioi_select_division').onclick = fct_click;

		});

		this.sleep_ms(200).then(() => {

			if (division_tab.length <= 1) {
				document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length-1].style.width = '340px';
			}else{
				document.getElementsByClassName('modal-content')[document.getElementsByClassName('modal-content').length-1].style.width = '555px';
			}
		});

	}

	retrieve_record_on_reload() {
		// Only for history/activity doctypes

		const selected_element = $('[id$="_selected"]');

		let id = ""
		let desc = ""

		if (selected_element.length == 2) {
			id = selected_element[0]?.value
			desc = selected_element[1]?.value
		} else {
			return false
		}

		const history_doctypes = ['ioi Clocking History', 'ioi Customer History', 'ioi Park History', 'ioi Item History', 'ioi Manufacturer Catalog History', 'ioi Production History', 'ioi Supplier History', 'ioi Customer History', 'ioi Dossier History']

		if (!!id && desc && !!this.frm.doctype && history_doctypes.includes(this.frm.doctype)) {

			const format_doctype = this.frm.doctype.toLowerCase().replaceAll(' ', '-')
			window.location.href = `/app/${format_doctype}?id=${id}&desc=${desc}`

			return true

		} else {
			return false
		}
	}

	// ***************************************************************************************************************************************
	// Show detail only
	// ***************************************************************************************************************************************

	static show_detail_only(detail_only)
	{
		let path_user_module_config = 'silicon_ioi.ioi_configuration.doctype.ioi_user_module_config.ioi_user_module_config';
		let method = path_user_module_config + '.ioi_user_module_config_update_config';

		let detail_focused = 0;

		if (detail_only) {
			detail_focused = 1;
		}

		frappe.call({  	method: method,
						args: {"doctype": cur_frm.doctype, "detail_focused": detail_focused},
						async: false,
						callback:function(r)	{}
		});


		silicon_ioi.doctype.ioiDocType.do_show_detail_only(detail_only);
	}

	static do_show_detail_only(detail_only)
	{
		let path_module = 'silicon_ioi.ioi_configuration.doctype.ioi_module.ioi_module';
		let method = path_module + '.ioi_module_get_data';

		let tab_name = '';
		let detail_name = '';
		let grid_profile = '';


		frappe.call({  	method: method,
						args: {"doctype": cur_frm.doctype},
						async: false,
						callback:function(r)	{

							if (r.message) {
								if (r.message.length > 0) {
									if (r.message[0].tabname != null) {
										tab_name = r.message[0].tabname;
									}

									if (r.message[0].detail != null) {
										detail_name = r.message[0].detail;
									}

									if (r.message[0].grid_profile != null) {
										grid_profile = r.message[0].grid_profile;
									}
								}
							}
						}
		});

		// Tab break
		if (tab_name != '')
		{
			let dc = cur_frm.doctype.toLowerCase();
			dc = dc.replaceAll(' ', '-')
			dc = dc + '-' + tab_name + '-tab';

			silicon_ioi.doctype.ioiDocType.static_save_current_doc_tab(cur_frm.doctype, dc);
			document.getElementById(dc).click();

			if (document.getElementsByClassName('form-tabs-list')) {

				if (document.getElementsByClassName('form-tabs-list').length > 0) {
					document.getElementsByClassName('form-tabs-list')[0].hidden = detail_only;
				}
			}
		}


		// Status banner
		if (document.getElementById('banner_status')) {
			document.getElementById('banner_status').hidden = detail_only;
		}

		// Sections
		if (detail_only) {

			for (var i = 0; i < cur_frm.layout.sections.length; i++) {

				if (detail_name != '') {

					if (cur_frm.layout.sections[i].fields_dict) {

						if (!cur_frm.layout.sections[i].fields_dict[detail_name]) {
							cur_frm.layout.sections[i].wrapper[0].hidden = true;
						}

					}else {
						cur_frm.layout.sections[i].wrapper[0].hidden = true;
					}
				}else{
					cur_frm.layout.sections[i].wrapper[0].hidden = true;
				}
			}
		}else {

			for (var i = 0; i < cur_frm.layout.sections.length; i++) {
				cur_frm.layout.sections[i].wrapper[0].hidden = false;
			}
		}

		// Left banner
		if (document.getElementsByClassName('col-lg-2 layout-side-section')) {

			if (document.getElementsByClassName('col-lg-2 layout-side-section').length > 0) {
				document.getElementsByClassName('col-lg-2 layout-side-section')[0].hidden = detail_only;
			}
		}

		// Form footer
		if (document.getElementsByClassName('form-footer')) {

			if (document.getElementsByClassName('form-footer').length > 0) {
				document.getElementsByClassName('form-footer')[0].hidden = detail_only;
			}
		}


		// Grid profile
		if (grid_profile != '')
		{

			silicon_ioi.doctype.ioiDocType.static_sleep_ms(1000).then(() => {

				let gp = grid_profile;
				gp = gp.replaceAll(' ', '%20');
				gp = gp.replaceAll('•', '%E2%80%A2');

				for(var i = 0; i < document.getElementsByClassName('dropdown-item').length; i++) {

					if (document.getElementsByClassName('dropdown-item')[i].attributes) {

						if (document.getElementsByClassName('dropdown-item')[i].attributes.getNamedItem('data-value')) {

							let s = document.getElementsByClassName('dropdown-item')[i].attributes.getNamedItem('data-value').value;

							if (s == gp) {
								document.getElementsByClassName('dropdown-item')[i].click();
								break;
							}
						}
					}

				}

			});

		}


	}


	// ***************************************************************************************************************************************
	// Events : Build html page
	// ***************************************************************************************************************************************
	async events_build_page()
	{
		if (document.getElementById("event_search"))
		{	document.getElementById("event_search").remove();
		}

		if (document.getElementById("event_dt_from"))
		{	document.getElementById("event_dt_from").remove();
		}

		if (document.getElementById("event_dt_to"))
		{	document.getElementById("event_dt_to").remove();
		}

		if (document.getElementById("event_status"))
		{	document.getElementById("event_status").remove();
		}

		if (document.getElementById("event_event_ioi_category"))
		{	document.getElementById("event_event_ioi_category").remove();
		}

		if (document.getElementById("event_event_type"))
		{	document.getElementById("event_event_type").remove();
		}

		if (document.getElementById("event_nb_document"))
		{
			for (var i = 0; i < document.getElementById("event_nb_document").value; i++)
			{
				if (document.getElementById("event_document_" + i.toString()))
				{
					document.getElementById("event_document_" + i.toString()).remove();
				}
			}

			document.getElementById("event_nb_document").remove();
		}



		if (document.getElementById("event_topn"))
		{	document.getElementById("event_topn").remove();
		}
		if (document.getElementById("event_orderby"))
		{	document.getElementById("event_orderby").remove();
		}
		if (document.getElementById("event_order"))
		{	document.getElementById("event_order").remove();
		}

		if (document.getElementById('event_bt_search'))
		{	document.getElementById('event_bt_search').remove();
		}

		if (document.getElementById('event_bt_clear'))
		{	document.getElementById('event_bt_clear').remove();
		}

		if (document.getElementById('event_bt_export'))
		{	document.getElementById('event_bt_export').remove();
		}

		if (document.getElementById('event_grid'))
		{	document.getElementById('event_grid').remove();
		}

		if (document.getElementById('event_grid_header'))
		{	document.getElementById('event_grid_header').remove();
		}

		if (document.getElementById('event_grid_detail'))
		{	document.getElementById('event_grid_detail').remove();
		}

		if (document.getElementById('event_bt_new'))
		{	document.getElementById('event_bt_new').remove();
		}

		if (document.getElementById('event_bt_delete'))
		{	document.getElementById('event_bt_delete').remove();
		}

		this.event_categories = await frappe.call('silicon_ioi.utils.lib.system.get_categories', { doctype: this.frm.doctype }).then(r => [''].concat(r.message))

		let html = '';

		html += '<div style="overflow-x: auto; height:135px; width:100%;">';

		// Event Search
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="event_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="event_search" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		// From
		html += '<div style="position: relative; top: 0px; left: 180px; width:170px;">';
		html += '	<label id="event_dt_from_label" style="position: absolute; top: 0px; left: 2px;">' + __("From") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '		<input id="event_dt_from" type="date" class="input-with-feedback form-control bold">';
		html += '	</div>';
		html += '</div>';

		// To
		html += '<div style="position: relative; top: 0px; left: 360px; width:170px;">';
		html += '	<label id="event_dt_to_label" style="position: absolute; top: 0px; left: 2px;">' + __("To") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<input id="event_dt_to" type="date" class="input-with-feedback form-control bold" pattern="\y{4}-\m{2}-\d{2}">';
		html += '	</div>';
		html += '</div>';

		// Status
		html += '<div style="position: relative; top: 0px; left: 540px; width:120px;">';
		html += '	<label id="event_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Status") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 120px; height: 30px;"> ';
		html += '		<select id="event_status" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '			<option value="' + __("Open") + '">' + __("Open") + '</option> ';
		html += '			<option value="' + __("Completed") + '">' + __("Completed") + '</option> ';
		html += '			<option value="' + __("Closed") + '">' + __("Closed") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Documents
		if ((this.frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER'))
		{
			html += '<div style="position: relative; top: 0px; left: 650px; width:300px;">';
			html += '	<label id="event_document_label" style="position: absolute; top: 0px; left: 2px;">' + __("Documents") + '</label>';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 100px; background:#F4F5F6; border-radius:6px; padding: 4px; overflow: auto; overflow-x: auto;"> ';

			if (this.frm.doctype.toUpperCase() == 'IOI CUSTOMER')
			{	html += '		<input type="hidden" id="event_nb_document" value="4">';
				html += '		<input type="checkbox" id="event_document_0" style="position: relative; top: 2px; left: 2px" value="ioi Sales Quote" checked><label style="position: absolute; top: 4px; left: 30px;">'      + __("Sales Quote") + '</label>';
				html += '		<input type="checkbox" id="event_document_1" style="position: absolute; top: 26px; left: 6px" value="ioi Sales Order" checked><label style="position: absolute; top: 24px; left: 30px;">'    + __("Sales Order") + '</label>';
				html += '		<input type="checkbox" id="event_document_2" style="position: absolute; top: 46px; left: 6px" value="ioi Sales Delivery" checked><label style="position: absolute; top: 44px; left: 30px;">' + __("Sales Delivery") + '</label>';
				html += '		<input type="checkbox" id="event_document_3" style="position: absolute; top: 66px; left: 6px" value="ioi Sales Invoice" checked><label style="position: absolute; top: 64px; left: 30px;">'  + __("Sales Invoice") + '</label>';
			}else if (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER')
			{	html += '		<input type="hidden" id="event_nb_document" value="4">';
				html += '		<input type="checkbox" id="event_document_0" style="position: relative; top: 2px; left: 2px" value="ioi Purchases Price Request" checked><label style="position: absolute; top: 4px; left: 30px;">' + __("Purchases Price Request") + '</label>';
				html += '		<input type="checkbox" id="event_document_1" style="position: absolute; top: 26px; left: 6px" value="ioi Purchases Order" checked><label style="position: absolute; top: 24px; left: 30px;">'    	+ __("Purchases Order") + '</label>';
				html += '		<input type="checkbox" id="event_document_2" style="position: absolute; top: 46px; left: 6px" value="ioi Purchases Receipt" checked><label style="position: absolute; top: 44px; left: 30px;">' 	+ __("Purchases Receipt") + '</label>';
				html += '		<input type="checkbox" id="event_document_3" style="position: absolute; top: 66px; left: 6px" value="ioi Purchases Invoice" checked><label style="position: absolute; top: 64px; left: 30px;">'  	+ __("Purchases Invoice") + '</label>';
			}
			html += '	</div>';
			html += '</div>';
		}

		// Event ioi Category
		html += '<div style="position: relative; top: 65px; left: 180px; width:170px;">';
		html += '	<label id="event_event_ioi_category_label" style="position: absolute; top: 0px; left: 2px;">' + __("ioi Category") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="event_event_ioi_category" class="input-with-feedback form-control bold"> ';
		html += 			this.event_categories.map(el => `<option value="${el}">${el}</option>`);
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Event Type
		html += '<div style="position: relative; top: 65px; left: 360px; width:170px;">';
		html += '	<label id="event_event_type_label" style="position: absolute; top: 0px; left: 2px;">' + __("Type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="event_event_type" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '			<option value="' + __("Private") + '">' + __("Private") + '</option> ';
		html += '			<option value="' + __("Public") + '">' + __("Public") + '</option> ';
		html += '			<option value="' + __("Cancelled") + '">' + __("Cancelled") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';



		// Topn
		html += '	<div style="position: relative; top: 65px; left: 540px; width:120px;">';
		html += '		<label id="event_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width:120px; height: 30px;"> ';
		html += '			<input id="event_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: absolute; top: 65px; left: 0px; width:150px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 28px">';
		html +='			<button id="event_bt_search" title="' + __("Refresh") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 110px;" onclick="">' + __("Refresh") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '<div style="position: absolute; top: 65px; left: 120px; width:50px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 28px;">';
		html +='		<button id="event_bt_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Export
		html += '<div style="position: absolute; top: 98px; left: 0px; width:170px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 28px;">';
		html +='		<button id="event_bt_export" title="' + __("Export") +'" data-label="Export" class="btn btn-default ellipsis" style="height: 28px; width: 170px;" onclick="">' + __("Export") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '<input id="event_orderby" type="hidden" value="starts_on">';
		html += '<input id="event_order" type="hidden" value="desc">';

		html += '</div>';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		// Grid Header
		html += '<div id="event_grid_header" style="overflow: auto; overflow-x: auto; height:600px;">';

		html += '<table id="event_grid" border=1 style="border: 1px solid #E8EAEB" width=1345px data-custom-grid="true">';

		html += `<tr style="height:30px">`;

		html += '<td width=30px align="center" style="vertical-align: middle;">';
		html += '<input type="checkbox" id="event_check_all_none" style="postion:absolute; top: 2px; left: 2px;" ';
		html += '       onclick=" ';
		html += '					for (var i = 0; i < document.getElementById(\'event_grid_detail\').rows.length; i++) ';
		html += '					{	';
		html += '						if (document.getElementById(\'event_checked_id_\' + i.toString())) ';
		html += '						{ ';
		html += '							document.getElementById(\'event_checked_id_\' + i.toString()).checked = this.checked; ';
		html += '						} ';
		html += '					} ';
		html += '                " ';
		html += '>';
		html += '</td>';

		html += '<td id="event_col_starts_on"			width=140px style="vertical-align: middle;"><b>&nbsp;' + __("Starts on") 		 + '<label id="event_col_label_starts_on" 		  style="width:30px; height:8px" align="right">&uarr;</label></b></td>';
		html += '<td id="event_col_ends_on"				width=140px style="vertical-align: middle;"><b>&nbsp;' + __("Ends on")	 		 + '<label id="event_col_label_ends_on" 		  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="event_col_subject"				width=550px style="vertical-align: middle;"><b>&nbsp;' + __("Subject") 		 	 + '<label id="event_col_label_subject" 		  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="event_col_status"		    	width=150px style="vertical-align: middle;"><b>&nbsp;' + __("Status") 		 	 + '<label id="event_col_label_status" 			  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="event_col_bt_status"		    width=110px  style="vertical-align: middle;text-align: center;"><b>' + __("Action") + '</b></td>';
		html += '<td id="event_col_event_ioi_category"	    width=175px style="vertical-align: middle;"><b>&nbsp;' + __("ioi Category")	 		 + '<label id="event_col_label_event_ioi_category" 	  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="event_col_event_type"		    width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Type") 		 	 + '<label id="event_col_label_event_type" 		  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '</tr>';
		html += '</table>';

		// Result
		html += this.#events_empty_table_row();

		let me = this;

		html += '</div>'

		html += '<div id="event_actions" style="height:60px;">';

		html += '	<div id="event_bt_new"><br>';
		html += '		<button data-label="New event" class="btn btn-default ellipsis" style="position:relative;left:3px;" ';
		html += '		onclick="';
		html += '			if (cur_frm.is_new()) { ';
		html += '				frappe.msgprint({title: __(\'Message\'), message: __(\'Save or cancel data before\'), indicator: \'red\'}); ';
		html += '				raise; ';
		html += '			} '

		html += '			frappe.new_doc(&quot;Event&quot;, {}, doc => { 	let row = frappe.model.add_child(doc, \'event_participants\'); ';
		html += '															row.reference_doctype = \'' + me.frm.doctype + '\'; ';
		html += '															row.reference_docname = \'' + me.frm.doc.name + '\'; ';
		html += '			}); ';
		html += '		">';
		html += '		New event</button>';
		html += '	</div>';

		html += '	<div id="event_bt_delete">';
		html += '		<button data-label="Delete" class="btn btn-default ellipsis" style="position:relative;top:-35px;left:117px;">Delete</button>';
		html += '		<br>';
		html += '	</div>';

		html += '</div>';

		this.frm.fields_dict['html_module_events'].$wrapper.empty();
		this.frm.fields_dict['html_module_events'].$wrapper.append(html);

		let fct_search = function() { me.#events_search(); };
		let fct_clear = function() { me.#events_clear(); };
		let fct_export = function() { me.export_event_data(); };

		let fct_delete_click = function ()	{ me.#events_delete(); };

		document.getElementById('event_bt_delete').onclick = fct_delete_click;


		document.getElementById('event_search').onkeydown = this.#events_search_key_down;

		document.getElementById('event_topn').onkeydown = this.#events_search_key_down;
		document.getElementById('event_topn').onchange = this.#events_search_on_change;

		document.getElementById('event_dt_from').onkeydown = this.#events_search_key_down;
		document.getElementById('event_dt_from').onchange = this.#events_search_on_change;

		document.getElementById('event_dt_to').onkeydown = this.#events_search_key_down;
		document.getElementById('event_dt_to').onchange = this.#events_search_on_change;


		document.getElementById('event_status').onchange = this.#events_search_on_change;
		document.getElementById('event_event_ioi_category').onchange = this.#events_search_on_change;
		document.getElementById('event_event_type').onchange = this.#events_search_on_change;

		if (document.getElementById("event_nb_document"))
		{
			for (var i = 0; i < document.getElementById("event_nb_document").value; i++)
			{
				if (document.getElementById("event_document_" + i.toString()))
				{
					document.getElementById("event_document_" + i.toString()).onclick = this.#events_search_on_change;
				}
			}
		}


		document.getElementById('event_bt_search').onclick = fct_search;
		document.getElementById('event_bt_clear').onclick = fct_clear;
		document.getElementById('event_bt_export').onclick = fct_export;

		let fct_col_click = function () { me.#events_col_click(this); };

		document.getElementById('event_col_starts_on').onclick = fct_col_click;
		document.getElementById('event_col_ends_on').onclick = fct_col_click;
		document.getElementById('event_col_subject').onclick = fct_col_click;
		document.getElementById('event_col_event_ioi_category').onclick = fct_col_click;
		document.getElementById('event_col_event_type').onclick = fct_col_click;
		document.getElementById('event_col_status').onclick = fct_col_click;

		let fct_col_over = function () { me.#events_col_mouse_over(this); };

		document.getElementById('event_col_starts_on').onmouseover = fct_col_over;
		document.getElementById('event_col_ends_on').onmouseover = fct_col_over;
		document.getElementById('event_col_subject').onmouseover = fct_col_over;
		document.getElementById('event_col_event_ioi_category').onmouseover = fct_col_over;
		document.getElementById('event_col_event_type').onmouseover = fct_col_over;
		document.getElementById('event_col_status').onmouseover = fct_col_over;

		let fct_col_leave = function () { me.#events_col_mouse_leave(this); };

		document.getElementById('event_col_starts_on').onmouseleave = fct_col_leave;
		document.getElementById('event_col_ends_on').onmouseleave = fct_col_leave;
		document.getElementById('event_col_subject').onmouseleave = fct_col_leave;
		document.getElementById('event_col_event_ioi_category').onmouseleave = fct_col_leave;
		document.getElementById('event_col_event_type').onmouseleave = fct_col_leave;
		document.getElementById('event_col_status').onmouseleave = fct_col_leave;

		document.getElementById('event_bt_search').click();
	}

	// ***************************************************************************************************************************************
	// Events : Empty table row
	// ***************************************************************************************************************************************
	#events_empty_table_row()
	{
		let html = '';
		html += '<table id="event_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1345px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=550px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=150px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '</tr>';
		html += '</table>';

		return html;
	}

	// ***************************************************************************************************************************************
	// Events clear params & list
	// ***************************************************************************************************************************************
	#events_clear()
	{
		if (cur_frm.doctype) {

			let dt = cur_frm.doctype;
			dt = dt.toLowerCase();
			dt = dt.replaceAll(' ', '-')

			let dt2 = cur_frm.doctype;
			dt2 = dt2.toLowerCase();
			dt2 = dt2.replaceAll(' ', '_')

			if (document.getElementById(dt + '-' + dt2 + '_tab_events-tab')) {
				document.getElementById(dt + '-' + dt2 + '_tab_events-tab').innerText = __("Events")
			}
		}

		document.getElementById('event_search').value = '';
		document.getElementById('event_topn').value = '100';

		document.getElementById('event_dt_from').value = '';
		document.getElementById('event_dt_to').value = '';
		document.getElementById('event_status').selectedIndex = 0;
		document.getElementById('event_event_ioi_category').selectedIndex = 0;
		document.getElementById('event_event_type').selectedIndex = 0;

		if (document.getElementById("event_nb_document"))
		{
			for (var i = 0; i < document.getElementById("event_nb_document").value; i++)
			{
				if (document.getElementById("event_document_" + i.toString()))
				{
					document.getElementById("event_document_" + i.toString()).checked = true;
				}
			}
		}


		if (document.getElementById('event_grid_detail'))
		{
			for (var i = 0; i < document.getElementById('event_grid_detail').rows.length; i++)
			{
				if (document.getElementById('event_id_' + i.toString()))
				{	document.getElementById('event_id_' + i.toString()).remove();
				}

				if (document.getElementById('event_name_' + i.toString()))
				{	document.getElementById('event_name_' + i.toString()).remove();
				}

				if (document.getElementById('jump_id_' + i.toString()))
				{	document.getElementById('jump_id_' + i.toString()).remove();
				}

				if (document.getElementById('jump_name_' + i.toString()))
				{	document.getElementById('jump_name_' + i.toString()).remove();
				}

				if (document.getElementById('jump_dc_' + i.toString()))
				{	document.getElementById('jump_dc_' + i.toString()).remove();
				}

				if (document.getElementById('event_checked_id_' + i.toString()))
				{	document.getElementById('event_checked_id_' + i.toString()).remove();
				}

				if (document.getElementById('event_status_bt_close_' + i.toString()))
				{	document.getElementById('event_status_bt_close_' + i.toString()).remove();
				}

				if (document.getElementById('event_status_bt_complete_' + i.toString()))
				{	document.getElementById('event_status_bt_complete_' + i.toString()).remove();
				}

				if (document.getElementById('event_status_bt_reopen_' + i.toString()))
				{	document.getElementById('event_status_bt_reopen_' + i.toString()).remove();
				}

			}

			document.getElementById('event_grid_detail').remove();
		}

		let html = this.#events_empty_table_row();
	}

	// ***************************************************************************************************************************************
	// Events Search
	// ***************************************************************************************************************************************
	#events_search(table)
	{
		if (cur_frm.doctype) {

			let dt = cur_frm.doctype;
			dt = dt.toLowerCase();
			dt = dt.replaceAll(' ', '-')

			let dt2 = cur_frm.doctype;
			dt2 = dt2.toLowerCase();
			dt2 = dt2.replaceAll(' ', '_')

			if (document.getElementById(dt + '-' + dt2 + '_tab_events-tab')) {
				document.getElementById(dt + '-' + dt2 + '_tab_events-tab').innerText = __("Events")
			}
		}


		if (this.frm.is_new())
		{
			this.#events_clear();
			return false;
		}

		let html = '';
		let me = this;
		let method = this.path_event_todo_file + '.module_events_get_data';

		let other_doctypes = '';
		let match_fields = '';

		const table_name = table && table.element.id === "event_sv_grid" ? "event_sv" : "event"

		if (document.getElementById(`${table_name}_nb_document`))
		{
			for (var i = 0; i < document.getElementById(`${table_name}_nb_document`).value; i++)
			{
				if (document.getElementById(`${table_name}_document_` + i.toString()))
				{
					if (document.getElementById(`${table_name}_document_` + i.toString()).checked)
					{
						other_doctypes += document.getElementById(`${table_name}_document_` + i.toString()).value + ';'

						if (this.frm.doctype.toUpperCase() == 'IOI CUSTOMER')
						{
							if ((document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES QUOTE') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES ORDER') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES DELIVERY') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES INVOICE'))
							{
								match_fields += 'order_customer_id,delivery_customer_id,invoice_customer_id;'
							}
						}else if (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER')
						{
							if ((document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES ORDER') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES INVOICE'))
							{
								match_fields += 'order_supplier_id,delivery_supplier_id,invoice_supplier_id;'
							}
						}
					}
				}
			}
		}

		frappe.call({  	method: method,
						args: {
							"doctype": this.frm.doctype,
							"name" : this.frm.doc.name,
							"search": document.getElementById(`${table_name}_search`).value,
							"dt_from": document.getElementById(`${table_name}_dt_from`).value,
							"dt_to": document.getElementById(`${table_name}_dt_to`).value,
							"event_ioi_category": document.getElementById(`${table_name}_event_ioi_category`).value,
							"event_type": document.getElementById(`${table_name}_event_type`).value,
							"status": document.getElementById(`${table_name}_status`).value,
							"topn" : document.getElementById(`${table_name}_topn`).value,
							"orderby": document.getElementById(`${table_name}_orderby`).value,
							"order": document.getElementById(`${table_name}_order`).value,
							"other_doctypes": other_doctypes,
							"match_fields": match_fields
						},
						async: true,
						callback:function(r)	{
													if (table_name === "event_sv") {
														if (table.initialized) {
															table.replaceData(r.message)
														} else {
															table.on('tableBuilt', () => table.replaceData(r.message))
														}

														let cpt_open = 0;

														for (var i = 0; i < r.message.length; i++) {
															if (r.message[i].status.toUpperCase() == __("OPEN")) {
																cpt_open++;
															}
														}

														if (cpt_open != 0) {
															if (cur_frm.doctype) {

																let dt = cur_frm.doctype;
																dt = dt.toLowerCase();
																dt = dt.replaceAll(' ', '-')

																let dt2 = cur_frm.doctype;
																dt2 = dt2.toLowerCase();
																dt2 = dt2.replaceAll(' ', '_')

																if (document.getElementById(dt + '-' + dt2 + '_tab_events-tab')) {
																	document.getElementById(dt + '-' + dt2 + '_tab_events-tab').innerText = __("Events") + ' (' + cpt_open + ')';
																}
															}
														}

													} else {
														if (document.getElementById('event_grid_detail'))
														{
															for (var i = 0; i < document.getElementById('event_grid_detail').rows.length; i++)
															{
																if (document.getElementById('event_id_' + i.toString()))
																{	document.getElementById('event_id_' + i.toString()).remove();
																}

																if (document.getElementById('event_name_' + i.toString()))
																{	document.getElementById('event_name_' + i.toString()).remove();
																}

																if (document.getElementById('jump_id_' + i.toString()))
																{	document.getElementById('jump_id_' + i.toString()).remove();
																}

																if (document.getElementById('jump_name_' + i.toString()))
																{	document.getElementById('jump_name_' + i.toString()).remove();
																}

																if (document.getElementById('jump_dc_' + i.toString()))
																{	document.getElementById('jump_dc_' + i.toString()).remove();
																}

																if (document.getElementById('event_checked_id_' + i.toString()))
																{	document.getElementById('event_checked_id_' + i.toString()).remove();
																}

																if (document.getElementById('event_status_bt_close_' + i.toString()))
																{	document.getElementById('event_status_bt_close_' + i.toString()).remove();
																}

																if (document.getElementById('event_status_bt_complete_' + i.toString()))
																{	document.getElementById('event_status_bt_complete_' + i.toString()).remove();
																}

																if (document.getElementById('event_status_bt_reopen_' + i.toString()))
																{	document.getElementById('event_status_bt_reopen_' + i.toString()).remove();
																}



															}

															document.getElementById('event_grid_detail').remove();
														}

														if (r.message.length > 0)
														{
															let cpt = 0;

															let cpt_open = 0;

															html += '<table id="event_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1345px>';

															for (var i = 0; i < r.message.length; i++)
															{
																if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																	html += '<tr style="height:30px" bgcolor="#ced1d6">';
																}else{
																	html += '<tr style="height:30px">';
																}

																html += '<td width=30px align="center" style="vertical-align: middle;">';
																html += '<input type="checkbox" id="event_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;">';
																html += '</td>';


																if (r.message[i].starts_on != null)
																{	html += '<td width=140px style="vertical-align: middle;';

																	if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																		html += 'color:#000000;';
																	}

																	html += '">&nbsp;' + r.message[i].starts_on.substring(0, 16) + '</td>';
																}else
																{	html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
																}

																html += '<input type="hidden" id="event_name_' + i.toString() + '" value="' + r.message[i].name + '">';
																html += '<input type="hidden" id="jump_name_' + i.toString() + '" value="' + r.message[i].reference_docname + '">';
																html += '<input type="hidden" id="jump_dc_' + i.toString() + '" value="' + r.message[i].reference_doctype + '">';



																if (r.message[i].ends_on != null)
																{	html += '<td width=140px style="vertical-align: middle;';

																if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																	html += 'color:#000000;';
																}


																	html += '">&nbsp;' + r.message[i].ends_on.substring(0, 16) + '</td>';
																}else
																{	html += '<td width=140px style="vertical-align: middle;">&nbsp;</td>';
																}

																if (r.message[i].subject != null)
																{	html += '<td height=30px width=550px style="vertical-align: middle;">';
																	html += '<div style="position:relative; top: 10px; left: 2px; width: 15px; height: 15px; border-radius: 50%; background:' + r.message[i].color + '"></div>';
																	html += '<div style="position:relative; top: -7px; left: 25px; height: 20px">'
																	html += '<a id="event_id_' + i.toString() + '" onmouseover="this.style.textDecoration = \'underline\';" onmouseout="this.style.textDecoration = \'none\';" style="';

																	if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																		html += 'color:#000000;';
																	}

																	html += '">';

																	if (r.message[i].subject.length > 70)
																	{	html += r.message[i].subject.substring(0,70) + ' ... ';
																	}else
																	{	html += r.message[i].subject;
																	}

																	html += '</a></div>';

																	if (r.message[i].reference_doctype != me.frm.doctype)
																	{
																		if (r.message[i].reference_docname != null)
																		{	html += '<div style="position:relative; top: -6px; left: 25px; height: 20px;';

																			if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																				html += 'color:#000000;';
																			}

																			html += '">'

																			let s = r.message[i].reference_doctype;
																			s = s.replace('ioi ', '');
																			html += '<u>' + s + '</u>&nbsp;:&nbsp;<a id="jump_id_' + i.toString() + '" onmouseover="this.style.textDecoration = \'underline\';" onmouseout="this.style.textDecoration = \'none\';">' + r.message[i].reference_docname + '</a></div>';

																		}
																	}


																	html += '</td>';
																}else
																{	html += '<td width=550px style="vertical-align: middle;">&nbsp;</td>';
																}

																if (r.message[i].status != null)
																{	html += '<td width=150px style="vertical-align: middle;">';

																	if (r.message[i].status.toUpperCase() == __("OPEN"))
																	{	html += '<div style="position:relative; top: 10px; left: 2px; width: 15px; height: 15px; border-radius: 50%; background:#E7A4A4"></div>';
																	}else
																	{	html += '<div style="position:relative; top: 10px; left: 2px; width: 15px; height: 15px; border-radius: 50%; background:#9ABBA7"></div>';
																	}

																	html += '<div style="position:relative; top: -7px; left: 25px; height: 20px;';

																	if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																		html += 'color:#000000;';
																	}

																	html += '">' + r.message[i].status + '</div></td>';
																}else
																{	html += '<td width=150px style="vertical-align: middle;" align="right">&nbsp;</td>';
																}




																if (frappe.session.user.toUpperCase() == 'ADMINISTRATOR') {

																	html += '<td width=110px style="vertical-align: middle;">';

																	if (r.message[i].status.toUpperCase() == __("OPEN")) {

																		html += '<input id="event_status_bt_complete_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Complete") + '">';
																		html += '&nbsp;'
																		html += '<input id="event_status_bt_close_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Close") + '">';

																	}else if (r.message[i].status.toUpperCase() == __("CLOSED")) {
																		html += '<input id="event_status_bt_reopen_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Reopen") + '">';
																	}else{
																		html += '<input id="event_status_bt_reopen_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Reopen") + '">';
																	}

																	html += '</td>';

																}else {
																	if ((r.message[i].reference_docname != null) && (r.message[i].reference_docname != '')) {

																		if ((frappe.session.user.toUpperCase() == r.message[i].reference_docname.toUpperCase()) || (frappe.session.user_email.toUpperCase() == r.message[i].reference_docname.toUpperCase())) {

																			html += '<td width=110px style="vertical-align: middle;">';

																			if (r.message[i].status.toUpperCase() == __("OPEN")) {

																				html += '<input id="event_status_bt_complete_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Complete") + '">';
																				html += '&nbsp;'
																				html += '<input id="event_status_bt_close_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Close") + '">';

																			}else if (r.message[i].status.toUpperCase() == __("CLOSED")) {
																				html += '<input id="event_status_bt_reopen_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Reopen") + '">';
																			}else{
																				html += '<input id="event_status_bt_reopen_' + i.toString() + '" style="width:106px;" type="button" value="' + __("Reopen") + '">';
																			}

																			html += '</td>';

																		}else{
																			html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
																		}

																	}else{
																		html += '<td width=110px style="vertical-align: middle;">&nbsp;</td>';
																	}

																}






																if (r.message[i].ioi_category_id != null)
																{	html += '<td width=175px style="vertical-align: middle;';
																	if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																		html += 'color:#000000;';
																	}
																	html += '">&nbsp;' + r.message[i].ioi_category_id + '</td>';
																}else
																{	html += '<td width=175px style="vertical-align: middle;">&nbsp;</td>';
																}

																if (r.message[i].event_type != null)
																{	html += '<td width=100px style="vertical-align: middle;';

																	if ((r.message[i].status.toUpperCase() == __("OPEN")) && (r.message[i].active == 0)) {
																		html += 'color:#000000;';
																	}

																	html += '">&nbsp;' + r.message[i].event_type + '</td>';
																}else
																{	html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
																}

																html += '</tr>';

																if (r.message[i].active == 1) {

																	if (r.message[i].status.toUpperCase() == __("OPEN")) {
																		cpt_open++;
																	}
																}
															}
															html += '</table>';

															if (cpt_open != 0) {

																if (cur_frm.doctype) {

																	let dt = cur_frm.doctype;
																	dt = dt.toLowerCase();
																	dt = dt.replaceAll(' ', '-')

																	let dt2 = cur_frm.doctype;
																	dt2 = dt2.toLowerCase();
																	dt2 = dt2.replaceAll(' ', '_')

																	if (document.getElementById(dt + '-' + dt2 + '_tab_events-tab')) {
																		document.getElementById(dt + '-' + dt2 + '_tab_events-tab').innerText = __("Events") + ' (' + cpt_open + ')';
																	}
																}
															}

														}
														else
														{
															html += me.#events_empty_table_row();
														}

														document.getElementById('event_grid_header').insertAdjacentHTML('beforeend', html);


														for(var i = 0; i < document.getElementById('event_grid_detail').rows.length; i++)
														{
															if (document.getElementById('event_id_' + i.toString()))
															{
																let n = document.getElementById('event_name_' + i.toString()).value;

																let fct = function () 	{
																							me.open_event(n);
																						};

																document.getElementById('event_id_' + i.toString()).onclick = fct;
															}


															if (document.getElementById('jump_id_' + i.toString()))
															{

																let dc = document.getElementById('jump_dc_' + i.toString()).value
																let n = document.getElementById('jump_name_' + i.toString()).value;
																let fct = function () 	{
																							me.open_link(dc, n);
																						};

																document.getElementById('jump_id_' + i.toString()).onclick = fct;
															}

															if (document.getElementById('event_status_bt_close_' + i.toString())) {

																let fct_button_close = function () {

																	me.event_close(this);

																};

																document.getElementById('event_status_bt_close_' + i.toString()).onclick = fct_button_close;

															}

															if (document.getElementById('event_status_bt_complete_' + i.toString())) {

																let fct_button_complete = function () {

																	me.event_complete(this);

																};

																document.getElementById('event_status_bt_complete_' + i.toString()).onclick = fct_button_complete;


															}

															if (document.getElementById('event_status_bt_reopen_' + i.toString())) {

																let fct_button_reopen = function () {

																	me.event_reopen(this);

																};

																document.getElementById('event_status_bt_reopen_' + i.toString()).onclick = fct_button_reopen;


															}

														}
													}
												}
		});

	}


	event_close(element, row_name) {
		let new_status = __("Closed")

		if (row_name) {
			this.event_change_status(row_name, new_status)

		} else {
			let s = element.id;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_') + 1, s.length);
			}

			s = s.trim();

			let name = document.getElementById('event_name_' + s).value;

			this.event_change_status(name, new_status)
		}
	}

	event_complete(element, row_name) {
		let new_status = __("Completed")

		if (row_name) {
			this.event_change_status(row_name, new_status)

		} else {
			let s = element.id;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_') + 1, s.length);
			}

			s = s.trim();

			let name = document.getElementById('event_name_' + s).value;

			this.event_change_status(name, new_status)
		}
	}

	event_reopen(element, row_name) {
		let new_status = __("Open")

		if (row_name) {
			this.event_change_status(row_name, new_status)

		} else {
			let s = element.id;

			while (s.indexOf('_') != -1) {
				s = s.substring(s.indexOf('_') + 1, s.length);
			}

			s = s.trim();

			let name = document.getElementById('event_name_' + s).value;

			this.event_change_status(name, new_status)
		}
	}

	event_update_datas(name, fieldname, new_value, table) {
		let me = this;
		let method = this.path_event_todo_file + '.module_event_update_datas';

		frappe.call({
			method: method,
			args: {
				"name": name,
				"fieldname": fieldname,
				"new_value": new_value
			},
			async: false,
			callback: function (r) {

				if (r.message.error == 1) {
					frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
					raise;
				} else {
					me.#events_search(table);
				}
			}
		})
	}

	event_change_status(name, new_status)
	{
		let me = this;

		let method = this.path_event_todo_file + '.module_event_change_status';

		frappe.call({  	method: method,
						args: {	"name" : name,
								"new_status": new_status
				  		},
						async: false,
						callback:function(r)	{

							if (r.message.error == 1) {
								frappe.msgprint({title: __("Message"), message: r.message.error_msg, indicator: "red"});
								raise;

							}else{
								me.#events_search();
							}
						}
		});

	}



	// ***************************************************************************************************************************************
	// Events Search
	// ***************************************************************************************************************************************
	#events_search_key_down(event)
	{
		if (event.keyCode == 13)
		{
			document.getElementById('event_bt_search').click();
		}
	}

	// ***************************************************************************************************************************************
	// Events Search
	// ***************************************************************************************************************************************
	#events_search_on_change()
	{
		document.getElementById('event_bt_search').click();
	}


	// ***************************************************************************************************************************************
	// Column header click
	// ***************************************************************************************************************************************
	#events_col_click(obj)
	{
		let s = obj.id;

		s = s.substring(10, obj.id.length);

		document.getElementById('event_col_label_starts_on').innerHTML = '';
		document.getElementById('event_col_label_ends_on').innerHTML = '';
		document.getElementById('event_col_label_subject').innerHTML = '';
		document.getElementById('event_col_label_event_ioi_category').innerHTML = '';
		document.getElementById('event_col_label_event_type').innerHTML = '';
		document.getElementById('event_col_label_status').innerHTML = '';

		if (document.getElementById('event_orderby').value == s)
		{
			if (document.getElementById('event_order').value == 'desc')
			{	document.getElementById('event_order').value = 'asc';

				document.getElementById('event_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('event_order').value = 'desc';
				document.getElementById('event_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('event_orderby').value = s;
			document.getElementById('event_order').value = 'desc';
			document.getElementById('event_col_label_' + s).innerHTML = '&uarr;';
		}


		document.getElementById('event_bt_search').click();
	}

	// ***************************************************************************************************************************************
	// Open Event
	// ***************************************************************************************************************************************
	open_event(name)
	{
		window.open(this.url_event + name);
	}

	// ***************************************************************************************************************************************
	// Open Link
	// ***************************************************************************************************************************************
	open_link(dc, name)
	{
		dc = dc.toLowerCase()
		dc = dc.replaceAll(' ', '-')

		dc = '/app/' + dc + '/';
		window.open(dc + name);
	}

	// ***************************************************************************************************************************************
	// Event delete
	// ***************************************************************************************************************************************
	#events_delete(table)
	{
		const table_name = table && table.element.id === "event_sv_grid" ? "event_sv" : "event"
		let names = '';

		if (table_name === "event_sv") {
			names = table.getSelectedData().map(data => data.name).join(';') + ";"
		} else {
			if (document.getElementById('event_grid_detail').rows.length <= 1) {
				if (document.getElementById('event_grid_detail').rows.length == 0) {
					return false;
				} else {
					if (!document.getElementById('event_checked_id_0')) {
						return false;
					}
				}
			}

			for (var i = 0; i < document.getElementById('event_grid_detail').rows.length; i++) {
				if (document.getElementById('event_checked_id_' + i.toString())) {
					if (document.getElementById('event_checked_id_' + i.toString()).checked) {
						names += document.getElementById('event_name_' + i.toString()).value + ';';
					}
				}
			}
		}

		if (names.trim() != '')
		{
			let msg = 'Delete selected event(s) ?'

			frappe.confirm(	msg,
				() => 	{
							// action to perform if Yes is selected
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

							let method = this.path_event_todo_file + '.module_events_delete';

							frappe.call({  	method: method,
											args: {"names" : names},
											async: false,
											callback:function(r)	{

												document.getElementById(`${table_name}_bt_search`).click();
											}
							});
						},
				() => 	{
							// action to perform if No is selected or if click outsite the modal screen
						}
			);
		}
	}


	// ***************************************************************************************************************************************
	// Column header mouse over
	// ***************************************************************************************************************************************
	#events_col_mouse_over(obj)
	{
		obj.style.cursor = 'pointer';
	}

	// ***************************************************************************************************************************************
	// Column header mouse leave
	// ***************************************************************************************************************************************
	#events_col_mouse_leave(obj)
	{
		obj.style.cursor = 'none';
	}


	// ***************************************************************************************************************************************
	// Export to Excel
	// ***************************************************************************************************************************************
	static export_event()
	{
		let filename = document.getElementById('export_filename').value;
		let filetype = document.getElementById('export_file_type').value;
		let inhibit_nb_records = 0;

		if (document.getElementById('export_inhibit_topn').checked)
		{	inhibit_nb_records = 1;
		}

		let go_to_server  = false;

		if (inhibit_nb_records == 1)
		{	go_to_server = true
		}else
		{
			if (filetype.toUpperCase() == 'XLSX')
			{	go_to_server = true;
			}
		}

		if (filename.trim() == '')
		{
			filename = 'events';
		}

		if (filetype.toUpperCase() == 'XLSX')
		{
			if (filename.toLowerCase().indexOf('.xls') == -1)
			{
				filename += '.xlsx';
			}
		}else
		{
			if (filename.toLowerCase().indexOf('.csv') == -1)
			{
				filename += '.csv';
			}
		}



		if (go_to_server)
		{
			let topn = document.getElementById('event_topn').value;

			if (inhibit_nb_records == 1)
			{	topn = -1;
			}



			let path_event_todo_file = 'silicon_ioi.ioi_crm.event_todo_file';
			let method = path_event_todo_file + '.module_events_get_data';

			let other_doctypes = '';

			let match_fields = '';

			if (document.getElementById("event_nb_document"))
			{
				for (var i = 0; i < document.getElementById("event_nb_document").value; i++)
				{
					if (document.getElementById("event_document_" + i.toString()))
					{
						if (document.getElementById("event_document_" + i.toString()).checked)
						{
							other_doctypes += document.getElementById("event_document_" + i.toString()).value + ';'

							if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER')
							{
								if ((document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI SALES QUOTE') ||
									(document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI SALES ORDER') ||
									(document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI SALES DELIVERY') ||
									(document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI SALES INVOICE'))
								{
									match_fields += 'order_customer_id,delivery_customer_id,invoice_customer_id;'
								}
							}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER')
							{
								if ((document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
									(document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES ORDER') ||
									(document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
									(document.getElementById("event_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES INVOICE'))
								{
									match_fields += 'order_supplier_id,delivery_supplier_id,invoice_supplier_id;'
								}
							}
						}
					}
				}
			}


			frappe.call({  	method: method,
							args: {	"doctype": cur_frm.doctype,
									"name" : cur_frm.doc.name,
									"search": document.getElementById('event_search').value,
									"dt_from": document.getElementById('event_dt_from').value,
									"dt_to": document.getElementById('event_dt_to').value,
									"event_ioi_category": document.getElementById('event_event_ioi_category').value,
									"event_type": document.getElementById('event_event_type').value,
									"status": document.getElementById('event_status').value,
									"topn" : topn,
									"orderby": document.getElementById('event_orderby').value,
									"order": document.getElementById('event_order').value,
									"other_doctypes": other_doctypes,
									"match_fields": match_fields
							},

							async: true,
							callback:function(r)	{

														let data = '';

														let t = document.getElementById('event_grid');

														for (var j = 1; j < t.rows[0].cells.length; j++)
														{
															let s = t.rows[0].cells[j].outerText.trim();
															s = silicon_ioi.doctype.ioiDocType.csv_format_element(s, filetype);

															data += s;

															if (j < t.rows[0].cells.length)
															{   data += ';';
															}
														}

														if (filetype.toUpperCase() == 'XLSX')
														{	data += '~';
														}else
														{	data += '\n';
														}



														for (var i = 0; i < r.message.length; i++)
														{


															if (r.message[i].starts_on != null)
															{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].starts_on.substring(0, 16), filetype) + ';';
															}else
															{	data += ';';
															}


															if (r.message[i].ends_on != null)
															{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].ends_on.substring(0, 16), filetype) + ';';
															}else
															{	data += ';';
															}

															if (r.message[i].subject != null)
															{
																let s = r.message[i].subject;

																if (r.message[i].reference_doctype != cur_frm.doctype)
																{
																	if (r.message[i].reference_docname != null)
																	{	s += ' ' + r.message[i].reference_docname;
																	}
																}

																data += silicon_ioi.doctype.ioiDocType.csv_format_element(s, filetype) + ';';

															}else
															{	data += ';';
															}

															if (r.message[i].status != null)
															{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].status, filetype) + ';';
															}else
															{	data += ';';
															}

															data += ';';


															if (r.message[i].ioi_category_id != null)
															{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].ioi_category_id, filetype) + ';';
															}else
															{	data += ';';
															}

															if (r.message[i].event_type != null)
															{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].event_type, filetype) + ';';
															}else
															{	data += ';';
															}


															if (filetype.toUpperCase() == 'XLSX')
															{	data += '~';
															}else
															{	data += '\n';
															}
														}


														let path_common = 'silicon_ioi.common.common';

														let url = '';

														if (filetype.toUpperCase() == 'XLSX')
														{	url = "/api/method/" + path_common + ".export_xlsx" + frappe.utils.make_query_string({"data":data, "filename":filename}, false);
															window.open(url);
														}else
														{ 	silicon_ioi.ioiCommon.export_to_csv_raw(data);
														}

													}
			});
		}else
		{
			silicon_ioi.ioiCommon.export_to_csv(document.getElementById('event_grid'), document.getElementById('event_grid_detail'));
		}
	}

	export_event_data()
	{
		silicon_ioi.ioiCommon.export_form(silicon_ioi.doctype.ioiDocType.export_event);
	}


	// static export_todo()
	// {
	// 	let filename = document.getElementById('export_filename').value;
	// 	let filetype = document.getElementById('export_file_type').value;
	// 	let inhibit_nb_records = 0;

	// 	if (document.getElementById('export_inhibit_topn').checked)
	// 	{	inhibit_nb_records = 1;
	// 	}

	// 	let go_to_server  = false;

	// 	if (inhibit_nb_records == 1)
	// 	{	go_to_server = true
	// 	}else
	// 	{
	// 		if (filetype.toUpperCase() == 'XLSX')
	// 		{	go_to_server = true;
	// 		}
	// 	}

	// 	if (filename.trim() == '')
	// 	{
	// 		filename = 'todo';
	// 	}

	// 	if (filetype.toUpperCase() == 'XLSX')
	// 	{
	// 		if (filename.toLowerCase().indexOf('.xls') == -1)
	// 		{
	// 			filename += '.xlsx';
	// 		}
	// 	}else
	// 	{
	// 		if (filename.toLowerCase().indexOf('.csv') == -1)
	// 		{
	// 			filename += '.csv';
	// 		}
	// 	}



	// 	if (go_to_server)
	// 	{
	// 		let topn = document.getElementById('todo_topn').value;

	// 		if (inhibit_nb_records == 1)
	// 		{	topn = -1;
	// 		}

	// 		let path_event_todo_file = 'silicon_ioi.ioi_crm.event_todo_file';
	// 		let method = path_event_todo_file + '.module_todo_get_data';

	// 		let other_doctypes = '';
	// 		let match_fields = '';

	// 		if (document.getElementById("todo_nb_document"))
	// 		{
	// 			for (var i = 0; i < document.getElementById("todo_nb_document").value; i++)
	// 			{
	// 				if (document.getElementById("todo_document_" + i.toString()))
	// 				{
	// 					if (document.getElementById("todo_document_" + i.toString()).checked)
	// 					{
	// 						other_doctypes += document.getElementById("todo_document_" + i.toString()).value + ';'

	// 						if (cur_frm.doctype.toUpperCase() == 'IOI CUSTOMER')
	// 						{
	// 							if ((document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI SALES QUOTE') ||
	// 								(document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI SALES ORDER') ||
	// 								(document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI SALES DELIVERY') ||
	// 								(document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI SALES INVOICE'))
	// 							{
	// 								match_fields += 'order_customer_id,delivery_customer_id,invoice_customer_id;'
	// 							}
	// 						}else if (cur_frm.doctype.toUpperCase() == 'IOI SUPPLIER')
	// 						{
	// 							if ((document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
	// 								(document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES ORDER') ||
	// 								(document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
	// 								(document.getElementById("todo_document_" + i.toString()).value.toUpperCase() == 'IOI PURCHASES INVOICE'))
	// 							{
	// 								match_fields += 'order_supplier_id,delivery_supplier_id,invoice_supplier_id;'
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}


	// 		frappe.call({  	method: method,
	// 						args: {	"doctype": cur_frm.doctype,
	// 								"name" : cur_frm.doc.name,
	// 								"search": document.getElementById('todo_search').value,
	// 								"dt_from": document.getElementById('todo_dt_from').value,
	// 								"dt_to": document.getElementById('todo_dt_to').value,
	// 								"status": document.getElementById('todo_status').value,
	// 								"priority": document.getElementById('todo_priority').value,
	// 								"topn" : topn,
	// 								"orderby": document.getElementById('todo_orderby').value,
	// 								"order": document.getElementById('todo_order').value,
	// 								"other_doctypes": other_doctypes,
	// 								"match_fields": match_fields
	// 						},

	// 						async: true,
	// 						callback:function(r)	{
	// 													let data = '';

	// 													let t = document.getElementById('todo_grid');

	// 													for (var j = 1; j < t.rows[0].cells.length; j++)
	// 													{
	// 														let s = t.rows[0].cells[j].outerText.trim();
	// 														s = silicon_ioi.doctype.ioiDocType.csv_format_element(s, filetype);

	// 														data += s;

	// 														if (j < t.rows[0].cells.length)
	// 														{   data += ';';
	// 														}
	// 													}

	// 													if (filetype.toUpperCase() == 'XLSX')
	// 													{	data += '~';
	// 													}else
	// 													{	data += '\n';
	// 													}



	// 													for (var i = 0; i < r.message.length; i++)
	// 													{
	// 														if (r.message[i].date != null)
	// 														{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].date, filetype) + ';';
	// 														}else
	// 														{	data += ';';
	// 														}

	// 														if (r.message[i].status != null)
	// 														{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].status, filetype) + ';';
	// 														}else
	// 														{	data += ';';
	// 														}

	// 														data += ';';

	// 														if (r.message[i].description != null)
	// 														{
	// 															let s = r.message[i].description;


	// 															if (r.message[i].reference_type != cur_frm.doctype)
	// 															{
	// 																if (r.message[i].reference_name != null)
	// 																{
	// 																	s += '  ' + r.message[i].reference_name
	// 																}
	// 															}

	// 															let newDiv = document.createElement("div");
	// 															newDiv.innerHTML = s;

	// 															data += silicon_ioi.doctype.ioiDocType.csv_format_element(newDiv.innerText, filetype) + ';';
	// 															newDiv.remove();
	// 														}else
	// 														{	data += ';';
	// 														}

	// 														if (r.message[i].priority != null)
	// 														{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].priority, filetype) + ';';
	// 														}else
	// 														{	data += ';';
	// 														}

	// 														if (r.message[i].allocated_to != null)
	// 														{	data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].allocated_to, filetype) + ';';
	// 														}else
	// 														{	data += ';';
	// 														}


	// 														if (filetype.toUpperCase() == 'XLSX')
	// 														{	data += '~';
	// 														}else
	// 														{	data += '\n';
	// 														}
	// 													}


	// 													let path_common = 'silicon_ioi.common.common';

	// 													let url = '';

	// 													if (filetype.toUpperCase() == 'XLSX')
	// 													{	url = "/api/method/" + path_common + ".export_xlsx" + frappe.utils.make_query_string({"data":data, "filename":filename}, false);
	// 														window.open(url);
	// 													}else
	// 													{ 	silicon_ioi.ioiCommon.export_to_csv_raw(data);
	// 													}

	// 												}
	// 		});
	// 	}else
	// 	{
	// 		silicon_ioi.ioiCommon.export_to_csv(document.getElementById('todo_grid'), document.getElementById('todo_grid_detail'));
	// 	}
	// }

	// export_todo_data()
	// {
	// 	silicon_ioi.ioiCommon.export_form(silicon_ioi.doctype.ioiDocType.export_todo);
	// }


	static export_file()
	{
		let filename = document.getElementById('export_filename').value;
		let filetype = document.getElementById('export_file_type').value;
		let inhibit_nb_records = 0;

		if (document.getElementById('export_inhibit_topn').checked)
		{	inhibit_nb_records = 1;
		}

		let go_to_server  = false;

		if (inhibit_nb_records == 1)
		{	go_to_server = true
		}else
		{
			if (filetype.toUpperCase() == 'XLSX')
			{	go_to_server = true;
			}
		}

		if (filename.trim() == '')
		{
			filename = 'events';
		}

		if (filetype.toUpperCase() == 'XLSX')
		{
			if (filename.toLowerCase().indexOf('.xls') == -1)
			{
				filename += '.xlsx';
			}
		}else
		{
			if (filename.toLowerCase().indexOf('.csv') == -1)
			{
				filename += '.csv';
			}
		}



		if (go_to_server)
		{
			let topn = document.getElementById('file_topn').value;

			if (inhibit_nb_records == 1)
			{	topn = -1;
			}

			let path_event_todo_file = 'silicon_ioi.ioi_crm.event_todo_file';
			let method = path_event_todo_file + '.module_files_get_data';


			frappe.call({  	method: method,
							args: {	"doctype": cur_frm.doctype,
									"name" : cur_frm.doc.name,
									"search": document.getElementById('file_search').value,
									"tp": document.getElementById('file_tp').value,
									"topn" : topn,
									"orderby": document.getElementById('file_orderby').value,
									"order": document.getElementById('file_order').value
							},
							async: true,
							callback:function(r)	{

														let data = '';

														let t = document.getElementById('file_grid');

														for (var j = 1; j < t.rows[0].cells.length; j++)
														{
															let s = t.rows[0].cells[j].outerText.trim();
															s = silicon_ioi.doctype.ioiDocType.csv_format_element(s, filetype);

															data += s;

															if (j < t.rows[0].cells.length)
															{   data += ';';
															}
														}

														if (filetype.toUpperCase() == 'XLSX')
														{	data += '~';
														}else
														{	data += '\n';
														}


														for (var i = 0; i < r.message.length; i++)
														{
															if (r.message[i].file_name != null)
															{
																data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].file_name, filetype) + ';';
															}else
															{	data += ';';
															}

															if (r.message[i].is_private == 1)
															{
																data += __("Private") + ';';
															}else
															{	data += __("Public") + ';';
															}

															if (r.message[i].file_url != null)
															{
																let p = r.message[i].file_url;
																p = p.substring(0, p.indexOf(r.message[i].file_name))
																data += p + ';' ; // silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].file_url, filetype) + ';';
															}else
															{	data += ';';
															}


															if (r.message[i].user_tags != null)
															{
																data += silicon_ioi.doctype.ioiDocType.csv_format_element(r.message[i].user_tags, filetype) + ';';
															}else
															{	data += ';';
															}



															if (filetype.toUpperCase() == 'XLSX')
															{	data += '~';
															}else
															{	data += '\n';
															}
														}


														let path_common = 'silicon_ioi.common.common';

														let url = '';

														if (filetype.toUpperCase() == 'XLSX')
														{	url = "/api/method/" + path_common + ".export_xlsx" + frappe.utils.make_query_string({"data":data, "filename":filename}, false);
															window.open(url);
														}else
														{ 	silicon_ioi.ioiCommon.export_to_csv_raw(data);
														}

													}
			});
		}else
		{
			silicon_ioi.ioiCommon.export_to_csv(document.getElementById('file_grid'), document.getElementById('file_grid_detail'), 1);
		}
	}

	export_file_data()
	{
		silicon_ioi.ioiCommon.export_form(silicon_ioi.doctype.ioiDocType.export_file);
	}


	static csv_format_element(element, tp)
	{
		let s = '';

		if (element != null)
		{	s = element;
		}

		if (tp.toUpperCase() == 'CSV')
		{
			s = s.replaceAll('•', '-');
			s = s.replaceAll('↑', '');
			s = s.replaceAll('↓', '');
		}

		s = s.replaceAll('/', ' ');
		s = s.replaceAll('\\', ' ');
		s = s.replaceAll(',', ' ');
		s = s.replaceAll(';', ' ');
		s = s.replaceAll('\n', ' ');
		s = s.replaceAll('~', ' ')

		return s;
	}

	// Generic function to export from tabulator
	export_data(table, default_filename) {
		let export_tabulator = () => {
			let filename = document.getElementById('export_filename').value;
			let filetype = document.getElementById('export_file_type').value;

			if (filename.trim() == '') {
				filename = default_filename;
			}

			if (filetype.toUpperCase() == 'XLSX') {
				table.download("xlsx", `${filename}.xlsx`);
			} else {
				table.download("csv", `${filename}.csv`, { delimiter: ";" })
			}
		}

		silicon_ioi.ioiCommon.export_form(export_tabulator, true);
	}

	// ***************************************************************************************************************************************
	// Todo : Build html page
	// ***************************************************************************************************************************************
	async todo_build_page()
	{
		if (document.getElementById("todo_search"))
		{	document.getElementById("todo_search").remove();
		}

		if (document.getElementById("todo_dt_from"))
		{	document.getElementById("todo_dt_from").remove();
		}

		if (document.getElementById("todo_dt_to"))
		{	document.getElementById("todo_dt_to").remove();
		}

		if (document.getElementById("todo_status"))
		{	document.getElementById("todo_status").remove();
		}

		if (document.getElementById("todo_only_open"))
		{	document.getElementById("todo_only_open").remove();
		}

		if (document.getElementById("todo_only_mine"))
		{	document.getElementById("todo_only_mine").remove();
		}

		if (document.getElementById("todo_ioi_category"))
		{	document.getElementById("todo_ioi_category").remove();
		}

		if (document.getElementById("todo_nb_document"))
		{
			for (var i = 0; i < document.getElementById("todo_nb_document").value; i++)
			{
				if (document.getElementById("todo_document_" + i.toString()))
				{
					document.getElementById("todo_document_" + i.toString()).remove();
				}
			}

			document.getElementById("todo_nb_document").remove();
		}

		if (document.getElementById("todo_topn"))
		{	document.getElementById("todo_topn").remove();
		}
		if (document.getElementById("todo_orderby"))
		{	document.getElementById("todo_orderby").remove();
		}
		if (document.getElementById("todo_order"))
		{	document.getElementById("todo_order").remove();
		}

		if (document.getElementById('todo_bt_search'))
		{	document.getElementById('todo_bt_search').remove();
		}

		if (document.getElementById('todo_bt_clear'))
		{	document.getElementById('todo_bt_clear').remove();
		}

		if (document.getElementById('todo_bt_export'))
		{	document.getElementById('todo_bt_export').remove();
		}

		if (document.getElementById('todo_grid'))
		{	document.getElementById('todo_grid').remove();
		}

		if (document.getElementById('todo_bt_new'))
		{	document.getElementById('todo_bt_new').remove();
		}

		if (document.getElementById('todo_bt_delete'))
		{	document.getElementById('todo_bt_delete').remove();
		}

		this.event_categories = await frappe.call('silicon_ioi.utils.lib.system.get_categories', { doctype: this.frm.doctype }).then(r => [''].concat(r.message))

		this.allocated_users = await frappe.call('silicon_ioi.utils.lib.system.get_allocated_users').then(r => [''].concat(r.message))

		let html = '';

		html += '<div style="overflow-x: auto; height:135px; width:100%;">';

		// To do Search
		html += '	<div style="position: relative; top: 0px; left: 0px; width:170px;">';
		html += '		<label id="todo_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 25px;"> ';
		html += '			<input id="todo_search" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		// From
		html += '<div style="position: relative; top: 0px; left: 180px; width:170px;">';
		html += '	<label id="todo_dt_from_label" style="position: absolute; top: 0px; left: 2px;">' + __("From") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 170px; height: 30px;"> ';
		html += '		<input id="todo_dt_from" type="date" class="input-with-feedback form-control bold">';
		html += '	</div>';
		html += '</div>';

		// To
		html += '<div style="position: relative; top: 0px; left: 360px; width:170px;">';
		html += '	<label id="todo_dt_to_label" style="position: absolute; top: 0px; left: 2px;">' + __("To") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<input id="todo_dt_to" type="date" class="input-with-feedback form-control bold" pattern="\y{4}-\m{2}-\d{2}">';
		html += '	</div>';
		html += '</div>';

		// Status
		html += '<div style="position: relative; top: 0px; left: 540px; width:100px;">';
		html += '	<label id="todo_status_label" style="position: absolute; top: 0px; left: 2px;">' + __("Status") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 100px; height: 30px;"> ';
		html += '		<select id="todo_status" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '			<option value="' + __("Open") + '">' + __("Open") + '</option> ';
		html += '			<option value="' + __("Closed") + '">' + __("Closed") + '</option> ';
		html += '			<option value="' + __("Cancelled") + '">' + __("Cancelled") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Documents
		if ((this.frm.doctype.toUpperCase() == 'IOI CUSTOMER') || (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER'))
		{
			html += '<div style="position: relative; top: 0px; left: 650px; width:300px;">';
			html += '	<label id="todo_document_label" style="position: absolute; top: 0px; left: 2px;">' + __("Documents") + '</label>';
			html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 300px; height: 100px; background:#F4F5F6; border-radius:6px; padding: 4px; overflow: auto; overflow-x: auto;"> ';

			if (this.frm.doctype.toUpperCase() == 'IOI CUSTOMER')
			{	html += '		<input type="hidden" id="todo_nb_document" value="4">';
				html += '		<input type="checkbox" id="todo_document_0" style="position: relative; top: 2px; left: 2px" value="ioi Sales Quote" checked><label style="position: absolute; top: 4px; left: 30px;">'      + __("Sales Quote") + '</label>';
				html += '		<input type="checkbox" id="todo_document_1" style="position: absolute; top: 26px; left: 6px" value="ioi Sales Order" checked><label style="position: absolute; top: 24px; left: 30px;">'    + __("Sales Order") + '</label>';
				html += '		<input type="checkbox" id="todo_document_2" style="position: absolute; top: 46px; left: 6px" value="ioi Sales Delivery" checked><label style="position: absolute; top: 44px; left: 30px;">' + __("Sales Delivery") + '</label>';
				html += '		<input type="checkbox" id="todo_document_3" style="position: absolute; top: 66px; left: 6px" value="ioi Sales Invoice" checked><label style="position: absolute; top: 64px; left: 30px;">'  + __("Sales Invoice") + '</label>';
			}else if (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER')
			{	html += '		<input type="hidden" id="todo_nb_document" value="4">';
				html += '		<input type="checkbox" id="todo_document_0" style="position: relative; top: 2px; left: 2px" value="ioi Purchases Price Request" checked><label style="position: absolute; top: 4px; left: 30px;">' + __("Purchases Price Request") + '</label>';
				html += '		<input type="checkbox" id="todo_document_1" style="position: absolute; top: 26px; left: 6px" value="ioi Purchases Order" checked><label style="position: absolute; top: 24px; left: 30px;">'    	+ __("Purchases Order") + '</label>';
				html += '		<input type="checkbox" id="todo_document_2" style="position: absolute; top: 46px; left: 6px" value="ioi Purchases Receipt" checked><label style="position: absolute; top: 44px; left: 30px;">' 	+ __("Purchases Receipt") + '</label>';
				html += '		<input type="checkbox" id="todo_document_3" style="position: absolute; top: 66px; left: 6px" value="ioi Purchases Invoice" checked><label style="position: absolute; top: 64px; left: 30px;">'  	+ __("Purchases Invoice") + '</label>';
			}
			html += '	</div>';
			html += '</div>';
		}


		// ioi Category
		html += '<div style="position: relative; top: 65px; left: 180px; width:170px;">';
		html += '	<label id="todo_ioi_category_label" style="position: absolute; top: 0px; left: 2px;">' + __("ioi Category") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 170px; height: 30px;"> ';
		html += '		<select id="todo_ioi_category" class="input-with-feedback form-control bold"> ';
		html += 			this.event_categories.map(el => `<option value="${el}">${el}</option>`);
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';


		// Topn
		html += '	<div style="position: relative; top: 65px; left: 540px; width:100px;">';
		html += '		<label id="todo_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width:100px; height: 30px;"> ';
		html += '			<input id="todo_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: absolute; top: 65px; left: 0px; width:150px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 28px">';
		html +='			<button id="todo_bt_search" title="' + __("Refresh") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 110px;" onclick="">' + __("Refresh") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '<div style="position: absolute; top: 65px; left: 120px; width:50px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 28px;">';
		html +='		<button id="todo_bt_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Export
		html += '<div style="position: absolute; top: 98px; left: 0px; width:170px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 28px;">';
		html +='		<button id="todo_bt_export" title="' + __("Export") +'" data-label="Export" class="btn btn-default ellipsis" style="height: 28px; width: 170px;" onclick="">' + __("Export") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '<input id="todo_orderby" type="hidden" value="date">';
		html += '<input id="todo_order" type="hidden" value="desc">';

		html += '</div>';

		let me = this;
		html += '<div id="todo_actions" class="d-flex flex-row mt-3">';

		html += '	<div id="todo_bt_new" class="mr-2">';
		html += `		<button data-label="Add todo" class="btn btn-default ellipsis" onclick="
							if (cur_frm.is_new()) {
									frappe.msgprint({title: __('Message'), message: __('Save or cancel data before'), indicator: 'red'});
									raise;
								}

							// filter ioi Category with Reference Doctype
							frappe.new_doc('ToDo', { reference_type: '${me.frm.doctype}', reference_name: '${me.frm.doc.name}'}, doc => {
								doc.fields_dict.ioi_category_id.get_query = function() {
									return {
										filters: {
											related_doctype: '${me.frm.doctype}'
										}
									};
								};
							})
						">`;
		html += '		'+__('Add')+'</button>';
		html += '	</div>';

		html += '	<div id="todo_bt_delete" class="mr-2">';
		html += '		<button data-label="Delete" class="btn btn-default ellipsis">Delete</button>';
		html += '	</div>';

		html += 	`<div id="todo_bt_duplicate" class="mr-2">
						<button title="${__("Duplicate ToDo")}" data-label="Duplicate todo" class="btn btn-default ellipsis">
							${__('Duplicate')}
						</button>
					</div>`

		html += '	<div class="d-flex flex-row align-items-center ml-3 mt-1">'

		// Only Open
		html += '		<div>';
		html += '			<div class="form-check form-check-inline">'
		html += '				<input class="form-check-input" type="checkbox" id="todo_only_open" value="only_open" checked>'
		html += `				<label class="form-check-label" for="todo_only_open">${__('Only open')}</label>`
		html += '			</div>'
		html += '		</div>';

		// Only Mine
		html += '		<div>';
		html += '			<div class="form-check form-check-inline">'
		html += '				<input class="form-check-input" type="checkbox" id="todo_only_mine" value="only_mine" checked>'
		html += `				<label class="form-check-label" for="todo_only_mine">${__('Only mine')}</label>`
		html += '			</div>'
		html += '		</div>';

		html += '	</div>'
		html += '</div>';

		// Grid
		html += '<div id="todo_grid" class="table table-bordered" data-custom-grid="true"></div>';

		this.frm.fields_dict['html_module_todo'].$wrapper.empty();
		this.frm.fields_dict['html_module_todo'].$wrapper.append(html);

		this.#todo_grid([])

		let fct_search = function() { me.#todo_search(me.todo_table); };
		let fct_clear = function() { me.#todo_clear(me.todo_table); };
		// let fct_export = function() { me.export_todo_data(); };
		let fct_export = function() { me.export_data(me.todo_table, 'todo'); };

		document.getElementById('todo_search').onkeydown = this.#todo_search_key_down;

		document.getElementById('todo_topn').onkeydown = this.#todo_search_key_down;
		document.getElementById('todo_topn').onchange = this.#todo_search_on_change;

		document.getElementById('todo_dt_from').onkeydown = this.#todo_search_key_down;
		document.getElementById('todo_dt_from').onchange = this.#todo_search_on_change;

		document.getElementById('todo_dt_to').onkeydown = this.#todo_search_key_down;
		document.getElementById('todo_dt_to').onchange = this.#todo_search_on_change;

		document.getElementById('todo_status').onchange = this.#todo_search_on_change;
		document.getElementById('todo_ioi_category').onchange = this.#todo_search_on_change;

		document.getElementById("todo_only_open").onclick = this.#todo_search_on_change;
		document.getElementById("todo_only_mine").onclick = this.#todo_search_on_change;

		if (document.getElementById("todo_nb_document"))
		{
			for (var i = 0; i < document.getElementById("todo_nb_document").value; i++)
			{
				if (document.getElementById("todo_document_" + i.toString()))
				{
					document.getElementById("todo_document_" + i.toString()).onclick = this.#todo_search_on_change;
				}
			}
		}

		document.getElementById('todo_bt_search').onclick = fct_search;
		document.getElementById('todo_bt_clear').onclick = fct_clear;
		document.getElementById('todo_bt_export').onclick = fct_export;

		let fct_delete_click = function ()	{ me.#todo_delete(me.todo_table); };
		document.getElementById('todo_bt_delete').onclick = fct_delete_click;

		$("#todo_bt_duplicate")[0].onclick = () => this.todo_duplicate(this.todo_table)

		document.getElementById('todo_bt_search').click();
	}


	// ***************************************************************************************************************************************
	// To do Grid
	// ***************************************************************************************************************************************
	#todo_grid(data) {
		let me = this

		let format_due_date = (cell) => {
			let date = cell.getValue()
			let status = cell.getData().status

			if (date) {
				let today = frappe.datetime.get_today()
				let container = frappe.ui.form.make_control({
					parent: cell.getElement(),
					df: {
						fieldname: 'date',
						fieldtype: 'Date'
					},
					render_input: true
				})

				container.input.value = cell.getValue()
				container.input.classList.add('w-100', 'm-2')

				if (today > date && (status && status == __('Open'))) {
					container.input.classList.add('bg-danger')
				} else if (today == date && (status && status == __('Open'))) {
					container.input.classList.add('bg-warning')
				} else if (today < date && (status && status == __('Open'))) {
					container.input.classList.add('bg-success')
				}

				return container.input
			}
		}

		let format_status = (cell) => {
			if (cell.getValue()) {
				return `<div class="d-flex flex-row align-items-center">
							<div class="mr-2" style="width: 15px; height: 15px; border-radius: 50%; background:${cell.getValue().toUpperCase() == __("OPEN") ? "#E7A4A4" : "#9ABBA7"}"></div>
							${cell.getValue()}
						</div>`
			}
		}

		let format_action = (cell) => {
			const row = cell.getRow()
			const index = row.getPosition() - 1

			const build_buttons = () => {
				if (cell.getData().status.toLowerCase() === "open") {
					return `<div class="d-flex flex-column w-100">
								<button id="todo_status_bt_close_${index}" type="button" class="btn btn-xs btn-secondary m-2 mt-3 w-100" style="max-width: 85px">${__('Close')}</button>
								<button id="todo_status_bt_cancel_${index}" type="button" class="btn btn-xs btn-secondary m-2 mb-3 w-100" style="max-width: 85px">${__('Cancel')}</button>
							</div>`
				} else {
					return `<div class="d-flex flex-column w-100">
								<button id="todo_status_bt_reopen_${index}" type="button" class="btn btn-xs btn-secondary m-2 mb-3 w-100" style="max-width: 85px">${__('Reopen')}</button>
							</div>`
				}
			}

			const build_empty = () => `<div class="w-100"></div>`

			if (frappe.session.user.toUpperCase() == 'ADMINISTRATOR') {
				return build_buttons()
			} else {
				const allocated_to = row.getData().allocated_to

				if (allocated_to && allocated_to !== "") {
					if ((frappe.session.user.toUpperCase() == allocated_to.toUpperCase()) || (frappe.session.user_email.toUpperCase() == allocated_to.toUpperCase())) {
						return build_buttons()
					} else {
						return build_empty()
					}
				} else {
					return build_empty()
				}
			}
		}

		let action_click = (e, cell) => {
			const index = cell.getRow().getPosition() - 1

			if (e.target.id == `todo_status_bt_close_${index}`) {
				this.todo_close(cell.getData().name, this.todo_table)
			} else if (e.target.id == `todo_status_bt_cancel_${index}`) {
				this.todo_cancel(cell.getData().name, this.todo_table)
			} else if (e.target.id == `todo_status_bt_reopen_${index}`) {
				this.todo_reopen(cell.getData().name, this.todo_table)
			}
		}

		let format_link = (cell, formatterParams) => {
			if (cell.getValue() != null && cell.getValue() != "") {
				const data = cell.getData()
				const index = cell.getRow().getPosition() - 1

				let s = data.reference_type;
				s = s.replace('ioi ', '');

				const div = document.createElement('div')
				div.innerHTML = cell.getValue()
				div.firstElementChild ? div.firstElementChild.style.pointerEvents = "none" : ''

				let html = `<div class="d-flex flex-row align-items-center w-100 h-100 position-relative" style="overflow: auto;">
								<div id="todo_edit_${index}" style="position: absolute; top: 0; right: 0">
									<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-pencil-square" style="pointer-events: none;" viewBox="0 0 16 16">
										<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
										<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
									</svg>
								</div>
								${data.color ? `<div class="mr-2" style="width: 15px; min-width: 15px; height: 15px; border-radius: 50%; background:${data.color}"></div>` : ''}
								<div class="d-flex flex-column">
									<a id="todo_id_${index}" onmouseover="this.style.textDecoration = 'underline';" onmouseout="this.style.textDecoration = 'none';">${div.innerHTML}</a>
									${data.reference_type != me.frm.doctype && data.reference_name ? `
										<div>
											<u>${s}</u>&nbsp;:&nbsp;
											<a id="todo_jump_id_${index}" onmouseover="this.style.textDecoration ='underline';" onmouseout="this.style.textDecoration = 'none';">${data.reference_name}</a>
										</div>`
									: ''}
								</div>
							</div>`

				return html
			}
		}

		let link_onclick = (e, cell) => {
			const value = cell.getValue()
			const row = cell.getRow()
			const index = row.getPosition() - 1

			if (value && value !== "") {
				if (cell.getColumn().getField() == "description") {
					if (e.target.id == `todo_id_${index}`) {
						this.open_todo(row.getData().name)
					} else if (e.target.id == `todo_jump_id_${index}`) {
						this.open_link(row.getData().reference_type, row.getData().reference_name)
					} else if (e.target.id == `todo_edit_${index}`) {
						edit_todo_description(e, cell, index)
					}
				}
			}
		}

		let edit_todo_description = (e, cell, index) => {
			const desc_link = document.getElementById(`todo_id_${index}`)

			let d = new frappe.ui.Dialog({
				title: __('Update Description'),
				fields: [
					{
						fieldname: 'description',
						fieldtype: 'Text Editor',
						default: desc_link.innerHTML
					},
				],
				size: 'small',
				primary_action_label: __('Submit'),
				primary_action(values) {
					desc_link.innerHTML = values.description
					desc_link.firstElementChild ? desc_link.firstElementChild.style.pointerEvents = "none" : ''

					desc_link.classList.add('desc_updated')

					d.hide();
				}
			});

			d.show();
		}

		let format_ioi_category = (cell) => {
			let options = this.event_categories

			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'ioi_category',
					fieldtype: 'Select',
					options: options,
				},
				render_input: true
			})

			container.wrapper.querySelector('select').value = cell.getValue()
			container.wrapper.classList.add('w-100', 'm-2')

			return container.wrapper
		}

		let format_allocated = (cell) => {
			let container = frappe.ui.form.make_control({
				parent: cell.getElement(),
				df: {
					fieldname: 'allocated_to',
					fieldtype: 'Select',
					options: this.allocated_users.map(allocated_user => allocated_user),
				},
				render_input: true
			})

			if (cell.getValue()) {
				container.wrapper.querySelector('select').value = cell.getValue()
			}
			container.wrapper.classList.add('w-100', 'm-2')

			return container.wrapper
		}

		let save_changes_onclick = (e, cell) => {
			if (e.target.id.includes("btn_save_changes")) {
				let data = cell.getData()

				let updated_allocated = cell.getData().allocated_to && cell.getRow().getElement().querySelector('[data-fieldname="allocated_to"] select')
				let updated_ioi_category = cell.getRow().getElement().querySelector('[data-fieldname="ioi_category"] select')
				let updated_date = cell.getData().date && cell.getRow().getElement().querySelector('[data-fieldname="date"]')
				let updated_description = cell.getRow().getElement().querySelector(`#todo_id_${cell.getRow().getPosition() - 1}`)

				if (updated_date && (data.date != updated_date.value)) {
					this.todo_update_datas(cell.getData().name, "date", updated_date.value, this.todo_table)
				}
				if (data.ioi_category_id != updated_ioi_category.value) {
					this.todo_update_datas(cell.getData().name, "ioi_category_id", updated_ioi_category.value, this.todo_table)
				}
				if (updated_allocated && (data.allocated_to != updated_allocated.value)) {
					this.todo_update_datas(cell.getData().name, "allocated_to", updated_allocated.value, this.todo_table)
				}
				if (updated_description && updated_description.classList.contains('desc_updated')) {
					this.todo_update_datas(cell.getData().name, "description", updated_description.innerHTML, this.todo_table)
				}
			}
		}

		let format_save_changes = () => {
			return `<div class="d-flex justify-content-center align-items-center w-100 h-100">
						<button id="btn_save_changes" type="button" class="btn btn-primary btn-xs">
							${__('Save')}
						</button>
					</div>`
		}

		let format_null_accessor = (value, data, type, params, column) => {
			if (value) {
				return value
			} else {
				return ""
			}
		}

		let format_description_accessor = (value, data, type, params, column) => {
			if (value) {
				if (data.reference_type != cur_frm.doctype) {
					if (data.reference_name != null) value += '  ' + data.reference_name
				}
				return value
			} else {
				return ""
			}
		}

		this.todo_table = new ioi.Tabulator("#todo_grid", {
			maxHeight: 600,
			rowHeight: 110,
			data: data,
			layout: "fitColumns",
			movableColumns: true,
			wrapLines: false,
			resizableColumns: true,
			showProfiles: true,
			autoRedraw: true,
			columnDefaults:{
				minWidth: 100,
				vertAlign: "middle",
			},
			initialSort:[
				{column:"date", dir:"desc"},
			],
			rowFormatter: (row) => {
				row.getElement().id = `todo_name_${row.getPosition() - 1 }`
			},
			columns: [
				{ title: "", field: "checkbox", formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort: false, download: false, minWidth: 40, maxWidth: 40 },
				{ title: __("Due Date"), field: "date", width: 125, formatter: format_due_date, accessorDownload: format_null_accessor },
				{ title: __("Status"), field: "status", formatter: format_status, accessorDownload: format_null_accessor },
				{ title: __("Action"), field: "action", width: 110, headerSort: false, download: false, resizable: false, formatter: format_action, cellClick: (e, cell) => action_click(e, cell) },
				{ title: __("Description"), field: "description", formatter: format_link, cellClick: (e, cell) => link_onclick(e, cell), accessorDownload: format_description_accessor },
				{ title: __("ioi Category"), field: "ioi_category_id", width: 150, formatter: format_ioi_category, accessorDownload: format_null_accessor },
				{ title: __("Allocated to"), field: "allocated_to", formatter: format_allocated, accessorDownload: format_null_accessor },
				{ title: "", field: "save_changes", width: 110, formatter: format_save_changes, cellClick: (e, cell) => save_changes_onclick(e, cell), hozAlign: "center", headerSort: false, download: false }
			]
		});

		let columns_not_to_order = ['checkbox', 'action', 'header_menu']

		this.todo_table.on("headerClick", (e, column) => {
			let ordered_field = document.getElementById('todo_orderby')
			ordered_field.value = column.getField()

			let ordered_dir = document.getElementById('todo_order')
			ordered_dir.value = me.todo_table.getSorters()[0].dir

			let field = column.getField()

			if (!columns_not_to_order.includes(field)) {
				me.#todo_col_click(column.getField(), me.todo_table)
			}
		});
	}

	// ***************************************************************************************************************************************
	// To do : Empty table row
	// ***************************************************************************************************************************************
	#todo_empty_table_row(table) {
		if (table && table.initialized) table.clearData()
	}

	// ***************************************************************************************************************************************
	// To do : clear params & list
	// ***************************************************************************************************************************************
	#todo_clear(table) {
		if (cur_frm.doctype) {

			let dt = cur_frm.doctype;
			dt = dt.toLowerCase();
			dt = dt.replaceAll(' ', '-')

			let dt2 = cur_frm.doctype;
			dt2 = dt2.toLowerCase();
			dt2 = dt2.replaceAll(' ', '_')

			if (document.getElementById(dt + '-' + dt2 + '_tab_todo-tab')) {
				document.getElementById(dt + '-' + dt2 + '_tab_todo-tab').innerText = __("To do")
			}
		}

		const table_name = table && table.element.id === "todo_grid" ? "todo" : "todo_sv"

		document.getElementById(`${table_name}_search`).value = '';
		document.getElementById(`${table_name}_topn`).value = '100';

		document.getElementById(`${table_name}_dt_from`).value = '';
		document.getElementById(`${table_name}_dt_to`).value = '';
		document.getElementById(`${table_name}_status`).selectedIndex = 0;
		document.getElementById(`${table_name}_ioi_category`).selectedIndex = 0;

		if (document.getElementById("todo_nb_document")) {
			for (var i = 0; i < document.getElementById("todo_nb_document").value; i++) {
				if (document.getElementById("todo_document_" + i.toString())) {
					document.getElementById("todo_document_" + i.toString()).checked = true;
				}
			}
		}

		this.#todo_empty_table_row(table);
	}

	// ***************************************************************************************************************************************
	// To do : Search
	// ***************************************************************************************************************************************
	#todo_search(table)
	{
		if (cur_frm.doctype) {

			let dt = cur_frm.doctype;
			dt = dt.toLowerCase();
			dt = dt.replaceAll(' ', '-')

			let dt2 = cur_frm.doctype;
			dt2 = dt2.toLowerCase();
			dt2 = dt2.replaceAll(' ', '_')

			if (document.getElementById(dt + '-' + dt2 + '_tab_todo-tab')) {
				document.getElementById(dt + '-' + dt2 + '_tab_todo-tab').innerText = __("To do")
			}
		}

		if (this.frm.is_new())
		{
			this.#todo_clear(table);
			return false;
		}

		let html = '';
		let me = this;
		let method = this.path_event_todo_file + '.module_todo_get_data';

		let other_doctypes = '';
		let match_fields = '';

		const table_name = table && table.element.id === "todo_sv_grid" ? "todo_sv" : "todo"

		if (document.getElementById(`${table_name}_nb_document`))
		{
			for (var i = 0; i < document.getElementById(`${table_name}_nb_document`).value; i++)
			{
				if (document.getElementById(`${table_name}_document_` + i.toString()))
				{
					if (document.getElementById(`${table_name}_document_` + i.toString()).checked)
					{
						other_doctypes += document.getElementById(`${table_name}_document_` + i.toString()).value + ';'

						if (this.frm.doctype.toUpperCase() == 'IOI CUSTOMER')
						{
							if ((document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES QUOTE') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES ORDER') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES DELIVERY') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI SALES INVOICE'))
							{
								match_fields += 'order_customer_id,delivery_customer_id,invoice_customer_id;'
							}
						}else if (this.frm.doctype.toUpperCase() == 'IOI SUPPLIER')
						{
							if ((document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES PRICE REQUEST') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES ORDER') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES RECEIPT') ||
								(document.getElementById(`${table_name}_document_` + i.toString()).value.toUpperCase() == 'IOI PURCHASES INVOICE'))
							{
								match_fields += 'order_supplier_id,delivery_supplier_id,invoice_supplier_id;'
							}
						}
					}
				}
			}
		}

		frappe.call({
			method: method,
			args: {
				"doctype": this.frm.doctype,
				"name": this.frm.doc.name,
				"search": document.getElementById(`${table_name}_search`).value,
				"dt_from": document.getElementById(`${table_name}_dt_from`).value,
				"dt_to": document.getElementById(`${table_name}_dt_to`).value,
				"status": document.getElementById(`${table_name}_status`).value,
				"ioi_category": document.getElementById(`${table_name}_ioi_category`).value,
				"topn": document.getElementById(`${table_name}_topn`).value,
				"orderby": document.getElementById(`${table_name}_orderby`).value || "date",
				"order": document.getElementById(`${table_name}_order`).value,
				"only_mine": document.getElementById(`${table_name}_only_mine`).checked,
				"only_open": document.getElementById(`${table_name}_only_open`).checked,
				"other_doctypes": other_doctypes,
				"match_fields": match_fields
			},
			async: true,
			callback: function (r) {

				if (r.message.length > 0) {

					if (table.initialized) {
						table.replaceData(r.message)
					} else {
						table.on('tableBuilt', () => table.replaceData(r.message))
					}

					let cpt = 0;
					let cpt_open = 0;

					for (var i = 0; i < r.message.length; i++) {
						if (r.message[i].status.toUpperCase() == __("OPEN")) {
							cpt_open++;
						}
					}

					if (cpt_open != 0) {
						if (cur_frm.doctype) {

							let dt = cur_frm.doctype;
							dt = dt.toLowerCase();
							dt = dt.replaceAll(' ', '-')

							let dt2 = cur_frm.doctype;
							dt2 = dt2.toLowerCase();
							dt2 = dt2.replaceAll(' ', '_')

							if (document.getElementById(dt + '-' + dt2 + '_tab_todo-tab')) {
								document.getElementById(dt + '-' + dt2 + '_tab_todo-tab').innerText = __("To do") + ' (' + cpt_open.toString() + ')';
							}
						}
					}
				} else {
					html += me.#todo_empty_table_row(table);
				}
			}
		})
	}

	todo_close(name, table) {
		let new_status = __("Closed")
		this.todo_change_status(name, new_status, table)
	}

	todo_cancel(name, table) {
		let new_status = __("Cancelled")
		this.todo_change_status(name, new_status, table)
	}

	todo_reopen(name, table) {
		let new_status = __("Open")
		this.todo_change_status(name, new_status, table)
	}

	todo_update_datas(name, fieldname, new_value, table) {
		let me = this;
		let method = this.path_event_todo_file + '.module_todo_update_datas';

		frappe.call({
			method: method,
			args: {
				"name": name,
				"fieldname": fieldname,
				"new_value": new_value
			},
			async: false,
			callback: function (r) {

				if (r.message.error == 1) {
					frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
					raise;
				} else {
					me.#todo_search(table);
				}
			}
		});

	}

	todo_change_status(name, new_status, table) {
		let me = this;
		let method = this.path_event_todo_file + '.module_todo_change_status';

		frappe.call({
			method: method,
			args: {
				"name": name,
				"new_status": new_status
			},
			async: false,
			callback: function (r) {

				if (r.message.error == 1) {
					frappe.msgprint({ title: __("Message"), message: r.message.error_msg, indicator: "red" });
					raise;
				} else {
					me.#todo_search(table);
				}
			}
		});
	}

	todo_duplicate(table) {
		if (cur_frm.is_new()) {
			frappe.msgprint({title: __('Message'), message: __('Save or cancel data before'), indicator: 'red'});
			raise;
		}
		const selected_table = table && table.element.id === "todo_sv_grid" ? this.todo_sv_table : this.todo_table
		let me = this

		let d = new frappe.ui.Dialog({
			title: __('Duplicate ToDo'),
			fields: [
				{
					label: __('Allocated to'),
					fieldname: 'user',
					fieldtype: 'Autocomplete',
					options: this.allocated_users
				},
			],
			size: 'small',
			primary_action_label: __('Duplicate'),
			async primary_action(values) {
				const rows = selected_table.getSelectedRows();

				for (const row of rows) {
					const data = row.getData();

					await frappe.db.insert({
						doctype: 'ToDo',
						status: data.status,
						ioi_category_id: data.ioi_category_id,
						color: data.color,
						date: data.date,
						allocated_to: values.user,
						description: data.description,
						reference_type: me.frm.doctype,
						reference_name: me.frm.doc.name,
						role: data.role,
						assigned_by: data.assigned_by,
						assignment_rule: data.assignment_rule
					})
				}

				me.#todo_search(selected_table);
				d.hide();
			}
		});

		d.show();
	}

	// ***************************************************************************************************************************************
	// To do : key down
	// ***************************************************************************************************************************************
	#todo_search_key_down(event)
	{
		if (event.keyCode == 13)
		{
			document.getElementById('todo_bt_search').click();
		}
	}

	// ***************************************************************************************************************************************
	// To do : change
	// ***************************************************************************************************************************************
	#todo_search_on_change()
	{
		document.getElementById('todo_bt_search').click();
	}


	// ***************************************************************************************************************************************
	// Column header click
	// ***************************************************************************************************************************************
	#todo_col_click(obj, table) {
		const table_name = table && table.element.id === "todo_sv_grid" ? "todo_sv" : "todo"

		if (document.getElementById(`${table_name}_orderby`).value == obj) {
			if (document.getElementById(`${table_name}_order`).value == 'desc') {
				document.getElementById(`${table_name}_order`).value = 'asc';
			} else {
				document.getElementById(`${table_name}_order`).value = 'desc';
			}
		} else {
			document.getElementById(`${table_name}_orderby`).value = obj;
			document.getElementById(`${table_name}_order`).value = 'desc';
		}

		document.getElementById(`${table_name}_bt_search`).click();
	}

	// ***************************************************************************************************************************************
	// Open Todo
	// ***************************************************************************************************************************************
	open_todo(name)
	{
		window.open(this.url_todo + name);
	}

	// ***************************************************************************************************************************************
	// Todo delete
	// ***************************************************************************************************************************************
	#todo_delete(table)
	{
		const table_name = table && table.element.id === "todo_sv_grid" ? "todo_sv" : "todo"
		let selected_rows = table.getSelectedRows()

		if (selected_rows.length <= 1) {
			if (selected_rows.lenght == 0) {
				return false;
			} else {
				if (!document.getElementById(`${table_name}_name_0`)) {
					return false;
				}
			}
		}

		let names = selected_rows.map(row => row.getData().name).join(';')

		if (names.trim() != '') {
			let msg = 'Delete selected todo(s) ?'

			frappe.confirm(msg,
				() => {
					// action to perform if Yes is selected
					document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length - 1].disabled = true;

					let method = this.path_event_todo_file + '.module_todo_delete';

					frappe.call({
						method: method,
						args: { "names": names },
						async: false,
						callback: function (r) {

							document.getElementById(`${table_name}_bt_search`).click();
						}
					});
				},
				() => {
					// action to perform if No is selected or if click outsite the modal screen
				}
			);
		}
	}

	// ***************************************************************************************************************************************
	// Files : Build html page
	// ***************************************************************************************************************************************
	files_build_page()
	{
		if (document.getElementById("file_search"))
		{	document.getElementById("file_search").remove();
		}

		if (document.getElementById("file_tp"))
		{	document.getElementById("file_tp").remove();
		}

		if (document.getElementById("file_topn"))
		{	document.getElementById("file_topn").remove();
		}
		if (document.getElementById("file_orderby"))
		{	document.getElementById("file_orderby").remove();
		}
		if (document.getElementById("file_order"))
		{	document.getElementById("file_order").remove();
		}

		if (document.getElementById('file_bt_search'))
		{	document.getElementById('file_bt_search').remove();
		}

		if (document.getElementById('file_bt_clear'))
		{	document.getElementById('file_bt_clear').remove();
		}

		if (document.getElementById('file_bt_export'))
		{	document.getElementById('file_bt_export').remove();
		}

		if (document.getElementById('file_grid'))
		{	document.getElementById('file_grid').remove();
		}

		if (document.getElementById('file_grid_header'))
		{	document.getElementById('file_grid_header').remove();
		}

		if (document.getElementById('file_grid_detail'))
		{	document.getElementById('file_grid_detail').remove();
		}

		if (document.getElementById('file_bt_new'))
		{	document.getElementById('file_bt_new').remove();
		}

		if (document.getElementById('file_bt_delete'))
		{	document.getElementById('file_bt_delete').remove();
		}



		let html = '';

		html += '<div style="overflow-x: auto; height:105px; width:100%;">';

		// File Search
		html += '	<div style="position: relative; top: 0px; left: 0px; width:340px;">';
		html += '		<label id="file_search_label" style="position: absolute; top: 0px; left: 2px;">' + __("Search") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width: 340px; height: 25px;"> ';
		html += '			<input id="file_search" type="text" class="input-with-feedback form-control bold">';
		html += '		</div>';
		html += '	</div>';

		// Tp
		html += '<div style="position: relative; top: 0px; left: 350px; width:100px;">';
		html += '	<label id="file_tp_label" style="position: absolute; top: 0px; left: 2px;">' + __("Type") + '</label>';
		html += '	<div class="control-input" style="position: absolute; top: 25px; left:2px; width: 100px; height: 30px;"> ';
		html += '		<select id="file_tp" class="input-with-feedback form-control bold"> ';
		html += '			<option value=""></option> ';
		html += '			<option value="0">' + __("Public") + '</option> ';
		html += '			<option value="1">' + __("Private") + '</option> ';
		html += '		</select> ';
		html += '	</div>';
		html += '</div>';

		// Topn
		html += '	<div style="position: relative; top: 0px; left: 460px; width:100px;">';
		html += '		<label id="file_topn_label" style="position: absolute; top: 0px; left: 2px;">' + __("No records") + '</label>';
		html += '		<div class="control-input" style="position: absolute; top: 25px; left: 2px; width:100px; height: 30px;"> ';
		html += '			<input id="file_topn" type="number" class="input-with-feedback form-control bold" min="1" max="1000" value="100">';
		html += '		</div>';
		html += '	</div>';


		// Search
		html += '	<div style="position: absolute; top: 65px; left: 0px; width:150px;">';
		html += '		<div style="position: absolute; top:0px; left: 2px; height: 28px">';
		html +='			<button id="file_bt_search" title="' + __("Refresh") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 110px;" onclick="">' + __("Refresh") + '</button>';
		html += '		</div>';
		html += '	</div>';

		// Clear
		html += '<div style="position: absolute; top: 65px; left: 120px; width:50px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 28px;">';
		html +='		<button id="file_bt_clear" title="' + __("Clear") +'" data-label="Search" class="btn btn-default ellipsis" style="height: 28px; width: 50px;" onclick="">' + __("...") + '</button>';
		html += '	</div>';
		html += '</div>';

		// Export
		html += '<div style="position: absolute; top: 65px; left: 180px; width:160px;">';
		html += '	<div style="position: absolute; top:0px; left: 2px; height: 28px;">';
		html +='		<button id="file_bt_export" title="' + __("Export") +'" data-label="Export" class="btn btn-default ellipsis" style="height: 28px; width: 160px;" onclick="">' + __("Export") + '</button>';
		html += '	</div>';
		html += '</div>';

		html += '<input id="file_orderby" type="hidden" value="file_name">';
		html += '<input id="file_order" type="hidden" value="asc">';

		html += '</div>';

		let is_dark_mode = document.documentElement.getAttribute("data-theme") == "dark" ? 1 : 0;

		// Grid Header
		html += '<div id="file_grid_header" style="overflow: auto; overflow-x: auto; height:600px;">';

		html += '<table id="file_grid" border=1 style="border: 1px solid #E8EAEB" width=1180px data-custom-grid="true">';

		html += `<tr style="height:30px">`;

		html += '<td width=30px align="center" style="vertical-align: middle;">';
		html += '<input type="checkbox" id="check_all_none" style="postion:absolute; top: 2px; left: 2px;" ';
		html += '       onclick=" ';
		html += '					for (var i = 0; i < document.getElementById(\'file_grid_detail\').rows.length; i++) ';
		html += '					{	';
		html += '						if (document.getElementById(\'file_checked_id_\' + i.toString())) ';
		html += '						{ ';
		html += '							document.getElementById(\'file_checked_id_\' + i.toString()).checked = this.checked; ';
		html += '						} ';
		html += '					} ';
		html += '                " ';
		html += '>';
		html += '</td>';

		html += '<td id="file_col_file_name"			width=350px style="vertical-align: middle;"><b>&nbsp;' + __("Filename") 		 + '<label id="file_col_label_file_name" 		  style="width:30px; height:8px" align="right">&darr;</label></b></td>';
		html += '<td id="file_col_is_private"			width=100px style="vertical-align: middle;"><b>&nbsp;' + __("Type") 		 	 + '<label id="file_col_label_is_private" 		  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="file_col_file_url"				width=400px style="vertical-align: middle;"><b>&nbsp;' + __("Path") 		 	 + '<label id="file_col_label_file_url" 		  style="width:30px; height:8px" align="right"></label></b></td>';
		html += '<td id="file_col_user_tags"			width=300px style="vertical-align: middle;"><b>&nbsp;' + __("Tag(s)") 		 	 + '<label id="file_col_label_user_tags" 		  style="width:30px; height:8px" align="right"></label></b></td>';

		html += '</tr>';
		html += '</table>';

		// Result
		html += this.#files_empty_table_row();

		html += '</div>'


		html += '<div id="file_actions" style="height:60px;">';

		html += '	<div id="file_bt_new"><br>';
		html += '		<button data-label="Add file" class="btn btn-default ellipsis" style="position:relative;left:3px;">Add file</button>';
		html += '	</div>';

		html += '	<div id="file_bt_delete">';
		html += '		<button data-label="Delete file" class="btn btn-default ellipsis" style="position:relative;top:-36px;left:100px;">Delete</button>';
		html += '		<br>';
		html += '	</div>';

		html += '</div>';

		let me = this;


		this.frm.fields_dict['html_module_files'].$wrapper.empty();
		this.frm.fields_dict['html_module_files'].$wrapper.append(html);


		let fct_search = function() { me.#files_search(); };
		let fct_clear = function() { me.#files_clear(); };
		let fct_export = function() { me.export_file_data(); };


		document.getElementById('file_search').onkeydown = this.#files_search_key_down;
		document.getElementById('file_tp').onchange = this.#files_search_on_change;

		document.getElementById('file_topn').onkeydown = this.#files_search_key_down;
		document.getElementById('file_topn').onchange = this.#files_search_on_change;


		document.getElementById('file_bt_search').onclick = fct_search;
		document.getElementById('file_bt_clear').onclick = fct_clear;
		document.getElementById('file_bt_export').onclick = fct_export;

		let fct_col_click = function () { me.#files_col_click(this); };

		document.getElementById('file_col_file_name').onclick = fct_col_click;
		document.getElementById('file_col_is_private').onclick = fct_col_click;
		document.getElementById('file_col_file_url').onclick = fct_col_click;
		document.getElementById('file_col_user_tags').onclick = fct_col_click;


		let fct_col_over = function () { me.#files_col_mouse_over(this); };

		document.getElementById('file_col_file_name').onmouseover = fct_col_over;
		document.getElementById('file_col_is_private').onmouseover = fct_col_over;
		document.getElementById('file_col_file_url').onmouseover = fct_col_over;
		document.getElementById('file_col_user_tags').onmouseover = fct_col_over;


		let fct_col_leave = function () { me.#files_col_mouse_leave(this); };

		document.getElementById('file_col_file_name').onmouseleave = fct_col_leave;
		document.getElementById('file_col_is_private').onmouseleave = fct_col_leave;
		document.getElementById('file_col_file_url').onmouseleave = fct_col_leave;
		document.getElementById('file_col_user_tags').onmouseleave = fct_col_leave;


		let fct_new_click = function ()	{ me.#files_upload_file(); };

		document.getElementById('file_bt_new').onclick = fct_new_click;

		let fct_delete_click = function ()	{ me.#files_delete_file(); };

		document.getElementById('file_bt_delete').onclick = fct_delete_click;

	}

	// ***************************************************************************************************************************************
	// Files : Empty table row
	// ***************************************************************************************************************************************
	#files_empty_table_row()
	{
		let html = '';
		html += '<table id="file_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1180px>';
		html += '<tr style="height:30px">';
		html += '<td width=30px  style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=100px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
		html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';

		html += '</tr>';
		html += '</table>';

		return html;
	}

	// ***************************************************************************************************************************************
	// Files clear params & list
	// ***************************************************************************************************************************************
	#files_clear()
	{
		document.getElementById('file_search').value = '';
		document.getElementById('file_topn').value = '100';
		document.getElementById('file_tp').selectedIndex = 0;

		if (document.getElementById('file_grid_detail'))
		{
			for (var i = 0; i < document.getElementById('file_grid_detail').rows.length; i++)
			{
				if (document.getElementById('file_id_' + i.toString()))
				{	document.getElementById('file_id_' + i.toString()).remove();
				}

				if (document.getElementById('file_name_' + i.toString()))
				{	document.getElementById('file_name_' + i.toString()).remove();
				}

				if (document.getElementById('file_url_' + i.toString()))
				{	document.getElementById('file_url_' + i.toString()).remove();
				}

				if (document.getElementById('file_checked_id_' + i.toString()))
				{	document.getElementById('file_checked_id_' + i.toString()).remove();
				}
			}

			document.getElementById('file_grid_detail').remove();
		}

		let html = this.#files_empty_table_row();

		document.getElementById('file_grid_header').insertAdjacentHTML('beforeend', html);
	}


	// ***************************************************************************************************************************************
	// Files Search
	// ***************************************************************************************************************************************
	#files_search()
	{
		if (this.frm.is_new())
		{
			this.#files_clear();
			return false;
		}

		let file_extensions = []

		let method = this.path_event_todo_file + '.module_files_get_extensions';

		frappe.call({  	method: method,
						args: {	},
						async: false,
						callback:function(r)	{

							if (r.message.length > 0) {

								for (var i = 0; i < r.message.length; i++) {
									file_extensions[file_extensions.length] = r.message[i];
								}

							}
						}
		});



		let html = '';
		let me = this;
		method = this.path_event_todo_file + '.module_files_get_data';


		frappe.call({  	method: method,
						args: {	"doctype": this.frm.doctype,
								"name" : this.frm.doc.name,
								"search": document.getElementById('file_search').value,
								"tp": document.getElementById('file_tp').value,
								"topn" : document.getElementById('file_topn').value,
								"orderby": document.getElementById('file_orderby').value,
								"order": document.getElementById('file_order').value
						},
						async: true,
						callback:function(r)	{
													if (document.getElementById('file_grid_detail'))
													{
														for (var i = 0; i < document.getElementById('file_grid_detail').rows.length; i++)
														{
															if (document.getElementById('file_id_' + i.toString()))
															{	document.getElementById('file_id_' + i.toString()).remove();
															}

															if (document.getElementById('file_name_' + i.toString()))
															{	document.getElementById('file_name_' + i.toString()).remove();
															}

															if (document.getElementById('file_url_' + i.toString()))
															{	document.getElementById('file_url_' + i.toString()).remove();
															}

															if (document.getElementById('file_checked_id_' + i.toString()))
															{	document.getElementById('file_checked_id_' + i.toString()).remove();
															}
														}

														document.getElementById('file_grid_detail').remove();
													}


													if (r.message.length > 0)
													{
														let cpt = 0;

														html += '<table id="file_grid_detail" border=1 style="border: 1px solid #E8EAEB" width=1180px>';

														for (var i = 0; i < r.message.length; i++)
														{

															html += '<tr style="height:30px">';

															html += '<td width=30px align="center" style="vertical-align: middle;">';
															html += '<input type="checkbox" id="file_checked_id_' + i.toString() +'" style="postion:absolute; top: 2px; left: 2px;">';
															html += '</td>';

															if (r.message[i].file_name != null)
															{
																html += '<td width=350px style="vertical-align: middle;">';

																let s = r.message[i].file_name;
																let idx = s.lastIndexOf('.');

																if (idx != -1)
																{
																	s = s.substring(idx+1, s.length);

																	if (s.trim() != '')
																	{
																		//let res = me.checkFileExist(window.location.protocol+'://' + window.location.host + me.imgextpath + 'file_extension_' + s + '.png');

																		let res = false

																		for (var k = 0; k < file_extensions.length; k++) {

																			if (file_extensions[k] == 'file_extension_' + s + '.png') {
																				res = true;
																				break;
																			}
																		}

																		if (res == true)
																		{	html += '<img src="' + me.imgextpath + 'file_extension_' + s + '.png" width="32px" height="32px"></img>';
																		} else
																		{	html += '<img src="' + me.imgextpath + 'file_extension_none.png" width="32px" height="32px"></img>';
																		}
																	}
																}

																html+= '&nbsp;';
																if (r.message[i].file_url.includes('http') || r.message[i].file_url.includes('https')){
																	html += `<a onclick="window.open(\'${r.message[i].file_url}\');">${r.message[i].file_name}</a>`;
																}
																else {
																	html += `<a onclick="window.open(window.location.protocol+\'${r.message[i].file_url}\');">${r.message[i].file_name}</a>`;
																}
																html += '</td>';
															}else
															{	html += '<td width=350px style="vertical-align: middle;">&nbsp;</td>';
															}

															html += '<input type="hidden" id="file_id_' + i.toString() + '" value="' + r.message[i].name + '">';
															html += '<input type="hidden" id="file_name_' + i.toString() + '" value="' + r.message[i].file_name + '">';
															html += '<input type="hidden" id="file_url_' + i.toString() + '" value="' + r.message[i].file_url + '">';

															if (r.message[i].is_private == 1)
															{
																html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Private") + '</td>';
															}else
															{	html += '<td width=100px style="vertical-align: middle;">&nbsp;' + __("Public") + '</td>';
															}

															if (r.message[i].file_url != null) {

																let path = r.message[i].file_url;
																path = path.substring(0, path.indexOf(r.message[i].file_name))

																html += '<td width=400px style="vertical-align: middle;">&nbsp;' + path + '</td>';
															}else{
																html += '<td width=400px style="vertical-align: middle;">&nbsp;</td>';
															}

															if (r.message[i].user_tags != null) {
																html += '<td width=300px style="vertical-align: middle;">&nbsp;' + r.message[i].user_tags + '</td>';
															}else{
																html += '<td width=300px style="vertical-align: middle;">&nbsp;</td>';
															}


															html += '</tr>';
														}
														html += '</table>';
													}
													else
													{	html += me.#files_empty_table_row();

													}

													document.getElementById('file_grid_header').insertAdjacentHTML('beforeend', html);

												}
		});

	}

	checkFileExist(urlToFile)
	{
		var xhr = new XMLHttpRequest();
		xhr.open('GET', urlToFile, false);
		xhr.send();

		if (xhr.status == "404")
		{	return false;
		}else
		{	return true;
		}
	}

	// ***************************************************************************************************************************************
	// Files Search
	// ***************************************************************************************************************************************
	#files_search_key_down(event)
	{
		if (event.keyCode == 13)
		{
			document.getElementById('file_bt_search').click();
		}
	}

	// ***************************************************************************************************************************************
	// Files Search
	// ***************************************************************************************************************************************
	#files_search_on_change()
	{
		document.getElementById('file_bt_search').click();
	}


	// ***************************************************************************************************************************************
	// Column header click
	// ***************************************************************************************************************************************
	#files_col_click(obj)
	{
		let s = obj.id;

		s = s.substring(9, obj.id.length);

		document.getElementById('file_col_label_file_name').innerHTML = '';
		document.getElementById('file_col_label_is_private').innerHTML = '';
		document.getElementById('file_col_label_file_url').innerHTML = '';
		document.getElementById('file_col_label_user_tags').innerHTML = '';

		if (document.getElementById('file_orderby').value == s)
		{
			if (document.getElementById('file_order').value == 'desc')
			{	document.getElementById('file_order').value = 'asc';

				document.getElementById('file_col_label_' + s).innerHTML = '&darr;';
			}else
			{	document.getElementById('file_order').value = 'desc';
				document.getElementById('file_col_label_' + s).innerHTML = '&uarr;';
			}
		}else
		{	document.getElementById('file_orderby').value = s;
			document.getElementById('file_order').value = 'desc';
			document.getElementById('file_col_label_' + s).innerHTML = '&uarr;';
		}


		document.getElementById('file_bt_search').click();
	}

	// ***************************************************************************************************************************************
	// Column header mouse over
	// ***************************************************************************************************************************************
	#files_col_mouse_over(obj)
	{
		obj.style.cursor = 'pointer';
	}

	// ***************************************************************************************************************************************
	// Column header mouse leave
	// ***************************************************************************************************************************************
	#files_col_mouse_leave(obj)
	{
		obj.style.cursor = 'none';
	}

	// ***************************************************************************************************************************************
	// Files : Upload file
	// ***************************************************************************************************************************************
	#files_upload_file()
	{
		let me = this;

		new frappe.ui.FileUploader({
									doctype: me.frm.doctype,
									docname: me.frm.docname,
									frm: me.frm,
									folder: 'Home/Attachments',
									on_success: (file_doc) => 	{
																	document.getElementById('file_bt_search').click();
																	me.frm.sidebar.reload_docinfo();
																},
									make_attachments_public: cur_frm.meta.make_attachments_public
		});
	}

	// ***************************************************************************************************************************************
	// Files : Delete file
	// ***************************************************************************************************************************************
	#files_delete_file()
	{
		let me = this;

		if (document.getElementById('file_grid_detail').rows.length <= 1)
		{
			if (document.getElementById('file_grid_detail').rows.length == 0)
			{	return false;
			}else
			{
				if (!document.getElementById('file_checked_id_0'))
				{
					return false;
				}
			}
		}

		let names = '';

		for (var i = 0; i < document.getElementById('file_grid_detail').rows.length; i++)
		{
			if (document.getElementById('file_checked_id_' + i.toString()))
			{
				if (document.getElementById('file_checked_id_' + i.toString()).checked)
				{
					names += document.getElementById('file_id_' + i.toString()).value + ';';
				}
			}
		}

		if (names.trim() != '')
		{
			let msg = 'Delete selected file(s) ?'

			frappe.confirm(	msg,
				() => 	{
							// action to perform if Yes is selected
							document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary')[document.getElementsByClassName('btn btn-primary btn-sm btn-modal-primary').length-1].disabled = true;

							let method = this.path_event_todo_file + '.module_files_delete';

							frappe.call({  	method: method,
											args: {"names" : names},
											async: false,
											callback:function(r)	{
												document.getElementById('file_bt_search').click();
												me.frm.sidebar.reload_docinfo();
											}
							});
						},
				() => 	{
							// action to perform if No is selected or if click outsite the modal screen
						}
			);
		}
	}

	load_function_menu()
	{
		if (!this.frm.is_new()) {

			let data = [];

			let method = 'silicon_ioi.ioi_configuration.doctype.ioi_module_function.ioi_module_function.ioi_module_function_get_functions';

			frappe.call({  	method: method,
							args: {"doctype": this.frm.doctype},
							async: false,
							callback:function(r)	{

								if (r.message.length > 0) {
									data = r.message;
								}

							}
			});

			this.frm.remove_custom_button(__("Functions"));

			if (data.length > 0) {

				let me = this;

				for (var i = 0; i < data.length; i++) {

					let id = data[i].name;

					this.frm.add_custom_button(__(data[i].description),  function() { me.user_function_menu_execute(id); }, __("Functions"));
				}
			}
		}

	}

	user_function_menu_execute(name)
	{
		if (this.frm.is_dirty())
		{
			let me = this;
			let fct_callback = function () { me.do_user_function_menu_execute(name); };
			this.frm.save('Save', fct_callback);
		}else
		{
			this.do_user_function_menu_execute(name);
		}
	}

	do_user_function_menu_execute(name)
	{
		let data = {};

		let method = 'silicon_ioi.ioi_configuration.doctype.ioi_module_function.ioi_module_function.ioi_module_function_execute_function';

		frappe.call({  	method: method,
						args: {	"module_function_id": name,
								"document_name" : this.frm.doc.name
						},
						async: false,
						callback:function(r)	{
							data = r.message;
						}
		});

		if (data.error == 1) {
			frappe.msgprint({title: __("Message"), message: data.error_msg, indicator: "red"});
			return false;
		}

		if (data.function_type == 1) {
			window.eval(data.script_js);
		}else{
			//window.location.reload();
		}

	}

	get_general_settings_parameters(key, doctype = '')
	{
		let method = this.path_general_settings + '.get_parameter_value';
		let data = null
		frappe.call({  	method: method,
						args: {'key': key, 'doctype': doctype},
						async: false,
						callback:function(r){
								data = r.message
						}
		})
		return data
	}


}


silicon_ioi.doctype.ioiDocType = ioiDocType;
